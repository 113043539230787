@import (reference) "../Theme/Common/_common.less";

.auto-complete-menu {
    padding: 5px 0;
    margin: 0;
    border: 1px solid @COLOR-TNL-GRAY4;
    position: absolute; 
    z-index: 10001; 
    background-color: #fff; 
}
.auto-complete-menu-list {
    padding: 0;
    margin: 0;
}

.auto-complete-menu-item {
    padding: 5px 0 5px 12px;
    .LESS-ELLIPSIS-ONE-LINE();
    display: block;
    a {
        text-decoration: none;
    }
    .auto-complete-description {
        color: @COLOR-TNL-GRAY3;
        margin: 0 3px 0 5px;
    }
    .auto-complete-title{
        font-weight: bold;
    }
}
