@font-face {
    font-family: 'tnl-custom-icons';
    src: url('fonts/tnl-custom-icons.eot');
    src: url('fonts/tnl-custom-icons.eot?#iefix') format('embedded-opentype'),
         url('fonts/tnl-custom-icons.woff') format('woff'),
         url('fonts/tnl-custom-icons.ttf') format('truetype'),
         url('fonts/tnl-custom-icons.svg#tnl-custom-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="tnlif-"],
[class*=" tnlif-"] {
    font-family: 'tnl-custom-icons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.tnlif-status-not-started:before {
    content: "\e616";
}

.tnlif-status-in-progress:before {
    content: "\e617";
}

.tnlif-status-inactive:before {
    content: "\e618";
}

.tnlif-status-complete:before {
    content: "\e619";
}

.tnlif-program-structure:before {
    content: "\e900";
}

.tnlif-program-dynamic-relationship:before {
    content: "\e901";
}

.tnlif-draggable-vertical:before {
    content: "\e903";
}

.tnlif-draggable-horizontal:before {
    content: "\e902";
}