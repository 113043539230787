@import (reference) "../Theme/Common/_common.less";

.tnl-rating-table {
    width: 100%;
    border: 1px solid @COLOR-TNL-GRAY3;

    th { 
        padding: 10px;
        color: @COLOR-TNL-GRAY1;
        text-align: center;
        background: @COLOR-TNL-GRAY5;
        border: 1px solid @COLOR-TNL-GRAY3;
    } 
    td {
        padding: 10px;
        border: 1px solid @COLOR-TNL-GRAY3;
    }
}

.tnl-workflow-popover-checkall {
    white-space: nowrap;
    border-bottom: 1px solid #CCC; 
    margin-bottom: 5px; 
    padding-bottom: 5px;
}

.tnl-workflow-popover-footer {
    border-top: 1px solid #ccc; 
    margin-top: 5px; 
    padding-top: 8px;
}

.tnl-workflow-search-padded {
    margin-top: 10px; margin-bottom: 8px;
}

.field-group-header {
    padding-top: 6px;
    padding-bottom: 10px;
}

.tnl-user-name-group {
    white-space: nowrap;
}
.tnl-user-pic {
    display: inline-block;
    .LESS-ROUNDED-CORNERS();
    margin-right: 8px;
}
.text-word-break {
    word-break: break-all;
    -ms-word-break: break-all;
}