@import (reference) "_pm-global.less";

// === PM Table Grid Styles === //
// == 10/5/2017 - Chris Brosky == //

.pm-table-grid {

  table {
    margin: 10px 0 10px;

    &.dataTable {
      .sorting {
        background: url("../static/images/pm/table-grid/sort-both-pm.png") no-repeat center right !important;
      }
      .sorting_asc {
        background: url("../static/images/pm/table-grid/sort-asc-pm.png") no-repeat center right !important;
      }
      .sorting_desc {
        background: url("../static/images/pm/table-grid/sort-desc-pm.png") no-repeat center right !important;
      }

      tr.odd td.sorting_1 {
        background-color: #f5f5f5;
      }

      tr.even td.sorting_1 {
        background-color: #fff;
      }
    }
  }

  .dataTables_filter,
  .dataTables_length {
    margin-bottom: 5px !important;
  }

  .dataTables_info,
  .dataTables_paginate {
    margin-top: 5px !important;
  }

  thead {
    background-color: @COLOR-PM-GREY7;

    tr {
      th {
        color: @COLOR-PM-WHITE1;
        font-size: @COS-FONT-SIZE-12;
        text-transform: uppercase;
        padding: 15px 15px 15px 10px !important;
        border-bottom: none !important;
        .COS-OPEN-SANS-REGULAR();
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 5px 10px;

        .pm-icon-sm,
        .pm-icon-svg {
          position: relative;
          top: 2px;
        }

        .pm-btn-group {
          margin-right: 10px;

          &:last-child {
            margin-right: 0;
          }

          &.pm-icon-action-btns {
            .pm-action-btn {
              .pm-icon-sm {
                height: 2.3rem;
                width: 2.3rem;

                .pm-icon-font {
                  &:before {
                    font-size: 23px;
                  }
                }
              }
            }
          }
        }

        .pm-checkbox-container {
          position: relative;
          top: 0;
          left: 0;

           .pm-checkbox.vertical {
             margin-bottom: 0;
           }
        }
      }
    }
  }
}

// MANAGE ROSTER CREDIT PAGES //
.cos-manage-roster {
    .row {
        margin-bottom: 15px;
    }

    .cos-back-icon {
        padding-left: 0;
    }

    .pm-btn-sm {
        padding: 5px 16px 5px 0;
    }

    .cos-zero-left {
        padding-left: 0 !important;
        margin-left: 0 !important;
    }

    .cos-table-responsive {
        margin-bottom: 20px;
    }

    .cos-td-input-field {
        padding: 10px !important;
    }
}

// ---=== BOOTSTRAP RESPONSIVE BREAKPOINTS ===--- //
// Screens greater than 992px //
@media (min-width: @PM-BOOTSTRAP-MD) {
}

// Screens between 768px and 992px //
@media (min-width: @PM-BOOTSTRAP-SM) and (max-width: @PM-BOOTSTRAP-MD ) {
}

// Screens smaller than 992px //
@media (max-width: @PM-BOOTSTRAP-MD) {
  .pm-table-grid {
    table,
    thead,
    tbody,
    th,
    td,
    tr  {
      display: block;
    }

    .dataTables_length {
      margin-left: 10px !important;
    }

    .dataTables_filter {
      width: 100% !important;
      float: left !important;
      text-align: left !important;
      margin: 10px 0 5px 10px !important;
    }

    .dataTables_paginate {
      margin: 5px 10px 5px 5px !important;
    }

    .dataTables_info {
      margin: 5px 5px 5px 10px !important;
    }

    table {
      &.dataTable {

        tr.odd td.sorting_1 {
          background-color: #f5f5f5;
        }

        tr.even td.sorting_1 {
          background-color: #fff;
        }
      }
    }

    thead {
      tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }
    }

    tr {
      border: 1px solid #ccc;
    }

    tbody {
      tr {
        td {
          .pm-icon-sm,
          .pm-icon-svg {
            top: 0;
          }
        }
      }
    }

    /* --== BEHAVE LIKE A ROW ==-- */
    td {
      border: none !important;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50% !important;
      white-space: normal;
      text-align: left;

      /* --== LABEL THE DATA ==-- */
      &:before {
        content: attr(data-title);
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align:left;
        font-weight: bold;
      }
    }

    .table-bordered {
        border: none !important;
    }
  }
}

// Screens smaller than 768px //
@media (max-width: @PM-BOOTSTRAP-SM) {
}

// .tnl-table-container header print fix //
@media print {
  .tnl-table-container {
    table {
      &.tnl-table {
        thead th {
          color: #000 !important;
          font-weight: 700;
          background-color: #fff !important;
        }
      }
    }
  }
}