@import (reference) "../Theme/Common/_common.less";

.tnl-site-sub-nav {
    margin-top: 0;
    padding: 10px 10px 30px;

    .nav-pills > li {
        height: auto;
    }
    .nav-pills > li.active > a,
    .nav-pills > li.active > a:hover,
    .nav-pills > li.active > a:focus {
        background: @COLOR-PM-BLUE-MED;
    }
}