@import (reference) "../Theme/Common/_common.less";

.tnl-table-filters {
    margin: 10px 0;

    .tnl-show-hide-filters-button {
        font-size: @FONT-SIZE-SM;
    }
    .tnl-filter-row {
        padding: 2px 0 3px;

        &:last-child {
            padding-bottom: 0;
        }
        .tnl-filter {
            margin-left: 10px;
            float: left;
        }
        .tnl-filter-remove-button {
            cursor: pointer;
        }
        .tnl-filter-remove-button:hover {
            text-decoration: none;
        }

        a,
        button,
        div,
        select,
        span {
            vertical-align: middle;
        }
    }
}

.tnl-filter-row {
  .pm-remove-filter-icon {
    position: relative;
    top: 4px;
  }
}

.tnl-sort,
.tnl-filter,
.tnl-exclusion {
    position: relative;
    cursor: pointer;
    padding: 0;
}
