@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

.nav-pills {

    > li {
        padding: 0 10px 0 0;
        height: 50px;
        
        > span {
            border-radius: 4px;
            display: block;
            padding: 10px 15px;
            position: relative;

            > label {
                font-weight:400 !important;

                .neg {
                    color: @COLOR-DANGER;
                }
                .pos {
                    color: @COLOR-PM-GREEN;
                }
            }

        }
    }

    > li.active > a,
    > li.active > a:hover,
    > li.active > a:focus,
    > li.active > span,
    > li.active > span:hover,
    > li.active > span:focus {
        background: @COLOR-PM-BLUE-MED;

        .icon-font {
            color: #fff;
        }
    }

    .dropdown {
        border: none;
    }

    &.nav-stacked {
        li+li {
            margin-top: 0;
        }
        > li {
            height: auto;
        }
        .tnl-nav-header {
            height: 24px;
            margin-top: 20px;
            color: @COLOR-TNL-GRAY3;
            font-size: @FONT-SIZE-SM;
            .COS-OPEN-SANS-600();
    
            &:first-child {
                margin-top: 0;
            }
        }
        .tnl-nav-sub-header {
            height: 24px;
            .COS-OPEN-SANS-600();
        }
    }

    &.tnl-tab-small {

        > li  {
            height: auto;
            padding: 0 1px 0 0;

            > a {
                font-size: 12px;
                padding: 5px 5px;
            }
        }
    }

    > li.active > span > label,
    > li.active > span > label .neg,
    > li.active > span > label .pos {
        color: #fff;
        font-weight:600 !important;
    }
           

}