@import (reference) "_pm-global.less";

.cos-login-page {
    background-color: transparent !important;

    body {
        border: 0 !important;
    }

    .cos-login-panel {
        width: 88% !important;
        max-width: 950px !important;
        background-color: @COLOR-PM-WHITE1;
        padding: 20px 20px 10px;

        h2 {
            margin: 0 0 10px;
        }
    }

    .cos-main-login {
      margin-top: 5%;
      border: 1px solid @COLOR-PM-GREY5;
      .PM-ROUNDED-CORNERS();

      .form-horizontal {
        .form-group {
          margin-bottom: 15px;

          &:nth-child(2) {
            margin-bottom: 0 !important;
          }
        }
      }
    }

    .cos-background-container {
        background-size: cover;
        background-position: top center !important;
        background-repeat: no-repeat !important;
        background-attachment: fixed;
    }

    .cos-icon-container {
        position: relative;

        .form-control {
            padding-left: 32px;
        }

        .cos-login-icon {
            position: absolute;
            top: 9px;
            left: 20px;
            color: @COLOR-COS-DEFAULT;
            font-size: @COS-FONT-SIZE-16;
        }
    }

    .has-success {
        .cos-login-icon {
            color: @COLOR-COS-DEFAULT;
        }
    }

    .has-error {
        .cos-login-icon,
        .help-block {
            color: @COLOR-PM-RED1;
        }
    }

    .btn-help {
        position: absolute;
        top: 0;
        right: -36px;
        background-color: transparent;

        .btn-help .fa {
            font-size: @COS-FONT-SIZE-18;
        }
    }

    .copyright {
        padding: 10px 0;
        font-size:@COS-FONT-SIZE-12;

        .cos-login-footer-text {
            height: 17px;
            line-height: 17px;
            color: @COLOR-COS-DEFAULT;
            font-size: @COS-FONT-SIZE-12;
        }
    }

    .cos-survey .fa {
        font-size: 20px;
    }

    header {
        margin-bottom: 20px;
    }

    legend {
        border: none;
    }

    .help-msg-text {
        color: @COLOR-COS-DEFAULT;
    }
}

@media (max-width: 768px) {
    .cos-login-page {
        .cos-icon-container {
            padding: 0 !important;

            .cos-login-icon {
                left: 10px !important;
            }
        }

        .cos-login-btn {
            padding-left: 0 !important;
        }
    }
}
