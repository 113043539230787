@import (reference) "../Theme/Common/_common.less";

.tnl-notes-note-list {
	font-size: 12px;
}
.tnl-notes-note-list > li {
	border: 1px solid #999;
	margin: 0 0 10px 0;
	padding: 15px;
}	
.tnl-notes-edit-panel {
	margin: 10px 0;
	padding: 10px;
	box-shadow: 0 0 4px rgba(255,153,0,0.5);
	border: 1px solid #f90;
}
.tnl-notes-title, .tnl-notes-title-edit {
	float: left;
	font-size: 10px;
	color: #666;
}
.tnl-notes-title-edit {
	margin: 0 0 5px 0;
}
.tnl-notes-menu {
	float: right;
	display: inline-block;
	margin-bottom: 10px;
}
.tnl-notes-note-content {
	clear: both;
    color: #666;
}
.tnl-notes-tagged  {
	color: #000;
}
.tnl-notes-not-tagged  {
	color: #666;
	margin: 10px 0;
	padding: 10px;
	border: 2px solid #fff;
}
.tnl-notes-tagged, .tnl-notes-not-tagged, .tnl-notes-tagged-menu  {
    padding: 8px;
	line-height: 18px;
	cursor: pointer;
}
.tnl-notes-tagged > .tnl-notes-tagged {
	background: none;
}.tnl-notes-note-list

.tnl-notes-tagged:hover, .tnl-notes-not-tagged:hover, .tnl-notes-tagged-highlight {
	color: #000;
}
.tnl-notes-tagged-no-highlight {
	border-color: #fff !important;
}
.tnl-notes-tag, .tnl-notes-selector-tag {
	display: inline-block;
	margin: 2px;
	padding: 0 20px 0 4px;
	color: #000;
	font-weight: bold;
	text-transform: uppercase;
    background-image: url(../images/close2.gif);
    background-repeat: no-repeat;
    background-position: right;
	border: 2px solid #fff;
	border-radius: 4px;
	cursor: pointer;
	line-height: 28px;
}
.tnl-notes-tagged-menu {
	display: inline-block;
	width: 30px;
    height: 10px;
	background: url(../images/down-arrow.gif) no-repeat center;
}
.tnl-notes-tagged-menu:hover {
	background: url(../images/down-arrow.gif) no-repeat center;
}

.tnl-notes-tagged > .tnl-notes-tag:hover,
.tnl-notes-tag-selector-list .tnl-notes-tag-selector-q span:hover,
.tnl-notes-tag-selector-grid .tnl-notes-tag-selector-sub > li > span:hover {
	background-color: #fff !important;
}
.tnl-notes-tag-selector-list .tnl-notes-tag-selector-sub > li > span,
.tnl-notes-tag-selector-grid .tnl-notes-tag-selector-sub > li > div span {
	cursor: default;
}
.tnl-notes-tag,
.tnl-notes-tagged-left,
.tnl-notes-tagged-right,
.tnl-notes-menu,
.tnl-notes-selector-tag {
	font-size: 10px;
}
.tnl-notes-add-input,
.tnl-notes-edit-panel textarea {
	width: 99%;
    /* enable auto-height adjust, per http://stackoverflow.com/a/8522283/120955 */
    overflow-y: hidden; /* fixes scrollbar flash */
    padding-bottom: 1.1em; /* fixes text jump on Enter keypress */
}

.tnl-notes-edit-panel textarea {
	resize: vertical;
	margin: 0;
	padding: 0 0 1.1em 0;
}
.tnl-notes-new-panel,
.tnl-notes-edit-panel {
	text-align: right;
}
.tnl-notes-new-panel a, .tnl-notes-edit-panel a {
	display: inline-block;
	margin: 5px 5px 0;
	font-size: 11px;
}
.tnl-notes-note-list > li, .tnl-notes-tag-selector {
	box-shadow: 0 0 4px rgba(0,0,0,0.25);
}
.tnl-notes-tagged, .tnl-notes-tagged-no-highlight,
.tnl-notes-tag, .tnl-notes-tagged:hover,
.tnl-notes-tagged-highlight,
.tnl-notes-selector-tag { 
	box-shadow: 0 0 4px rgba(0,0,0,0.75);
}
.tnl-notes-tagged, .tnl-notes-tagged-no-highlight, .tnl-notes-tagged-no-highlight { /* Gray Fade */
	background: -webkit-gradient(linear, 0 0, 0 100%, from(#ff9), to(#f5f5d4));
	background: -webkit-linear-gradient(top, #f5f5d4, #ff9);
	background: -moz-linear-gradient(top, #f5f5d4, #ff9);
	background: -ms-linear-gradient(top, #f5f5d4, #ff9);
	background: -o-linear-gradient(top, #f5f5d4, #ff9);
}
.tnl-notes-tagged:hover, .tnl-notes-not-tagged:hover, .tnl-notes-tagged-highlight { /* Yellow Fade */
	background: #ffc;
}
.tnl-notes-standard {
	margin: 0 0 20px;
}
.tnl-notes-standard h1, .tnl-notes-standard h2, .tnl-notes-standard h3 {
	margin: 0;
	padding: 0;
	color: #000;
}
.tnl-notes-standard h1 {
	margin-bottom: 10px;
	font-size: 18px;
	font-weight: bold;
	line-height: 18px;
	border-bottom: 1px dashed #ccc;
}
.tnl-notes-standard h2 {
	font-size: 15px;
	line-height: 18px;
}
.tnl-notes-standard h3 {
	font-size: 12px;
	font-weight: normal;
	line-height: 18px;
	background: #efefef;
}
.tnl-notes-standard .tnl-notes-standard, .tnl-notes-standard h2, .tnl-notes-standard h3 {
	display: inline;
}
.tnl-notes-standard-sub, .tnl-notes-question {
	margin: 0 0 10px 0;
}
.tnl-notes-standard-sub div, .tnl-notes-question div {
	display: inline-block;
	width: 70px;
	text-align: right;
}
.tnl-notes-standard .tnl-notes-panel {
	margin: 0 0 20px 75px;
}
.tnl-notes-standard .tnl-notes-standard-sub .tnl-notes-tag, .tnl-notes-standard .tnl-notes-question .tnl-notes-tag {
	width: 30px;
    background-image: none;
}
.tnl-notes-header-tags {
	float: right;
	margin: 0 10px;
	padding: 0 6px 0 0;
	color: #666;
	font-size: 10px;
	border-right: 1px solid #ccc;
}
.tnl-notes-standard .tnl-notes-note-header .tnl-notes-tag {
	margin: 4px 4px 0;
	padding: 0 20px 0 5px;
	line-height: 18px;
}
.tnl-notes-standard .tnl-notes-tag:hover {
	cursor: default;
}

.tnl-notes-question {
	background: #efefef;
	margin-left: 75px;
}
.tnl-notes-question span, .tnl-notes-question h3 {
	position: relative;
	left: -75px;
}
.tnl-notes-question h3 {
	position: relative;
	left: -70px;
}
.tnl-notes-tag-selector {
	padding: 20px;
	width: 600px;
	text-align: right;
	background: #fff;
	border: 1px solid #999;
	border-radius: 4px;
}
.tnl-notes-tag-selector > div {
	overflow: auto;
	height: 310px;
}
.tnl-notes-tag-selector > span {
	margin: 0 4px 4px 0
}
.tnl-notes-tag-selector > h1 {
	float: left;
	display: inline-block;
	margin: 0;
	padding: 0;
	color: #666;
	font-size: 11px;
	font-weight: normal;
}
.tnl-notes-tag-selector ul {
	margin: 0;
	padding: 0;
	text-align: left;
	list-style-type: none;
}
.tnl-notes-tag-selector ul li {
	margin: 0;
	padding: 4px 0;
}
.tnl-notes-tag-selector-grid .tnl-notes-tag-selector ul.tnl-notes-tag-selector-std > li {
	overflow: hidden;
	padding: 10px;
}
.tnl-notes-tag-selector-grid .tnl-notes-tag-selector-std {
	display: block;
}
.tnl-notes-tag-selector-grid ul.tnl-notes-tag-selector-sub {
	overflow: hidden;
	clear: both;
}
.tnl-notes-tag-selector-list ul.tnl-notes-tag-selector-sub > li > span {
	width: 40px;
	margin-left: 4px;
	font-weight: bold;
	text-align: center;
}
.tnl-notes-tag-selector-list ul.tnl-notes-tag-selector-sub > li > ul {
	margin-left: 60px;
}
.tnl-notes-tag-selector-grid ul.tnl-notes-tag-selector-sub > li {
	float: left;
	width: 168px;
}
.tnl-notes-tag-selector-grid ul.tnl-notes-tag-selector-sub > li div span {
	width: 152px;
	text-align: center;
}
.tnl-notes-tag-selector-std > li > span {
	font-size: 13px;
	font-weight: bold;
}
.tnl-notes-tag-selector-grid .tnl-notes-tag-selector-sub > li > span {
	width: 40px;
	font-weight: bold;
	text-align: center;
}
.tnl-notes-tag-selector-sub > li {
	font-size: 11px;
}
.tnl-notes-selector-tag {
	padding: 0 4px;
	background-image: none;
}
.tnl-notes-selector-tag.disabled {
    opacity: .5;
    cursor: default;
}
.tnl-icon-grid-view-sm {
	background: url(../images/tnl-icon-grid-view-sm.png) no-repeat;
}
.tnl-icon-list-view-sm {
	background: url(../images/tnl-icon-list-view-sm.png) no-repeat;
}

.tnl-notes-menu-wrapper {
    display: inline;
}
.tnl-notes-note-panel > a {
	margin-bottom: 10px;
	font-size: 11px;
	float: right;
}
.tnl-notes-note-panel > ul {
	clear: both;
}

.tag01 {
    background-color: #c93;
}
.tag02 {
	background-color: #ffff66;
}
.tag03 {
	background-color: #f6c;
}

/*No HTML 5 Support*/
html.lt-ie9 .tnl-notes-tagged, .lt-ie9 .tnl-notes-not-tagged {
	padding: 4px;
}
html.lt-ie9 .tnl-notes-tagged {
	border: 1px solid #ccc;
	border-right-width: 4px;
	border-left-width: 4px;
}
html.lt-ie9 .tnl-notes-tagged:hover, html.lt-ie9 .tnl-notes-not-tagged:hover  {
	background: #ff9;
	border-color: #fc3;
}
html.lt-ie9 .tnl-notes-tag, .lt-ie9 .tnl-notes-selector-tag {
	border: none;
}
/*overriding drop down menu*/
.tnl-notes-panel .tnl-menu-div, .tnl-notes-panel div.tnl-menu ul {
	box-shadow: 0 0 4px rgba(0,0,0,0.25);
}
.tnl-notes-panel a.tnl-btn-icon-text, .tnl-notes-panel a.tnl-btn-icon-text:visited {
	margin: 0;
	padding: 2px 12px 3px;
	color: #666; /*ideally this should be the cascaded link color*/
	font-size: 11px;
	font-weight: normal;
	background: #fff;
}
.tnl-notes-panel a.tnl-btn-icon-text:hover {
	text-decoration: underline !important;
}
.tnl-notes-panel a.tnl-btn-icon-text, .tnl-notes-panel .tnl-menu-btn, .tnl-notes-panel div.tnl-menu ul  {
	border: 1px solid #bbb;
}
.tnl-notes-panel div.tnl-menu ul {
	border-top: 0;
}
.tnl-notes-panel a.tnl-btn-icon-text {
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}
.tnl-notes-panel .tnl-menu-btn{
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
	border-left: none;
}
.tnl-notes-panel .tnl-menu-btn:hover {
	background: #bbb;
}
.tnl-notes-panel .tnl-menu-box {
	top: auto;
	left: auto;
	margin: 0;
	padding: 0;
	background-color: initial;
	border: none;
}
.tnl-notes-panel li > a, .tnl-notes-panel{
	background: none;
	border: none;
}
.tnl-notes-panel a.tnl-icon-text-link,
.tnl-notes-panel li > a.tnl-text-link {
	margin: 0;
	padding: 6px 12px;
	font-size: 11px;
}
.tnl-notes-panel a {
	color: #666; /*ideally this should be the cascaded link color*/
}
.tnl-notes-panel a:hover {
	text-decoration: underline;
}
.tnl-notes-panel .tnl-menu-btn,
.tnl-notes-panel .tnl-menu-div.open > .tnl-menu-btn {
	margin: 0;
	padding: 0 0 1px 0;
	background: #eee;
	background: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#eff1f2));
	background: -webkit-linear-gradient(top, #fff, #eff1f2);
	background: -moz-linear-gradient(top, #fff, #eff1f2);
	background: -ms-linear-gradient(top, #fff, #eff1f2);
	background: -o-linear-gradient(top, #fff, #eff1f2)
}
/*overriding popup panel*/

.tnl-notes-menu-wrapper div{
	border-color: #ccc;
}
.tnl-notes-menu-wrapper div {
	position: absolute;
	top: 20px;
	left: 0;
	width: 175px;
	margin: 0;
	padding: 0;
	font-size: 11px;
	z-index: 100;
	overflow: hidden;
	background: #fff;
	background: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#eff1f2));
	background: -webkit-linear-gradient(top, #fff, #eff1f2);
	background: -moz-linear-gradient(top, #fff, #eff1f2);
	background: -ms-linear-gradient(top, #fff, #eff1f2);
	background: -o-linear-gradient(top, #fff, #eff1f2);
	border-radius: 4px;
	border: 1px solid #ccc;  
	box-shadow: -1px 2px 4px rgba(0,0,0,0.25);
}
.tnl-notes-menu-wrapper li {
	padding: 0;
}
.tnl-notes-menu-wrapper li a {
	display: block;
	padding: 4px 8px;
	text-decoration: none;
}
.tnl-notes-menu-wrapper li a:hover {
	color: #fff;
	background: #ccc;
}
.tnl-notes-region-header-tags { /*TODO: Handle this better after demo */
	margin: 0 10px;
	padding: 0 6px 0 0;
	color: #666666;
	font-size: 10px;
	border-right: 1px solid #cccccc;
	display: inline-block;
}
.tnl-notes-region-header { /*TODO: Handle this better after demo */
	display: block;
	margin: -2px 0 5px 0;
	text-align: right;
}
.tnl-notes-region-header .tnl-menu-div { /*TODO: Handle this better after demo */
	line-height: 13px;
}
.tnl-notes-region-header span.tnl-notes-tag { /*TODO: Handle this better after demo */
	padding: 2px 20px 2px 4px;
	font-size: 10px;
	line-height: 13px;
}
.tnl-notes-note-header { /*TODO: Handle this better after demo */
	float: left;
	width: 100%;
	margin-bottom: 10px;
	border-bottom: 1px dashed #ccc;
	margin: 0;
}
