@import (reference) "_pm-global.less";

// === PM Form Element Styles === //
// == 9/25/2017 - Chris Brosky == //

// ---=== TEXT INPUT FIELDS ===--- //
.pm-text-input {
  .pm-required {
    color: @COLOR-PM-RED1;
    font-weight: bold;
  }
}

fieldset[disabled] select:hover,
fieldset[disabled] select,
select[disabled]:hover,
select[disabled] {
  border: 1px solid @COLOR-PM-GREY12 !important;
  background-color: @COLOR-PM-GREY4 !important;
  cursor: not-allowed;
  opacity: 1;

}

.form-group {
  &.pm-text-input {
    margin-bottom: 10px;
    max-width: 800px;

      input {
          padding: 0 10px 3px;
      }
  }

  &.pm-text-input,
  &.pm-text-area  {
    input,
    textarea.form-control {
      border-radius: 4px;
      font-size: 14px;
      font-weight: normal;
      color: @COLOR-COS-DEFAULT;
    }
  }

  label {
    &.error {
      margin: 3px 0 0 0;
      font-weight: normal !important;
    }
  }

  .validation-icon:before {
    font-family: "pm-font-icons";
    content: "";
    height: 1.6rem;
    width: 1.6rem;
    font-size: 1.6rem;
    display: inline-block;
    position: relative;
    top: -2.8rem;
    right: 1rem;
    float: right;
    color: transparent;
    transition: all .25s ease-in-out;
  }

  .error + .validation-icon {
    &:before {
      content: "\e958";
      color: @COLOR-PM-RED1;
      transition: all .5s ease-in-out;
    }
  }

  &.has-success .validation-icon {
    &:before {
      content: "\e916" !important;
      color: @COLOR-PM-GREEN4 !important;
      transition: all .5s ease-in-out;
    }
  }

  .pm-radio-btn {
    position: relative;
    top: 2px;
    margin-right: 5px;
  }

  .pm-radio-btn-label {
    margin-right: 10px;
  }

  .pm-date {
    &.pm-date-inline {
      display: inline-block;
    }
  }

  .pm-course-catalog-include {
    margin: 5px 0;
    padding-left: 18px;

    &.divider {
      padding-left: 0;
    }
  }

    select.form-control {
        padding: 0 10px 3px;
    }
}

// ---=== CHECKBOX & RADIO BUTTONS ===--- //
.pm-checkbox-container,
.pm-radio-container {
  label {
    input[type="checkbox"],
    input[type="radio"] {
        height: 0; // Hides default form input boxes
        width: 0;
        display: none;
    }

      &.pm-checkbox {
          position: relative;
          top: .25rem;
      }
  }
}

.pm-checkbox.checkbox-inline input[type="checkbox"],
.pm-checkbox input[type="checkbox"],
.pm-radio-btn input[type="radio"] {
  + .pm-cr > .pm-cr-icon {
    transform: scale(0);
    opacity: 0;
    transition: all .15s ease-in-out;
  }
}

.pm-checkbox.checkbox-inline input[type="checkbox"],
.pm-checkbox input[type="checkbox"],
.pm-radio-btn input[type="radio"] {
  &:checked {
    + .pm-cr > .pm-cr-icon {
      transform: scale(1);
      opacity: 1;
      transition: all .15s ease-in-out;
    }
  }
}

.pm-checkbox.checkbox-inline,
.pm-checkbox.vertical {
    .pm-cr .pm-cr-icon {
        color: #fff;
    }

    &.disabled {

        input[type=checkbox]:checked+span {
            box-shadow: none;
        }

        .pm-cr {
            .pm-cr-icon {
                color: @COLOR-PM-GREY5;
            }
        }
    }
}

.pm-checkbox-container .pm-checkbox,
.pm-radio-container .pm-radio-btn {
  font-weight: normal;

  label {
    padding-left: 0;

    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  .pm-cr {
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    border-radius: 4px;
    width: 20px;
    height: 20px;
    float: left;
    margin-right: .35em;
  }

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    opacity: 1 !important;
    color: @COLOR-PM-GREY5;

    &:hover {
      cursor: default !important;
    }
  }

  &.vertical {
    display: block;
  }

  &.checkbox-inline,
  &.radio-inline {
    padding-left: 0;
    margin-bottom: 10px;
  }
}

td {
	&>.pm-checkbox-container {
		label {
			&.pm-checkbox:not(.checkbox-inline) {
				display: table;
			}
		}
	}
}

.pmf-select-framework-form {
	.pm-checkbox-container {
		margin-left: 2em;
		
		& + a {
			position: absolute;
			left: 16px;
			top: 1px;
		}
	}
}

.pm-checkbox-container {
  .pm-checkbox {

    &.vertical {
        margin-bottom: 9px;

        input[type="checkbox"] {

            &:checked + span {
                box-shadow: none !important;
            }
        }
    }

    .pm-cr {
      .pm-cr-icon {
        position: absolute;
        font-size: 2em;
        line-height: 0;
        top: 50%;
        left: 50%;
        margin-left: -1.38rem;
      }
      
      & + text {
      	display: block;
      	margin-left: 27px;
        color: @COLOR-COS-DEFAULT;
      }
    }
    
    &.checkbox-inline {
    	input[type="checkbox"] {
    		margin-left: 0;
    	}
    }

    input[type="checkbox"] {

      &:disabled {
        + .pm-cr {
          border-radius: 4px;
          opacity: inherit !important;
          border: 1px solid @COLOR-PM-GREY12;
          background-color: @COLOR-PM-GREY4;
        }
      }

      &:not(:checked) {
        + span.pm-cr {
          border: 1px solid @COLOR-PM-GREY5;
          background: @COLOR-PM-WHITE1;
          border-radius: 4px;
        }
      }

      &:checked {
        + span.pm-cr {
            border-radius: 4px;
            background-color: @COLOR-PM-BLUE2;
            border-color: @COLOR-PM-BLUE2;
            color: @COLOR-PM-WHITE1;
            /* box-shadow: 0 0 2px 2px @COLOR-PM-BLUE-FOCUS; */
        }

        &:disabled {
          + .pm-cr {
            color: @COLOR-PM-GREY5;
            background-color: @COLOR-PM-GREY5;
            border-color: @COLOR-PM-GREY12;
            box-shadow: none;
          }
        }
      }

        &:active,
        &:focus {
            + .pm-cr {
                margin: 0 5px 0 0;
            }
        }
      
      &:focus {
      	+ .pm-cr {
            border: 1px solid #01C1CB;
            box-shadow: 0 0 2px 2px  @COLOR-PM-BLUE-FOCUS;
      	}
      }
    }
  }
}

.pm-radio-container {
  .pm-radio-btn {
	display: inline-flex;
	align-items: center;

    &.vertical {
      margin-bottom: 7px;
    }

    .pm-cr {
      height: 2rem;
      width: 2rem;
      background-color: @COLOR-PM-WHITE1;
      display: inline-block;
      border-radius: 50%;
      border: 1px solid @COLOR-PM-GREY5;
      box-sizing: border-box;

      .pm-cr-icon {
        font-size: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -.98rem;
        margin-top: -.98rem;
        height: 2rem;
        width: 2rem;
        color: @COLOR-PM-BLUE2;
      }
    }

    &.disabled {
      .pm-cr {
        border-color: @COLOR-PM-GREY12;
        background-color: @COLOR-PM-GREY4;
      }
    }

    input[type=radio] {
      &:checked {
        + .pm-cr {
          border-color: @COLOR-PM-BLUE2;
          transition: none !important;
        }
        + span {
           background-color: @COLOR-PM-WHITE1;
           transition: none !important;
        }

        &:disabled {
          + .pm-cr {
            border: 1px solid @COLOR-PM-GREY12;
            background-color: @COLOR-PM-GREY4;
            transition: none !important;
            overflow: hidden;
            transform: scale(1);

            > .pm-cr-icon {
              color: @COLOR-PM-GREY4;
              background-color: @COLOR-PM-GREY5;
              transition: none !important;
              border-radius: 50%;
            }
          }
        }
      }
      
      &:focus {
        + .pm-cr {
          border-width: 0.3rem;
          border-color: @COLOR-PM-BLUE-FOCUS;
          
          .pm-cr-icon {
          	transform: scale(.8);
          }
        }
        
        ~ text {
        	margin-left: 3px;
        }
      }
    }
  }
}

// ---=== SEARCH INPUT FIELD ===--- //
.pm-search-container {
  .input-group {
    .form-control {
      border-radius: 4px !important;
    }
  }

  .input-group-addon {
    padding: 0 0 0 10px;
    font-size: inherit;
    line-height: inherit;
    text-align: inherit;
    background-color: transparent;
    border: none;
    position: relative;
    top: 13px;
    left: 0;
  }
}

// ---=== TEXT AREA ===--- //
.pm-textarea-container {
  textarea {
    margin: 0 0 5px 0;
    padding-top: 6px;
    border-radius: 4px;
  }
}

// ---=== CKEditor PM STYLES ===--- //
.cke_chrome {
    &:not(.pds-rich-text-widget){
      border-color: @COLOR-PM-GREY5 !important;
      margin-bottom: 5px !important;
    }
    border-radius: 4px !important;
}

.cke_top:not(.pds-rich-text-widget) {
    border-bottom-color: @COLOR-PM-GREY5 !important;
    background: #f7f7f7 !important;
    overflow: hidden;
}

.cke_bottom:not(.pds-rich-text-widget) {
    border-top-color: @COLOR-PM-GREY5 !important;
    background: #f7f7f7 !important;
    overflow: hidden;
}

.cke_inner {
  border-radius: 5px !important;
  overflow: hidden !important;
}

.cke_path a {
    color: @COLOR-PM-GREY5 !important;
}

.cke_resizer {
    border-color: transparent @COLOR-PM-GREY5 transparent transparent !important;
}

a.cke_path_item {
  .PM-TRANSITION() !important;
}

a.cke_path_item:hover,
a.cke_path_item:focus,
a.cke_path_item:active {
    background-color: @COLOR-PM-BLUE2 !important;
    color: @COLOR-PM-WHITE1 !important;
    opacity: 1 !important;
    border-radius: 4px !important;
    cursor: pointer !important;
    .PM-TRANSITION() !important;
}

// ---=== ELEMENT WIDTH CONTROL ===--- //
.pm-primary-btn {
  &.form-control {
    &.pm-width-ctrl {
      width: initial;
      border: none !important;
    }
  }
}

// ---=== BOOTSTRAP RESPONSIVE BREAKPOINTS ===--- //
// Screens greater than 992px //
@media (min-width: @PM-BOOTSTRAP-MD) {

}

// Screens between 768px and 992px //
@media (min-width: @PM-BOOTSTRAP-SM) and (max-width: @PM-BOOTSTRAP-MD ) {
  .pm-checkbox,
  .pm-radio-btn {
    &.checkbox-inline,
    &.radio-inline {
      display: inline-block;
      width: 50%;
      float: left;
      margin-left: 0;
    }
  }
}

// Screens smaller than 768px //
@media (max-width: @PM-BOOTSTRAP-SM) {

  .pm-primary-btn {
    &.form-control {
      &.pm-width-ctrl {
        width: 100% !important;
      }
    }
  }

  .pm-checkbox,
  .pm-radio-btn {
    &.checkbox-inline,
    &.radio-inline {
      display: block !important;
      margin-left: 0 !important;
    }

    &.checkbox-inline {
      margin-bottom: 9px !important;
    }

    &.radio-inline {
      margin-bottom: 7px !important;
    }
  }
}


//Badges courses search box//

.pm-badge-course-search {
    margin-top: 10px;

    .pm-search-container {
        margin-bottom: 0;
        width: 100%;

        .input-group {
            width: 100%;

            .input-group-addon {
                top: -9px;
                width: 50px;
            }
        }
    }
}


//Inline Checkbox/Radio fix //

.checkbox-inline, 
.radio-inline {
    position: relative;
}

.pm-input-min {
  max-width: 580px;
}
