@import (reference) "_pm-global.less";

@COS-TAB-HOVER: @COLOR-PS-BLUE3;
@COS-TAB-ACTIVE: @COLOR-PS-BLUE2;

//PRIORITY NAV BAR - cbrosky 4/11/17
.pm-priority-navbar .container-fluid {
    padding: 0;
    height: 5rem;

    .visible-links {
        margin-bottom: 0;
        padding: 0;
    }
}

.pm-priority-navbar {
    position: relative;
    min-width: 26rem;
    padding-left: 3.5rem;
    background: @COLOR-PM-GREY1;
    margin: 0 -10px;

    button {
        position: absolute;
        height: 100%;
        right: 0;
        padding: 0 8px;
        border: 0;
        outline: none;
        background-color: @COLOR-PM-GREY1;
        color: @COLOR-PM-WHITE1;
        cursor: pointer;
        .PM-TRANSITION();

        &:hover {
            background-color: @COLOR-PS-BLUE3; //#0066A5
            .PM-TRANSITION();
        }

        .pm-icon-ui-expand-chevron {
            font-size: 3.8rem;
            color: @COLOR-PM-WHITE1;
        }
    }

    a {
        display: block;
        padding: 15px 10px;
        background: transparent;
        font-size: 1.4rem;
        text-decoration: none;
        text-transform: capitalize;
        white-space: nowrap !important;
        .PM-TRANSITION();

        &.dropdown-toggle {
          span:first-child {
              .COS-OPEN-SANS-REGULAR() !important;
              color: @COLOR-PM-WHITE1 !important;
          }

            .caret {
                color: @COLOR-PM-WHITE1 !important;
            }
        }

        &:hover {
            color: @COLOR-PM-WHITE1;
            background: @COS-TAB-HOVER;
            .PM-TRANSITION();

            span {
                color: @COLOR-PM-WHITE1;
            }
        }
    }

    .visible-links {
        display: inline-table;
        padding: 0;
        margin: 0;


        .dropdown-menu {
            border-top: 0;
            border-color: #bbb;

            &:before {
                content: "";
                height: 5px;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: @COLOR-PM-BLUE6;
            }

            li {
                border-bottom: none;
            }
        }

        li {
            display: table-cell;
            padding-bottom: 0;

            a {
                .COS-OPEN-SANS-REGULAR();
                text-transform: capitalize;
                font-size: 1.4rem;
                color:  @COLOR-PM-WHITE1;

                &:hover {
                    color: @COLOR-PM-WHITE1 !important;
                }
            }

            ul {
                border-radius: 0 0 4px 4px;
                padding: 0;
                margin: 0;

                overflow: hidden;

                li {
                    display: block;
                    padding-bottom: 0;
                    border-bottom: 1px solid @COLOR-PM-GREY8;

                    &:first-child a {
                        margin-top: 5px;
                    }

                    &:last-child {
                        padding-bottom: 0;
                        border-bottom: 0;
                    }

                    .tnl-menu-text {
                        margin-left: 0;
                        color: @COLOR-COS-DEFAULT !important;
                    }

                    a .fa {
                        display: none;
                    }
                }

                a {
                    .COS-OPEN-SANS-REGULAR();
                    color: @COLOR-COS-DEFAULT;
                    padding: 12px 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    .PM-TRANSITION();
                    .PM-DROPDOWN-HOVER-ACCENT();
                }

                .divider {
                    display: none !important;
                }
            }
        }
    }

    .hidden-links {
        position: absolute;
        right: 0;
        top: 100%;
        z-index: 105 !important;
        background-color: @COLOR-PM-WHITE1;
        margin: 0;
        padding: 0;
        min-width: 220px;
        max-width: 260px;
        border: 1px solid #bbb;
        border-top: none;
        border-radius: 0 0 0 4px;
        overflow: hidden;
        box-shadow:         0 0 10px rgba(0,0,0,.1);
        -moz-box-shadow:    0 0 10px rgba(0,0,0,.1);
        -webkit-box-shadow: 0 0 10px rgba(0,0,0,.1);

        &:before {
            content: "";
            height: 5px;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: @COLOR-PS-BLUE3;
        }

        li {
            display: block;
            padding-bottom: 0;

            &:first-child a {
                margin-top: 15px;
            }

            &:last-child {
                padding-bottom: 0;
                border-bottom: 0;
                margin-bottom: 10px;
            }
        }

        a {
            color: @COLOR-COS-DEFAULT;
            .COS-OPEN-SANS-REGULAR();
            padding: 6px 20px; //12px 20px
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            .PM-TRANSITION();
            .PM-DROPDOWN-HOVER-ACCENT();
        }
    }

    .hidden {
        visibility: hidden;
    }
}

.pm-priority-navbar .visible-links li.active > a {
    background-color: @COS-TAB-ACTIVE;
}
