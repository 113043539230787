@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

/* TODO: Replace tnl-panel with Bootstrap panel */

.tnl-panel-2 .panel-heading {
    padding: 0;
}

.tnl-panel-2 .panel-body {
    padding-right: 0;
    padding-left: 0;
}

.tnl-panel-2 .panel-title {
    margin-right: 50px;
    font-size: @FONT-SIZE-LG;
    .COS-OPEN-SANS-600();
    .LESS-ELLIPSIS-ONE-LINE;
}

.tnl-panel-2 {
    .LESS-ROUNDED-CORNERS-RESET();
    .LESS-BOX-SHADOW-RESET;
    border: none;
    border-bottom: 1px solid @COLOR-TNL-GRAY4;
}

.panel,
.tnl-panel-1 {
    position: relative;
    .LESS-ROUNDED-CORNERS-RESET();
    .LESS-BOX-SHADOW-RESET();

    .panel-heading {
        position: relative;
        padding-right: 15px; // Required so title does not overlap menu.
        white-space: nowrap;
        background: none;
        .LESS-ROUNDED-CORNERS-RESET();
        border: none;

        h1, h2, h3, h4, h5,
        .panel-title {
            display: inline-block;
            position: relative;
            top: 3px;
            margin: 0;
            padding: 0;
            max-width: 100%; // Required for ellipsis.
            color: @COLOR-TNL-GRAY1;
            font-size: @FONT-SIZE-MD;
            .COS-OPEN-SANS-600();
            .LESS-ELLIPSIS-ONE-LINE();

            .icon-font.fa {
                font-size: @FONT-SIZE-MD;
            }
        }
        > .panel-title > a {
            color: @COLOR-LINK;
            font-weight: normal;

            &:hover {
                color: @COLOR-LINK-HOVER;
            }
        }
        .actions {
            position: absolute;
            top: 4px;
            right: 4px;
        }

        .panel-title {
            margin-left: 10px !important;
        }
    }

    .panel-footer {
        padding: 10px 15px;
        color: @COLOR-TNL-GRAY3;
        font-size: @FONT-SIZE-SM;
        background-color: transparent;
        .LESS-ROUNDED-CORNERS-RESET();
        border-top: none;
    }

    &.panel-default {
        background: #fff;
        border: 1px solid @COLOR-TNL-GRAY4;

        > .panel-heading {
            color: @COLOR-TNL-GRAY1;
            background: none;

            .form-group {
                &:last-child {
                    margin: 0;
                }
            }
        }
    }

    &.panel-success,
    &.panel-error,
    &.panel-warning {
        color: #fff;

        .panel-heading {
            h1, h2, h3, h4, h5,
            .panel-title > a {
                color: #fff;
            }
        }
        .tnl-button-menu.btn-group {
            border-color: #fff;

            .dropdown-toggle .icon-font {
                color: #fff;
            }

            > .btn, 
            > .btn:focus, 
            > a.btn-default:active {
                  background-color: transparent;
                  color: #fff;
            }
        } 
    }

    &.panel-warning {
        color: #fff;
        background: @COLOR-WARNING;
    }    
    
    &.panel-error {
        background: @COLOR-TNL-ORANGE1;
    }

    &.panel-success {
        background: @COLOR-TNL-GREEN1;
    }
}

.panel-group {

    .panel-body {
        padding: 15px;
    }
    .panel,
    .panel-heading {
        background: none;
        .LESS-ROUNDED-CORNERS-RESET();
        .LESS-BOX-SHADOW-RESET();
    }
    .panel {
        border-color: @COLOR-TNL-GRAY4;
        border-right: none;
        border-left: none;
        border-bottom: none;

        &:last-child {
            border-bottom: 1px solid @COLOR-TNL-GRAY4;
        }
    }
    .panel-group {
        margin: 20px 20px 20px 40px;
    }
    .panel-heading + .panel-collapse > .panel-body {
        border-top: none;
    }
    .tnl-panel-expand {
        position: relative;

        .tnl-panel-expander {
            position: absolute;
            top: 4px;
        }
        .tnl-panel-expander-title {
            position: relative;
            margin-left: 20px;
        }

    }
}

.panel-heading,
.panel-body {

    .pm-dropdown-btn-group {
        text-align: left !important;
        margin-top: 15px !important;
        margin-left: 10px;
    }

    .dropdown-menu.dropdown-menu-right-aligned,
    .dropdown-menu.dropdown-menu-left-aligned {
        .PM-ROUNDED-CORNERS();
        padding: 0;
        right: 10px !important;
        min-width: 150px;
        max-width: 250px;

        li {
            display: block;
            padding-bottom: 0;
            border-bottom: 1px solid @COLOR-PM-GREY8;

            &:last-child {
                padding-bottom: 0;
                border-bottom: 0;
            }

            a {
                color: @COLOR-COS-DEFAULT !important;

                &:hover {
                    background-color: transparent !important;
                    text-decoration: none !important;
                    color: @COLOR-PM-LINK-H !important;
                }
            }
        }

        a {
            color: @COLOR-COS-DEFAULT;
            padding: 12px 20px; 
            overflow: hidden;
            text-overflow: ellipsis;
            .COS-OPEN-SANS-REGULAR();
            .PM-TRANSITION();
            .PM-DROPDOWN-HOVER-ACCENT();
        }
    }
}

.panel-body {
    th, td {
        text-align: left !important;
        padding: 10px !important;
    }
}