@import (reference) "_pm-global.less";

// File Attachment Styles //
// Author: Chris Brosky - November 2017 //

.pm-attachments {
  .pm-file-list-item {
    padding: 3px 0;
    margin-left: 0px;
    border-bottom: none;
    
    .pm-icon-sm {
        top: 6px;
        padding-right: 5px;
    }

    .pm-replace-file,
    .pm-delete-file {

      a {
        margin: 0;
        height: 23px;

        .pm-icon-sm {
          margin-right: 5px;
        }
      }

      .pm-btn-group {
        margin-right: 0 !important;
      }
    }

    .pm-file-link {
      position: relative !important;
      top: -5px !important;
    }

    .description {
      &.pm-file-description {
        display: none !important;
      }
    }
  }
}

/* --=== RESPONSIVE STYLES ===-- */
@media (max-width: 992px) {

}
