@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

.tnl-hidden-submit {
  border: 0 none;
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.tnl-form-title {
  padding: 5px;
  color: @COLOR-COS-DEFAULT;
  background: @COLOR-TNL-GRAY3;
  border: 1px solid @COLOR-TNL-GRAY3;
  font-size: @COS-FONT-SIZE-18;
  .COS-OPEN-SANS-600();
}

.tnl-form-box {
  background: @COLOR-TNL-GRAY4;
  overflow: auto;
  border: 1px solid @COLOR-TNL-GRAY3;
  border-top: 0;
  padding: 5px 10px 5px 10px;
}

.tnl-form-table {
  width: 100%;
  margin: 0;
}

div.size-checking {
  position: relative;
  font-size: @COS-FONT-SIZE-18;
}

div.size-checking > div {
  position: absolute;
  top: -30000px;
}

.size-checking .tnl-form-table {
  width: auto;
}

.tnl-form-section {
  margin: 0 0 25px 0;
  padding: 10px;
}

.tnl-form-section-title {
  color: @COLOR-COS-DEFAULT;
  padding: 0 0 3px 5px;
  font-size: @FONT-SIZE-DEFAULT;
  font-weight: bold;
}

.tnl-form-label {
  color: @COLOR-COS-DEFAULT;
  padding: 15px 0 0;
  font-weight: bold;
  text-align: right;
  vertical-align: top;
}

.tnl-form-label label {
  margin-bottom:0;
}

.tnl-form-label > div.aside {
  position: relative;
}

.form-control.max-length-2 {
  width: 40px;
}
.form-control.tnl-editable {
    width: 139px!important;
}

.tnl-form-label > div.aside > * {
  position: absolute;
  left: -14px;
  top: -2px;
}

.tnl-form-field {
  width: 65%;
  vertical-align: middle;
  padding: 15px 10px 0;
}

.tnl-form-field.tnl-form-field-hover {}

.tnl-form-field input, textarea, select {
  padding: 2px;
}

.tnl-form-description {
  display: inline-block;
  color: @COLOR-COS-DEFAULT;
  font-size: @COS-FONT-SIZE-10;
}
.tnl-form-description p {
  margin-bottom: 10px;
}
.tnl-form-description p:last-child {
  margin-bottom: 0;
}

.tnl-check-box {
  vertical-align: middle;
}

.tnl-check-box-label {
  padding: 0 0 8px;
}

.tnl-check-box-label > span {
  margin-left: 5px;
  vertical-align: middle;
  line-height: 22px;
}

.tnl-radio-button-label {
  padding: 2px 0 4px;
  vertical-align: middle;
}

.tnl-radio-button-label > span {
  margin-left: 5px;
  vertical-align: top;
  line-height: 18px;
}

input.tnl-date {
  /*Override Bootstrap's input[type=date] settings until we allow bootstrap to define more of our styles.*/
  line-height: 18px;
  width: 175px!important;
}

.tnl-input-hover {
  border: 1px solid @COLOR-TNL-ORANGE1;
}

.tnl-input-focus {
  border: 1px solid @COLOR-TNL-ORANGE1;
  .LESS-BOX-SHADOW;
}

.tnl-input-error {
  border: 2px solid @COLOR-DANGER;
}

.tnl-error-summary-box {
  padding: 10px;
  background: #fff;
  border: 1px solid @COLOR-DANGER;
  margin-top: 2px;
  margin-bottom: 10px;
  color: @COLOR-DANGER;
}

.tnl-required > label {
  color: @COLOR-REQUIRED;
}

.tnl-required-box {
  text-align: right;
  font-size: @COS-FONT-SIZE-10;
  color: @COLOR-REQUIRED;
}
.tnl-form-container-fluid {
  &:extend(.container-fluid);
  padding-left:0;
  padding-right:0;
}

.tnl-form-group-collection {
  margin-bottom: 20px;

  > header {
    margin-bottom: 15px;

    h1, h2, h3, h4, h5 {
      color: @COLOR-TNL-ORANGE1;
    }
    p {
      font-size: @COS-FONT-SIZE-12;

      &:last-child {
          margin-bottom: 0;
      }
    }
  }
}

//TODO: Why is this Bootstrap stuff (.form-control, label, .help-block) not contained by .form-group?
.form-control {
  color: @COLOR-COS-DEFAULT;
  border: 1px solid @COLOR-PM-GREY5 !important;

  &:focus {
    border-color: @COLOR-TNL-BLUE2;
    .LESS-BOX-SHADOW-INSET-RESET();
    .LESS-BOX-SHADOW(0 0 8px rgba(145, 180, 206, 0.6));
  }

  &.error {
    &:focus {
      .LESS-BOX-SHADOW-RESET() !important;
    }
  }
}

.form-control[disabled] {
  border: 1px solid @COLOR-PM-GREY12 !important;
  background-color: @COLOR-PM-GREY4 !important;
}

label,
.control-label,
.has-success .control-label {
  color: @COLOR-COS-DEFAULT;
  .COS-OPEN-SANS-600();

  input[type=checkbox] {
    position: relative;
    top: 2px;
  }
}

.control-label {
  .COS-OPEN-SANS-600();
  font-size: 16px;
}

.help-block {
  color: @COLOR-COS-DEFAULT;
  font-size: @COS-FONT-SIZE-12;
  margin: 2px 0;

  .tnl-menu-text {
      position: relative;
      top: 1px;
      font-size: @COS-FONT-SIZE-12;
  }
}

.form-group {
  margin-bottom: 20px;

  .checkbox,
  .radio {
    margin: 0 0 5px 0;
  }

  label {
    &.disabled {
      color: @COLOR-PM-GREY5 !important;
      opacity: 1 !important;
    }
  }

  .pm-datepicker {
    input {
      border-radius: 4px 0 0 4px !important;
    }

    .control-label {
      display: table-caption;
    }

    .input-group-addon {
      border-color: @COLOR-PM-GREY5 !important;
      padding: 0 5px;

      &.right {
        border-radius: 0 4px 4px 0 !important;

        .pm-icon-font{
          top: 50%;
          margin-top: -10px;
          left: 50%;
          margin-left: -10px;
          font-size: 20px !important;
          color: @COLOR-COS-DEFAULT;

          &:before {
            font-size: 20px !important;
          }
        }
      }
    }
  }
}

fieldset {
  margin: 0;
  padding: 0;

  legend {
    margin: 0;
    padding: 0;
    color: @COLOR-COS-DEFAULT;
    font-size: 15px;
    border: none;
    .COS-OPEN-SANS-600();
  }

  p {
    margin-bottom: 10px;
    color: @COLOR-COS-DEFAULT;
    font-size: @COS-FONT-SIZE-12;
  }

  label {
    font-weight: normal;
  }
}

.has-error {
  input,
  select,
  textarea,
  .checkbox,
  .checkbox-inline,
  .radio,
  .radio-inline,
  fieldset legend {
      color: @COLOR-PM-RED1;
  }

  .control-label {
    color: @COLOR-COS-DEFAULT;
    font-size: @COS-FONT-SIZE-DEFAULT;

    span {
      color: @COLOR-PM-RED1;
    }
  }
}

// TODO: Remove .input-validation-error and .validation-summary-errors from DOM. Use Bootstrap's .has-error
.has-error .form-control,
.has-error .form-control:active,
.input-validation-error,
.validation-summary-errors,
.has-error > div.checkbox,
.has-error > fieldset > div.radio-btn-list,
.has-error > fieldset > div.checkbox-list,
div.file-set-editable-inner.has-error {
  border-color: @COLOR-PM-RED1 !important;
  .LESS-BOX-SHADOW-RESET();
}

.has-error > div.checkbox,
.has-error > fieldset > div.radio-btn-list,
.has-error > fieldset > div.checkbox-list,
div.file-set-editable-inner.has-error {
  border: 1px solid;
  border-radius: 4px;
  padding-left: 10px;
}

div.file-set-editable-inner.has-error {
  padding-bottom: 20px;
}

.has-error > div.checkbox,
.has-error > fieldset > div.radio-btn-list
{
  padding-bottom: 5px;
}

.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline {
    //color: @COLOR-TNL-GREEN1;
}

.has-success .form-control {
  .LESS-BOX-SHADOW-RESET() !important;
}

.validation-summary-errors {
  margin: 10px;
  padding: 10px 20px;
}

.validation-summary-errors li {
  list-style: none;
  color: @COLOR-PM-RED1;
}

// Cannot change the names of these...
// TODO: The DOM is pairing these classes with Bootstrap's .help-block. Remove the .help-block class.
.field-validation-error {
  display: block;
  padding: 0;
  font-size: @COS-FONT-SIZE-10;
  color: @COLOR-PM-RED1;
  line-height: normal;
}
.field-validation-valid {
  display: none;
}
// ...end rule.


.tnl-footnotes {
  margin-top: 20px;
}

.form-horizontal {
  .control-label {
    margin-bottom: 0;
    padding: 5px 0;
    text-align: left;

    @media (max-width: 768px) {
        text-align: left;
    }
  }

  .form-group {
    margin: 0;
  }
}

.form-inline {
  .form-group {
    margin-bottom: 20px;
  }
}

.form-inline {
  .form-group {
    margin-bottom: 20px;
  }
}

.form-control-inline  {
  display:inline-block;
	margin-top:6px;
	width:auto;
}

.file-set-readonly > ul {
  padding-left: 20px;
}

.pm-updated-field-options {
  background-color: #f1f1f1;
  padding-top: 10px;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -15px;
  padding-bottom: 10px;
}

.pm-updated-field-options-offset {
  margin-left: 15px;
  margin-right: 15px;
}

.pm-date-label {
  display: block;
  position: relative;
  top: 50%;
  margin-top: -10px;
}

@media screen and (max-width: 768px) {
  .pm-date-label {
    text-align: left !important;
    float: left !important;
  }
}

.pm-module-programs {
    input[type=date],
    input[type=datetime-local] {
        width: 221px !important;
    }
  select {
    width: 100%;
    max-width: 580px;
  }
}
