@import (reference) "../Theme/Common/_common.less";

div.tnl-person-header-wrapper {
    width: 99%;
}

div.tnl-person-profile {
    text-align: left;
    padding: 5px;
    overflow-x: hidden;
    overflow-y: visible;
    min-height: 500px;
}

div.tnl-person-profile > div {
    float: left;
    clear: left;
    margin-left: 10px;
    *margin-right: -95px;
}

div.tnl-person-profile > div > h3 {
    margin: 0 8px;
}

span.tnl-person-profile-empId,
a.tnl-person-profile-email,
span.tnl-person-profile-authtype {
    margin: 0;
    display: block;
}

/* User profile cards */
.profile {
    h1 {
        font-size: 36px;
        font-weight: normal;
        margin-bottom: .5em;
    }

    dt,
    dd {
       padding: .15em 0;
       font-size: 14px;
    }

    span.tnl-multi-answer-value {
        color: @COLOR-TNL-GRAY1;
    }
}