// PM GLOBAL STYLES //
// Author: Chris Brosky - October 2017 //

// Font Sizes
html {font-size: 62.5%} /* DO NOT REMOVE -- Sets base font-size for using REM units */

body {
    color: @COLOR-COS-DEFAULT;
    font-size: @COS-FONT-SIZE-DEFAULT;
    .COS-OPEN-SANS-REGULAR();
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    color: @COLOR-COS-DEFAULT;
    .COS-OPEN-SANS-600();
}

h1, .h1 {
    margin-bottom: 15px;
    font-size: @COS-FONT-SIZE-22;
}

h2, .h2 {
    margin-bottom: 15px;
    font-size: @COS-FONT-SIZE-18;
}

h3, .h3 {
    margin-bottom: 10px;
    font-size: @COS-FONT-SIZE-16;
}

h4, .h4,
h5, .h5,
h6, .h6 {
    font-size: @COS-FONT-SIZE-DEFAULT;
}

// This seems to break RTE's ability to show bullet points //
/*ol, ul {
   list-style-type: none;
   padding: 0;
   margin: 0;
 }*/

p, span, li {
    color: @COLOR-COS-DEFAULT;
    font-size: @COS-FONT-SIZE-DEFAULT;
    .COS-OPEN-SANS-REGULAR();
}

// FONT STYLES
@COS-FONT-SIZE-10:      10px;
@COS-FONT-SIZE-12:      12px;
@COS-FONT-SIZE-DEFAULT: 14px;
@COS-FONT-SIZE-16:      16px;
@COS-FONT-SIZE-18:      18px;
@COS-FONT-SIZE-22:      22px;
@COS-FONT-SIZE-24:      24px;
@COS-FONT-SIZE-30:      30px;

// @FONT-SIZE-XSM, @FONT-SIZE-SM // Sizes need to be replaced
@PM-FONT-SIZE-SMALL: 12px;
@PM-FONT-SIZE-DEFAULT: 14px;
@PM-FONT-SIZE-MEDIUM: 18px;
@PM-FONT-SIZE-LARGE: 22px;
@PM-FONT-SIZE-XLARGE: 24px;
@PM-FONT-SIZE-XXLARGE: 30px;

// OPEN SANS //

/* Normal - 400 */
.COS-OPEN-SANS-REGULAR() {
    font-family: 'Open_Sans_Regular', sans-serif;
    font-weight: normal;
}

/* Normal - 400 Italic */
.COS-OPEN-SANS-REGULAR-ITALIC() {
    font-family: 'Open_Sans_Regular_Italic', sans-serif;
}

/* Semibold - 600 */
.COS-OPEN-SANS-600() {
    font-family: 'Open_Sans_Semibold', sans-serif;
}

/* Semibold - 600 Italic */
.COS-OPEN-SANS-600i() {
    font-family: 'Open_Sans_Semibold_Italic', sans-serif;
}

/* Bold - 700 */
.COS-OPEN-SANS-700() {
    font-family: 'Open_Sans_Bold', sans-serif;
}

.NEON-ROBOTO-FONT-STACK() {
    font-family: Roboto,Arial,Helvetica,sans-serif;
}

.pm-font-size-default {
    font-size: @COS-FONT-SIZE-DEFAULT;
}

.pm-font-weight-600 {
    font-family: 'Open_Sans_Semibold', sans-serif;
}

// Primary Colors
@COLOR-PM-BLUE1: #165fab;       /* Links, Primary Buttons */
@COLOR-PM-BLUE2: #2570ef;       /* Selected Tab Borders */
@COLOR-PM-GREEN1: #8ec641;      /* New Corporate Green */

// Accent Colors
@COLOR-PM-YELLOW1: #ffbe22;     /* Yellow Accent, Instructor-Led Course */
@COLOR-PM-YELLOW2: #ff8a00;     /* Dark Yellow Accent or Orange2 Accent */
@COLOR-PM-YELLOW3: #ffffdd;
@COLOR-PM-PURPLE1: #8340e8;     /* Purple Accent (from badging mocks) */
@COLOR-PM-SLATE1: #116384;      /* Slate Accent */
@COLOR-PM-TEAL1: #00baa2;       /* Teal2 Accent (from badging mocks) */
@COLOR-PM-ORANGE1: #e05e22;     /* Orange Accent */
@COLOR-PM-FUSCHIA1: #93268f;    /* Fuschia Accent, PLC Accent */
@COLOR-PM-PINK1: #f49ac0;       /* Pink Accent */
@COLOR-PM-PINK2: #fe407e;       /* Pink2 Accent (from badging mocks) */
@COLOR-PM-PINK3: #f24157;       /* Pink3 hover color for buttons */
@COLOR-PM-GOLD1: #E49F19;       /* Border color for header boxes */
@COLOR-PM-GREEN2: #508703;      /* Green for Success Icons and States */
@COLOR-PM-GREEN3: #3d6802;      /* Dark Green for Success Button */
@COLOR-PM-GREEN4: #008f07;
@COLOR-PM-GREEN5: #cce5a9;
@COLOR-PM-GREEN6: #e8f4d9;
@COLOR-PM-PEACH1: #f8dfb2;      /* Peach Accent */
@COLOR-PM-BLUE4: #8abde6;       /* Light Blue Accent */
@COLOR-PM-BLUE5: #2470ef;       /* Light Blue for Mobile Nav Items */
@COLOR-PM-BLUE6: #4488f7;       /* Light Blue for Mobile Nav Hover -- PS color: #006a55 */
@COLOR-PM-BLUE7: #d0dfee;       /* Blue Highlight BG */
@COLOR-PM-BLUE-FOCUS: #49f6fe;	/* For blue inputs when they receive focus */
@COLOR-PM-BROWN1: #964702;      /* Brown Accent */
@COLOR-PM-RED1: #bb2127;        /* PM Primary Red */
@COLOR-PM-RED2: #bb1c1a;        /* Red for Error Icons and Text */
@COLOR-PM-RED3: #f7d3d2;        /* Red for Error Background */

// GREYS
@COLOR-PM-GREY1:    #252d30;    /* Grey 1 */
@COLOR-PM-GREY10:   #354247;    /* Grey 2 */
@COLOR-PM-GREY9:    #535e62;    /* Grey 3 */
@COLOR-PM-GREY11:   #9aa0a3;    /* Grey 4 */
@COLOR-PM-GREY8:    #e7e9ed;    /* Grey 5 */
@COLOR-PM-GREY3:    #ebeff2;    /* Main Navigation Background for Light Theme */
@COLOR-PM-GREY4:    #f1f5f8;    /* Footer and Inactive Tab Background */
@COLOR-PM-GREY7:    #344146;    /* Main Navigation Background for Dark Theme */
@COLOR-PM-GREY5:    #a4aaba;    /* Form element border color */
@COLOR-PM-GREY12:   #dee7ed;    /* Disabled form element border color */
@COLOR-PM-WHITE1:   #ffffff;    /* Plain Old White */
@COLOR-PM-BLACK1:   #000000;    /* Plain Old Black */
@COLOR-PM-HEADER2:  #495574;    /* Level 2 Headers and Action Icons */

// Header Colors
@COLOR-PM-GREY6: @COLOR-PM-GREY7;       /* Portal - Dark grey background */
@COLOR-PM-BLUE3: #165fab;               /* Blue Header Background */

// Text & Button Colors
@COLOR-COS-DEFAULT: #333;               /* Body Text Color */
@COLOR-PM-LINK:     @COLOR-PS-BLUE3;    /* Link Normal - PS Colors - #0066A5 */
@COLOR-PM-LINK-V:   @COLOR-PM-LINK;     /* Link Visited - PS Colors - #0066A5  */
@COLOR-PM-LINK-A:   @COLOR-PS-BLUE1;    /* Link Active - PS Colors - #00427C  */
@COLOR-PM-LINK-H:   @COLOR-PS-BLUE1;    /* Link Hover - PS Colors - #00427C  */

// PowerSchool Colors //
@COLOR-PS-BLUE1:    #00427C;
@COLOR-PS-BLUE2:    #005096;
@COLOR-PS-BLUE3:    #0066A5;
@COLOR-PS-BLUE4:    #0083C4;
@COLOR-PS-BLUE5:    #018DB6;
@COLOR-PS-BLUE6:    #137ABB;
@COLOR-PS-GREY1:    #e0e0e0;
@COLOR-PS-GREY2:    #f7f7f7;
@COLOR-PS-ORANGE1:  #f97d16;
@COLOR-PS-ORANGE2:  #ffeee6;
@COLOR-PS-ORANGE3:  #f1934e;
@COLOR-NEON-BRAND-SECONDARY: #0075DB;
@COLOR-NEON-NAVY-400: #85B1FF;
@COLOR-NEON-NAVY-100: #E8F0FD;
@COLOR-NEON-TEXT-DARK: #333;

a {
    span {
        color: @COLOR-PS-BLUE3;
    }

    &:hover,
    &:active {
        span {
            color: @COLOR-PS-BLUE1;
        }
    }

    &:visited {
        span {
            color: @COLOR-PS-BLUE3;
        }
    }
}


// Legacy Text Classes
.titletext {
    .COS-OPEN-SANS-600();
    color: @COLOR-PM-GREY1;
    font-size: 20px;
}

.text-success {
    color: @COLOR-PM-GREEN3;
}

.text-warning {
    color: @COLOR-PM-YELLOW1;
}

.text-danger {
    color: @COLOR-PM-RED1;
}

// LESS MIXINS
.COS-ROTATE (@deg) {
    -webkit-transform:  rotate(@deg);
    -moz-transform:     rotate(@deg);
    -ms-transform:      rotate(@deg);
    -o-transform:       rotate(@deg);
    transform:          rotate(@deg);
}

.PM-TRANSITION (@prop: all, @time: .15s, @ease: ease-in-out) {
  -webkit-transition:   @prop @time @ease;
  -moz-transition:      @prop @time @ease;
  -o-transition:        @prop @time @ease;
  -ms-transition:       @prop @time @ease;
  transition:           @prop @time @ease;
}

.PM-DROPDOWN-HOVER-ACCENT () {
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -15px;
    width: 3px;
    height: 30px;
    border-radius: 0 1px 1px 0;
    background-color: transparent;
    .PM-TRANSITION();
  }

  &:hover {
    background-image: none !important;
    color: @COLOR-PS-BLUE3;
    background-color: transparent;
    .PM-TRANSITION();

    &:before {
      background-color: @COLOR-PS-BLUE3;
      .PM-TRANSITION();
    }
  }

  &:active, &:visited, &:focus {
    background: none !important;
    text-decoration: none !important;
    outline: none !important;

    span:focus {
        outline: none !important;
        background: inherit;
    }
  }
}

.PM-DISABLED-BUTTON() {
  background-color: #eee !important;
  color: #6c6c6c !important;

  &:hover {
    cursor: not-allowed !important;
  }
}

.PM-ROUNDED-CORNERS() {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.PM-BTN-RADIUS () {
  border-radius: 4px;
}

.PM-OVERFLOW-ELLIPSIS () {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pm-read-more-text {
  overflow: hidden;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    background: linear-gradient(to bottom, transparent 0%, #fff 100%);
    display:block;
  }
}

.pm-text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &.pm-radio-btn {
    overflow: unset;
    white-space: unset;
  }
}

.pm-text-ellipsis-100 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.pm-ellipsis {
  float: right;
  display: none;
  margin-top: -1.3em;
  margin-top: -20px;
}

//When a long URL/Text needs a force break //
.pm-word-break {
    word-break: break-all; 
}

.pm-cropped-text-1 {
  max-height: 1.3em;
  max-height: 20px;

  &:before {
    padding: 6px 0; 
  }
}

.pm-cropped-text-2 {
  max-height: 2.6em;
  max-height: 39px;

  &:before {
    padding: 15px 0;
  }
}

.pm-cropped-text-3 {
  max-height: 3.9em;
  max-height: 57px;

  &:before {
    padding: 19px 0;
  }
}

.pm-cropped-text-4 {
  max-height: 5.2em;
  max-height: 75px;

  &:before {
    padding: 30px 0;
  }
}

// PAGE - CONTENT PADDING //
.pm-page-content,
.cos-page-content {
  padding: 25px;
  background-color: @COLOR-PM-WHITE1;

  &.padding-top-0 {
    padding-top: 0 !important;
  }

  &.bg-color-trans {
    padding: 0 25px;
    background-color: transparent;
  }

  &.padding-top-bottom-10 {
    padding: 10px 0 !important;
  }
}

.pm-modus-page-content {
  padding: 10px 25px 25px 25px;
  background-color: @COLOR-PM-WHITE1;
}

.pm-modus-padding {
  padding: 10px 25px 25px 25px;
}

// MARGIN - TOP //
.pm-margin-top {
  margin-top: 15px;
}

// MARGIN - BOTTOM //
.pm-margin-bottom {
  margin-bottom: 15px;
}

.pm-margin-bottom-20 {
  margin-bottom: 20px;
}

// PADDING 0 //
.pm-no-padding {
  padding: 0;
}

.pm-padding-sm {
  padding: 10px;
}

.pm-padding-md {
  padding: 20px;
}

.pm-padding-lg {
  padding: 30px;
}

.pm-search-results-content {
  padding: 5px 35px 0 35px;
}

// NO MARGIN //
.pm-no-margin {
  margin: 0;
}

// HIDDEN ELEMENTS //
.pm-hidden {
  display: none;
}

// FORM STYLES //

input, select, textarea {
  &:not(.pds-date-widget):not(.pds-select-widget):not(.pds-text-widget):not(.pds-text-area-widget):not([class^="neon-"]):not([class^="neon-"][class$="-component"] input):not([class^="neon-"][class$="-component"] select):not([class^="neon-"][class$="-component"] textarea)
  {
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    border-color: @COLOR-PM-GREY5 !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    .PM-TRANSITION();

    &:focus, &:active, &:hover {
      border-color: @COLOR-PM-BLUE2 !important;
      outline: none !important;
      .PM-TRANSITION();
    }
  }
}

textarea {
  transition: none !important;
  &:focus, &:active, &:hover {
    transition: none !important;
  }
}

input {
  padding: 6px 5px 6px 10px;
}

select {
  padding: 5px 5px 6px;
}

// Learning Opportunity Dashboard Search //

.pm-slo-form {
    select {
        margin-bottom: 10px;
    }
}

// BOOTSTRAP STYLES //

// Responsive Breakpoints //
@PM-BOOTSTRAP-SM: 768px ;
@PM-BOOTSTRAP-MD: 992px ;
@PM-BOOTSTRAP-LG: 1200px ;

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
  vertical-align: middle;
}

//Table Container puts the table inside the application with scroll bars to the side and top//
.pm-table-scroll {
    overflow: scroll;
    width: 100%;
    height: 100vh;
    border: 1px @COLOR-PM-GREY1 solid;
}

.pm-date {
  width: auto!important;
}
.pm-margin-fix {
  margin:0;
}

//btn class override
a.btn {
    color: @COLOR-PM-WHITE1 !important;
}

// Horizontal margin //
.pm-hmargin {
    margin: 0 15px;
}

// Vertical margin //
.pm-vmargin {
    margin: 15px 0;
}

// Add 15 margin //
.pm-margin {
    margin: 15px;
}

// margin top //
.pm-margin-top {
    margin-top: 5px;
}

.pm-center-content {
    text-align: center;
}

/*margin bottom */
.mb-1, .my-1 {
  margin-bottom: .25rem!important;
}

.mb-2, .my-2 {
  margin-bottom: .5rem!important;
}

.mb-3, .my-3 {
  margin-bottom: 1rem!important;
}

.mb-4, my-4 {
  margin-bottom: 1.5rem!important;
}

.mb-5, .my-5 {
  margin-bottom: 3rem!important;
}

/*margin top */
.mt-1, .my-1 {
  margin-top: .25rem!important;
}

.mt-2, .my-2 {
  margin-top: .5rem!important;
}

.mt-3, .my-3 {
  margin-top: 1rem!important;
}

.mt-4, my-4 {
  margin-top: 1.5rem!important;
}

.mt-5, .my-5 {
  margin-top: 3rem!important;
}

/*margin left */
.ml-1, .mx-1 {
  margin-left: .25rem!important;
}

.ml-2, .mx-2 {
  margin-left: .5rem!important;
}

.ml-3, .mx-3 {
  margin-left: 1rem!important;
}

.ml-4, mx-4 {
  margin-left: 1.5rem!important;
}

.ml-5, .mx-5 {
  margin-left: 3rem!important;
}

/*margin right */
.mr-1, .mx-1 {
  margin-right: .25rem!important;
}

.mr-2, .mx-2 {
  margin-right: .5rem!important;
}

.mr-3, .mx-3 {
  margin-right: 1rem!important;
}

.mr-4, mx-4 {
  margin-right: 1.5rem!important;
}

.mr-5, .mx-5 {
  margin-right: 3rem!important;
}

/*margin all */
.ma-1 {
  margin: .25rem!important;
}

.ma-2 {
  margin: .5rem!important;
}

.ma-3 {
  margin: 1rem!important;
}

.ma-4 {
  margin: 1.5rem!important;
}

.ma-5 {
  margin: 3rem!important;
}



/*padding bottom */
.pb-1, .py-1 {
  padding-bottom: .25rem!important;
}

.pb-2, .py-2 {
  padding-bottom: .5rem!important;
}

.pb-3, .py-3 {
  padding-bottom: 1rem!important;
}

.pb-4, py-4 {
  padding-bottom: 1.5rem!important;
}

.pb-5, .py-5 {
  padding-bottom: 3rem!important;
}

/*padding top */
.pt-1, .py-1 {
  padding-top: .25rem!important;
}

.pt-2, .py-2 {
  padding-top: .5rem!important;
}

.pt-3, .py-3 {
  padding-top: 1rem!important;
}

.pt-4, py-4 {
  padding-top: 1.5rem!important;
}

.pt-5, .py-5 {
  padding-top: 3rem!important;
}

/*padding left */
.pl-1, .px-1 {
  padding-left: .25rem!important;
}

.pl-2, .px-2 {
  padding-left: .5rem!important;
}

.pl-3, .px-3 {
  padding-left: 1rem!important;
}

.pl-4, px-4 {
  padding-left: 1.5rem!important;
}

.pl-5, .px-5 {
  padding-left: 3rem!important;
}

/*padding right */
.pr-1, .px-1 {
  padding-right: .25rem!important;
}

.pr-2, .px-2 {
  padding-right: .5rem!important;
}

.pr-3, .px-3 {
  padding-right: 1rem!important;
}

.pr-4, px-4 {
  padding-right: 1.5rem!important;
}

.pr-5, .px-5 {
  padding-right: 3rem!important;
}

/*padding all */
.pa-1 {
  padding: .25rem!important;
}

.pa-2 {
  padding: .5rem!important;
}

.pa-3 {
  padding: 1rem!important;
}

.pa-4 {
  padding: 1.5rem!important;
}

.pa-5 {
  padding: 3rem!important;
}

// Hackery
@-moz-document url-prefix() {
  fieldset {
    display: table-cell;
  }
}
