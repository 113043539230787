@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

//TODO: This is extremely close to forms. Reconsider tnl-form-group-collection vs. tnl-label-value-collection.
.tnl-label-value-collection {
    margin-bottom: 40px; 

    > header {
        margin-bottom: 15px;

        h1, h2, h3, h4, h5 {
            color: @COLOR-TNL-ORANGE1;
            font-size: @FONT-SIZE-MD;
        }
        p {
            font-size: @FONT-SIZE-SM;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .tnl-label-value-group {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .tnl-label,
    .tnl-value {
        text-align: left;
        vertical-align: top;
    }

    .tnl-label {
        .COS-OPEN-SANS-600();
    }

    &.inline {
        .tnl-label,
        .tnl-value {
            display: inline-block;
        }
    }

}
