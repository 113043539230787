@import (reference) "../Theme/Common/_common.less";

.tooltip-inner {
    background-color: @COLOR-PM-GRAY;
    color: #fff;
}

.tooltip-hover {
    text-decoration: dashed;
}

.tooltip {
    &.in {
    opacity: 1;
    z-index:9999;
    }
}

.tooltip.top .tooltip-arrow {
    border-top-color: @COLOR-PM-GRAY;
}