@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

.pmf-faceted-navigation-page .pm-faceted-navigation-row {
    margin-top: 20px !important;
}

.pm-expand-heading .pm-title,
.pm-expand-contents label {
    color: @COLOR-COS-DEFAULT !important;
}

.pm-expand-heading .pm-title {
    .COS-OPEN-SANS-600();
    font-size: 16px;
    float: left;
}

label.pm-css-label,
.pm-expand-contents .pm-expand-heading div {
    width: calc(100% - 2em);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
}

/* Checkbox Styling */
.pm-expand-contents label {
    .COS-OPEN-SANS-REGULAR();
    font-size: @COS-FONT-SIZE-DEFAULT !important;
}

.pm-faceted-checkbox-wrap,
label.pm-css-label {
    min-height: 20px;
}

label,
input,
.pm-faceted-checkbox-wrap {
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

.pmf-faceted-section [type="checkbox"]:not(:checked),
.pmf-faceted-section [type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}

.pmf-faceted-section [type="checkbox"]:not(:checked) + label,
.pmf-faceted-section [type="checkbox"]:checked + label {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer;
  line-height: 1.5em;
}

.pmf-faceted-section [type="checkbox"]:not(:checked) + label:before,
.pmf-faceted-section [type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px; height: 20px;
  border: 1px solid grey;
  background: #fff;
  border-radius: 2px;
}

/* Check Mark */
.pmf-faceted-section [type="checkbox"]:not(:checked) + label:after,
.pmf-faceted-section [type="checkbox"]:checked + label:after {
  font-family: 'pm-font-icons';
  content: "\e92e";
  position: absolute;
  top: -1px;
  left: -4px;
  font-size: 28px;
  line-height: 0.8;
  color: @COLOR-PM-WHITE1;
  .PM-TRANSITION();
}

.pmf-faceted-section [type="checkbox"]:checked + label:before {
    background-color: @COLOR-PM-BLUE6;
    border-color: @COLOR-PM-BLUE6;
}

/* Checkbox Animation */
.pmf-faceted-section [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.pmf-faceted-section [type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.pm-content-area.pm-refine-expand-heading {
    border-top-color: transparent;
}

.pm-refine-expand-contents {
    padding:10px;
    clear:both;
    margin-right:7px;
}

.pm-refine-section-heading {
    color: @COLOR-COS-DEFAULT;
    padding: 10px;
}
.pm-refine-expand-heading {
    .COS-OPEN-SANS-700();
    border-top: 1px solid #AEB1B6;
    padding: 10px;
    height: 20px;
    cursor: pointer;
}

.pm-expand-heading-icon {
    float: right;
}

.pm-pd-refine-expand-heading {
    position: relative;

    .pm-expand-heading-icon-up,
    .pm-expand-heading-icon-down {
        position: absolute;
        right: -7px;

        .pm-icon-font {
            font-size: 20px;
        }
    }
}

@media (max-width: 1330px) and (min-width: 1199px) {
    .pmf-expand-heading-icon-up,
    .pmf-expand-heading-icon-down {
        display: none;
    }
}

@-moz-document url-prefix() {
  fieldset { display: table-cell; }
}
