@import (reference) "_pm-global.less";

// PD PLAYLIST STYLES //
// Author: Chris Brosky - November 2017 //

.pm-view-plc-plan-approval-list {

  .tnl-table-container {
    margin-bottom: 0 !important;
  }

  .pm-icon-action-thumb-up {
    color: @COLOR-PM-GREEN4;
  }

  .pm-icon-action-thumb-down {
    color: @COLOR-PM-RED1;
  }
}

.pm-plc-legend,
.pm-user-account-emails {
  .pm-icon-info-payment-paid:before {
    color: @COLOR-PM-GREEN4;
  }

  .pm-icon-info-payment-cancelled:before {
    color: @COLOR-PM-RED1;
  }
}

.pm-plc-legend {

  .pm-plc-icon {
    display: inline-block;
    height: 23px;

    .pm-icon-no-circle {
      margin-right: -5px;
    }

    .pm-plc-icon-text {
      position: relative;
      top: -6px;
      display: inline-block;
      margin: 0 15px 0 4px;
    }
  }
}

.pm-legend-row {
  &.pm-no-margin {
    border-top: 1px solid #ccc;
    padding-top: 10px;
    margin: -10px 0 15px 0;
  }

  .col-mw-140 {
    min-width: 135px;
    max-width: 140px;
  }
}

@media (max-width: 992px) {
  .pm-plc-legend {
    margin-top: 20px;
  }

  .pm-legend-row {
    .pm-plc-legend {
      margin-top: 5px;
    }

    .col-mw-140 {
      max-width: 100%;
    }
  }
}
