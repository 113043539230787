@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

.fuelux {

    .wizard,
    .tnl-wizzard {
        border: 0;
        background: none;
        .LESS-ROUNDED-CORNERS-RESET();
        .LESS-BOX-SHADOW-RESET();
        position: static;

        .steps {
            .active {
                .badge {
                    background-color: #fff !important;
                }
            }
        }

        > .step-content {
            border: none;
            padding: 25px 0 20px;


            .form-inline .checkbox input[type=checkbox]{
                position: relative;
                margin-left:0;
            }

            [class*=checkbox] input[type=checkbox],
            [class*=radio] input[type=radio] {
                width: auto;
                height: auto;
                clip: auto;
                margin-left: -20px;
                margin-top: 4px;
            }

            .tnl-container-header {
                padding-bottom: 15px;
            }

            .tnl-container-header h1 {
                margin: 0;
                color: @COLOR-TNL-GRAY1;
                font-size: @FONT-SIZE-MD;
                .COS-OPEN-SANS-600();
            }

             .tnl-container-header p {
                margin: 0;
                font-size: @FONT-SIZE-DEFAULT;
            }

            .tnl-container-content {
                padding: 0 15px;
            }

            .tnl-container-footer {
                margin-top: 30px;
                padding: 15px;
                border-top: 1px solid @COLOR-TNL-GRAY4;
            }

            .tnl-container-footer .actions {
                text-align: center;
            }

            .tnl-container-footer .actions a {
                margin-right: 15px;
            }

            .tnl-container-footer .actions a:last-child {
                margin-right: 0;
            }
        }

        .steps-container {
            border-radius: 4px 0 0 4px;
            overflow: visible;
        }

        .steps-container > ul.steps li,
        ul.steps li {

            span.fa {
                color: @COLOR-PM-WHITE1;
            }
            color: @COLOR-TNL-GRAY2;
            background: none;
            .LESS-ROUNDED-CORNERS-RESET();

            height: 46px;
            line-height: 46px;
            font-size: 13px;
            display: inline-block;
            
            &:first-child,
            &:last-child {
                .LESS-ROUNDED-CORNERS-RESET();
            }

            &.active {
                color: #fff;
                background: @COLOR-PM-GREEN2;
            }

            &.complete,
            &.complete:hover,
            &.complete a {
                color: #fff;
                max-height: 46px;
                background: @COLOR-PM-GREEN1;
            }

            .chevron {
                display: none;
            }

            &.active .chevron {
                display: block;
                border-width: 23px;
                border-left-color: @COLOR-PM-GREEN2 !important;
        
                &:before {
                    border-left-color: @COLOR-PM-GREEN2 !important;
                }
            }

        }

        > .actions {
            z-index: 0;
            background: transparent;
            border-left: none !important;
            .LESS-ROUNDED-CORNERS-RESET();
            top: -64px;

            a, button {
                line-height: 18.6676px;
                font-size: 14px;
            }

            .btn {
                margin-left: 10px;
                margin-right: 0;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    .tnl-wizzard .actions {
        position:relative;
    }

    .markdown-wrapper {
        .cos-rte-button {
            margin-top: 10px;
        }
    }
}

.wiz-text-on {
    color: #000;
    font-size: 13px;
}

.wiz-text-off {
    color: @COLOR-TNL-GRAY4;
    font-size: 13px;
}

.wiz-step-on {
    color: #000;
    font-size: 11px;
}

.wiz-step-off {
    color: @COLOR-TNL-GRAY4;
    font-size: 11px;
}

.wiz-step-cur {
    color: #000;
    font-size: 11px;
    .COS-OPEN-SANS-600();
}

.wiz-bg-off {
    background: @COLOR-TNL-GRAY6;
}

.wiz-bg-on {
    background: #fffbf0;
}

.wiz-bg-cur {
    background: @COLOR-TNL-GREEN1;
}

.wiz-border-off {
    background: @COLOR-TNL-GRAY4;
}

.wiz-border-on {
    background: #000;
}

.wiz-text-cur {
    color: #000;
    font-size: 13px;
}

.wiz-border-cur {
    background: #000;
}

.ui-dialog .fuelux .wizard .actions {
    top: 0;
}

.pm-page-content .fuelux .wizard .actions {
    position: absolute;
    top:5px;
}