@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

// TODO: Refactor tnl-btn to use Bootstrap buttons.
.tnl-btn-text-set {
    padding-right: 4px;
    white-space: nowrap;

    .tnl-btn-text {
        margin-right: -4px;
    }
}

.tnl-btn-text {
    position: relative;
    vertical-align: middle;
}

a.tnl-btn-icon-text,
a.tnl-btn-icon-text:active,
a.tnl-btn-icon-text:visited,
a.tnl-btn-icon-text:hover {
    position: relative;
    margin: 0;
    padding: 3px 10px 3px 25px;
    color: #fff;
    font-size: @COS-FONT-SIZE-DEFAULT;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    vertical-align: middle;

    .tnl-menu-btn {
        padding-right: 22px;
        padding-left: 8px;
    }
}

.tnl-btn-icon,
a.tnl-btn-icon {
    position: relative;
    display: inline-block;
    height: 24px;
    width: 24px;
    vertical-align: middle;
    padding: 2px 0 0 0;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
    .icon-font {
        display: block;
        text-align: center;
        vertical-align: middle;
    }
    &.tnl-btn-toggled-off {
        background: @COLOR-TNL-GRAY4;
        border: 1px solid @COLOR-TNL-GRAY3;
        box-shadow: none;
    }
    .tnl-btn-icon-text .icon-font {
        position: absolute;
        top: 3px;
        left: 7px;
    }
}

.btn,
.btn-danger,
.btn-default,
.btn-info,
.btn-primary,
.btn-success,
.btn-warning,
a.btn,
a.btn-danger:active,
a.btn-default:active,
a.btn-primary:active,
a.btn-danger:visited,
a.btn-default:visited,
a.btn-primary:visited {
    box-shadow: none !important;
    background-image: none !important;
    text-shadow: none !important;
}

.ui-widget-content {
    a.btn-danger,
    a.btn-default,
    a.btn-primary,
    a.btn-danger:active,
    a.btn-default:active,
    a.btn-primary:active,
    a:visited a.btn-danger,
    a:visited a.btn-default,
    a:visited a.btn-primary,
    a.btn-danger:visited,
    a.btn-default:visited,
    a.btn-primary:visited {
         box-shadow: none !important;
         background-image: none !important;
         text-shadow: none !important;
    }
    a.ui-button:hover {
        color: @COLOR-PM-WHITE1;
        text-decoration: none;
    }
}

.upload-cancel-btn-div {
    clear: both;
    text-align: center;

    .upload-cancel-btn {
        display: none;
    }
}

.btn,
a.btn,
button.btn,
.boot-btn,
a.boot-btn,
button.boot-btn {
    background-image: none !important;
    background-position: 0;
    color: @COLOR-PM-WHITE1;
    font-size: @COS-FONT-SIZE-DEFAULT;
    line-height: 1.3334;
    padding-right: 12px;
    padding-left: 12px;
    text-shadow: none !important;
    background: @COLOR-PM-LINK;
    border: none;
    .PM-BTN-RADIUS();

    &.btn-lg {
        padding-right: 20px;
        padding-left: 20px;
    }

    &:hover {
        color: @COLOR-PM-WHITE1;
    }

    &.btn-default,
    &.btn-primary,
    &.btn-success,
    &.btn-info,
    &.btn-warning,
    &.btn-danger {
        background-image: none !important;
        background-position: 0;
        text-shadow: none !important;
    }

    &:hover,
    &.btn-default:hover,
    &.btn-primary:hover,
    &.btn-primary:active,
    &.btn-primary:focus,
    &.btn-success:hover,
    &.btn-info:hover,
    &.btn-warning:hover,
    &.btn-danger:hover {
         box-shadow: none !important;
         background-image: none !important;
    }

    &.btn-default {
        color: @COLOR-PM-WHITE1;
        background-color: @COLOR-PM-LINK;
        box-shadow: none !important;

        .icon-font {
            color: @COLOR-PM-WHITE1;
        }
    }

    &.btn-primary {
        color: @COLOR-PM-WHITE1;
        background-color: @COLOR-PM-LINK;
        box-shadow: none !important;

        .icon-font {
            color: @COLOR-PM-WHITE1;
        }
    }

    &.btn-success {
        color: @COLOR-PM-WHITE1;
        background-color: @COLOR-PM-GREEN3;
        box-shadow: none !important;
        .PM-BTN-RADIUS;

        .icon-font {
            color: @COLOR-PM-WHITE1;
        }
    }

    &.btn-warning {
        color: @COLOR-PM-WHITE1;
        background-color: @COLOR-PM-RED1;
        box-shadow: none !important;

        .icon-font {
            color: @COLOR-PM-WHITE1;
        }
    }

    &.btn-danger {
        color: @COLOR-PM-WHITE1;
        background-color: @COLOR-PM-RED1;
        box-shadow: none !important;

        .icon-font {
            color: @COLOR-PM-WHITE1;
        }
    }

    &.btn-info {
        color: @COLOR-PM-WHITE1;
        background-color: @COLOR-PM-BLUE1;
        box-shadow: none !important;

        .icon-font {
            color: @COLOR-PM-WHITE1;
        }
    }

    &:active,
    &[active],
    &:visited,
    &[visited],
    &:focus,
    &[focus] {
        color: @COLOR-PM-WHITE1;
        box-shadow: none !important;
        background-image: none !important;
        border: none;
        outline: 0 !important;
    }

    &:disabled,
    &[disabled],
    &.disabled {
        color: #767676;
        background: #eeeeee;
        box-shadow: none !important;
        cursor: not-allowed;
        background-image: none !important;

        span,
        .icon-font {
            color: #767676;
        }
    }

    &.btn-link:disabled,
    &.btn-link[disabled],
    &.btn-link.disabled {
        background: none;
        border: none;
    }

    &.btn-link {
        color: @COLOR-PM-LINK;
        text-decoration: none;
        background: none;
        box-shadow: none !important;
        text-shadow: none !important;
        // Do not underline on hover. Otherwise icon only buttons will be underlined too.

        .icon-font,
        span.icon-font {
            color: @COLOR-PM-WHITE1;
        }
        .caret {
            border-width: 5px;
        }
        &:hover,
        &:active {
            color: @COLOR-PM-WHITE1;
            box-shadow: none !important;
            text-shadow: none !important;

            span {
                color: @COLOR-PM-WHITE1;
            }
        }
        &.active {
            color: @COLOR-PM-WHITE1;
            text-decoration: none;
            .LESS-ROUNDED-CORNERS();
            .LESS-BOX-SHADOW-RESET();
            background: @COLOR-TNL-GRAY4;
            cursor: default;
            text-shadow: none !important;

            &:link,
            &:hover,
            &:visited,
            &:active {
                color: @COLOR-PM-WHITE1;
            }
        }
    }
 }

// TOTAL & COMPLETE HACKERY - REMOVE AT SOME POINT - CTB 5/26/17 //
.tnlf-roster-buttons {
    .btn {
        &.pm-outline-btn {

            &.pm-btn-lg {
                color: @COLOR-PM-HEADER2 !important;
                background-color: @COLOR-PM-WHITE1;
                border: 2px solid @COLOR-PM-HEADER2;
                box-sizing: border-box;
                padding: 6.8px 18px 6px;
                margin-top: 4px !important;
                .PM-TRANSITION();

                .pm-icon-font,
                .tnlif.im,
                .tnlif.fa {
                    color: @COLOR-PM-HEADER2 !important;
                    .PM-TRANSITION();
                }

                &:hover {
                    color: @COLOR-PM-BLUE2 !important;
                    text-decoration: none;
                    border-color: @COLOR-PM-BLUE2 !important;
                    .PM-TRANSITION();

                    .pm-icon-font,
                    .tnlif.im,
                    .tnlif.fa {
                        color: @COLOR-PM-BLUE2 !important;
                        .PM-TRANSITION();
                    }
                }
            }
        }
    }
}

.btn-group.tnl-btn-group-toggle{
     & button.btn.btn-link {
        color: @COLOR-TNL-GRAY4;
        text-decoration: none;
        background: #fafafa;
        box-shadow: none;
        text-shadow: none !important;

        // Do not underline on hover. Otherwise icon only buttons will be underlined too.

        .icon-font,
        span.icon-font {
            color: @COLOR-TNL-GRAY2;
        }
        .caret {
            border-width: 5px;
        }
        &:hover,
        &:active {
            color: #333;
            box-shadow: none;

            span {
                color: #333;
            }
        }
        &.active {
            color: @COLOR-PM-ORANGE1;
            text-decoration: none;
            .LESS-ROUNDED-CORNERS();
            .LESS-BOX-SHADOW-RESET();
            background: @COLOR-PM-WHITE1;
            cursor: default;

            &:hover {
            color: @COLOR-PM-ORANGE1;
                span {
                    color:  @COLOR-PM-ORANGE1;
                }
             }
        }
    }
}

.empari .ui-widget-content .ui-tabs-panel a.btn,
.empari .ui-widget-content a.btn {
    color: @COLOR-PM-WHITE1;

    &:active,
    &:visited {
        color: @COLOR-PM-WHITE1;
    }
}

// PM BUTTON STYLES TO OVERRIDE OLD BUTTON STYLES //
.btn-primary,
.btn-link {

    &:hover {
        background-color: @COLOR-PM-LINK-H;
        color: @COLOR-PM-WHITE1;
        text-decoration: none !important;
    }

    &:active {
        background-color: @COLOR-PM-LINK-A;
    }

    &:focus {
        background-color: @COLOR-PM-LINK;
    }

    &:active, &:focus {
        color: @COLOR-PM-WHITE1;
        text-decoration: none !important;
    }
}

//Back Link Bar
.pm-back-link-bar {
    background-color:#efefef;
    padding: 10px;
    display: inline-block;
    position: relative;

    .pm-icon-font {
        position: absolute;
        top:0;
        left:24px!important;
    }

    .pm-back-link-text {
        position: absolute;
        white-space: nowrap;
        top: 15px;
        left: 74px;
    }
}

//Roster buttons
.pm-roster-buttons .pm-outline-btn {
    margin: 0 5px 5px 0;
}