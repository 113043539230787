@import (reference) "../Theme/Common/_common.less";


.tnl-legend {
    padding: 5px 20px;
    color: @COLOR-TNL-GRAY3;
    font-size: @FONT-SIZE-XSM;
    font-weight: normal;
    text-align: center;

    > span {
        display: inline-block;
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }
    .icon-font {
        font-size: 12px;
    }
}