@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";   

.pm-header-search-row {
    position: relative;
    height: 100%;

    div[class^="col-"], 
    div[class*="col-"] {
        min-height: 47px;
    }

}
    
.pm-header-settings-btn,
.pm-header-search-input {
    min-height: 34px;
    position: relative;
    }
     
.pm-header-settings-btn .boot-btn.btn-default,
.pm-header-search-input input {
    position: absolute;
    top: 50%;
    margin-top: 8px;
    right: 10px;
}

.pm-header-settings-btn .boot-btn.btn-default {
    background-color: transparent;
    background-image: none !important;
    box-shadow: none !important;
    border: 2px solid #fff;
    color: #fff;
    padding: 6px 16px;
    border-radius: 32px;
    font-family: 'Open Sans',sans-serif!important;
    font-size: 14px;
    font-size: 1.4rem;
    left: 100%;
    margin-left: -115px;

    &:hover {
        background-color: transparent;
        background-image: none !important;
        box-shadow: none !important;
        border-color: rgba(255,255,255,.75);
        color: rgba(255,255,255,.75);
    }
}

.pm-header-search-input {
    padding: 0;
   
    input {
        border-radius: 32px;
        border: none;
        background-color: #fff;
    }
}
    
.pm-header-search-input input,
.pm-header-search-input input::placeholder {
    text-indent: 30px;
}
    
.pm-header-search-input .pm-icon-ui-search {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -7px;
    font-weight: bold;
    font-size: 18px;
    color: #36474f;
    background-color: #fff;
    padding-right: 12px;
    z-index: 5;

    &:hover {
        color: #4b8ccf;
    }
}
    
.pm-header-partial-view {

    .pm-badge-header-options-page {
        height: 47px;
        position: relative;
        top: 1px;

        .pm-header-options-btn {
            top: 50%;
            position: absolute;
            margin-top: -17.5px;

            button {
                margin-top: 0;
            }
        }
    }

    .pm-header-settings-btn .boot-btn.btn-default,
    .pm-header-settings-btn button {
        background-color: transparent !important;
        background-image: none !important;
        box-shadow: none !important;
        border: 2px solid #fff !important;
        color: #fff !important;
        padding: 6px 16px !important;
        border-radius: 32px !important;
        font-family: 'Open Sans', sans-serif !important;
        font-size: 14px !important;
        font-size: 1.4rem !important;

        &:hover {
            background-color: transparent;
            background-image: none !important;
            box-shadow: none !important;
            border-color: rgba(255,255,255,.75) !important;
            color: rgba(255,255,255,.75) !important;
        }
    }
}

/* =====================================
   ========= RESPONSIVE STYLES =========
   ===================================== */
@media (max-width: 992px) {
    .pm-heading-container {
        padding: 4px 35px 4px !important;
    }

    .pm-page-title {
        margin-bottom: 0;
        position: relative;
        height: 47px;

        h1 {
            position: absolute;
            top: 50%;
            margin-top: -12px;
        }
    }

    .pm-header-partial-view .pm-badge-header-options-page {
        top: 2px;
    }
}

@media (max-width: 768px) {

    .pm-header-search-row {
        padding: 0 10px;

        div[class^="col-"], 
        div[class*="col-"] {
            min-height: 34px;
        }
    }

    .pm-header-settings-btn .boot-btn.btn-default,
    .pm-header-settings-btn button {
        left: 0;
        margin-left: 0;

        &:hover {
            background-color: transparent;
            background-image: none !important;
            box-shadow: none !important;
            border-color: rgba(255,255,255,.75);
            color: rgba(255,255,255,.75);
        }
    }

    .pm-header-search-input .pm-icon-ui-search {
        left: 10px;
        top: 23px;
    }
        
    .pm-header-search-input {
        top: 1px;
        float: right;

        input {
            left: 0;
        }
    }

    .pm-page-title {
        margin-bottom: 0;
        position: relative;
        height: 47px;

        h1 {
            position: absolute;
            top: 50%;
            margin-top: -12px;
        }
    }

    .pm-header-partial-view .pm-badge-header-options-page {
        top: 2px;
    }
}