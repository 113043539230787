﻿@import (reference) "_pm-global.less";


// ICON - WITH TEXT - WITH DROPDOWN //
// Author: Mike Thomas - February 2018 //

// LESS VARIABLES //
@color-hover:  @COLOR-PM-LINK-H;
@color-active:  @COLOR-PM-LINK-A;
@color-open:    @COLOR-PM-GREY9;

.pm-btn-group {
    margin-right: 15px;
    vertical-align: baseline;

    &.pm-icon-dropdown {
        display: inline-flex;

        .pm-action-btn {
            box-shadow: none !important;
            background: none !important;
            border: none !important;
            padding: 0;
            margin: 0 5px 0 0;
            .PM-TRANSITION();

            &:first-child {
               margin-left: 0;
            }

            .pm-action-btn-label {
                height: 30px;
                display: inline-block;

                .pm-action-btn-text {
                    display: inline-block;
                    position: relative;
                    top: -9px;
                    vertical-align: middle;
                    padding: 5px 0;
                    color: @COLOR-PM-LINK;
                    .PM-TRANSITION();
                }
            }

            &:hover {
                .PM-TRANSITION(@prop: all, @time: .1s, @ease: ease-in-out);

                span {
                    color: @COLOR-PS-BLUE1;
                }
            }

            &:active {
                color: @color-active;
                .PM-TRANSITION(@prop: all, @time: .1s, @ease: linear);
            }

            &:focus {
                outline: none;
            }

            .pm-icon-sm {
                margin-right: 0 !important;
                height: 2.3rem;
                width: 2.3rem;
                top: -2px;

                .pm-icon-font {
                    &.pm-icon-font {
                        &:before {
                            font-size: 23px;
                        }
                    }
                }
            }
        }

        .pm-action-dropdown {
            border-radius: 4px;
            margin-top: 10px;

            .pm-icon-sm {
                margin-right: 5px !important;
                height: 2.3rem;
                width: 2.3rem;

                .pm-icon-font {
                    top: 1px;
                    display: inline-block;
                    height: 2.3rem;
                    width: 2.3rem;

                    &:before {
                        font-size: 23px;
                    }
                }
            }

            .pm-action-btn-text {
                position: relative;
                top: -6px;
            }

            a .pm-icon-font {
                padding-left: 0 !important;
                position: relative;
                top: 4px;
            }

            &:before, &:after {
                content: '';
                position: absolute;
                display: inline-block;
            }

            &.dropdown-menu-right {
              &:before {
                top: -7px;
                right: 1.25rem;
                left: auto;
                border-right: 7px solid transparent;
                border-bottom: 7px solid #999;
                border-left: 7px solid transparent;
              }

              &:after {
                top: -6px;
                right: 1.35rem;
                left: auto;
                border-right: 6px solid transparent;
                border-bottom: 6px solid #ffffff;
                border-left: 6px solid transparent;
              }
            }

            &.dropdown-menu-left {
              &:before {
                top: -7px;
                right: auto;
                left: 0.8rem;
                border-right: 7px solid transparent;
                border-bottom: 7px solid #999;
                border-left: 7px solid transparent;
              }

              &:after {
                top: -6px;
                right: auto;
                left: 0.9rem;
                border-right: 6px solid transparent;
                border-bottom: 6px solid #ffffff;
                border-left: 6px solid transparent;
              }
            }

            li {
                a {
                    color: @COLOR-COS-DEFAULT;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 0;
                        margin-top: -15px;
                        width: 3px;
                        height: 30px;
                        border-radius: 0 1px 1px 0;
                        background-color: transparent;
                        .PM-TRANSITION();
                    }

                    &:hover {
                        background-image: none !important;
                        background-color: transparent;
                        color: @color-hover;
                        .PM-TRANSITION();

                        &:before {
                            color: @color-hover;
                            background-color: @color-hover;
                            .PM-TRANSITION();
                        }
                    }

                    &:active {
                        color:@color-active;
                        .PM-TRANSITION(@prop: all, @time: .1s, @ease: linear);
                    }

                    &:focus {
                        outline: none;
                        background-image: none;
                        background-color: transparent;
                    }
                }
            }
        }

        .dropdown-toggle {
            &.pm-action-btn {
                .pm-icon-font {
                    .PM-TRANSITION(@prop: all, @time: .1s, @ease: linear);
                }
            }
        }
    }

    &.open {
        .dropdown-toggle {
            &.pm-action-btn {
                .pm-icon-font {
                    color: @color-open;
                    .PM-TRANSITION();
                }
            }
        }

        .pm-action-dropdown {

            li {
              padding-bottom: 0;

                a {
                    overflow: hidden;
                    width: auto;
                    display: block;
                    margin-right: 0;
                    padding: 5px 10px;

                    &:active {
                        &:before {
                            color: @color-active;
                            background-color: @color-active;
                        }
                    }

                    .pm-action-btn-text {
                        width: 100%;
                        padding: 6px 5px;
                    }
                }
            }
        }
    }
}
