@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";


.icon-font {
    color: @COLOR-TNL-GRAY2;
    
    &.fa {
        font-size: 14px;
        margin: 0 3px;
    }

    &.im {
        display: inline-block;
        font-size: 14px;
    }

    &.tnlif {
        font-size: 15px;
    }

    &.disabled {
        color: @COLOR-TNL-GRAY5 !important;
    }

    &.tnl-pinned {
        color: @COLOR-TNL-GREEN1 !important;
    }

    &.tnl-not-pinned {
        color: @COLOR-TNL-GRAY4 !important;
    }

}

a:hover > .icon-font {
    color: @COLOR-LINK-HOVER;
}

.icon-sprite {
    cursor: pointer;

    &.icon-sprite.small {
        display: inline-block;
        height: 16px;
        width: 16px;
    }

}

.tnl-btn-icon {

    .fa,
    .im,
    .tnlif {
        position: relative;
    }

    .fa {
        top: 1px;
    }

    .im {
        top: 1px;
    }

    .tnlif {
        top: 1px;
    }

    .fa-question {
        left: -1px;
    }

    .fa-play {
        top: 2px;
        left: 1px;
        font-size: 10px;
    }

    .fa-stop {
        top: 2px;
        font-size: 10px;
    }
}

.tnl-icon-rate {
    background: url("../Images/ratings.png") no-repeat;

    &.off {
        background-position: -2px -2px;
        cursor: auto;
    }

    &.empty {
        background-position: -22px  -2px;
    }

    &.half {
        background-position: -42px -2px;
    }

    &.full {
	    background-position: -62px -2px;
    }

    &.ready {
	    background-position: -82px -2px;
    }

}

.fa-rotate-45 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.fa-rotate-135 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
}
:root .fa-rotate-45,
:root .fa-rotate-135 {
    filter: none;
}