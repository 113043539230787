@import (reference) "../Css/_pm-global.less";

// BOOTSTRAP OVERRIDES
.pm-col-fixed-70 {
    width: 50px;
    height: 100%;
    max-height: 50px;
    position: absolute;
    z-index: 7;
        .row {
            margin: 0;
        }
}

.pm-col-offset-70 {
    z-index: 5;
    max-height: 50px;
}

.pm-col-border-right {
    border-right: 1px solid @COLOR-PM-GREY1;
    padding-right: 10px; 
}

// GLOBAL STYLES
.pm-site-header {
    height: 100px;
    height: 10rem;
    background-color: @COLOR-PS-BLUE1 !important;

    .pm-site-header-wrap {
        margin: 0;
    }

    li > a, li > a > span {
        .COS-OPEN-SANS-REGULAR();
    }

    .pm-desktop {
        display: block;
    }

    .pm-mobile {
        display: none;
    }

    .pm-site-header {

        .navbar-nav  {
            height: inherit !important;

            li {
                height: inherit;

                a > span {
                    height: inherit;
                    font-size: 1.2rem;
                    padding: 5px;
                    white-space: nowrap;
                }

                a:hover .icon-font {
                    color: @COLOR-PM-GREEN1 !important;
                    .PM-TRANSITION();
                }
                    
                a.caret {
                    display: inline-block;
                    width: 0;
                    height: 0;
                    margin-left: 2px;
                    vertical-align: middle;
                    border-top: 4px solid;
                    border-right: 4px solid transparent;
                    border-left: 4px solid transparent;
                } 
            }
        }
    }

    // SYSTEM LOGO
    .pm-system-logo-col  {
        position: relative;
        left: 0;
        top: 0;
        width: 50px;
        height: 50px !important;
        background-image: url(/images/pm/portal-images/pm-tnl-logo-white-96x96.png);
        background-repeat: no-repeat;
        background-color: @COLOR-PM-LINK;
        background-size: 48px 48px;
        background-position: center center;
        .PM-TRANSITION();

        &:after {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0 12px 12px;
            border-color: transparent transparent @COLOR-PM-WHITE1 transparent;
            z-index: 999;
            position: absolute;
            top: 100%;
            right: 0;
            margin-top: -12px;
        }

        &:hover {
            cursor: pointer !important;
            background-color: @COLOR-PM-BLUE6;
            .PM-TRANSITION();
        }
    }
    
    // SYSTEM TOGGLE PANEL
    .pm-system-toggle-panel {
        position: absolute;
        top: 50px;
        width: 26rem;
        height: 7.7rem;
        z-index: 999;
        border-width: 5px 0 0 0;
        border-style: solid;
        border-color: @COLOR-PM-BLUE6;
        background-color: @COLOR-PM-WHITE1;
        border-top-left-radius: 0;
        -webkit-border-top-left-radius: 0;
        border-top-right-radius: 0;
        -webkit-border-top-right-radius: 0;
        border-bottom-right-radius: 4px;
        -webkit-border-bottom-right-radius: 4px;
        box-shadow: 0 0 10px rgba(0,0,0,.1), ;
        -moz-box-shadow: 0 0 10px rgba(0,0,0,.1);
        -webkit-box-shadow: 0 0 10px rgba(0,0,0,.1);
        display: none;    

        .pm-toggle-btn-list {
            list-style-type: none;
            padding: 5px 10px;
            margin: 0;

            .pm-toggle-btn {
                position: relative;
                border-radius: 4px;
                border: 1px solid;
                box-sizing: border-box;
                width: 24rem;
                height: 5rem;
                margin: 5px 0;

                > a {
                    font-family: 'Montserrat', sans-serif;
                    font-size: 14px;
                    color: @COLOR-PM-GREY1;
                    position: absolute;
                    top: 50%;
                    margin-top: -2.5rem;
                    padding-bottom: 2px;
                    overflow: hidden;
                    height: 5rem;
                    width: 24rem;
                    left: 50%;
                    margin-left: -12rem;      

                    .pm-toggle-icon {
                        content: "";
                        display: inline-block;
                        position: relative;
                        top: 50%;
                        margin-top: -1.9rem;
                        left: 10px;
                        height: 3.8rem;
                        width: 3.8rem;
                        background-size: 3.8rem 3.8rem;
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-color: transparent;
                    }

                    .pm-toggle-btn-title {
                        display: inline-block;
                        position: absolute;
                        top: 50%;
                        left: 58px;
                        height: 38px;
                        line-height: 38px;
                        margin-top: -19px;
                    }

                    &:focus {
                        outline: none;
                    }
                }

                &:hover {
                    border-width: 2px;
                    box-sizing: border-box;

                    a {
                        background-image: none !important;
                        background-color: transparent;
                    }
                }

                &:first-child {
                    margin-bottom: 10px;
                }
            }

            .pm-pro-learning-btn {
                background-color: #f6f6e8;
                border-color: #a8ad00;

                .pm-toggle-icon {
                    background-image: url(/images/pm/portal-images/pm-tnl-logo-76x76.png);
                }

            }

            .pm-student-growth-btn {
                background-color: #f0f6ff;
                border-color: #4488f7;

                .pm-toggle-icon {
                    background-image: url(/images/pm/portal-images/pm-unify-logo-76x76.png);
                }

                .pm-icon-info-checkmark {
                    position: absolute;
                    top: 50%;
                    margin-top: -.9rem;
                    right: 1.5rem;
                    color: #fff;
                    background: #3d6802;
                    border-radius: 50%;
                    font-size: 18px;
                }
            }
        }
    }

    // CUSTOM SITE LOGO
    .pm-site-logo-col {
        right: 0;
        margin: 5px 0 0 10px;
        padding-left: 10px;

        .pm-site-logo {
            display: block;
            height: 3.5rem;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: left center;
        }
    }

    // HEADER ICONS
    .pm-header-icon-col {
        height: 5rem;
        width: auto;
        float: right;
        margin-top: 10px;

        .pm-header-icon-list {
            height: 5rem;
            position: relative;
            top: 50%;
            left: 0;
            margin: -2.5rem 0 0 0;
            padding: 0;
            text-indent: 0;
            list-style-type: none;
            text-align: right;

            .tnl-popover {
                margin-top: -37px;

                .icon-font {
                    font-size: 23px;
                }
            }

            .pm-header-icon {
                position: relative;
                display: inline-block;
                width: 27px;
                height: 50px;
                margin: 0 5px;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 5px;
                }

                &.pmf-header-icon-popover {
                    cursor: pointer !important;

                    span.badge {
                        position: absolute;
                        top: 6px !important;
                        left: 15px !important;
                        padding: 1px 7px;
                        z-index: 5;
                        background-color: @COLOR-PM-RED1;

                        span {
                            color: #fff;
                        }
                    }
                }

                .pm-header-icon-span {
                    font-size: 24px;
                    color: @COLOR-PM-WHITE1;
                    display: block;
                    position: absolute;
                    top: 50%;
                    margin-top: -12px;
                    left: 50%;
                    margin-left: -12px;
                    .PM-TRANSITION();

                    &:hover,
                    &:active {
                        color: @COLOR-PM-GREEN1 !important;
                        cursor: pointer !important;
                        .PM-TRANSITION();
                    }

                    &:focus {
                        outline: none !important;
                    }
                }

                .pm-icon-menu-help {
                    border-radius: 50%;
                    box-sizing: border-box;
                    margin-top: -12px;

                    &:hover {
                        border-color: @COLOR-PM-GREEN1;
                        .PM-TRANSITION();
                    }
                }

                .pm-icon-menu-messages {
                    font-size: 30px;
                    margin-top: -14px;
                    margin-left: -15px;
                }

                .pm-icon-menu-medialocker {
                    font-size: 30px;
                    margin-top: -14px;
                    margin-left: -15px;
                }
            }

            .tnl-popover {
                padding-right: 10px;
                padding-left: 10px;

                .fa-external-link {
                    font-size: 27px!important;
                    margin-top: -3px;
                    margin-left: -7px;
                    margin-right: -9px;
                    
                    &:hover {
                        color: @COLOR-PM-GREEN1;
                        cursor: pointer !important;
                        .PM-TRANSITION(); 
                    }
                }
            }
        }
    }

    // USER MENU
    .pm-user-menu-col   {
        padding-left: 0;
        padding-right: 0;
        height: 5rem;
        position: relative;

        ul > li {
            padding-bottom: 0;
        }

        .pm-user-logout {

            .icon-font {
                display: none !important;
            }
        }

        .tnl-menu-text {
            margin-left: 0 !important;
        }

        .navbar-left {
            position: relative;
            min-width: 1.5rem;
            height: 5rem;
            left: 0;
            top: 50%;
            margin-top: -2.5rem;
            padding-left: 0;

            &:hover .pm-icon-ui-expand-chevron  {
                color: @COLOR-PM-WHITE1 !important;
            }

            .dropdown {
                height: inherit;

                .dropdown-toggle {  
                    
                    background-color: transparent;
                    position: relative;
                    top: 50%;
                    margin-top: -3rem;
                    padding: 0;

                    &:hover {
                        .pm-user-name {
                            color: @COLOR-PM-GREEN1 !important;
                            .PM-TRANSITION();
                        }
                    }

                    .pm-user-menu {
                        font-size: 1.4rem;
                        font-weight: 400;
                        font-style: normal;
                        text-shadow: none;
                        text-overflow: ellipsis;
                        display: block;
                        padding: 10px 0 10px 0; //10px 18px 10px 10px

                        .pm-user-greeting {
                            .COS-OPEN-SANS-REGULAR();
                            color: @COLOR-PM-WHITE1 !important;
                            display: block;
                            font-size: inherit;
                            margin-bottom: -0.5rem;
                        }

                        .pm-user-name {
                            .COS-OPEN-SANS-REGULAR();
                            .PM-TRANSITION();
                            color: @COLOR-PM-WHITE1;
                            white-space: nowrap;
                            //overflow: hidden; //mike
                            text-overflow: ellipsis !important;
                            //min-width: 26.5rem; //mike
                            max-width: 27.5rem;
                            display: inline-block;

                            &:after {
                                font-family: 'pm-font-icons';
                                content: "\e929";
                                display: inline-block;
                                position: relative;
                                top: 6px;
                                left: 3px; 
                                font-size: 20px;
                                text-shadow: none;
                                width: 13px;
                                height: 8px;
                            }
                        } 
                    }
                }

                .dropdown-menu {
                    padding: 0;
                    left: -1rem;
                    border-radius: 0 0 4px 4px;
                    -webkit-border-radius: 0 0 4px 4px;
                    border-top: 0;
                    border-color: #bbb;
                    overflow: hidden;

                    &:before {
                        content: "";
                        height: 5px;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-color: @COLOR-PM-BLUE6;
                    }

                    li { 
                        border-bottom: none;

                        a {
                            padding: 12px 10px 12px 20px;
                            color: @COLOR-COS-DEFAULT;
                            .COS-OPEN-SANS-REGULAR();
                            .PM-TRANSITION();
                            .PM-DROPDOWN-HOVER-ACCENT();
                        }

                        &:first-child a {
                            margin-top: 5px;
                        }

                        &:last-child a {
                            &:hover {
                                &:before {
                                    background-color: transparent !important;
                                }
                            }
                        }
                    }

                    .pm-user-logout {
                        .PM-TRANSITION();
                        background-color: @COLOR-PM-GREY8 !important;
                        border-radius: 0 0 4px 4px;
                        -webkit-border-radius: 0 0 4px 4px;
                        -moz-border-radius: 0 0 4px 4px;

                        span {
                            color: @COLOR-COS-DEFAULT;
                            .PM-TRANSITION();
                        }

                        &:hover {
                            .PM-TRANSITION();
                            background-color: @COLOR-PM-GREY1 !important;
                            color: @COLOR-PM-WHITE1;

                            span {
                                color: @COLOR-PM-WHITE1;
                                .PM-TRANSITION();
                            }
                        }
                    }
                }
            }
        }
    }
}
//pm-modus-header
.pm-modus-header {
    .pm-col-offset-70, .pds-app-actions {
        padding-left: 0;
        padding-right: 0;
    }
    .pds-app-header-bar {
        margin-left: 0;
        margin-right: 0;
    }
    .pds-app-actions {
        margin-right: -10px;
    }
    .pm-site-logo-col {
        margin: 10px 0 0 0;
        padding-left: 0;
    }
    .pm-header-icon-col {
        margin-top: 0px;
    }
    .pm-header-icon {
        margin: 0 20px 0 0!important;
    }
}
@media  (max-width: 1415px) and (min-width: 1200px) {

    .pm-col-border-right {
       &.col-lg-4 {
            width: 31.33333333%
        }
    }

    .pm-user-name-header {
        &.col-lg-2 {
            width: 18.66666667%;
        }
    }
}

// RESPONSIVE GOODNESS
@media  (max-width: 1200px) and
        (min-width: 1025px) {
    .pm-desktop {
        display: block !important;
    }

    .pm-mobile {
        display: none;
    }

    .pm-site-header {
        height: 100px !important;
        height: 10rem !important;

        .pm-site-logo-col {
            height: 5rem;
            left: 0;
        }   

        .pm-header-icon-col {

            .pm-header-icon-list {
                left: 0;
            }
        }

        .pm-user-menu-col {

            .navbar-left {
                left: 0;
            }
        }
    }
}

@media  (max-width: 1215px) and 
        (min-width: 1025px) {

    .ui-layout-container {
        .pm-site-header {
            .pm-header-icon-col {
                .pm-header-icon-list{
                    .pm-header-icon {
                        margin: 0;
                    }
                }
            }
        }
    }
 }

@media  (max-width: 965px) {

    .ui-layout-container {
        .pm-site-header {
            .pm-header-icon-col {
                .pm-header-icon-list{
                    .pm-header-icon {
                        display:none;
                    }
                }
            }
        }
    }
 }

@media  (max-width: 1024px) and 
        (min-width: 769px) {

    .pm-desktop {
        display: block !important;
    }

    .pm-mobile {
        display: none !important;
    }

    .pm-site-header {
        height: 100px !important;
        height: 10rem !important;

        .pm-col-offset-70 .pm-col-border-right {
            padding-right: 10px;
        }

        .pm-system-logo-col {
            width: 5rem;
            height: 5rem!important;
            background-size: 34px 34px;

            &:after {
                border-width: 0 0 8px 8px;
                margin-top: -8px;
            }
        }

        .pm-system-toggle-panel {
            display: none;
            top: 6rem;
        }

        .pm-site-logo-col {
            height: 5rem;
            right: 0;

            .pm-site-logo {
                margin-left: 0;
            }
        }

        .pm-header-icon-col {
            width: 100%;
            height: 5rem;
                
            .pm-header-icon-list {
                left: 0;
                text-align: right;

                .pm-header-icon {
                    width: 27px;
                    margin: 0;

                    &.pmf-header-icon-popover {
                        cursor: pointer !important;

                        span.badge {
                            position: absolute;
                            top: 17px !important;
                            left: 15px !important;
                            padding: 2px 6px;
                            width: 20px;
                            height: 15px;
                            z-index: 5;

                            > span {
                                font-size: 12px;
                                display: block;
                                position: relative;
                                top: 9px;
                                left: 4px;
                            }
                        }
                    }

                    span {
                        font-size: 18px;
                        margin-top: -9px;
                        margin-left: -9px;
                    }

                    .pm-icon-menu-help {
                        margin-top: -12px;
                    }                  
                }
                &:last-child {
                    margin-right: 0;
                }
            }

            .tnl-masquerading-bar {
                right: 195px;
            }
        }
                           
        .pm-user-menu-col {
            height: 5rem;
            width: 100%;
            padding: 0;
            left: -1rem;

            .navbar-left {
                position: relative;
                height: inherit; 
                width: inherit;   
                left: 0;
                top: 50%;
                margin-top: -3rem;
                
                .dropdown {  
                    height: inherit;     
                    width: inherit;
                    
                    .dropdown-toggle {
                        position: absolute;
                        top: 50%;
                        margin-top: -2.5rem;
                        width: inherit;
                        height: inherit;

                        .pm-user-menu {
                            padding: 11px 0; //11px 14px

                            .pm-user-greeting {
                                margin-bottom: -0.7rem;
                            }

                            .pm-user-greeting, .pm-user-name {
                                font-size: 1.2rem !important;
                            }
                        }
                    }

                    .dropdown-menu {
                        left: 0;
                    }
                }
            }  
        }
    }
}

@media  (max-width:768px) {

    .pm-col-fixed-70 {
        max-height: 5rem;
        max-width: 6rem;
    }

    .pm-col-offset-70 .pm-user-name-mobile {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .pm-desktop, .pm-main-portal-nav {
        display: none !important;
    }

    //MOBILE MENU
    .pm-mobile {
        display: block !important;
        width: 100%;
        height: 5rem;

        &.pm-user-name-mobile {
            display: block !important;
            color: @COLOR-PM-WHITE1;
            height: 5rem;
            height: 50px;

            .dropdown-toggle {  
                    
                background-color: transparent;
                position: relative;
                top: 50%;
                margin-top: -3rem;
                padding: 0;
            }

            .pm-user-name-block {
                position: absolute;
                left: 1.5rem;
                left: 15px;
                top: 50%;
                margin-top: -20px;
                width: 100%;

                .pm-user-greeting {
                    .COS-OPEN-SANS-REGULAR();
                    display: block !important;
                    font-size: inherit;
                    margin-bottom: -1.5rem;
                }

                &:after {
                    content: "\00A0";
                    display: inline-block;
                    position:relative;
                    top: 8px;
                    left: 0;
                    font-size: 26px;
                    text-shadow: none;
                    width: 13px;
                    height: 8px;
                    .PM-TRANSITION();
                }

                &:hover {
                    .pm-user-name, &::after {
                        .PM-TRANSITION();
                    }
                }
            }

            .navbar {
                margin-bottom: 0;
            }
        }

        .navbar-inverse {
            background-image: none !important;
            background-color: transparent !important;
            border: none !important;
            margin-bottom: 0;

            .navbar-toggle {
                padding: 10px 10px;
                border: none;
                border-radius: 0;

                &:hover, &:visited, &:focus {
                    background-color: transparent;
                }
            }
        }

        .tnl-masquerading-bar {
            left: 14px;
        }
    }

    .pm-mobile-nav-col {
        background-color: @COLOR-PM-GREY7;
        min-height: 0;

        .navbar-collapse {
            border: none !important;
            box-shadow: none;

            ul .pm-header-icon:first-child a:hover {
               box-shadow: inset 0 1px 0 #252d31;
            }

            li > a {
                padding: 14px;
            }
        }

        ul > li {
            padding-bottom: 0;
        }

        .navbar-nav {
            margin: 0 -10px;

            .pm-header-icon {
                padding-bottom: 0;
                border-bottom: 1px solid #252d31;

                .pm-icon-menu-help {
                    border: 2px solid #fff;
                    border-radius: 50%;
                    box-sizing: border-box;
                }
            
                span {
                    font-size: 18px;
                    display: inline-block;
                    position: relative;
                    top: 2px;
                    margin-right: 10px;
                }
            }

            .pm-mobile-nav-item  {

                a {
                    padding: 14px;
                }

                ul > li > a .fa {
                    display: none;
                }

            }

            li > a {
                text-shadow: none;
                color: @COLOR-PM-WHITE1;

                &:hover {
                    background-color: @COLOR-PM-BLUE6;
                }
            }

            .pm-mobile:last-child {
                margin-bottom: 20px;
            }
        }

        .nav .open > a,
        .nav > li > a:focus {
            background-color: @COLOR-PM-BLUE6;
        }

        .pm-mobile-dropdown-menu {
            background-color: @COLOR-PM-WHITE1 !important;
            border-radius: 0 !important;
            padding: 0;

            li {

                a {
                    color: @COLOR-COS-DEFAULT;
                    font-weight: normal;
                    font-family: "Open Sans", sans-serif;
                    max-width: 100%;
                    box-sizing: border-box;
                    padding: 14px 15px 14px 20px !important;
                    .PM-TRANSITION();

                    &:before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 0;
                        margin-top: -15px;
                        width: 3px;
                        height: 30px;
                        border-radius: 0 1px 1px 0;
                        background-color: transparent;
                        .PM-TRANSITION();
                    }
                    
                    &:hover {
                        color: @COLOR-PM-BLUE5;
                        background-color:transparent;
                        box-sizing: border-box;
                        .PM-TRANSITION();

                        &:before {
                            background-color: @COLOR-PM-BLUE6;
                            .PM-TRANSITION();
                        }
                    }
                }

                .tnl-menu-text {
                    margin-left: 0;
                }
            }

            .divider {
                display: none !important;
            }
        }
    }

    .pm-priority-navbar {
        display: none !important;
    }

    .pm-site-header {
        height: 50px !important;
        height: 5rem !important;
        background-color: @COLOR-PM-GREY1 !important;

        .pm-site-header-container {
            height: 5rem;

            .pm-site-header-wrap {
                height: 5rem !important;
            }

            .pm-system-logo-col {
                width: 5rem;
                height: 5rem !important;
                background-size: 34px 34px;

                &:after {
                    border-width: 0 0 8px 8px;
                    margin-top: -8px;
                }
            }

            .pm-system-toggle-panel {
                display: none;
                top: 5rem;
            }

            .pm-header-icon-col {
                width: 5rem;
                height: 5rem;
                
                .pm-header-icon-list {
                    float: none;
                }
            }

            .pm-user-menu-col {
                padding-left: 15px;

                .navbar-left {
                    margin: 7.5px 0;
                    top: 4px;
                    left: 10px;
                    border: none !important;

                    .dropdown .dropdown-toggle {
                        padding: initial !important;
                        line-height: 18px;

                        .pm-icon-ui-expand-chevron {
                            display: none !important;
                        }
                    }
                }  
            }
        }
    }
}

.navbar-default .navbar-collapse {
    width: 100% !important;
    box-shadow: none !important;
}

.pds-header {
    display: flex;
    justify-content: space-between;

    .pds-header-logo-bar{
        display: flex;
        flex: 1 1 50%;
        align-items: center;
    }

    .navbar-toggle {
        width: 45px;
        height: 36px;
        float: unset;
        .icon-bar {
            background-color: #fff;
        }

    }

    .tnl-masquerading-bar {
        position: static;
        top: unset;
        margin-top: 0;
        right: unset;
        z-index: unset;
        display: inline-block;

        a span {
            color: #fff !important;
        }
    }

    .cos-badge-header {
        position: relative;

        .badge {
            position: absolute;
            top: -5px;
            right: -11px;
            background-color: @COLOR-PM-RED1;

            span {
                color: #fff;
            }
        }
    }

    .pm-system-logo-col {
        display: inline-block;
    }

    .logo {
        display:block;
        margin-left: 10px;
        position: relative;
        width:100%;

        .pm-site-logo {
            height: 40px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: left center;
            display: block;

            margin-top: unset!important;
            margin-left: unset!important;
            top: unset!important;
            position: unset!important;
            overflow: unset!important;
        }
    }

    .pm-header-icon-list {
        list-style-type: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height:     100%;
        margin-bottom: 0;
    }
    .pm-header-icon {
        display: inline-block;
        margin: 0 20px 0 0;

        .pm-header-icon-span {
            font-size: 24px;
            fill: @COLOR-PM-WHITE1;
            display: block;
            .PM-TRANSITION();

            &:hover,
            &:active {
                fill: @COLOR-PM-GREEN1 !important;
                cursor: pointer !important;
                .PM-TRANSITION();
            }

            &:focus {
                outline: none !important;
            }
        }

        .pm-icon-menu-medialocker {
            //There isn't a PDS icon for media locker so instead, let's just mimic the size and color of pds icon
            color:white;
            font-size: 30px;
            width: 26px;
            height: 35px;
            position: relative;
            &:before {
                top: 0px;
                left: 0px;
                position: absolute;
            }
            &:hover,
            &:active {
                color: @COLOR-PM-GREEN1 !important;
                cursor: pointer !important;
                .PM-TRANSITION();
            }
        }

    }

    .tnl-masquerading-bar {
        right: unset!important;
    }

}

@media (max-width: 767px) {
    .pds-header {
        grid-template-columns: auto 1fr auto;
        .logo {
            display: none;
        }
        .right-side {
            grid-template-columns: auto auto auto;
        }
        .user .nav {
            margin-top: -0.8rem;
        }
        .navbar-nav .open .dropdown-menu {
            position: absolute;
            top: 58px;
            background: white;
        }
        .pm-header-icon-col {
            width: unset!important;
            height: unset;
        }

    }
}
@media (max-width: 560px) {
    .pds-header {
        .logo-bar {
            display: none;
        }
        .right-side {
            grid-template-columns: auto auto auto;
        }

        #pds-toolbar-user-menu {
            order: 1;
        }

        .pm-header-icon-col {
            order: 2;
            li {
                display: none;
            }
        }

        pds-app-switcher {
            order: 3;
            justify-self: end;
        }
    }
}
