@import (reference) "../Theme/Common/_common.less";

.tnl-tree, .tnl-tree ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.tnl-tree ul {
    background-color: white;
    margin-top: 4px;
    display: none;
}

.tnl-tree-option {
    font-size: @FONT-SIZE-SM;
}

.tnl-tree ul.tnl-tree-ul-open {
    display: block;
}

.tnl-tree li {
    margin: 0;
    padding: 2px 0 0 16px;
    border: 0 solid @COLOR-TNL-GRAY4;
    list-style: none;
    background: url("../images/tnl-tree-line.gif") no-repeat scroll 0 0 transparent;
}

.tnl-tree-checkbox {
    margin-right: 2px;
    *margin-right: -1px;
}

.tnl-tree li.tnl-tree-li-last {
    background-position: 0 -1766px;
}

.tnl-tree-folder {
    display: inline-block;
    border: 0 solid @COLOR-TNL-GRAY4;
    margin-top: -3px;
    *padding-bottom: 3px;
    cursor: pointer;
}

.tnl-tree-text-on {
    vertical-align: top;
    color: #1C5E8E;
    border-bottom: 1px dotted @COLOR-TNL-GRAY3;
    cursor: pointer;
}

.tnl-tree-text-off {
    vertical-align: top;
    color: @COLOR-TNL-GRAY2;
    cursor: pointer;
}

.tnl-tree-text-checked {
    color: #f00;
}

.tnl-tree-text-disabled {
}