@import (reference) "_pm-global.less";

.tnl-footnotes {
    color: @COLOR-PM-GREY11;
    font-size: @COS-FONT-SIZE-10;
    margin-top: 10px;

    .icon-font {
        color: @COLOR-PM-GREY11;
    }
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            margin: 3px 0 0;
            padding: 0;

            a {
                color: @COLOR-PM-GREY11;
            }
        }
    }
}