@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

.tnl-item-list {
	margin: 0;
	padding: 0;
	list-style-type: none;

    > li {
      position: relative;
	    padding: 15px 0;
	    border-bottom: 1px solid #ccc;

        .pm-btn-lg {
            margin-top: 5px;
        }

        &:first-child {
            padding-top: 0;
        }

        .pm-icon-list{
            float: left;
            clear: both;
        }
        .pm-text-list {
            float: left;
            padding: 5px 0 0 5px;
        }

				.fa {
					position: relative;
					top: -8px;
					left: -10px;
				}
    }

    > li.tnl-no-results:hover {
	    background: none;
	    border-bottom: none;
        .PM-ROUNDED-CORNERS();
    }

    > li:last-child {
	    border-bottom: none;
    }

    > li .title {
	    margin-right: 20px;
    }

    > li .description,
    > li .links {
	    margin: 1px 0;
	    padding: 0;
	    color: @COLOR-TNL-GRAY1;
	    font-size: 13px;
	    line-height: 15px;
    }

    > li .links a {
	    margin-left: 10px;
    }

    > li .links a:first-child {
	    margin-left: 0;
    }

    > li .description:last-child {
	    margin-bottom: 0;
    }

    > li.selected {
	    color: @COLOR-TNL-ORANGE1;
        .COS-OPEN-SANS-600();

        a,
        span.tnlif,
        &:hover a,
        label {
	        color: @COLOR-TNL-ORANGE1;
	        text-decoration: none;
            .COS-OPEN-SANS-600();
        }
    }

    .pagination {
        display: inline-block;
        margin: 0;
        text-align: center;
    }

    .right {
	    text-align: right;
    }

    > li.has-icon > .list-icon {
        position: absolute;
        top: auto;
        left: 0;
	    display: inline-block;
	    width: 25px;
	    text-align: center;
    }

    > li.has-icon > div {
        position: relative;
        left: 30px;
        margin-right: 71px;
	    display: block;
    }

    > li.show-all {
        text-align: center;
    }

    > li.show-all a,
    > li.note {
	    border-bottom: none;
	    font-size: 13px;
	    padding-bottom: 0;
    }

    > li.note {
	    padding: 5px 10px 5px 0;
	    color: @COLOR-TNL-GRAY1;
    }

    &.attachments .actions {
        display: inline-block;
        position: absolute;
        top: 13px;
        left: 5px;
        width: 18px;
        height: 17px;
        text-align: center;
    }

    &.attachments .file {
        position: relative;
    }

    &.attachments .title {
        position: relative;
        left: 24px;
        margin-right: 24px;
    }

    &.attachments .tnlif {
        cursor: default;
    }

    &.simple li {
        padding: 0 0 10px;
        border: none;
    }

    &.simple li:last-child {
        padding: 0;
    }

    li.no-results {
        border: none;
    }

    &.tnl-item-list-small {
        font-size: @FONT-SIZE-SM;
    }

}

.tnl-dual-column .tnl-item-list {

    > li:first-child {
	    border-top: 1px dashed @COLOR-TNL-GRAY4;
    }

    > li:last-child {
	    border-bottom: 1px dashed @COLOR-TNL-GRAY4;
        padding-bottom: 12px;
    }

}

.tnl-link-list {
	margin: 0;
	padding: 0;
	list-style-type: none;

    > li,
    a {
        margin: 0;
        padding: 0;
    }
}

.tnl-list {
    margin: 5px 0;
    padding: 0;
    list-style-type: none;

    & > li {
        margin: 0 0 10px;
        padding: 0;
    }
}

.tnl-list-simple {

    .tnl-list-simple header h1 {
        font-size: 13px;
        margin-bottom: 3px;
        color: @COLOR-TNL-GRAY2;
    }

    .tnl-list-simple ul {
        margin: 0 0 1em 10px;
        padding: 0;
        list-style-type: none;
    }

    .tnl-list-simple li {
        margin: 0;
        padding: 2px 0;
    }

}
