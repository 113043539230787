@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

.well {
    padding: 15px;
    background-image: none;
    background: @COLOR-TNL-GRAY6;
    border-color: @COLOR-TNL-GRAY4;
    .PM-ROUNDED-CORNERS();
    .LESS-BOX-SHADOW-RESET();


    .actionbar > div {
        margin-top: 0;
        margin-bottom: 0;
    }

    header,
    .well-header {

        p {
            color: @COLOR-TNL-GRAY2;
            font-size: @COS-FONT-SIZE-DEFAULT;
        }

        h1,
        h2,
        h3 {
            margin: 0;

            small {
                vertical-align: baseline;
            }
        }
    }

    &.well-success {
        background: @COLOR-PM-GREEN6;
        border-color: @COLOR-PM-GREEN5;
    }

    &.well-complete {
        background: @COLOR-PM-GREEN6;
        border-color: @COLOR-PM-GREEN5;

        header,
        .well-header,
        main,
        .well-content,
        aside {
            > h1,
            > p,
            > span,
            > .icon-font {
                color: @COLOR-PM-GREEN4;
            }
        }
    }

    &.well-scheduled {
        background: #fff;
        border-color: @COLOR-COS-DEFAULT;

        p, span, .icon-font {
            color: @COLOR-COS-DEFAULT;
        }
    }

    &.well-in-progress {
        .icon-font {
            color: @COLOR-COS-DEFAULT;
        }
    }
}
