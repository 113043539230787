@import (reference) "_pm-global.less";

// LESS VARIABLES //
@primary-default: @COLOR-PM-LINK;       // #0066A5
@primary-hover: @COLOR-PM-LINK-H;       // #00427C
@primary-active: @COLOR-PM-LINK-A;      // #00427C

@secondary-default: @COLOR-PM-LINK;     // #0066A5
@secondary-hover: @COLOR-PM-LINK-H;     // #00427C
@secondary-active: @COLOR-PM-LINK-A;    // #00427C

@success-default: @COLOR-PM-GREEN3;     // #3d6802
@success-hover: @COLOR-PM-GREEN1;       // #8ec641

@error-default: @COLOR-PM-RED1;         // #bb1c1a
@error-hover: @COLOR-PM-PINK3;          // #f24157

@disabled-default: #eee;                // #eeeeee
@disabled-dark: #767676;                // #767676


// GENERAL BUTTON STYLES //
button {
    white-space: nowrap;
    text-overflow: ellipsis;

    &:active span,
    &:focus span {
        position: relative;
        top: 0;
        left: 0;
    } // Prevents button text from jumping onClick in stupid IE
}

.pm-btn-lg,
.pm-btn-sm {
    position: relative;
    top:0;
    left:0;
    font-size: @COS-FONT-SIZE-DEFAULT !important;
    border: none;
    text-transform: capitalize;
    display: inline-block;
    .PM-BTN-RADIUS();
    .PM-TRANSITION();
    .COS-OPEN-SANS-REGULAR();


    &:hover {
        text-decoration: none;
        outline: none;
        .PM-TRANSITION();
    }

    &:focus {
        outline: none;
    }

    span.pm-icon-font {
        display: inline-block !important;
        padding: 0 !important;
        margin: 0 5px 0 0;
        position: relative;
        top: 2px;
        height: 14px;
        width: 14px;

        &:before,
        &::before {
            position: absolute;
            top: 0;
            left: 0;
        }

        &:focus {
            outline: none;
        }
    }

    .pm-icon-font {
        margin-right: 8px;
    }
}

.pm-btn-lg {
    padding: 7px 18px;
    margin: 0 15px 0 0;
    white-space: nowrap;

    .pm-icon-font,
    .tnlif.im,
    .tnlif.fa {
        color: @COLOR-PM-WHITE1;
    }

    &.btn-block {
        text-align: center;
    }
}

.pm-btn-sm {
    padding: 5px 16px;
    margin: 5px 8px 0 0;
    white-space: nowrap;

    .pm-icon-font,
    .tnlif.im,
    .tnlif.fa {
        color: @COLOR-PM-WHITE1;
    }
}

.pm-outline-btn.pm-btn-lg,
.pm-disabled-outline-btn.pm-btn-lg {
    padding: 5px 18px;
    margin-right: 10px;
}

.pm-outline-btn.pm-btn-sm,
.pm-disabled-outline-btn.pm-btn-sm {
    padding: 3px 16px;
    margin-right: 10px;
}

// PRIMARY BUTTON //
.pm-primary-btn {
    background-color: @COLOR-PM-LINK;
    border: none;
    color: @COLOR-PM-WHITE1 !important;

    span {
        color: @COLOR-PM-WHITE1 !important;
    }

    &:hover {
        background-color: @primary-hover;
    }

    &:active {
        background-color: @primary-active;
    }
    .icon-font {
        color: #fff;
    }
}

a[disabled] {
   pointer-events: none;
   cursor: default;
}

form[disabled] .pm-primary-btn,
.pm-primary-btn[disabled],
.pm-primary-btn[disabled] span {
    color: #767676!important;
    background-color: #eee !important;

    &:hover {
        cursor: not-allowed;
    }
}

form[disabled] .form-control {
    cursor: not-allowed;
    background-color: #ededed;
    opacity: 1;
}

.pm-inline-form {
    margin:10px;

    .input-group-btn:last-child>.pm-primary-btn {
        margin-left: 7px!important;
        .PM-BTN-RADIUS();
    }
    .form-control:first-child {
        .PM-ROUNDED-CORNERS();
    }
}

// OUTLINE BUTTON
.pm-outline-btn {
    color: @secondary-default !important;
    background-color: @COLOR-PM-WHITE1;
    border: 1px solid @secondary-default;
    box-sizing: border-box;
    .PM-TRANSITION();

    .pm-icon-font,
    .tnlif.im,
    .tnlif.fa {
        color: @secondary-default !important;
        .PM-TRANSITION();
    }

    &:hover, &:focus {
        color: @secondary-hover !important;
        text-decoration: none;
        border-color: @secondary-hover !important;
        .PM-TRANSITION();

        .pm-icon-font,
        .tnlif.im,
        .tnlif.fa {
            color: @secondary-hover !important;
           .PM-TRANSITION();
        }
    }

    &:active {
        color: @secondary-active !important;
        border-color: @secondary-active !important;
        .PM-TRANSITION();

        .pm-icon-font,
        .tnlif.im,
        .tnlif.fa {
            color: @secondary-active !important;
            .PM-TRANSITION();
        }
    }
    .icon-font {
        color: @secondary-default !important;
    }
}

fieldset[disabled] .pm-outline-btn,
.pm-outline-btn[disabled],
.pm-outline-btn[disabled] span {
    color: @disabled-dark !important;
    background-color: @COLOR-PM-WHITE1 !important;
    border-color: @disabled-dark !important;

    .fa {
        color: @disabled-dark !important;
    }

    &:hover {
        cursor: not-allowed;
        color: @disabled-dark !important;

        span, .fa {
            color: @disabled-dark !important;
        }
    }
}

// SUCCESS BUTTON
.pm-success-btn {
    background-color: @success-default;
    color: @COLOR-PM-WHITE1 !important;

    &:hover {
        background-color: @success-hover;
    }
    .icon-font {
        color: #fff;
    }
}

// ERROR BUTTON
.pm-error-btn {
    background-color: @error-default;
    color: @COLOR-PM-WHITE1 !important;

    &:hover {
        background-color: @error-hover;
    }
}

a[disabled] {
    pointer-events: none;
    cursor: default;
    color:  @disabled-dark !important;
}

// DISABLED BUTTON
.pm-disabled-btn,
.pm-success-btn[disabled],
.pm-error-btn[disabled]  {
    background-color: @disabled-default;
    color:  @disabled-dark !important;

    &:hover {
        background-color: @disabled-default !important;
        color:  @disabled-dark !important;
        cursor: not-allowed;
    }
}

.pm-disabled-outline-btn {
    background-color: @COLOR-PM-WHITE1;
    color:  @disabled-dark !important;
    border: 2px solid @disabled-dark;
    box-sizing: border-box;

    &:hover {
        background-color: @COLOR-PM-WHITE1 !important;
        color:  @disabled-dark !important;
        cursor: not-allowed;
    }
}

// ACTION BUTTON DISABLED
.pm-action-btn[disabled] {
  color: @disabled-dark !important;

  &:hover {
    cursor: default;
  }
}

// BUTTON LINKS (HREFs) //
.pm-link-btn {
    position: relative;
    background-color: transparent !important;
    color: @COLOR-PS-BLUE3; //#0066A5
    .PM-TRANSITION();

    &:focus {
        outline: 5px auto -webkit-focus-ring-color;
    }

    &:hover {
        color: @COLOR-PS-BLUE3 !important; //#0066A5
        .PM-TRANSITION();

        span {
            color: @COLOR-PS-BLUE3 !important; //#0066A5
            .PM-TRANSITION();
        }
    }

    &:active {
        color: @COLOR-PS-BLUE3 !important; //#0066A5
        .PM-TRANSITION();

        span {
            color: @COLOR-PS-BLUE3 !important; //#0066A5
            .PM-TRANSITION();
        }
    }

    &:visited {
        color: @COLOR-PS-BLUE3;
    }

    .pm-icon-font,
    .tnlif.im,
    .tnlif.fa {
        color: @COLOR-PM-LINK;
        .PM-TRANSITION();

        &:hover {
            color: @COLOR-PM-LINK-H !important;
            .PM-TRANSITION();
        }

        &:visited {
            color: @COLOR-PM-LINK-V;
        }

        &:active {
            color: @COLOR-PM-LINK-A !important;
            outline: none !important;
            .PM-TRANSITION();
        }
    }
    .icon-font {

        color: @COLOR-PM-LINK!important;
    }
}

// DROP-DOWN BUTTON //
.pm-solid-dropdown-btn {

    &.btn-group {

        .pm-outline-btn {
            background-color: @COLOR-PM-LINK;
            border-color: @COLOR-PM-LINK;
            color: @COLOR-PM-WHITE1 !important;

            &:hover {
                background-color: @COLOR-PM-GREEN1;
                border-color: @COLOR-PM-GREEN1 !important;
                color: @COLOR-PM-WHITE1;
            }
        }

        &.open .dropdown-toggle {
            box-shadow: none !important;
        }

        .fa {
            left: 14px;

            &:before {
                margin-left: -4px;
            }

            &:after {
                content: "";
                height: 32px;
                width: 1px;
                background-color: rgba(255,255,255, .15);
                position: absolute;
                top: -7px;
                right: 21px;
            }
        }

    }

    .dropdown-menu {
        margin-top: 10px;
        border-radius: 4px;
        padding: 0;
        float: right;
        right: 0;

        &:before {
            position: absolute;
            top: -7px;
            right: 18px;
            display: inline-block;
            border-right: 7px solid transparent;
            border-bottom: 7px solid #5a5a5a;
            border-left: 7px solid transparent;
            content: '';
        }

        &:after {
            position: absolute;
            top: -6px;
            right: 19px;
            display: inline-block;
            border-right: 6px solid transparent;
            border-bottom: 6px solid #ffffff;
            border-left: 6px solid transparent;
            content: '';
        }


        li {
            display: block;
            padding-bottom: 0;

            &:last-child {
                padding-bottom: 0;
                border-bottom: 0;
            }

            a {
                color: @COLOR-COS-DEFAULT;
                padding: 12px 20px;
                position: relative;
                overflow: hidden;
                text-overflow: ellipsis;
                .COS-OPEN-SANS-REGULAR();
                .PM-TRANSITION();
                .PM-DROPDOWN-HOVER-ACCENT();
            }
        }
    }
}

// DELETE BUTTON (ICON) //
.pm-delete-btn {
    .pm-link-btn {
        .pm-icon-font {
            font-size: 24px;
            color: #535e62;
        }

        &:hover {
            .pm-icon-font {
                color: @COLOR-COS-DEFAULT !important;
            }
        }
    }
}

// LESS MIXINS //
.PM-TRANSITION (@prop: all, @time: .15s, @ease: ease-in-out) {
    -webkit-transition: @prop @time @ease;
    -moz-transition: @prop @time @ease;
    -o-transition: @prop @time @ease;
    -ms-transition: @prop @time @ease;
    transition: @prop @time @ease;
 }

//Share button
.pm-share-course {
    padding: 10px 15px 0 0;
    float: left;
    font-size: 18px;

    a {
        font-family: "Open Sans", sans-serif;
        padding-left: 5px;
        font-size: 14px;
    }

}

.pm-search {
  button {
    line-height: 1.3;

    border-radius: 0 32px 32px 0;

    .icon-font {
        color: #fff;
    }
  }
}

.pm-no-caps {
  text-transform:none!important;
}

.tnl-button-menu .btn.btn-link {

  &:hover {
      color: #333;
  }
}

.pm-header-partial-view {

    .pm-btn-lg {
        margin-top: 7px;
    }

    .pm-btn-sm {
        margin-top: 9px;
    }

    .pm-btn-lg, .pm-btn-sm {
        float: right;
        color: @COLOR-PM-WHITE1 !important;
        background-color: #27509F;
        border: 2px solid @COLOR-PM-WHITE1;

        &:hover {
            background-color: @primary-hover;
            border: 2px solid @COLOR-PM-WHITE1 !important;
        }

        &:active {
            background-color: @primary-active;
            border: 2px solid @COLOR-PM-WHITE1 !important;
        }
        .icon-font {
            color: #fff;
        }
    }
}

// POPOVER UPDATES //
.tnl-popover {
    cursor:pointer;

    .pm-icon-sm {
        margin-top:5px;
    }

    .pm-show-text {
        display: inline-block;
        height: 3rem;
        line-height: 3rem;
        width: auto;
        position: relative;
        vertical-align: top;
        top: 2px;
        color: @COLOR-PM-BLUE1;
        margin-left: 6px;
    }
}

// MOVE UP or DOWN ARROWS //
.cos-move-up-down {
    position: relative;
    top: 11px;
    width: 30px;
    height: 30px;
    display: inline-block;

    .pm-icon-font {
        color: @COLOR-COS-DEFAULT;

        &:hover {
            color: @COLOR-PM-LINK-H;
        }

        &:active {
            color: @COLOR-PM-LINK-A;
        }
    }
}

//Observation template updates
.tnl-toolbar {

    .pm-btn-sm {
        vertical-align: top;
        margin-top: 0;
    }
    .cos-obs-icon {

        button {
            text-align: right;
        }

        .pm-btn-lg {
            margin-right: 0;
            padding: 7px 37px 7px 0;

            .pm-icon-font {
                position: absolute;
                margin: 0;
                min-height: 0;
                min-width: 0;
                width: 30px;
                height: 30px;
                right: 0;
                top: 0;

                &:before {
                    font-size: 30px;
                    right: 0;
                    top: 3px;
                }
            }
        }
    }
}

.cos-checkbox-dropdown {
    .pm-checkbox-container {
        margin-left: 10px;
    }
    .pm-btn-sm {
        span.pm-icon-font {
            display: inline-block !important;
            padding: 0 !important;
            margin: 0 0 0 5px;
            position: relative;
            top: 0px;
            font-size: 19px;
            height: 14px;
            width: 14px;
        }
    }
}

.cos-obs-edit-btn {
    .pm-icon-font {
        font-size: 23px;
        height: 23px !important;
        width: 23px !important;
    }
}


