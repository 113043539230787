@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

.tnl-masquerading-bar {
    position: absolute;
    top: 50%;
    margin-top: -16px;
    right: 275px;
    z-index: 100;

    a {
        background-color: @COLOR-PM-ORANGE1;
        border-radius: 4px;
        margin-top: 0 !important;
        border: none !important;

        &:hover {
            background-color: @COLOR-PM-RED1;
            box-shadow: none;

            span {
                color: @COLOR-PM-WHITE1;
            }
        }

        span {
            color: @COLOR-PM-WHITE1;

            &:hover {
                color: @COLOR-PM-WHITE1;
            }
        }

        .icon-font{
            color: @COLOR-PM-WHITE1!important;
            position: relative;
            top: 1px;
            padding: 0 10px 0 0;
        }
    }
}

.tnl-masquerading {

    .nav-user {
        background: transparent !important;

        .caret {
            border-top-color: #fff !important;
         }
    }
}