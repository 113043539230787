@import (reference) "../Theme/Common/_common.less";

.popover {
    max-width: 800px;

    .popover-content {
        padding: 15px;
        h1 {
            font-size: 16px;
        }
    }

    &.right .popover-content {
    white-space: normal;
    }
}



.tnl-main-popover .popover {
    width: 480px;
    max-width: 480px;

    .popover-title {
        color: #555;
    }
     
    h1 {
        margin: 0 0 5px;
    }

    h3 {
        margin: 0;
    }
}