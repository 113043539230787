@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";


/* Refactor to use button-dropdown */
.dropdown-menu {
    padding: 10px 0;
    .LESS-ROUNDED-CORNERS-RESET();
    .LESS-BOX-SHADOW();

    > li > a {
        position: relative;
        padding-right: 35px;
        padding-left: 35px;
        color: @COLOR-TNL-GRAY2;
        max-width: 300px;
        .LESS-ELLIPSIS-ONE-LINE();
    }

    > li > a .tnl-menu-text {
        margin-left: 5px;
    }

    > li > a .icon-font {
        position: absolute;
        top: 8px;
        left: 10px;
        color: @COLOR-TNL-GRAY2;
    }

    .dropdown-header {
        padding-right: 10px;
        padding-left: 10px;
        color: @COLOR-TNL-GRAY3;
    }

    li b {
        font-weight: normal;
    }

    > li > a,
    .dropdown-header {
        padding: 6px 40px 6px 10px;
    }

    &.dropdown-menu-right-aligned {
        right: auto !important;
    }
}

.dropdown-menu-mega {
    padding: 1em;
}

.mega-menu {

    .nav,
    .collapse,
    .dropup,
    .dropdown {
        position: static;
    }

    .container {
        position: relative;
    }

    .dropdown-menu {
        left: auto;
    }

    .nav.navbar-right .dropdown-menu {
        left: auto;
        right: 0;
    }

    .mega-menu-content {
        padding: 20px 30px;
    }

    .dropdown.mega-menu-fw .dropdown-menu {
        left: 0;
        right: 0;
    }

    ul li ul {
        margin: .25em;
    }

    ul li ul li {
        height: 25px;
        line-height: 1em;
        margin: .5em 0;
        white-space: nowrap;
    }
}

.tnl-button-menu.btn-group > .tnl-link {
    // Do not reduce padding. Need space for mobile finger size.
    float: none;
    background-color: transparent;
    color: @COLOR-TNL-GRAY1;
    box-shadow: none;
    border-right: 1px solid #fff;

    .icon-font.fa {
        padding-right: 5px;
    }
}

.tnl-button-menu.btn-group > .tnl-link:focus,
.tnl-button-menu.btn-group > a.text-default:active {
    // Do not reduce padding. Need space for mobile finger size.
    float: none;
    background-color: transparent;
    color: @COLOR-TNL-GRAY1;
    box-shadow: none;
    border-right: 1px solid #fff;
}

.tnl-button-menu.btn-group {
    // Do not reduce padding. Need space for mobile finger size.
    background: #fff;
    white-space: nowrap;
    position: static;
    border: 1px solid #a9a9a9;
    margin-top: -1px;
    overflow: hidden;
    .PM-ROUNDED-CORNERS();

    ul > li > a {
        border-right: none !important;
    }

    a.tnl-link {
        &:first-child {
            position: relative;
            top: 2px;
            padding: 6px 12px;
            border-right: 1px solid #a9a9a9;
            z-index: 3;
                
            &:hover,
            &:focus {
                border-right: 1px solid #a9a9a9;
                border-radius: 3px 0 0 3px;
                background-color: @COLOR-PM-GREY3;

                .icon-font {
                    color: #67696b;
                }
            }
        }
    }
    a.boot-btn {
        &:first-child {
            border-right: 1px solid #a9a9a9;
            z-index: 3;
                
            &:hover,
            &:focus {
                border-right: 1px solid #a9a9a9;
                border-radius: 3px 0 0 3px;
                background-color: @COLOR-PM-GREY3;
            }
        }
    }

    > .btn,
    > .boot-btn,
    > .btn:focus,
    > a.btn-default:active {
        float: none;
        background-color: transparent;
        color: @COLOR-TNL-GRAY2!important;
        box-shadow: none;
        border-right: 1px solid transparent;
        > .icon-font {
            color: @COLOR-TNL-GRAY2;
        }

        &:focus {
            outline: none !important;
        }
    }
    > a.btn-default:hover > .icon-font {
        color: #67696b;
    }

    &.open {
        .btn-default.dropdown-toggle {
            background: transparent;
            .LESS-BOX-SHADOW-RESET();
        }

        > .btn,
        > .btn:focus,
        > a.btn-default:active {
            background: @COLOR-TNL-GRAY5;
            .LESS-BOX-SHADOW-RESET();
        }
    }

    .btn-default {
        background: transparent;
        border-color: transparent;

        &:active {
            border: 0;
        }
    }

    > .btn:hover,
    > .boot-btn:hover {
        background-color: @COLOR-PM-GREY3;
        border-radius: 0 3px 3px 0;
        .LESS-BOX-SHADOW-RESET();
    }

    .dropdown-toggle {
        .fa-caret-down {
            color: #67696b;
        }
    }

    .dropdown-menu > li > a:focus,
    .dropdown-menu > li > a:hover {
        background: @COLOR-TNL-GRAY5;
    }

    .cos-add-filter-btn,
    .cos-remove-filter-btn {
        padding: 3px 12px;

        .pm-icon-md {
            height: 23px;
            width: 23px;
            top: 2px;

            [class*=" pm-icon-"] {
                font-size: 23px !important;

                &:before {
                    font-size: 23px !important;
                }
            }
        }

        span:nth-child(2) {
            position: relative;
            top: -3px;
        }

        span:only-child {
            padding-left: 0 !important;
        }
    }
}


.tnl-btn-group {

    .btn.btn-link {
        .LESS-BOX-SHADOW-RESET();
        text-decoration: none;

        span {
            text-decoration: none;
        }
    }

    .btn-link.active,
    .btn-link.focus,
    .btn-link:hover,
    .open > .dropdown-toggle.btn-link {
        background-color: @COLOR-TNL-GRAY5;

        a {
            color: @COLOR-LINK-HOVER;
        }
    }
}
.pm-create-badge-btn .dropdown-menu>li>a {
    max-width: none;
}