@import (reference) "../Theme/Common/_common.less";

#portalAjaxLoading {
    bottom: 4px;
    display: none;
    font-size: @FONT-SIZE-SM;
    position: fixed;
    right: 4px;
    z-index: 20000;

    .portal-ajax-loading {
        background: @COLOR-TNL-GRAY6;
        border: 1px solid @COLOR-TNL-GRAY4;
        color: @COLOR-TNL-GRAY2;
        display: inline-block;
        font-size: @FONT-SIZE-XSM;
        list-style: none;
        margin: 0 0 10px 0;
        padding: 4px 10px;
        border-radius: 1px;
    }
}

.tnl-ajax-load {
    position: relative;
    top: 0;
    left: 0;

    .waiting-indicator {
        opacity:0;
        background-image: url("../../Icon/ajax-loader.gif");
        width: 16px;
        height: 11px;
        position: absolute;
        left: 50%;
        margin-left: -8px;
        top: -5px;
    }
}

.waiting-indicator.in {
    opacity:0;  /* make things invisible upon start */
    -webkit-animation:fadeIn ease-in 1;  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
    -moz-animation:fadeIn ease-in 1;
    -o-animation:fadeIn ease-in 1;
    animation:fadeIn ease-in 1;
 
    /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
    -o-animation-fill-mode:forwards;
    -moz-animation-fill-mode:forwards;
    -webkit-animation-fill-mode:forwards; 
    animation-fill-mode:forwards;
 
    -webkit-animation-duration:1s;
    -moz-animation-duration:1s;
    -o-animation-duration:1s;
    animation-duration:1s;

    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -o-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

