@import (reference) "../Theme/Common/_common.less";

.bs-callout {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: #eee;
    border-image: none;
    border-radius: @BORDER-RADIUS;
    border-style: solid;
    border-width: 1px 1px 1px 5px;
    margin: 20px 0; 
    padding: 20px
}

.bs-callout-primary {
    border-left-color: @COLOR-PRIMARY;
}

.bs-callout-success {
    border-left-color: @COLOR-SUCCESS;
}

.bs-callout-info {
    border-left-color: @COLOR-INFO;
}

.bs-callout-warning {
    border-left-color: @COLOR-WARNING;
}

.bs-callout-danger {
    border-left-color: @COLOR-DANGER;
}