@import (reference) "../Theme/Common/_common.less";

.tnl-rubric {
    margin-bottom: 50px;
}
.tnl-rubric:last-child {
    margin-bottom: 0;
}
.tnl-rubric > header > h1 {
    margin-bottom: 10px;
    color: @COLOR-TNL-GRAY2;
    font-size: @FONT-SIZE-LG;
    font-weight: normal;
}
.tnl-rubric-box {
    margin: 0 0 20px;
    .LESS-BOX;
}
.tnl-rubric-box > header > h1 {
    margin: 0 0 15px 0;
    color: @COLOR-TNL-ORANGE1;
    font-size: @FONT-SIZE-MD;
    line-height: 18px;
    font-weight: normal;
}
.tnl-rubric-box > header > p {
    margin: 5px 0 0 0;
}
.tnl-rubric-box-item {
    display: inline-block;
    min-width: 125px;
    margin: 0 5px 15px;
    padding: 20px;
    vertical-align: top;
    font-size: @FONT-SIZE-SM;
    background: #fff;
    .LESS-ROUNDED-CORNERS;
    .LESS-BOX-SHADOW;

    > header > h1 {
        font-weight: bold;
    }
    > ul {
        list-style-type: none;
    }
}
.tnl-rubric-box-item > ul {
    margin: 0;
    padding: 0;
}
.tnl-rubric-box-item > ul > li {
    margin: 0 0 10px;
}
.tnl-rubric-box-item > header > h1 {
    color: @COLOR-TNL-GRAY2;
    font-size: @FONT-SIZE-MD;
}
html.lt-ie9 .tnl-rubric-box-item {
    border: 1px solid @COLOR-TNL-GRAY3;
}

// For smaller tablets and phones
@media (max-width: 720px) {

    .tnl-program-group-head-text, 
    .tnl-program-group-head-actions, 
    .tnl-program-group-head-stamp {
        display: block;
    }
 
    // Rubric
    .tnl-rubric {
        margin: 0 -10px;
        padding: 25px 10px;
        background: none;
        border: none;
        border-bottom: 2px solid @COLOR-TNL-GRAY4;
        .LESS-BOX-SHADOW-RESET;
    }
    .tnl-rubric-box {
        margin: 0;
        padding: 25px 10px;
        background: none;
        border: none;
        border-bottom: 1px dotted @COLOR-TNL-GRAY4;
        .LESS-BOX-SHADOW-RESET;
    }
    .tnl-rubric-box-item {
        display: block;
        width: auto;
        margin-right: 0;
        margin-left: 0;
        padding: 10px;
        .LESS-ROUNDED-CORNERS-RESET();
    }
}