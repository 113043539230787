@import (reference) "../Theme/Common/_common.less";

/*
    Make sure that rules added to the site toolbar are specific,
    else they may cascade down and cause un-expected results in the popover's content.
*/

.tnl-site-toolbar {
    display: inline-block;
    position: absolute;
    @WIDTH-MD: 60px;
    @WIDTH-LG: 60px;
    @HEIGHT: 50px;

    .hidden {
        display: none;
    }

    .vertical-background {
        background: #eee;
    }

    .horizontal-bacground {
        background: #eee;
        background: -moz-linear-gradient(left,  #eee 0%, #eee 97%, #ccc 100%);
        background: -webkit-gradient(linear, left top, right top, color-stop(0%,#eee), color-stop(97%,#eee), color-stop(100%,#ccc));
        background: -webkit-linear-gradient(left,  #eee 0%,#eee 97%,#ccc 100%);
        background: -o-linear-gradient(left,  #eee 0%,#eee 97%,#ccc 100%);
        background: -ms-linear-gradient(left,  #eee 0%,#eee 97%,#ccc 100%);
        background: linear-gradient(to right,  #eee 0%,#eee 97%,#ccc 100%);
    }

    .fixed-to-left {
        top: 0;
        bottom: 0;
        left: 0;
    }

    .fixed-to-bottom {
        right: 0;
        bottom: 0;
        left: 0;
    }
        
    .vertical-background();
             
    .tnl-site-toolbar-items {
        list-style-type: none;
        margin: 0;
        padding: 0;

        > li {
            display: block;
            .LESS-ELLIPSIS-ONE-LINE;
            cursor: pointer;
        }
        > li > a,
        > li > a:visited {
            position: relative;
            display: block;
            background-color: transparent;
        }

        > li > a:hover,
        > li > a:active {
            text-decoration: none;
            border: none;
            background-color: transparent;
            box-shadow: none;
        }
        > li > a:hover .icon-font,
        > li > a:active .icon-font,
        > li > a:hover .tnl-site-toolbar-desc,
        > li > a:active .tnl-site-toolbar-desc {
            color: @COLOR-TNL-ORANGE1;
        }

        > li > a span.icon-font {
            padding: 5px 0;
            color: @COLOR-TNL-GRAY2;
            font-size: 18px;
        }

        > li > a > .badge > span {
            color: #fff;
        }
        
        .tnl-site-toolbar-desc {
            display: none;
            color: @COLOR-TNL-GRAY2;
        }

    }

    @media (min-width: 0px) {
        position: fixed;
        height: @HEIGHT;
        .fixed-to-bottom();

        .tnl-site-toolbar-items {
            display: inline-block;
            border-right: 1px solid @COLOR-TNL-GRAY4;
        
            &:last-child {
                border: none;
            }
            > li {
                display: inline-block;
            }
            > li > .tnl-popover > .badge {
                position: absolute;
                top: 14px;
                left: 20px;
            }
        }
    }
    @media (min-width: 768px) {
        .tnl-site-toolbar-items {
            > li:first-child {
                margin-top: 5px;
            }
        }
    }
    @media (min-width: 992px) {
        position: absolute;
        .fixed-to-left();
        width: @WIDTH-MD;
        height: auto;
        .horizontal-bacground();

        .tnl-site-toolbar-items {
            display: block;
            border-bottom: 1px solid @COLOR-TNL-GRAY4;

            &:last-child {
                border: none;
            }
            > li,
            > li > a,
            > li > a:visited {
                display: block;
            }
            > li > .tnl-popover > .badge {
                position: absolute;
                top: 16px;
                left: 30px;
            }
        }
    }
    @media (min-width: 1200px) {           
        width: @WIDTH-LG;
    }

}