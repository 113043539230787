@import (reference) "_pm-global.less";

// PD PLAYLIST & DISCUSSION STYLES //
// Author: Chris Brosky - January 2018 //

.pm-registration-table {
    input {
        position: relative;
        top: 1px;
    }

    &.tnl-table {
        th {
            padding: 15px;
        }

        td {
            .pm-registration-btn.pm-btn-lg {
                margin: 5px !important;
            }
        }

        .pm-checkbox-container {
            .pm-checkbox {
                .pm-cr + text {
                    .COS-OPEN-SANS-REGULAR();
                    color: #fff;
                    position: relative;
                    top: 2px;
                }
            }
        }
    }
}

.pm-registration-action {
  &.pm-deny-all-btn {
    margin-left: 10px;
  }
}

@media (max-width: @PM-BOOTSTRAP-MD) {
  .pm-registration-action {
    margin: 0 0 10px;
    text-align: left !important;
  }
}
