@import (reference) "_pm-global.less";

/** PM COLORS **/
@lightGreen: #01ae00;
@lightGrey: #e0e0e0;
@mainBlue: #2470ee;
@textBlue: #495574;
@pureWhite: #fff;

.pm-step-outline {
  margin-left: -25px;
  margin-right: -25px;
  height: 44px;
}

.pm-step-details {
  position: relative;
  top: -63px;
  margin: 0 -12px 0 -18px;
}

.pm-registration-progress-container {
  position: relative;
  top: 0;
  left: 0;
}
.pm-progress-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 44px;
  margin: 0 auto;
  box-shadow: none !important;
}

.pm-progress-bar-row {
    height: 0;
}

.pm-grey-bar {
  position: relative;
  top: 50%;
  height: 14px;
  background-color: @lightGrey;
  border: none;
  margin: -7px 0 0 6%;
}

.pm-gradient-bar {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 6px;
  margin: -3px 0 0 -6px;
  background: @pureWhite;
  z-index: 3;
}

.pm-grey-bar-2 {
  width: 16%;
}

.pm-grey-bar-3 {
  width: 34%;
}

.pm-grey-bar-4 {
  width: 50%;
}

.pm-grey-bar-5 {
  width: 68%;
}

.pm-grey-bar-6 {
  width: 85%;
}

.pm-grey-bar-7 {
  width: 90%;
}

.pm-registration-progress-container {

}

.pm-registration-progress-container .pm-steps {
  .pm-icon-wrap {
    position: relative;
    height: 50px;
    width: 50px;
    left: 50%;
    margin: 0 auto 10px -25px;
    padding: 0;
    z-index: 3;
  }

  .pm-icon-border {
    height: 28px;
    width: 28px;
    border-radius: 50%;
    background-color: transparent;
    border: 8px solid @lightGrey;
    box-sizing: content-box;
  }

  .pm-icon-bg {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    margin-top: -16px;
    left: 50%;
    margin-left: -16px;
    color: @pureWhite;
    z-index: 10 !important;
  }
  .pm-progress-complete.pm-icon-bg {
    background-color: @lightGreen;
  }
  .pm-progress-current.pm-icon-bg {
    background-color: @mainBlue;
  }
  .pm-progress-todo.pm-icon-bg {
    background-color: @pureWhite;
  }
  .pm-icon-bg .pm-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -9px 0 0 -12px;
    font-size: 18px;
    width: 24px;
    height: 18px;
    text-align: center;
  }

  .pm-icon-bg .pm-icon.fa-chevron-right {
    margin-left: -10px;
    margin-top: -8px;
  }

  .pm-progress-todo .pm-icon {
      color: @lightGrey;
  }

  .pm-progress-step,
  .pm-progress-text {
    .COS-OPEN-SANS-REGULAR();
    color: @textBlue;
    text-align: center;
  }
  .pm-progress-step {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .pm-progress-text {
    font-size: 12px;
    font-weight: 400;
    text-transform: capitalize;
  }
}

@media only screen and (max-width: 768px) {
  .pm-progress-bar-row {
      margin: 44px auto -20px -10px;
  }
  .pm-step-outline {
    display: none !important;
    top: 100%;
    margin-bottom: 70px;
  }
  .pm-step-details {
    margin-top: 0;
    top: 0;
  }
  .pm-step-details .pm-steps {
    margin-bottom: 35px;
  }
  .pm-registration-progress-container .pm-step-details .step .pm-icon-wrap {
    height: 44px;
    width: 44px;
    margin-top: -22px !important;
    margin-left: -22px !important;
  }
  .pm-registration-progress-container .pm-steps .pm-icon-border {
    display: none !important;
  }
  .pm-step-details .pm-icon-bg {
    border: 6px solid @lightGrey;
    box-sizing: content-box;
    margin-left: -21px !important;
    margin-top: -21px !important;
    background-clip: content-box !important;
  }
}

// If smaller than 20% loop //

.progress-bar {
    box-sizing: border-box!important;
}

.progress-loop (@valuenow) when (@valuenow > 0) {
    .progress-bar[aria-valuenow="@{valuenow}"] {
        padding-left: @valuenow * 1%;
        color: @COLOR-COS-DEFAULT;
        min-width: 0;
        text-indent: 3px;
    }
    .progress-loop(@valuenow - 1);
}
.progress-loop(20);


.cos-progress-vertical {
  list-style: none;
  padding-left: 0;

  li {
    font-size: 16px;
    padding-left: 23px;
    position: relative;
    padding-bottom: 18px;

    &:last-child {

      &:after {
        display: none;

      }
    }

    &:before {
      height: 16px;
      width: 16px;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 50%;
      content: "";
      display: block;
      position: absolute;
      top: 3px;
      left: 0;
      z-index: 2;

    }

    &.complete {

      &:after {
        background: #137ABB;
        top: 19px;
        height: 100%;

      }

      &:before {
        content: "\e92e";
        font-family: 'pm-font-icons' !important;
        font-size: 16px;
        color: #137ABB;
        background: #fff;
        top: 3px;
        left: 0;
        position: absolute;
        z-index: 2;
        border: #137ABB thin solid;
        line-height: 15px;
        text-indent: -1px;

      }
    }

    &.active {
      color: #137ABB;
      font-weight: 600;

      &:before {
        background: #137ABB;
        border: none;

      }
    }
    &.sub-notcomplete, &.sub-complete, &.sub-active {
      padding-left: 37px;
      &:before {
        display: none;
      }
    }

    &.sub-active {
      color: #137ABB;
      font-weight: 600;
    }

    &:after {
      content: " ";
      display: block;
      width: 2px;
      height: 100%;
      background: #ccc;
      position: absolute;
      top: 16px;
      left: 7px;
      z-index: 1;

    }
    ul {
      list-style: none;
      padding: 15px 0 0 10px;
      margin-bottom: -16px;

      li, li.complete {
        font-weight: 400;
        color: @COLOR-COS-DEFAULT;
        padding-left: 0;

        &:after, &:before {
          display: none;

        }
        &.active {
          font-weight: 600;

        }
      }
    }

  }
}

.cos-progress-vertical-new {
  list-style: none;
  padding-left: 0;

  li {
    font-size: 16px;
    padding-left: 23px;
    position: relative;
    padding-bottom: 18px;

    &:last-child {

      &:after {
        display: none;

      }
    }

    &.sub {
      padding-left: 37px;
      &:before {
        display: none;
      }
    }

    &:before {
      //complete
      content: "\e92e";
      font-family: 'pm-font-icons' !important;
      font-size: 16px;
      color: #137ABB;
      background: #fff;
      top: 3px;
      left: 0;
      position: absolute;
      z-index: 2;
      border: #137ABB thin solid;
      line-height: 15px;
      text-indent: -1px;
      border-radius: 50%;
    }

    &.complete {

      &:after {
        background: #137ABB;
        top: 19px;
        height: 100%;
      }
    }

    &.active {
      ul {
        li {
          color: #36474f;
          font-weight: 400;
        }
      }
    }

    &.active, &.subactive {
      //active
      color: #137ABB;
      font-weight: 600;

      &:before {
        background: #137ABB;
        border: none;
      }
    }
    &.subactive {
      li {
        color: #36474f;
        font-weight: 400;

        &.sub.active {
          color: #137ABB;
          font-weight: 600;
        }
      }
    }

    &.subactive ~ li {
      &:before {
        height: 16px;
        width: 16px;
        background-color: #fff;
        border: 2px solid #ccc;
        border-radius: 50%;
        content: "";
        display: block;
        position: absolute;
        top: 3px;
        left: 0;
        z-index: 2;
      }
    }



    //after active
    &.active ~ li {
      &:before {
        height: 16px;
        width: 16px;
        background-color: #fff;
        border: 2px solid #ccc;
        border-radius: 50%;
        content: "";
        display: block;
        position: absolute;
        top: 3px;
        left: 0;
        z-index: 2;
      }
      &.sub:before {
        display: none;
      }
    }
    &:after {
      //gray line
      content: " ";
      display: block;
      width: 2px;
      height: 100%;
      background: #ccc;
      position: absolute;
      top: 16px;
      left: 7px;
      z-index: 1;
    }

    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: -17px;
      padding-top: 16px;
      margin-left: -23px;
    }

  }
}