@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

.premlib-returns {
    
    .course-info-panel {
        margin-bottom: 4em;  
    }

    .library-item-image {
        border: 1px solid @COLOR-TNL-GRAY3;
    }

    .course-info-panel .title {
        color: @COLOR-TNL-GRAY3;
        font-size: 20px;
        .COS-OPEN-SANS-600();
    }

    .premlib-details {
        margin-top: 1em;
    }

    .return-fields {

        margin-top: 3em;

        .form-control {
            display: inline;
            margin: 10px 8px 20px 8px;
        }

        label {
            .COS-OPEN-SANS-REGULAR();
        }
    }
}