@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

.pagination {
    background-color: @COLOR-PM-GREY3;
    border: 1px solid #a9a9a9;
    min-height: 30px;

    li:focus,
    li:active {
        outline: none !important;
    }

    li {

        a {
          border-right: 1px solid #a9a9a9 !important;
          height: 100%;

          &:focus {
              border-right: 1px solid #a9a9a9 !important;
          }

          &.nav-arrows {
            .tnl-icon-wrapper {
              height: 16px !important;
            }
          }
        }

        &.active a {
            border-right: 1px solid #a9a9a9 !important;
        }
    }

    li:last-child a {
        border-right: none !important;
    }

    display: block;
    > li > a,
    > li > a:focus,
    > li > a:visited,
    li.nav-arrows a,
    li.nav-arrows a:focus,
    li.nav-arrows a:visited {
        padding: 6px 12px;
        color: @COLOR-COS-DEFAULT;
        background-color: transparent;
        border: none;
        outline: none !important;
        .LESS-ROUNDED-CORNERS-RESET();
        .COS-OPEN-SANS-REGULAR();
    }

    > li > a:hover,
    .nav-arrows:hover div > span {
        color: @COLOR-PM-ORANGE1 !important;
        background-color: transparent;
        cursor: pointer;
    }

    li.nav-arrows a .icon-font,
    li.nav-arrows a:focus .icon-font,
    li.nav-arrows a:visited .icon-font {
        color: @COLOR-LINK;
    }

    > li.nav-arrows > a:hover .icon-font {
        color: @COLOR-LINK-HOVER;
    }

    > li.active > a,
    > li.active > span,
    > li.active > a:hover,
    > li.active > span:hover,
    > li.active > a:focus,
    > li.active > span:focus {
        color: @COLOR-PM-WHITE1!important;
        background-color: @COLOR-PM-LINK;
        border-right: 1px solid #a9a9a9;
        cursor: default;
        .COS-OPEN-SANS-600();
    }
}
