@import (reference) "_pm-global.less";

.pm-instructor-grid-spacing {
    padding:15px;

    .pm-section-header-title {
        display:inline!important;
    }
    
    .pm-section-header-sub-title {
        display:inline;

        &:before {
            content: "\00a0 ";
        }
    }
}

.pm-outstanding-status-grid {
    background-color: rgba(147, 38, 143, 1);
    border-right: 3px #fff solid;
}

.pm-missing-attendance-grid {
    background-color: rgba(0, 143, 7, 1);
    border-right: 3px #fff solid;
    border-left: 3px #fff solid;
}

.pm-missing-surveys-grid {
    background-color: rgba(0, 186, 162, 1);
    border-left: 3px #fff solid;
}

.pm-outstanding-status-grid, .pm-missing-attendance-grid, .pm-missing-surveys-grid {
    padding: 15px 0 10px;

    h6 {
        font-weight: 300;
        font-family: 'Montserrat_Light_Italic', sans-serif;
    }

    h3, h6 {
        color: #fff;
        text-align: center;
    }
}