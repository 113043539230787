@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

.tnl-toolbar {
    .LESS-BUTTON-STYLE() {
        display: inline-block;
        margin: 0 10px 5px 0;
        vertical-align: bottom;

        &:last-child {
            margin-right: 0;
        }
        &.text-left {
            text-align: left !important;
        }
    }

    > .btn, // Deprecate this approach. TODO: Refactore code to use Bootstrap col approach below.
    > .btn-group,
    > select,
    > span,
    > div:not(.row) {
        .LESS-BUTTON-STYLE();
    }

    > .row > div { // Accomodates Bootstrap columns.
        > .btn,
        > .btn-group,
        > select,
        > span,
        > div {
            .LESS-BUTTON-STYLE();
        }
    }

    &.tnl-toolbar-top {
        margin: 5px 0;
        text-align: right;

        > label {
            padding-top: 8px;
        }
    }
    &.tnl-toolbar-bottom {
        margin: 15px 0 0;
    }
    .form-group {
        margin: 0;
    }

    .tnl-grid-search-form {
        .input-group {
            input {
                border-right: 0;
            }
        }
    }
}

.tnl-filter-button-wrapper {
    display: inherit!important;

    .tnl-button-menu {
        a {
            .pm-icon-font {
                position: relative;
                top: 2px;
                font-size: 18px;
                margin-right: 5px;
            }
        }

        .dropdown-toggle {
            padding: 0 5px;

            .pm-icon-font {
                font-size: 26px;
                margin: 0;
            }

            &:hover {
                .pm-icon-font {
                    color: @COLOR-PS-BLUE3;
                }
            }
        }

        ul > li > a {
            .pm-icon-font {
                margin: 0;
            }
        }
    }
}
.tnl-filter-placeholder .tnl-filter-type {
    margin: -2px 6px 0 0;
}