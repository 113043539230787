@import (reference) "_pm-global.less";

.pm-pd-refine-expand-contents {
    padding: 10px;
    clear: both;
    margin-right: 7px;
}
.pm-pd-refine-contents-scroll { 
    max-height: 185px; 
    overflow-y: scroll;
}
.pm-pd-link:link, .pm-pd-link:visited {
    color: #3A83F4 !important;
    text-decoration: none;
    font-weight: bold;
}
.pm-pd-sort-dropdown {
    border: 1px solid #ccc !important;
    color: #3A83F4;
    box-shadow: none !important;
}
.pm-pd-refine-section-heading {
    color: @COLOR-COS-DEFAULT;
    padding: 10px;
}
.pm-pd-refine-expand-heading {
    font-weight: bold;
    border-top: 1px solid #AEB1B6;
    padding: 10px;
    padding-right: 16px;
    cursor: pointer;
    overflow: hidden;
}
.pm-pd-course-section-table {
    border-top: 2px solid #ccc;
    border-bottom: 2px solid #ccc;
    border-collapse: collapse;
    width: 100%;
}
.pm-pd-course-section-table td {
    padding: 10px;
}
.pm-pd-section-no-button {
    padding: 6px;
}
.pm-pd-course-section-table tr:nth-child(odd) {
    background-color: #efefef;
} 

.pm-pd-course-banner-box {
    border-bottom: 2px solid #E6E6E6;
    background-color: #FBFBFB;
    padding: 25px;
}
.pm-pd-instructor-led {
    border-top: 4px solid @COLOR-PM-FUSCHIA1;
}
.pm-pd-self-paced {
    border-top: 4px solid @COLOR-PM-TEAL1;
}
.pm-pd-plc {
    border-top: 4px solid @COLOR-PM-YELLOW1;
}
.pm-pd-type-icon-text {
    height: 50px;
    font-weight: 600;
    align-items: center;
    display: flex;
    padding-left: 5px;
}
.pm-pd-course-title {
    padding-top: 13px;
    font-weight: 600;
}
.pm-pd-course-option {
    line-height: 1.7em;
    padding-right: 20px;
    .PM-TRANSITION();

    &:hover {
        .tnl-text-link, 
        .pm-icon-font {
            color: @COLOR-PM-ORANGE1;
            .PM-TRANSITION();
            text-decoration: none;
            cursor: pointer;
        }
    }
}
.pm-pd-course-option-item {
    float: left;
    padding: 0 5px;

    &:hover {
        .views,
        .tnl-text-link, 
        .pm-icon-font {
            color: @COLOR-PM-ORANGE1;
            .PM-TRANSITION();
            text-decoration: none;
            cursor: pointer;
        }
    }
}
.pm-pd-course-title  {
    span, span a {
        font-size: 18px !important;
    }
}
.pm-pd-course-desc {
    padding: 15px 0 10px 12px;
}
.pm-pd-course-desc-empty {
    padding: 10px; 
    border: 1px solid #E6E6E6;
    text-align: center;
}
.pm-pd-course-results-box {
    background-color: #fff; 
    margin-bottom: 20px; 
}
.pm-pd-course-sections {
    padding: 25px;
}
.pm-pd-registration-step-button-bar {
    padding: 20px;
    border-top: 1px solid #ccc;
}
.pm-pd-title-header {
    border-bottom: 1px solid #ccc;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}
.pm-pd-title-content {
    padding-left: 25px;
}
.pm-pd-info-header {
    font-size: 16px;
    font-weight: bold;
}
.pm-pd-registration-step-content {
    padding: 5px 20px 20px 20px;
}
.pm-pd-registration-step-title {
    font-size: 20px;
    padding: 10px;
    font-weight: bold;
    height: 36px;
}
.pm-pd-selection-title {
    font-weight: bold;
    font-size: 16px;
}
.pm-pd-selection-detail {
    padding: 5px 0px 25px 25px;
}

.pm-pd-registration-step-bar {
    border-top: 2px solid #e6e6e6;
    background-color: #f3f3f3;
    padding-top: 10px;
}
.pm-pd-step-details {
    width: 75%;
    margin: 0 auto;
}

//
// Course Rating stuff
//
.pm-pd-rating-box {
    min-width: 225px;
}
.pm-pd-course-rating {
    width: 125px;
    height: 25px;
}
.pm-pd-course-rating-bg {
    background-color: lightgrey;
    height: 25px;
}
.pm-pd-course-rating-color {
    background-color: orange;
    height: 25px;
}
.pm-pd-course-rating_star {
    background-image: url(/css/iconfonts/pm-icon-rating-star.svg);
    background-size: 25px 25px;
    background-position: -2px;
    background-repeat: repeat-x;
    height: 25px;
}
.pm-pd-course-rating.pm-pd-course-rating-overlay {
    margin: -30px 0px 0px 0px;
    background-color: aqua;
    height: 25px;
}
.pm-pd-course-rating.overlay {
    margin-top: -25px;
}
.pm-pd-course-rating-no {
    font-weight: bold;
    margin-left: 10px;
}
.pm-pd-course-rating-parts {
    float: left;
}
.pm-pd-rating-text {
    float: left;
    padding-top: 5px;
    padding-left: 5px;
}
.pm-pd-rating-item {
    float: left;
    width: 220px;
    padding: 10px 0 10px 0;
}
.pm-pd-rating-star {
    float: left;
    font-size: 18px;
    cursor: pointer;
}
.pm-pd-single-star {
    width: 25px;
    height: 25px;
}
.pm-pd-single-star.overlay {
    margin-top: -25px;
}
.pm-pd-empty-star {
    height: 25px;
    width: 0%;
}
.pm-pd-full-star {
    background-color: orange;
    height: 25px;
    width: 100%;
}
.pm-course-interface-icon {
    width: 200px; 
    border-right:none;

    @media (min-width: 1200px) {
        border-right:1px solid #aaa;
    }
}