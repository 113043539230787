@import (reference) "_pm-global.less";

// PD NOTIFICATION STYLES //
// Author: Chris Brosky - December 2017 //

.pm-plc-main-container {

  .tnl-form-group-collection {
    margin: 0;

    .form-group {
      margin: 0;
    }

    .form-bg .form-group:last-child {
      margin-bottom: 12px;
    }

    .form-bg.on,
    .form-bg.off {
        border-radius: 4px;
        margin-bottom: 15px;
        padding: 0 12px 12px;
    }

    .form-bg.off {
      background: transparent;
      border: 1px solid transparent;
    }

    .form-bg.on {
      background: #ffffdd;
      border: 1px solid rgba(247, 146, 50,.25);
      .PM-TRANSITION();
    }
  }

  .tnl-table-actions {
      margin-top: 0 !important;
  }
}
