@import (reference) "_pm-global.less";

// === PowerSchool Calendar Styles === //
// == 10/31/2018 - Chris Brosky == //

/* Color Variables */
@cos-cal-grey:      #333;
@cos-cal-teal:      #00baa2;
@cos-cal-gold:      #e49f19;
@cos-cal-red:       #f7284b;
@cos-cal-dk-blue:   #1a537e;
@cos-cal-blue:      #00b7df;
@cos-cal-green:     #8cc63f;
@cos-cal-orange:    #e96500;
@cos-cal-weekend:   #efefef;
@cos-cal-current:   #ffff99;
@cos-cal-default:   @COLOR-PM-WHITE1;

/* Global Styles */
#TipBox {
    display:none;
    position:absolute;
    font-size: 14px;
    border: 1px solid #ccc;
    padding: 0 15px;
    color: @cos-cal-grey;
    background-color: @COLOR-PM-WHITE1;
    width: 300px;
    z-index: 3;
    .COS-OPEN-SANS-600();
}

.cos-cal-weekend-bg {
    background-color: @cos-cal-weekend;
}
.cos-cal-current-bg {
    background-color: @cos-cal-current;
}
.cos-cal-default-bg {
    background-color: @cos-cal-default;
}

.cos-cal-view.week,
.cos-cal-view.day {
    .cos-cal-days,
    .cos-event {
        .cos-event {
            &.reminder,
            &.assignment {
                .cos-event-desc {
                    .COS-OPEN-SANS-REGULAR();
                    line-height: normal;
                }
            }
        }
    }
}

.cos-calendar-wrap {
    position: relative;
    width: 100%;

    header {
        text-align: center;

        h1 {
            text-align: center;
            margin-bottom: 0;
            height: 39px;
            line-height: 39px;
        }
    }

    .cos-cal-event-filter-btn {
        margin-bottom: 10px;

        button {
            margin-right: 0;

            .pm-icon-font {
                float: right;
                margin: 0 0 0 2px;
                font-size: 24px;
                height: 20px;
                width: 15px;

                &:before {
                    top: -4px;
                    .COS-ROTATE (-180deg);
                }
            }

            &.collapsed {
                .pm-icon-font {
                    &:before {
                        top: -4px;
                        .COS-ROTATE (0deg);
                    }
                }
            }
        }
    }

    .cos-event-filter-options {
        padding: 0 8px;
        background-color: #fff;
        border-radius: 4px;
        border: 1px solid #ccc;
    }

    .cos-date-picker-row {
        margin-top: 15px;

        .cos-cal-page-title {
            height: 40px;

            h1 {
                margin-bottom: 0;
                position: relative;
                top: 50%;
                margin-top: -12px;
            }
        }

        form {
            select {
                &[name="year"] {
                    margin-right: -5px;
                }

                .COS-OPEN-SANS-600();
                font-size: @COS-FONT-SIZE-18;

                option {
                    .COS-OPEN-SANS-REGULAR();
                    font-size: @PM-FONT-SIZE-DEFAULT;
                }
            }
        }
    }

    .pm-btn-group.pm-icon-action-btns {
        vertical-align: top;
        margin: 0 10px;

        .cos-calendar-btn {
            &.pm-action-btn {

                background-color: @COLOR-PS-BLUE3 !important;
                border: 1px solid @COLOR-PS-BLUE3 !important;
                color: @COLOR-PM-WHITE1 !important;
                border-radius: 4px;
                overflow: hidden;

                span {
                    color: @COLOR-PM-WHITE1 !important;
                }

                &:hover {
                    background-color: @COLOR-PS-BLUE1 !important;
                    border-color: @COLOR-PS-BLUE1 !important;
                }

                .pm-icon-ui-backchevron,
                .pm-icon-ui-chevron {
                    color: #666;
                }
            }

            &.cos-cal-view-btn {
                span {
                    left: -1px;
                }
            }
        }
    }

    .cos-cal-event-filter-row {
        margin: 20px 0;

        #cos-cal-event-ck-box {
            padding: 0;
        }
    }

    .cos-cal-header-row {
        margin: 15px 0;

        &:nth-child(2) {
            margin-bottom: 10px;
            height: 30px;
        }

        .pm-btn-group:last-child {
            margin-right: 6px;
        }

        .cos-cal-layout {
            .COS-OPEN-SANS-600();
            display: inline-block;
            width: 60px;
            text-align: center;

            .cos-cal-btn {

                .pm-btn-lg,
                .pm-btn-sm {
                    .COS-OPEN-SANS-600() !important;
                }

                &.active {
                    padding-bottom: 5px;
                    border-bottom: 5px solid #137ABB;
                }
            }

            .pm-btn-lg {
                padding: 0;
                margin: 0;
            }
        }

        .cos-cal-header-col {

            h1 {
                color: @COLOR-PS-BLUE6;
            }

            select {
                .COS-OPEN-SANS-600();
                font-size: @COS-FONT-SIZE-18;

                option {
                    .COS-OPEN-SANS-REGULAR();
                    font-size: @PM-FONT-SIZE-DEFAULT;
                }
            }
        }

    }

    .cos-cal-filters {
        label {
            margin-right: 15px;
        }

        &.pm-checkbox-container {
            .pm-checkbox {
                input[type=checkbox]:checked+span {
                    box-shadow: none;
                }
            }
        }
    }

    header,
    .cos-calendar {
        position: relative;
        width: 100%;
        margin: 0 auto;
    }

    .cos-calendar {
        a {
            color: #075B7F;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            width: 100%;
        }

        li {
            position: relative;
            display: block;
            float: left;
            width: 14.2857142%;
            padding: 5px;
            box-sizing: border-box;
            border: 1px solid #ccc;
            margin-right: -1px;
            margin-bottom: -1px;
            left: 4px;
        }

        .cos-cal-show-hide-wrapper {
            margin: 0 0 5px 0;
        }

        .cos-cal-weekdays {
            height: 40px;

            .cos-cal-day-header {
                text-align: left;
                line-height: 20px;
                background-color: #f7f7f7;
                border: 1px solid #ccc !important;
                padding: 10px 6px;
                color: #333;
                font-size: 14px;

                &:first-child {
                    border-radius: 4px 0 0 0;
                }

                &:last-child {
                    border-radius: 0 4px 0 0;
                }
            }
        }

        .cos-cal-days {
            li {
                height: 192px;
                overflow: auto;

                .date.current {
                    width: 20px;
                    margin-bottom: 5px;
                    padding: 3px;
                    text-align: center;
                }
            }

            .cos-cal-day {
                .cos-show-more-text {
                    position: relative;
                    left: 2px;
                    font-size: 12px;
                }
            }
        }

        .cos-cal-date {
            text-align: center;
            margin-bottom: 5px;
            padding: 3px;
            background: transparent;
            color: #36474f;
            width: 20px;
            border-radius: 50%;
            float: left;

            &.current {
                background: #075B7F;
                color: @COLOR-PM-WHITE1;
            }
        }

        .other-month {
            background: #f1f1f1;
            color: #6c6c6c;

            .cos-cal-date {
                background: transparent;
                color: #6c6c6c;
            }
        }

        .cos-event {
            clear: both;
            display: block;
            font-size: 14px;
            border-radius: 3px;
            padding: 5px 10px 10px;
            margin-bottom: 5px;
            line-height: 14px;
            text-decoration: none;
            border-top: 6px solid;
            background-color: #F1F1F1;
        }

        .cos-event-desc {
            .COS-OPEN-SANS-600();
            color: @COLOR-COS-DEFAULT;
            margin: 0 0 3px 0;
            text-decoration: none;
            line-height: initial;
            max-width: 204px;
            .PM-OVERFLOW-ELLIPSIS();
        }

        .cos-event-time {
            font-size: 12px;
        }
    }
}

/* --=== List & Day View Styles ===-- */
.cos-cal-view.list,
.cos-cal-view.day {
    .cos-calendar-wrap {
        .cos-calendar {
            .cos-cal-show-hide {
                display: inline-block;
                position: relative;
                top: 2px;
                left: 3px;
            }

            .cos-cal-weekdays {
                position: relative;
                left: -3px;
            }
        }
    }
}

/* --=== Month View Styles ===-- */
.cos-cal-view.month {
    .cos-calendar-wrap {
        .cos-cal-calendar-row {
            padding: 0 10px;
        }

        .cos-calendar {
            margin-top: 0;
            background: #fff;
            padding: 14px 12px 10px 10px;
            border-radius: 6px;
            border: 1px solid #ccc;
        }

        .cos-cal-month-wrapper {
            display: inline-block;
            width: 100%;
        }
    }
}

/* --=== List View Styles ===-- */
.cos-cal-view.list {
    .cos-calendar-wrap {

        .cos-calendar {
            .cos-cal-show-hide {
                display: inline-block;
                position: relative;
                top: 2px;
                left: 3px;
            }

            .cos-cal-weekdays {
                height: auto;

                li {
                    width: 100%;

                    &:first-child {
                        border-radius: 4px 4px 0 0;
                    }

                    &:last-child {
                        border-radius: 0 0 4px 4px;
                    }

                    .cos-cal-event-time,
                    .cos-cal-event-details {
                        margin-bottom: 5px;
                    }

                    .cos-cal-event-time {
                        a {
                            margin-left: 5px;
                        }
                    }

                    .cos-cal-event-details {
                        .cos-cal-title-link {
                            .COS-OPEN-SANS-600();
                        }

                        .cos-cal-details {
                            &.line2 {
                                margin-bottom: 3px;
                            }

                            &.line3 {
                                display: block;
                                margin-bottom: 5px;
                            }
                        }
                    }
                }
            }
        }
    }

    .cos-cal-weekdays.list {
        .cos-cal-day-header {
            .COS-OPEN-SANS-600() !important;
        }
    }
}

/* --=== Week View Styles ===-- */
.cos-cal-view.week {
    .cos-calendar-wrap {
        .cos-cal-view-wrapper {
            padding: 10px 25px 15px;
            border: 1px solid #e0e0e0;
            border-radius: 4px;
            background-color: @COLOR-PM-WHITE1;

            .cos-cal-header-row {
                text-align: center;
                border-bottom: 1px solid #e0e0e0;
                margin: 0 -25px 15px;

                .cos-cal-view-select {
                    height: 29px;
                }
            }

            .cos-cal-row {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                overflow: hidden;
                border: 1px solid #e0e0e0;
                margin: 3px -10px;
                border-radius: 0;

                .cos-cal-col {
                    width: 33.333333%;
                    padding: 0 10px 5px 10px;
                    border-width: 0 1px 0 0;
                    border-style: solid;
                    border-color: #e0e0e0;
                    min-width: 130px;

                    .cos-cal-head {
                        background-color: #f7f7f7;
                        color: #333;
                        font-size: 16px;
                        padding: 10px 15px;
                        line-height: 22px;
                        border-bottom: 1px solid #e0e0e0;
                        width: 100%;

                        .pm-btn-group.pm-icon-action-btns {
                            float: right;
                            margin-right: 0;
                        }
                    }

                    &:last-child {
                        border-right: none;
                    }

                    .row:nth-child(2) {
                        padding-top: 10px;
                    }

                    .cos-event.default {
                        padding: 5px 10px 5px;

                        .cos-event-desc {
                            font-family: 'Open_Sans_Regular', sans-serif;
                        }
                    }
                }

                &.events {
                    border-radius: 4px 4px 0 0 !important;

                    .event-row {
                        .event-wrap {
                            min-height: 65px;
                            max-height: 150px;
                            overflow: auto;
                        }
                    }
                }

                &.reminders {
                    border-radius: 0 0 4px 4px !important;
                }

                &.events,
                &.assignments,
                &.reminders {
                    .event-row {
                        padding: 10px 10px 0 10px !important;
                    }
                }

                .event-type {
                    background-color: #f7f7f7;
                    min-width: 146px;
                    max-width: 160px;

                    .row > div {
                        &:first-child {
                            min-height: 43px;
                        }

                        &:last-child {
                            font-size: 16px;
                            margin: 10px 10px 0 0;
                        }
                    }
                }
            }
        }
    }
}


/* --=== Day View Styles ===-- */
.cos-cal-view {
    &.day {
        .cos-calendar-wrap {
            .cos-calendar {
                .cos-cal-view-wrapper {
                    padding: 10px 25px 15px;
                    border: 1px solid #e0e0e0;
                    border-radius: 4px;
                    background-color: @COLOR-PM-WHITE1;

                    .cos-cal-header-row {
                        text-align: center;
                        border-bottom: 1px solid #e0e0e0;
                        margin: 0 -25px 15px;

                        .cos-cal-view-select {
                            height: 29px;
                        }
                    }

                    .cos-cal-row {
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex;
                        border-radius: 4px;
                        overflow: hidden;
                        border: 1px solid #e0e0e0;

                        .cos-cal-col {
                            width: 33.333333%;
                            padding: 0 10px 5px 10px;
                            border-width: 0 1px 0 0;
                            border-style: solid;
                            border-color: #e0e0e0;

                            .cos-cal-head {
                                background-color: #f7f7f7;
                                color: #333;
                                font-size: 16px;
                                padding: 10px 15px;
                                line-height: 22px;
                                border-bottom: 1px solid #e0e0e0;
                                width: 100%;

                                .pm-btn-group.pm-icon-action-btns {
                                    float: right;
                                    margin-right: 0;
                                }
                            }

                            &:last-child {
                                border-right: none;
                            }

                            .cos-cal-event {
                                padding: 10px 10px 0 10px;

                                .cos-event {
                                    &.no-event {
                                        .cos-event-desc {
                                            margin: 0;

                                            .cos-no-event-icon {
                                                color: #018db6;
                                                top: 4px;
                                                margin-right: 5px;

                                                .pm-icon-font {
                                                    color: @COLOR-PS-BLUE5;
                                                }
                                            }

                                            .cos-no-event-text {
                                                position: relative;
                                                top: -2px;
                                                display: inline-block;
                                                margin-left: 5px;
                                                .COS-OPEN-SANS-REGULAR();
                                            }
                                        }
                                    }

                                    &.default {
                                        .cos-event-desc {
                                            margin: 8px 0 6px 0;
                                            .COS-OPEN-SANS-REGULAR();
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .cos-cal-weekdays {
                    .cos-cal-day-header {
                        .COS-OPEN-SANS-600() !important;

                        &:first-child {
                            border-radius: 4px 4px 0 0;
                        }
                    }

                    li {
                        width: 100%;
                        border-radius: 0 0 4px 4px;

                        .cos-cal-event-time,
                        .cos-cal-event-details {
                            margin-bottom: 5px;
                        }

                        .cos-cal-event-time {
                            a {
                                margin-left: 5px;
                            }
                        }

                        .cos-cal-event-details {
                            .cos-cal-title-link {
                                .COS-OPEN-SANS-600();
                            }

                            .cos-cal-details {
                                &.line2 {
                                    margin-bottom: 3px;
                                }

                                &.line3 {
                                    display: block;
                                    margin-bottom: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Add Event Styles */
.cos-add-event-module {
    max-width: 450px;
    margin: 0 auto;
    padding: 0;
    background-color: @COLOR-PM-WHITE1;
    box-shadow: 0 0 20px rgba(0,0,0,.1);
    user-select: none;
    border-radius: 0 0 3px 3px;

    h1 {
        margin: 0;
        padding: 20px;
        background-color: #f1f5f8;
        border-bottom: 1px solid #a4aaba;
        text-align: center;
    }

    .control-label {
        .COS-OPEN-SANS-600();
        color: @cos-cal-grey;
        font-size: 16px;
    }

    .cos-cal-head {
        &.add-event {
            background-color: #f7f7f7;
            color: @cos-cal-grey;
            font-size: 18px;
            padding: 10px 15px;
            line-height: 22px;
            border-top: 5px solid #2470ef;
            border-bottom: 1px solid #e0e0e0;
            text-align: center;
            border-radius: 3px 3px 0 0;
            .COS-OPEN-SANS-600();

            .pm-btn-group.pm-icon-action-btns {
                float: right;
                margin-right: 0;
            }
        }
    }

    .cos-cal-modal-body {
        max-height: 350px;
        overflow-y: scroll;
        padding: 15px;
    }

    .cos-add-event-footer {
        width: 100%;
        border-top: 1px solid #ccc;

        .cos-add-event-btns {
            float: right;
            width: auto;
            margin: .7rem .5rem .75rem;

            .pm-btn-sm {
                margin-top: 0;
            }
        }
    }
}

/* Event Filter Styles */
*, *::before, *::after {
    box-sizing: border-box;
}

.cos-filter-module {
    margin: 0;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    overflow: hidden;
    user-select: none;

    h1 {
        margin: 0;
        padding: 20px;
        background-color: #f1f5f8;
        border-bottom: 1px solid #a4aaba;
        text-align: center;
    }

    h2 {
        position: relative;
        margin: 0;
        padding: 10px 0;
        font-size: 16px;
        color: @cos-cal-grey;
        .COS-OPEN-SANS-600();

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 10px;
            bottom: 10px;
            left: -20px;
            width: 5px;
            background-color: #435757;
        }

        &::after {
            display: block;
            float: right;
            font-weight: normal;
        }
    }

    .cos-cal-head {
        &.filter-event {
            background-color: #f7f7f7;
            color: @cos-cal-grey;
            font-size: 18px;
            padding: 10px 15px;
            line-height: 22px;
            border-top: 5px solid #2470ef;
            border-bottom: 1px solid #e0e0e0;
            text-align: center;
            border-radius: 3px 3px 0 0;
            .COS-OPEN-SANS-600();

            .pm-btn-group.pm-icon-action-btns {
                float: right;
                margin-right: 0;

            }
        }
    }

    .cos-cal-modal-body {
        &.items {
            input {
                display: block;
                height: 53px;
                margin: 0 0 -53px -9999px;
                order: 4;
                outline: none;

                &:checked + label {
                    &::before {
                        content: '\e92e';
                        color: #fff;
                    }
                }
            }

            label {
                display: block;
                position: relative;
                padding: 10px 0 5px 45px;
                order: 4;
                cursor: pointer;

                &::before {
                    content: ''; /* circle outline */
                    display: block;
                    position: absolute;
                    top: .94rem;
                    left: 10px;
                    font: 2em 'pm-font-icons';
                    border-radius: 4px;
                    border: 1px solid #ccc;
                    width: 22px;
                    height: 22px;
                    line-height: 21px;
                    text-indent: -4px;
                }

                &:hover,
                &:focus + label {
                    background-color: rgba(255, 255, 255, .2);
                }

                a {
                    position: relative;
                }
            }
        }
    }

    .cos-filter-event-footer {
        width: 100%;
        border-top: 1px solid #ccc;

        .cos-filter-event-btns {
            float: right;
            width: auto;
            margin: .7rem .5rem .75rem;

            .pm-btn-sm {
                margin-top: 0;
            }
        }
    }

    &.cos-filter-module-item-edit {
        .cos-cal-modal-body.items {
            padding: 0;

            label {
                cursor: default;
            }

            .cal-calendar-events {
                padding-left: 27px;

                &:before {
                    left: 0;
                }
            }
        }

        .cos-cal-color-picker {
            input:hover {
                opacity: .75;
            }
        }

        .cos-cal-my-events {
            margin-top: 20px;
        }
    }
}

.pm-section-block {
    .pm-section-content {
      .cos-filter-module {
        border: none;
      }

        .cos-cal-locations {
            display: inline-block;
            position: relative;
            top: -9px;
            left: -5px;
            height: 31px;
            line-height: 31px;
        }

        .cos-cal-form-wrapper {
            margin-bottom: 15px;
        }

        .cos-cal-form-field {
            min-height: 33px;
            line-height: 31px;
        }
    }
}

/* === Add New Event Page === */
.cos-cal-add-event-wrap {
    .cos-cal-add-event-row {
        margin-bottom: 15px;

        label {
            line-height: 34px;
        }

        .pm-checkbox-container {
            &.cos-cal-no-end-time {
                position: relative;
                top: -4px;
                display: inline-block;
                height: 34px;
                margin-left: 15px;
            }

            .pm-checkbox {
                &.cos-cal-label-default {
                    line-height: 23px;
                    margin-bottom: 5px;
                    .COS-OPEN-SANS-REGULAR();
                }
            }
        }
    }

    .cos-cal-date-select {
        select {
            margin-right: 5px;
        }
    }

    .cos-cal-input-req {
        color: @COLOR-PM-RED1;
    }

    .cos-cal-event-image {
        display: inline-block;
        height: 100px;
        width: 100px;
        border-radius: 4px;
    }

    .save-page-bar .cos-margin-left-0 {
        margin-left: 0 !important;
    }
}

/* === Event Manager Page Styles - EventManager.jsp === */
.cos-cal-manage-events-wrapper {
    .pm-section-container {
        margin-bottom: 0;

        .pm-section-block {
            .pm-section-content {
                padding-bottom: 0;
            }
        }
    }

    .cos-cal-row {
        padding: 0 10px;
        margin-bottom: 10px;

        .intitle {
            height: 34px;
            line-height: 34px;
        }

        .infield {
            height: 34px;

            input {
                position: relative;
                top: -8px;
            }
        }
    }

    .cos-cal-calendar-icon {
        display: inline-block;
        position: relative;
        top: 2px;
        left: 0;
        height: 30px;
    }

    .cos-cal-location-wrap {
        .cos-cal-location-icon {
            top: 2px;
            margin-right: 5px;

            .pm-icon-font {
                &:before {
                    color: #fff;
                }
            }

            &.green {
                background-color: #0f960f;
            }

            &.blue {
                background-color: #0176ab;
            }

            &.green,
            &.blue {
                border-radius: 50%;
            }

            &.red {
                .pm-icon-font:before {
                    color: @COLOR-PM-RED1;
                }
            }
        }

        .cos-cal-icon-text {
            display: inline-block;
            position: relative;
            top: -4px;
            height: 23px;
            margin-right: 15px;
        }
    }
}


/* === Responsive Styles === */
@media (max-width: 1324px) {
    .cos-cal-icon-legend {
        width: 100% !important;
        text-align: left !important;
        margin-top: 15px;
    }
}

@media (min-width: 1200px) {
    .hidden-lg{
        display: none !important;
    }
}

@media (max-width: 1199px) and (min-width: 325px) {
    .cos-calendar-wrap {
        .cos-date-picker-row {
            .cos-cal-page-title,
            .cos-cal-page-title h1 {
                text-align: center;
                margin-bottom: 15px;
            }
        }
    }

    .cos-cal-header-col.cos-cal-btn-icons {
        text-align: center !important;
        margin-top: 15px;
    }
}

@media (max-width: 992px) {
    .cos-cal-add-event-row .intitle {
        text-align: left;
    }

    .pm-checkbox-container  {

        &.cos-cal-no-end-time {
            height: initial !important;
            top: 2px !important;
        }

        label[for="noEndTime"] {
            top: 5px;
        }
    }

    /* Add New Event Styles */
    .pm-checkbox.checkbox-inline {
        width: 100% !important;
    }

    /* Event Manager Screen */
    .cos-cal-icon-legend .cos-cal-icon-wrap {
        display: block;
        width: 100% !important;
    }
}

@media (max-width: 992px) and (min-width: 768px) {

    .hidden-md {
        display: none !important;
    }

    .cos-calendar-wrap {
        .cos-date-picker-row {
            .cos-cal-page-title,
            .cos-cal-page-title h1 {
                text-align: center;
            }
        }

        .cos-cal-header-row {
            .cos-cal-filters label {
                margin-right: 15px;
            }

            .pm-checkbox.checkbox-inline {
                width: auto;
            }

            .cos-cal-header-col {
                margin-top: 15px;
            }
        }
    }

    /* Day View Styles */
    .cos-cal-view {
        &.day {
            .cos-calendar-wrap {
                .cos-cal-header-row {
                    .cos-cal-header-col {

                        &.cos-cal-date-picker {
                            div > .cos-cal-header-col {
                                height: 39px;
                                margin: 0;
                            }
                        }

                        &.cos-cal-btn-icons.text-right,
                        &.text-right {
                            text-align: center !important;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 768px) {

    .hidden-xs {
        display: none !important;
    }

    .cos-cal-view.month {
        .cos-calendar-wrap {
            .cos-date-picker-row {
                .cos-cal-page-title,
                .cos-cal-page-title h1 {
                    text-align: center;
                }
            }

            .cos-cal-view-wrapper {
                .cos-cal-header-row {
                    margin: 0 -12px 15px;
                }
            }
        }
    }

    .cos-calendar-wrap {
        .cos-cal-header-row {
            .cos-cal-filters {
                display: none;
            }
        }

        .cos-calendar {
            .cos-cal-weekdays,
            .other-month {
                display: none;
            }

            li {
                height: auto !important;
                width: 100%;
                padding: 10px;
                margin-bottom: -1px;
                left: 0;
            }

            .cos-cal-date {
                float: none;
            }
        }

        .cos-calendar .cos-event-desc {
            overflow:hidden;
            text-overflow: ellipsis;
        }

        .cos-date-picker-row {
            .cos-cal-btn-icons {
                margin-top: 15px;

                &.text-right {
                    text-align: center !important;
                }
            }
        }

        .cos-cal-calendar-row {
            margin-top: 15px;
        }
    }

    /* List View Styles */
    .cos-cal-view.list {
        .cos-calendar-wrap {

            .cos-date-picker-row {
                .cos-cal-page-title,
                .cos-cal-page-title h1 {
                    text-align: center;
                }

                .pm-btn-group.pm-icon-action-btns {
                    margin: 0 5px;
                }
            }

            .cos-calendar {
                .cos-cal-weekdays {
                    display: block;

                    .cos-cal-event-details {
                       padding-left: 15px;
                    }
                }
            }
        }
    }

    /* Week View Styles */
    .cos-cal-view.week {
        .cos-calendar-wrap .cos-cal-view-wrapper {
            .cos-cal-row {
                &.events {
                    .event-row {
                        .event-wrap {
                            overflow-y: scroll;
                        }
                    }
                }

                .event-type {
                    min-width: initial;
                    width: 30px !important;

                    .cos-cal-event-title {
                        min-height: 30px !important;
                        transform: rotate(-90deg);
                        left: 4px;
                        top: 92px;
                        padding: 0;
                        margin: 0 auto !important;

                        &.first {
                            margin-top: -18px !important;
                        }
                    }
                }

                .cos-cal-col {
                    display:block;
                    min-width: 130px;

                    &:first-child {
                        width: 30px !important;
                        min-width: initial;
                    }
                }
            }
        }
    }

    /* Day View Styles */
    .cos-cal-view {
        &.day {
            .cos-calendar-wrap {

                .cos-date-picker-row {
                    .cos-cal-page-title,
                    .cos-cal-page-title h1 {
                        text-align: center;
                    }


                    .cos-cal-header-col {
                        &.text-right {
                            text-align: center !important;
                        }

                        &.cos-cal-date-picker {
                            margin-bottom: 15px;
                            height: auto;
                        }

                        &:last-child {
                            height: auto;
                        }
                    }
                }

                .cos-calendar {
                    li {
                        border: 1px solid #ccc !important;
                        border-radius: 0 0 4px 4px;
                    }

                    .cos-cal-weekdays {
                        display: block;

                        .cos-cal-event-details {
                            padding-left: 15px;
                        }
                    }
                }

                .cos-cal-btn-icons {
                    margin-top: 15px;

                    .pm-btn-group.pm-icon-action-btns {
                        margin: 0 5px;

                        .cos-cal-view-btn {
                            .pm-icon-lg {
                                top: 1px;
                            }
                        }
                    }
                }
            }
        }
    }

    /* Add New Event Styles */
    .cos-cal-add-event-row .intitle {
      text-align: left;
    }
}

/* ---==== Bootstrap 7-column Media Query ====--- */
@media (min-width: 768px){
    .cos-seven-cols .col-md-1,
    .cos-seven-cols .col-sm-1,
    .cos-seven-cols .col-lg-1  {
        width: 100%;
        *width: 100%;
    }
}

@media (min-width: 992px) {
    .cos-seven-cols .col-md-1,
    .cos-seven-cols .col-sm-1,
    .cos-seven-cols .col-lg-1 {
        width: 14.285714285714285714285714285714%;
        *width: 14.285714285714285714285714285714%;
    }
}

@media (min-width: 1200px) {
    .cos-seven-cols .col-md-1,
    .cos-seven-cols .col-sm-1,
    .cos-seven-cols .col-lg-1 {
        width: 14.285714285714285714285714285714%;
        *width: 14.285714285714285714285714285714%;
    }
}



