@import (reference) "../Theme/Common/_common.less";

.tnl-tokens {
    display: inline
}

.tnl-token {
    background: @COLOR-TNL-BLUE4;
    display: inline-block;
    height: 34px;
    overflow: hidden;
    .LESS-ROUNDED-CORNERS();
    
    .tnl-token-texts {
        color: @COLOR-TNL-GRAY1;
        display: inline-block;
        font-size: @FONT-SIZE-SM;
        padding: 8px 8px;
        vertical-align: middle;

        .LESS-ELLIPSIS-ONE-LINE();
        max-width: 200px;

        &.full-display {
            .LESS-ELLIPSIS-RESET();
            max-width: none;
        }

        .tnl-token-text {
            display: inline;
        }
        .tnl-token-divider {
            margin: 0;
            padding: 0 2px;

            &::after {
                content: "&";
            }
        }
    }
    .tnl-token-image {
        display: inline-block;

        img {
            height: 34px;
            width: 34px;
        }
    }
    .tnl-token-remove {
        margin-right: 8px;
        vertical-align: middle;
        cursor: pointer;

        .icon-font {
            color: @COLOR-TNL-GRAY1;

            &:hover {
                color: @COLOR-TNL-ORANGE1;
            }
        }
    }
    &.simple {
        background: none;
        .LESS-ROUNDED-CORNERS-RESET();

        .tnl-token-image {
            .LESS-ROUNDED-CORNERS();
        }
    }
    &.small {
        height: 21px;
        width: auto;

        .tnl-token-texts {
            padding-bottom: 0;
            padding-left: 4px;
            padding-top: 0;
        }
        .tnl-token-image {
            img {
                height: 20px;
                width: 20px;
            }
        }
    }
    .tnl-token-select {
        margin: 0 0 0 8px;
        vertical-align: middle;
    }
    &.selected {
        background: @COLOR-TNL-BLUE1;

        .tnl-token-texts {
            color: #fff;
        }
    }
}

.tnl-token-simple {
    vertical-align: middle;

    img {
        display: inline-block;
        margin-right: 5px;
    }
    img, a {
        vertical-align: bottom;
    }

}

.tnl-token-group {

    max-height: 100px;
    overflow: auto;

    .tnl-token-group-divider {
        display: inline-block;
        margin-right: 5px;
    }
    .tnl-token {
        margin-bottom: 2px;
        margin-right: 5px;
        vertical-align: middle;
    }
    .tnl-token-action {
        display: inline-block;
        vertical-align: middle;
    }
}