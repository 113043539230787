@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

// TODO: Consider how this affects current observations in Empari.

.tnl-observation {

    .readonly-text-area {
        height: auto;
        max-height: none;
    }

    .tnl-observation-tabs {

        &:after {
            height: 8px;
            width: 103%;
            background: white;
            content: '';
            position: absolute;
            top: 45px;
            left: -30px;
            z-index: 3;
        }
        > li {

            > a {
                background-color: #F1F5F8;
                border-right: 1px solid rgba(232,232,232,.35);
                box-shadow: 0 1px 6px 2px #ced4da;
                border-left: 1px solid rgba(255,255,255,.35);
            }
        }

        >li.active>a {
            border-top-color: @COLOR-PM-BLUE2 !important;
            border-top-width: 6px !important;
            background: #fff;
            z-index: 2;
        }

    }

    .tnl-observation-header {
        margin-bottom: 15px;

        .tnl-toolbar {
            margin-top: 10px;
        }
    }

    .tnlf-observation-details-button-hide {
        display: none;
    }

    .tnlf-evidence { // TODO: Prototype use only.
        display: block !important;
    }

    .tnl-observation-observee {
        margin-left: 30px;
        font-size: @FONT-SIZE-XLG;

        .tnl-observation-observee-pic {
            display: inline-block;
            margin: 5px 5px 0 0;
            border-radius: 20px;
        }
    }

    /* Participants. */
    .tnl-message-creator {
        margin: 0;
    }

    .tnl-obs-taker {
        .nav-tabs {
            margin-bottom: 0;
        }
    }

    .process-observation-form {
        margin-top: -10px;
        margin-left: 5px;
    }

    .tnl-display-col {
        border: none;
    }

    .form-control {
        max-width: 580px;
    }
}

.tnl-observation-dashboard-schedule-grid {

    .tnl-toolbar-top {
        display: none;
    }

    .tnl-table-container {
        margin-bottom: 0;
    }

    footer {

        .tnl-row-selector-container {
            display: none;
        }

        .col-xs-8 {
            width: 100%;
            text-align: center;
        }

        li {
            display:inline-block;
        }
    }

}
.tnl-observation-status.label {
    display: block;
    padding: 10px;

    .LESS-ROUNDED-CORNERS-RESET();
}
.tnl-observation-dashboard-table {

    .tnl-observation-dashboard-observee {
        img {
            margin-right: 5px;
            vertical-align: baseline;
        }
        b {
            font-size: @FONT-SIZE-LG;
            font-weight: normal;
            vertical-align: baseline;
        }
    }

    .tnl-table-container {

        table.tnl-table {
            &.table-striped > tbody > tr:nth-child(even) > td {
                background: #fff;
            }
            > thead {
                > tr:last-child > th {
                    border-bottom: none;
                }
            }
            > tbody {
                > tr > td {
                    border-top: 1px solid @COLOR-TNL-GRAY4;
                    font-size: @FONT-SIZE-DEFAULT;
                    vertical-align: middle;
                }

            }
            table {
                border: 0 !important;
                margin-top: 5px;

                tr {
                    border: 0 !important;

                    td:first-child {
                        border-left: 2px solid transparent !important;
                    }

                }
                td {
                    padding: 5px;
                }
                td,
                td > a {
                    font-size: 13px;
                    border: 0;
                    vertical-align: middle;

                    &.tnl-status-evidence {
                        border: 1px solid;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }
}

.tnl-observation-insights-stats {
    > .row {
        margin-bottom: 20px;
    }
}

.tnl-well-group,
.tnl-observation-preview {
    padding: 15px; //Set padding locally since the aside and section positions depend on it.
    position: relative;

    aside {
        left: 0;
        position: absolute;
        text-align: center;
        top: 15px; //Consider for well padding.
        width: 65px; //Consider for well padding.

        .icon-font {
            font-size: 27px;
        }
    }
    .tnl-status-icon{
        width: 45px;
        .icon-font {
            font-size: 27px;
        }
    }
    section {
        left: 50px;
        margin-right: 60px;
        position: relative;

        .well-header {
            margin-bottom: 10px;

            h1 {
                font-size: 15px;
                .COS-OPEN-SANS-600();

                small {
                    color: inherit;
                }
            }
            p {
                color: @COLOR-TNL-GRAY3;
                font-size: @COS-FONT-SIZE-12;
                margin: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        > main {
            .call-out {
                color: @COLOR-PM-ORANGE1;
            }
            p {
                font-size: @FONT-SIZE-SM;
            }
            p:last-child {
                margin-bottom: 0;
            }
        }
    }
}


//TODO: Refactor .tnl-major-minor-pane location.
.tnl-major-minor-pane {
    @MINOR-WIDTH: 400px;
    min-height: 350px; //TODO: Reconsider this setting.
    position: relative;

    .on {
        padding-right: 440px;

        @media (max-width: @BOOTSTRAP-SM) {
            padding-right: 0;
        }
    }

    .tnl-pane-major {
        margin-right: 90px;
        padding-top: 20px;

        &.on {
            margin-right: 0;
        }

        @media (max-width: @BOOTSTRAP-SM) {
            margin-right: 0;
        }
    }

    @media (max-width: @BOOTSTRAP-SM) {
        margin-right: 0;
    }

    .tnl-ajax-minor-bar {
        display: inline-block;
        position: absolute;
        right: 0;
    }

    .tnl-pane-minor {
        background-color: @COLOR-TNL-GRAY6;
        border-bottom: 1px solid @COLOR-TNL-GRAY4;
        border-left: 1px solid @COLOR-TNL-GRAY4;
        border-right: 1px solid @COLOR-TNL-GRAY4;
        padding: 10px;
        width: @MINOR-WIDTH;
        overflow: auto;

        .tnl-pane-minor-header {
            h1, h2, h3, h4, h5, h6 {
                margin-bottom: 10px;
            }
        }
    }

    .tnl-minor-pane-actions {
        background: @COLOR-PM-BLUE2;
        text-align: center;
        width: 60px;
        .PM-ROUNDED-CORNERS();

        .active {
            background-color: @COLOR-PM-BLUE-DARK!important;
        }

        .pm-btn-lg {
            margin: 3px;
            padding: 10px;
            border-radius: 0;
        }

        .btn {
            display: inline-block;
            margin-bottom: 10px;

            &:first-child {
                margin-top: 10px;
            }
        }
        .icon-font {
            &.fa,
            &.im {
                font-size: 20px;
                color: #fff!important;
            }
        }
        &.fix-to-top {
            background: @COLOR-PM-BLUE2;
            border: 1px solid @COLOR-TNL-GRAY4;
            text-align: left;
            width: @MINOR-WIDTH;
            .LESS-ROUNDED-CORNERS-RESET();
            .LESS-BOX-SHADOW-RESET();

            .btn {
                border: 1px solid transparent;
                margin-top: 5px;

                &:first-child {
                    margin-left: 12px;
                }

                &:hover:not(.active) {
                    border: 1px solid @COLOR-TNL-GRAY3;

                    .icon-font {
                        color: #fff!important;
                    }
                }
                &.active {
                    background: transparent;
                    border: 1px solid @COLOR-TNL-GRAY3;
                    .PM-ROUNDED-CORNERS();

                    .icon-font {
                        color: @COLOR-TNL-GRAY1;
                    }

                    &:hover {
                        .icon-font  {
                            color: @COLOR-TNL-GRAY1;
                        }
                    }
                }
                &.tnl-close-minor-pane {
                    &:hover {
                        border-color: transparent;
                    }
                }
            }
            .tnlf-close-minor-pane {
                float: right;
            }

        }
    }
}

.tnl-observation-dash-stats {
    margin-bottom: 20px;
}

.stick-to-top {
    position: fixed !important;
    top: 10px;
}

.well.tnl-user-rating {
    background: @COLOR-PM-BLUE-LIGHT;
    border-color: @COLOR-PM-BLUE;
    min-width: 200px;

    > div {
        display: inline-block;
        vertical-align: middle;
        width: 49%;
        white-space: nowrap;
    }
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            color: @COLOR-TNL-GRAY2;
            font-size: @COS-FONT-SIZE-10;
            padding: 2px 20px 2px 0;
            text-align: right;
            .LESS-ELLIPSIS-ONE-LINE();

            &.rated {
                color: @COLOR-PM-ORANGE1;
                .COS-OPEN-SANS-600();
            }
        }
    }
    .tnl-user-rating-value {
        color: @COLOR-PM-BLUE;
        text-align: left;

        span {
            display: block;
            margin: 0;
            padding: 0;
            text-align: center;

            &:first-child {
                font-size: 48px;
                line-height: 40px;
            }
            &:last-child {
                font-size: @COS-FONT-SIZE-10;
            }
        }
    }
}

//TODO: Refactor legal agreement location.
.tnl-legal-agreement {
    border: 1px solid @COLOR-TNL-GRAY4;
    font-size: @COS-FONT-SIZE-12;
    margin-bottom: 20px;
    max-height: 200px;
    overflow: auto;
    padding: 10px;
}

//TODO: Refactor scripting session and notes location.
.tnl-scripting-session {
    .tnl-scripting-session-entry {
        border-bottom: 1px solid @COLOR-TNL-GRAY5;
        margin-bottom: 20px;
        .tnl-toolbar {
            margin-top: 5px;

            > .btn {
                margin-bottom: 5px;
            }
        }
    }
    .tnl-scripting-session-ellapsed {
        color: @COLOR-TNL-GRAY4;
        font-size: @COS-FONT-SIZE-10;
        padding: 5px 0;
    }
}

.tnl-scripting-session-fullscreen,
.tnl-pane-minor {
    .tnl-user-note {
        width: 99%; // Prevents container from rendering a horizontal scrollbar.

        .tnl-toolbar {
            display: none !important;
        }
    }
}

.tnl-user-notes {
    .tnl-user-note {
        padding: 5px 0;
    }
    p {
        font-size: @COS-FONT-SIZE-12;
        margin: 0;
    }
    .tnl-note-time {
        color: @COLOR-TNL-GRAY3;
        font-size: @COS-FONT-SIZE-10;
        margin-bottom: 0;
        padding: 5px 0;
        text-align: right;
        width: 90%;
        .LESS-ELLIPSIS-ONE-LINE();
    }
    .tnl-note-text {
        padding: 4px 0 6px;
        span {
            white-space: normal;
        }
    }
    .tnl-user-note-started,
    .tnl-user-note-stopped {
        color: @COLOR-TNL-GRAY3;
    }
    .tnl-user-note-started {
        border-top: 1px solid @COLOR-TNL-GRAY5;

        &:first-child {
            border: none;
        }
    }
    &.tnl-user-notes-vertical {
        .tnl-user-note {
            padding-bottom: 10px;
        }
        .tnl-note-time {
            padding: 0;
            text-align: left;
        }
        .tnl-note-text {
            padding: 0;
        }
    }
    .tnl-note-actions {

        a {

            &:hover {
                color: @COLOR-TNL-GRAY3;
            }
        }
        .tnl-note-action {
            display: inline-block;
            padding: 5px 10px;
        }
    }

    &.tnl-user-notes-draggable {
        .tnl-user-note {
            &.selected {
                background: #ffffcc !important;
            }
            &:hover {
                background: #ffffcc !important;
                cursor: move;
            }
        }
    }

}

//TODO: Extract this from _observation.less
.tnl-drag-to-able-container {
    position: relative;

    .tnl-drag-to-able {
        min-height: 80px;

        &.active {
            border: 2px dashed @COLOR-PM-GREEN;
            cursor: pointer !important;
        }
        &.hover {
            background: @COLOR-PM-GREEN-LIGHT;
        }
    }
    .tnl-drag-to-able-overlay {
        background-color: rgba(243,245,235,0.9);
        bottom: 0;
        color: @COLOR-PM-GREEN;
        cursor: pointer;
        left: 0;
        margin: 3px;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;

        .icon-font {
            color: @COLOR-PM-GREEN;
            margin-right: 10px;
        }
    }
}

input.form-control[type="text"]{
    &.active {
        border: 2px dashed @COLOR-PM-GREEN;
        cursor: pointer !important;
    }
    &.hover {
        background: @COLOR-PM-GREEN-LIGHT;
    }
}

.tnl-draggable-item {
    background-color: @COLOR-TNL-GRAY5;
    border: 1px solid @COLOR-PM-GREEN;
    cursor: move !important;
    max-width: 400px;
    padding: 5px;
    .PM-ROUNDED-CORNERS();
}

.tnl-not-implemented {
    cursor: not-allowed !important;
}

//TODO Where do we put this?
.checkbox .checkbox-check {
    position: absolute;
    margin-left: -20px;
    margin-top: 4px \9;
}

.tnl-scripting-session-fullscreen {
    background-color: rgba(0, 0, 0, .5);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2000;
}

.tnl-scripting-session-fullscreen > article {
    background-color: #fff;
    border-radius: 4px 4px;
    bottom: 10px;
    left: 10px;
    position: fixed;
    right: 10px;
    top: 10px;
}

.tnl-scripting-session-fullscreen > article > footer {
    bottom: 40px;
    left: 20px;
    position: fixed;
    right: 20px;
    z-index: 2001;
}

.tnl-scripting-session-fullscreen > article > header {
    padding: 10px;
}

.tnl-scripting-session-fullscreen > article > main {
    position: fixed;
    top: 80px;
    bottom: 146px;
    left: 20px;
    right: 20px;
    overflow: auto;
}

.tnl-scripting-session-fullscreen .tnl-scripting-session-entry {
    margin: 0;
    border: 0 !important;
}

.tnl-scripting-session-fullscreen > article > footer .btn,
.tnl-scripting-session-fullscreen > article > footer .input-group {
    margin-bottom: 0 !important;
}

.tnl-scripting-session-fullscreen .tnl-user-note {
    position: relative;
}

.tnl-scripting-session-fullscreen .tnl-toolbar > div:last-child {
    margin-bottom: 0 !important;
}

@media print {
    .tnl-observation-print-preview {
         /*Makes the borders play nice with wkhtmltopdf*/
         margin-left: 9px;
         margin-right: -7px;
    }
}