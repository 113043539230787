@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

.pm-bd-teacher .pm-bd-leaderboard-column {
    position: relative;
    width: 100%; 
    height: 140px; 
}
    
.pm-bd-second-place,
.pm-bd-first-place,
.pm-bd-third-place {
    background: #fff;
}
    
.pm-bd-profile-name {
    position: relative;
    margin: 0 auto;
    width: 75%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    color: #36474f;
    text-align: center;
    .COS-OPEN-SANS-600();
}
    
.pm-bd-profile-banner {
    width: 84px;
    height: 190px;
    text-align: center;
    margin: 10px auto 0;
}
    
.pm-bd-banner-ribbon {
    position: absolute;
    bottom: 50px;
    width: 84px;
    background-color: #8340e8;
    margin: 0 auto;
    text-align: center;
    padding: 0 10px;
    box-sizing: border-box;
    .COS-OPEN-SANS-REGULAR();
}
    
.pm-bd-second-place .pm-bd-banner-ribbon {
    height: 100px;
}
    
.pm-bd-second-place .pm-bd-profile-name {
    top: 105px;
}
    
.pm-bd-first-place .pm-bd-banner-ribbon {
    height: 140px;
}
    
.pm-bd-first-place .pm-bd-profile-name {
    top: 115px;
}
    
.pm-bd-third-place .pm-bd-banner-ribbon {
    height: 77px;
}
    
.pm-bd-third-place .pm-bd-profile-name {
    top: 105px;
}
    
.pm-bd-banner-ribbon:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 42px 0 42px;
    border-color: #8340e8 transparent transparent transparent;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -42px;
}
    
.pm-bd-banner-ribbon-text {
    position: relative;
    height: 56px;
    top: 50%;
    margin-top: -24px;
}
    
.pm-bd-banner-ribbon span {
    display: block;
    position: relative;
    color: #fff;
    text-align: center;
    .COS-OPEN-SANS-REGULAR();
}
    
.pm-bd-profile-badge-count {
    font-size: 28px;
    line-height: 31px;
}
    
.pm-bd-profile-badge-title {
    font-size: 14px;
    line-height: 17px;
}
    
.pm-bd-profile-image {
    position: absolute;
    left: 50%;
    margin-left: -40px;
    height: 76px;
    width: 76px;
    border-radius: 50%;
    border: 2px solid #00bb54;
    background-color: #fff;
    overflow: hidden;
}
    
.pm-bd-profile-rank {
    position: absolute;
    top: 69px;
    left: 50%;
    margin-left: -18.5px;
    width: 37px;
    height: 48px;
    text-align: center;
    line-height: 33px;
    color: #fff;
    background: 0 0 transparent no-repeat url(/images/pm/badge/badge-circle.svg);
    background-size: 37px 48px;
    .COS-OPEN-SANS-600();
}
    
.pm-bd-profile-rank.pm-bd-winner-ribbon {
    position: absolute;
    top: 69px;
    left: 50%;
    width: 37px;
    height: 48px;
    margin-left: -18.5px;
    background: 0 0 transparent no-repeat url(/images/pm/badge/badge-circle-ribbon.svg);
    background-size: 37px 48px;
}