@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

.pm-heading-container {
    padding: 5px 35px 8px;
    background-color: #00427c;
}

.pm-heading-container.unify {
    background-image: url(/images/pm-unify-300x170.png);
    background-size: 150px 85px;
    background-position: right 0;
}

.pm-page-title {
    margin-bottom: 5px;
}

.pm-breadcrumb ul > li,
.pm-breadcrumb ul > li > a {
    .COS-OPEN-SANS-REGULAR() !important;
}

.pm-page-title h1 {
    color: #fff;
    margin-bottom: 0;
}

.pm-breadcrumb {
    max-width: 847px;
    white-space: nowrap;
    overflow: hidden;

    ul {
        padding: 0;
        margin: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        line-height: 18px;

        li {
            list-style-type: none;
            padding: 0;
            display: inline-block;
            position: relative;
            color: #fff;
            line-height: 18px;
            margin-top: -18px;

            &:after {
                content: "\e91b";
                font-family: "pm-font-icons";
                display: inline-block;
                font-size: 20px;
                top: 4px;
                left: 8px;
                position: relative;
                width: 32px;
                height: 18px;
            }

            a {
                text-decoration: none;
                outline: none;
                border: none;
                color: rgba(255,255,255,.75);
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;

                &:hover {
                    color: rgba(255,255,255,1);
                }
            }

            &:last-child {
                &:after {
                    content: "";
                }
            }
        }
    }
}

.pm-breadcrumb li a,
.pm-breadcrumb li a:hover {
    transition: all ease-in-out .15s;
}

.pm-content-container {
    background: #fff;
    padding: 30px;
}

@media (max-width: 992px) {
    .pm-breadcrumb {
        display: none;
    }
}
