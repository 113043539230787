@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";


.tnl-search {
    .tnl-search input {
        padding: 5px;
        width: 100%;
        color: @COLOR-TNL-GRAY2;
        font-size: @FONT-SIZE-SM;
        -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15);
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15);
        border: none;
        .LESS-BOX-SHADOW-RESET;
    }
    .tnl-search input.inline-input {
      display: inline-block;
      width: auto;
    }
    .tnl-search a {
        display: inline-block;
        position: absolute;
        top: 5px;
        right: 10px;
        width: auto;
        padding: 5px;
        background: none;
        border: none;
    }
    .tnl-search a .icon-font {
        color: @COLOR-TNL-GRAY3;
    }
}
.tnl-transaction-search {
    font-size: @FONT-SIZE-XSM;
    .tnl-transaction-search input {
        margin: 0 0 0 5px;
        padding: 2px;
        width: 80px;
        font-size: @FONT-SIZE-XSM;
        border-radius: 2px;
        border: 1px solid @COLOR-TNL-GRAY3;
    }
    input {
        .LESS-BOX-SHADOW-INSET();
    }
}
.empari {
    .tnl-search-bar-box {
        max-width: 600px;

        .input-group {
            margin-bottom: 7px;

            .form-control {
                border-radius: 4px !important;
                box-shadow: none !important;

                &:focus {
                    border-color: @COLOR-PM-BLUE2;
                }
            }

            .input-group-btn button {
                margin-left: 10px;
            }
        }

        .btn.btn-success,
        .boot-btn.btn-success {
            border: 1px solid @COLOR-PM-GREEN3;
        }

        .btn.pm-primary-btn,
        .boot-btn.pm-primary-btn {
            .PM-TRANSITION();
            &:hover {
                background-color:@COLOR-PM-LINK-H;
                .PM-TRANSITION();
            }

            &:active {
                background-color: @COLOR-PM-LINK-A;
                .PM-TRANSITION();
            }
        }
    }
}

.tnl-search-or {
    border-radius: 50%;
    width: 50px;
    background-color: @COLOR-PM-GRAY-DARK;
    color: #fff;
    font-weight: 700;
    margin: 25px;
    padding: 14px 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}