@import (reference) "_pm-global.less";

// TOKEN BADGE STYLES //
// Author: Chris Brosky - December 2017 //

.pm-token-wrap {
  background-color: #d0dfee;
  border-radius: 4px;
  width: auto;
  height: 34px;
  padding: 0 10px;
  position: relative;
  display: block;
  margin: 0 15px 10px 0;
  float: left;
  clear: left;

  .pm-token-text {
      display: inline-block;
      position: relative;
      top: 7px;
      float: left;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
  }

  .pm-token-icon {
      display: inline-block;
      position: relative;
      top: 2px;
      right: -10px;
      width: 30px;
      height: 30px;
  }
}
.pm-token-elective{
  background-color: @COLOR-PM-GREEN1 ;
  color:@COLOR-PM-WHITE1;
}
.pm-token-required{
  background-color: @COLOR-PM-HEADER2 ;
  color:@COLOR-PM-WHITE1;
}
.pm-token-no-clear{
  clear: none;
}

@media (max-width: @PM-BOOTSTRAP-SM) {
  .pm-token-wrap {
      margin-right: 0;
  }
}
