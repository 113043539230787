@import (reference) "../Theme/Common/_common.less";

.tnl-message-creator {
    margin-bottom: 20px;

    header {
        margin-bottom: 20px;
        font-size: 14px;

        
        > div:last-child {
            border-bottom: 1px solid #bbb;
        }
    }
    .tnl-message-creator-recipients {
        max-height: 250px;
        overflow: auto;
    }
    .tnl-message-creator-to,
    .tnl-message-creator-from {
        padding: 5px;
    }
    .tnl-message-creator-to .label {
        display: inline-block;
        font-size: 14px;
        margin: 0 5px 5px 0;
        background: #efefef;
        border: 1px solid #ddd;
        color: #666;
        
        .icon-font {
            color: #bbb;
        }
    }
}