@import (reference) "../Theme/Common/_common.less";

.tnl-ratings img {
    margin-right: 0;
    vertical-align: middle;
}

.tnl-ratings .count {
    margin-left: 5px;
    font-size: @FONT-SIZE-XSM;
    vertical-align: middle;
}

.tnl-ratings .tnl-icon-rate {
    margin: 0 -2px 0 0;
    padding: 0;
    vertical-align: middle;
}

.pm-ratings {
    position: relative;
        
        span.tnl-popup-tab {
            left: 371px!important;
        }

        .tnl-popup {
            left:-338px;
        }
}