@import (reference) "_pm-global.less";

.pm-left-nav-item {
  border-bottom: 1px solid @COLOR-PM-GREY5;
  margin: 0 0 0 20px;
  padding: 10px 0;
  cursor: pointer;

  &.pm-left-nav-heading,
  &.pm-disabled-link {
    cursor: default;
  }

  &.pm-left-nav-heading {
    .pm-heading-align {
      margin: 20px 0 0 !important;
      padding: 0;

      span {
        color: @COLOR-COS-DEFAULT;
        font-size: @COS-FONT-SIZE-DEFAULT;
        .COS-OPEN-SANS-600();
      }
    }
  }

  &.pm-disabled-link {
    span {
      color: #6c6c6c;
      opacity: .6 !important;
    }
  }
}

.pm-left-nav-item-selected {
  background-color: @COLOR-PM-BLUE3;
  color: @COLOR-PM-WHITE1;
}

.pm-left-nav-text {
    line-height: 14px;
    color: @COLOR-PM-WHITE1;

    &:after {
      font-family: "pm-font-icons";
      content: "\e91b";
      display: inline-block;
      position: relative;
      font-size: 22px;
      top: 4px;
      right: -6px;
      float: right;
    }
}
