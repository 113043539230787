@import (reference) "_pm-global.less";

// PD PLAYLIST & DISCUSSION STYLES //
// Author: Chris Brosky - October 2017 //

.pm-pd-playlists,
.tnl-item-list > li.pm-pd-playlists {
  overflow: visible !important;
  
    .tnl-text-link {
        max-width: 193px;
    }
}

.pm-discussion-toggle {
  padding: 0 0 20px;

  div > input {
    position: relative;
    top: 2px;
  }
}

.pm-filter-header-action {
  display: inline-block;
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }
}

.pm-grid-bottom {
  height: 30px;

  p {
    display: none;
  }
}

.pm-approval-status-icon {
  position: relative;
  top: 10px;
  display: inline-block;
  height: 30px;
  width: 30px;
}

/* --=== DISCUSSION PAGE CONTENT AREA ===-- */
.pm-discussion-page {
  .container {
    padding: 0;
  }

  .pm-vertical-filter-box {
    padding: 10px 5px 10px;
    background-color: #fff;
    margin: 15px 0;

    .pm-filter-box-options-row {
      margin: 0 -5px;

      .pm-filter-box-options {
        margin: 0 !important;
      }
    }

    .pm-filter-header-action {
      .tnl-button-menu {
        &.btn-group {
          margin-top: -2px;
          margin-right: 0;
          .PM-TRANSITION();

          .boot-btn {
            padding: 5px 8px;

            &.dropdown-toggle {
              padding: 5px 8px;

              &:hover,
              &:active,
              &:focus {
                background-color: @COLOR-PM-WHITE1 !important;
              }
            }

            &:first-child {
              &:hover {
                background-color: transparent;
              }
            }
          }

          &:hover {
            border-color: @COLOR-PM-BLUE2 !important;
            .PM-TRANSITION();
          }

          .pm-create-thread {
            border-right: 1px solid transparent !important;

            span {
              vertical-align: top;
            }

            &:hover,
            &:active,
            &:focus {
              background-color: @COLOR-PM-WHITE1;
              border-right: 1px solid transparent !important;
            }
          }
        }
      }
    }
  }

  .pm-discussion-search-btn {
    margin-left: 10px;

    &.pm-btn-lg {
      padding: 7px 24px;
    }
  }

  &.tnl-discussion-board-content {
    background-color: #E7EAEC;
  }

  .pm-container-table {
    display: table;
    width: 100%;

    .pm-table-row {
      display: table-row;
      width: 100%;

      .pm-table-cell {
        display: table-cell;
      }
    }
  }

  .pm-discussion-entry,
  .pm-discussion-replies {
    padding: 20px;
  }

  .pm-discussion-entry {
    border-bottom: 1px solid #ddd;

    .row {
      margin: 0;

      .pm-topic-title-badge {
        height: 40px;
        margin: -20px 0 20px;
        padding: 0;

        .pm-discussion-topic-title {
            color: @COLOR-PM-WHITE1;
            padding: 10px 15px;
            max-width: 200px;
            text-align: center;
            float: right;
            border-radius: 0 0 5px 5px;
        }
      }
    }

    .pm-discussion-avatar {
      position: relative;
      top: 5px;
      left: 0;
      width: 30px;
      height: 30px;
      margin-right: 0;
      padding: 0;
      border-radius: 2px;
      vertical-align: top;

      img {
        margin-right: 0;
      }
    }
  }

  .pm-discussion-new-reply {
    padding: 0 20px 20px 40px;
    background-color: @COLOR-PM-GREY4;

    .pm-discussion-avatar {
      position: relative;
      top: 0;
      left: 0;
      width: 30px;
      height: 30px;
      margin-right: 0;
      padding: 0;
      border-radius: 2px;
      vertical-align: top;

      img {
        margin-right: 0;
      }
    }

    .pm-comment-area {
      margin-bottom: 15px;
    }

    button {
      margin-top: 5px;
    }
  }

  .pm-discussion-replies {
      background-color: @COLOR-PM-GREY4;
  }

  .pm-hidden-reply {
      display: none;
  }

  .pm-visible-reply {
  }

  .pm-attachments {
    .pm-file-list-item {
      a {
        position: relative;
        top: -3px;
      }
    }
  }

  .pm-discussion-buttons {
    text-align: right;
    padding: 10px 0;
    margin-bottom: 5px;

    .pm-show-all-replies,
    .pm-post-reply {
      top: 0;
      padding: 0 10px;
      border-right: 1px solid #ccc !important;
      line-height: 30px;
    }

    .pm-post-reply {
      margin-right: 10px;
    }

    .pm-remove-on-delete {
      .pm-btn-group {
        position: relative;
        top: 12px;
        margin-right: 0;
      }

      .pm-follow-thread {
        margin-left: 10px;
      }

      button {
        &:last-child {
          margin-right: 0 !important;
        }
      }

      .pm-btn-group {
        &.pm-icon-action-menu-btn {
          .pm-show-text {
            &.left {
              top: 0 !important;
            }
          }
        }
      }
    }
  }

  .pm-discussion-options {
    button, ul {
      display: inline-block;
      margin-top: 5px;
    }

    .pm-attach-file-control {
      margin-bottom: 10px;
    }
  }

  .pm-no-margin {
    &.pm-author-row {
      margin: 0 0 20px 0;
    }
  }

  .pm-deleted-post {
      padding: 0 0 20px 0;
      color: #ccc;
  }
  .pm-discussion-post-title {
      padding-top: 10px;
      .COS-OPEN-SANS-700();
  }
  .pm-discussion-post-comment {
      padding: 10px 0;
  }

  .pm-discussion-poster-name {
    margin-top: 1px;
    margin-bottom: -2px;
    .COS-OPEN-SANS-700();
  }

  .pm-discussion-post-time {
      color: @COLOR-COS-DEFAULT;
  }
}

/* --=== DISCUSSION LEFT NAV ===-- */
.pm-discussion-nav {
  .pm-nav-topic-selected a {
      color: @COLOR-PM-WHITE1;
      .COS-OPEN-SANS-700();
  }

  .pm-all-selected {
      background-color: #4F60EC;
  }

  .pm-discussion-all {
    margin-bottom: 0;

    &:hover {
      a {
        color: @COLOR-PM-LINK-H !important;
      }
    }

    &.pm-all-selected {

      a {
        color: @COLOR-PM-WHITE1;
        display: block;
        .COS-OPEN-SANS-700();
      }

      &:hover {
        a {
          color: @COLOR-PM-WHITE1 !important;
        }
      }
    }
  }

  .pm-discussion-left-nav {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      border-bottom: 1px solid #ccc;
      display: block;
      cursor: pointer;
      padding-bottom: 0;

      a {
        display: block;
        padding: 13px;
      }

      &:hover {
        a {
          color: @COLOR-PM-WHITE1;
        }
      }
    }

    .pm-discussion-nav-bullet {
        float: left;
        position: relative;
        top: 9px;
        left: 5px;
        width: 5px;
        height: 28px;
        margin-right: 15px;
    }
  }

  .pm-discussion-manage-topics {
    list-style-type: none;
    margin: 15px 0 10px;
    padding: 0;

    li {
      padding-bottom: 0;

      a {
        height: 24px;
        width: auto;
        position: relative;
        display: inline-block;
      }

      .pm-icon-sm {
          position: relative;
          height: 24px;
      }

      .pm-discussion-left-nav-item {
          display: inline-block;
          position: relative;
          top: -7px;
          left: 0;
          margin: 0 0 0 10px;
          height: 23px;
      }
    }
  }
}

/* --=== RESPONSIVE STYLES ===-- */
@media (min-width: 480px) and (max-width: 1200px) {
  .pm-filter-boxes {
    .pm-filter-box-options {
      float: left !important;
      text-align: left !important;

      .col-xs-12,
      .col-sm-12,
      .col-md-12 {
        float: left !important;
        text-align: left !important;
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 992px) {
  .pm-discussion-page {
    .pm-discussion-avatar {
        top: 0;
        margin: 0 10px 10px;
    }

    .pm-discussion-new-reply {
        padding: 20px 30px;
    }

    .pm-discussion-buttons {
      text-align: left;
      display: inline-block;

      .pm-remove-on-delete {
        a:first-child {
          padding-left: 0;
          margin-bottom: 20px;
        }
      }
    }
  }

  .pm-attachments {
    .pm-file-list-item {
      text-align: left;
      margin-left: 0;
    }
  }
}
