@import (reference) "_pm-global.less";

// ACTION BUTTONS - ICON ONLY //
// Author: Chris Brosky - June 2017 //

// LESS VARIABLES //
@color-hover:  @COLOR-PM-LINK-H;
@color-active:  @COLOR-PM-LINK-A;
@color-open:    @COLOR-PM-GREY9;

.pm-btn-group {
    margin-right: 15px;
    vertical-align: baseline;

    &.pm-icon-action-btns {
        position: relative;

        a {
            display: inline-block;
            width: 100%;

            .pm-icon-sm {
                height: 2.3rem;
                width: 2.3rem;

                .pm-icon-font {
                    color: @COLOR-COS-DEFAULT;
                    .PM-TRANSITION();
                }
            }

            &:link {
                color: @COLOR-COS-DEFAULT;
            }

            &:hover,
            &:focus {
                color: @color-hover;
                .PM-TRANSITION();

                .pm-icon-sm {
                    .pm-icon-font {
                        color: @color-hover;
                        .PM-TRANSITION();
                    }
                }
            }

            &:active {
                color: @color-active;
                .PM-TRANSITION();

                .pm-icon-sm {
                    .pm-icon-font {
                        color: @color-active;
                        .PM-TRANSITION(@prop: all, @time: .1s, @ease: linear);
                    }
                }
            }

            &:focus {
                outline: none;
            }
        }

        .pm-action-btn {
            box-shadow: none !important;
            background: none !important;
            border: none !important;
            padding: 0;
            .PM-TRANSITION();

            &:first-child {
               margin-left: 0;
            }

            span {
              color: @COLOR-PM-LINK;

              &:hover {
                color: @COLOR-PM-LINK-H;
                .PM-TRANSITION();

                .pm-icon-sm {
                  .pm-icon-font {
                    color: @COLOR-PM-LINK-H;
                    .PM-TRANSITION();
                    }
                }
              }

              &:active {
                color: @COLOR-PM-LINK-A;
                .PM-TRANSITION();

                .pm-icon-sm {
                  .pm-icon-font {
                    color: @COLOR-PM-LINK-A;
                    .PM-TRANSITION(@prop: all, @time: .1s, @ease: linear);
                  }
                }
              }

              &:visited,
              &:focus {
                color: @COLOR-PM-LINK;
                outline: none;
              }
            }

            .pm-show-text {
              display: inline-block;
              width: auto;
              position: relative;
              vertical-align: top;
              top: 2px;
              color: @COLOR-PM-BLUE1;

                &.pm-text-sm {
                    line-height: 2.1rem;
                }

                &.pm-text-md {
                    line-height: 2.6rem;
                }

                &.pm-text-lg {
                    line-height: 3.3rem;
                }

                &.left {
                    margin-right: 6px;
                }

                &.right {
                    margin-left: 6px;
                }
            }

            .pm-action-btn-label {
                height: 30px;
                display: inline-block;

                .pm-action-btn-text {
                    display: inline-block;
                    position: relative;
                    top: -9px;
                    vertical-align: middle;
                    padding: 5px 0;
                    color: @COLOR-PM-LINK;
                    .PM-TRANSITION();
                }
            }

            .pm-icon-sm {
              margin-right: 0 !important;
              height: 2.3rem;
              width: 2.3rem;

              .pm-icon-font {
                &.pm-icon-font {
                  &:before {
                      font-size: 23px;
                  }
                }
              }
            }

            &:hover,
            &:focus {

                span {
                    .PM-TRANSITION();
                    color: @COLOR-PS-BLUE1;
                }
            }

            &:active {
                color: @color-active;
                .PM-TRANSITION(@prop: all, @time: .1s, @ease: linear);
            }

             &:focus {
                outline: none;
            }

            &[disabled] {
                .pm-icon-font {
                    color: #767676 !important;
                }
            }
        }

        .cos-pseudo-class-overlay:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-color: #fff;
            border-radius: 32px;
            opacity: 0;
            cursor: pointer;
        }
    }
}

.pm-valign-middle {
    padding: 10px 0;
    .pm-btn-group {
        vertical-align: middle;
    }
}
