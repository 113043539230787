@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

.tnl-breadcrumb-container {
    //TODO: Once breadcrumb DOM Is refactored to be contained by .tnl-breadcrumb-container, move .breadcrumb here.
}

.tnl-page-back {
    padding: 5px 0;

    .btn {
        font-size: @FONT-SIZE-XSM;
    }
}
.no-bc-limit {
    .breadcrumb{
        > li > a {
            max-width: none;
        }
    }
}
.breadcrumb {
    background-color: transparent;
    color: @COLOR-TNL-GRAY2; 
    font-size: @FONT-SIZE-XSM;
    list-style: none outside none;
    margin: 0;
    padding: 10px 10px 10px 0;
    text-align: right;
    .LESS-ROUNDED-CORNERS-RESET();

    > .active {
        color: @COLOR-TNL-GRAY2;
        .COS-OPEN-SANS-600();
    }
    > li > a,
    > li > span {
        padding: 0 8px;
        .LESS-ELLIPSIS-ONE-LINE();
        display: inline-block;
        max-width: 150px;
        position: relative;
        top: 3px;
        vertical-align: text-bottom;
    }
    > li+li:before {
        content: "|";
    }
    .btn.btn-link .fa.icon-font {
        color: @COLOR-LINK;
    } 
}

.tnl-crumbs {
            margin-top: 10px;
            
            .tnl-crumb {
                display: inline-block;

                &.current {
                    color: @COLOR-TNL-ORANGE1;
                }
            }
            .tnl-crumb-level {
                .tnl-crumb,
                .tnl-crumb-divider {
                 vertical-align: top;
            }
        }
        .tnl-crumb-divider {
                border-bottom: 1px dotted gray;
                border-left: 1px dotted gray;
                clear: both;
                display: inline-block;
                height: 10px;
                margin-left: 5px;
                width: 20px;
        }
        .tnl-crumb-spacer {
            display: inline-block;
            width: 10px;
        }
}

