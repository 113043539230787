.pds-scope {
  .pds-page-toolbar {
    border-top: 1px solid #dfdfdf;

    .pds-button-ahref:hover {
      text-decoration: none;
    }
  }
  .pd-admin-saml-setup {
    padding: 20px;

    .tnl-item-list {
      list-style-type: none;
    }

    .pds-button-ahref:hover {
      text-decoration: none;
    }

    .pds-page-head {
      margin-bottom: 15px;
    }

    .pds-checkbox-widget {
      [type=checkbox]:checked~.pds-label-text:first-of-type:after {
        width: 15px;
        height: 15px;
      }
    }

    .pd-admin-edit-saml {
      background-color: #f1f1f1;
      margin: 20px 0;
      padding: 20px 0;
      border-radius: 4px;

      .pm-admin-saml-trash {
        display: inline-block;
        height: 28px;
        margin-top: 35px;
      }
    }
  }
}