@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

.tnl-table-container {
    margin: 0;

    > header,
    .tnl-table-container-header {
        margin-bottom: 10px;
    }

    table.tnl-table {

        .tnl-sort-col:hover {
            cursor: pointer;
            text-decoration: underline;
        }

        .table-padding() {
            padding: 10px 15px;
        }

        color: @COLOR-COS-DEFAULT;
        font-size: @COS-FONT-SIZE-12;
        margin: 0;
        width: 100%; // Prevents scrollbar from showing for table-responsive when scrolling is not necessary.

        > thead {
            th {
                background-color: @COLOR-PM-GREY7;
                vertical-align: middle;
                color: @COLOR-PM-WHITE1;
                font-size: @COS-FONT-SIZE-DEFAULT;
                text-transform: uppercase;
                .table-padding();
                .COS-OPEN-SANS-REGULAR();

                .tnl-menu-text,
                .icon-font {
                    color: @COLOR-PM-WHITE1;
                }
            }
            th.th-title {
                font-weight: normal;
                text-transform: uppercase;
                background-color: @COLOR-TNL-GRAY5;
            }

            .sorted {
                border-bottom-color: @COLOR-TNL-GRAY2;
                border-bottom-style: solid;
                .icon-font {
                     color: @COLOR-TNL-GRAY2;
                }
            }
            .form-group {
                margin: 0;
                padding: 0;
                label {
                    color: @COLOR-TNL-GRAY1;
                    font-size: @FONT-SIZE-XSM;
                    .COS-OPEN-SANS-600();
                }
            }
        }

        > tfoot {

            margin-bottom: 40px;
            font-weight: normal;
            color: @COLOR-TNL-GRAY3;

            td {
                color: @COLOR-TNL-GRAY3;
                font-size: @COS-FONT-SIZE-10;
                font-weight: normal;
                .table-padding();
            }
            .tnl-row-count {
                color: @COLOR-TNL-GRAY2;
                font-size: 14px;
            }
        }

        > caption {
            color: @COLOR-TNL-GRAY2;
            font-size: @COS-FONT-SIZE-10;
            line-height:  @COS-FONT-SIZE-10;
            text-align: left;
            margin: 10px 0 5px 5px;
        }

        .tnl-filter-dropdown {
            margin: 3px;
        }
        .tnl-filter-button-wrapper {
            margin-bottom: 10px;
            margin-left: 5px;
            margin-right: 5px;

            .tnl-menu-div {
                float: right;
            }
            .tnl-show-hide-filters-button {
                margin-top: 5px;
                float: left;
            }
        }

        > tbody {

            td {
                .table-padding();
                vertical-align: middle;
                font-size: @COS-FONT-SIZE-12;

                &:empty {
                    &:after {
                        color: @COLOR-TNL-GRAY4;
                        content: "\2014";
                    }
                }
            }
            div.readonly-text-area {
                padding: 0;
            }

            table,
            .tnl-table-container {
                margin: 0;
                padding: 0;
                width: 100%;
            }
        }

        .selected td {
            background: #ffffcc !important;
        }
        .has-error td {
            background: #ffffcc !important;
        }

        &.table-striped > tbody > tr:nth-child(odd) > td,
        &.table-striped > tbody > tr:nth-child(odd) > th {
            background-color: @COLOR-PM-WHITE1;
        }
        &.table-striped > tbody > tr:nth-child(even) > td,
        &.table-striped > tbody > tr:nth-child(even) > th {
            background-color: @COLOR-PM-GREY4;
        }

    }

    .pagination {
        margin: 0;
    }

    .tnl-footnotes {
        margin-top: 15px;
    }

}

#staffTableContainer span.pm-table-col-title {
    position: relative;
}

.tnl-table-header {
  .pm-grid-header-buttons {
    margin-left: 20px;

    a,
    .pm-btn-group {
      margin-right: 0;
    }
  }
}

.tnl-table th  {

    .tnl-icon-wrapper {
        height: 20px!important;
        width: 20px!important;
        display: inline-block!important;
        top: -5px !important;
        left: -67px !important;

        .fa {
            color: @COLOR-PM-WHITE1 !important;
            font-size: 18px;
            .PM-TRANSITION();

            &:hover {
                color: @COLOR-PM-GREEN1 !important;
                .PM-TRANSITION();
            }
        }
    }

    .ui-widget-content {
        border: none !important;
        background: none !important;
    }

    .pm-btn-group.pm-icon-dropdown .pm-action-btn .pm-action-btn-label .pm-action-btn-text, .pm-btn-group.pm-icon-dropdown .pm-action-btn:hover, .pm-btn-group.open .dropdown-toggle.pm-action-btn .pm-icon-font {
        color: #fff;
    }
}

.table-responsive {
    overflow-y: auto;

    .tnl-table {

        td {
            .pm-btn-lg {
                margin-top: 0 !important;
            }
        }
    }

}
@media screen and (max-width: 1600px) {
    .table-responsive {
        overflow-x: auto;
        width: 100%;
    }
}
@media screen and (max-width: 767px) {
    .table-responsive {
        width: 99%;
        border: none;
    }
}

.col-fixed-xs {
    width: 150px;
}

.col-fixed-sm {
    width: 250px;
}

.col-fixed-md {
    width: 350px;
}

.col-fixed-lg {
    width: 450px;
}

.grid-view {
    margin: 10px;
    margin-left: 0;

    .actions .dropdown-menu {
        left: auto;
        right: 0;
    }
}

tr.tnl-row-noresults > td {
    color: @COLOR-TNL-GRAY2;
    font-size: @COS-FONT-SIZE-12;
    text-align: left;
}

.popover h3.popover-title.pm-detail-header {
    color: @COLOR-PM-WHITE1;
    background-color: #36474f;
}
.cos-admin-drop-space {
    .cos-table-responsive {
        padding-bottom: 226px;
    }
}

.cos-floatThead-scroll-none {
    .floatThead-floatContainer {
        overflow: unset!important;
    }
}

//EDU-10556 space needed at the bottom of the table for dropdown not displayed
.cos-roster-attendance-grid .cos-double-scroll {
    padding-bottom: 46px;
}