@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

// the width attribute has been added to ensure our input groups are behaving as intended in bootstrap in all scenarios
.input-group {
    width: 100%;
}

.input-group-btn {
    border: none;

    .form-submit {
        &.pm-primary-btn {
            padding: 8px 12px;
            z-index: 1;
            .PM-TRANSITION();

            &:hover  {
                .PM-TRANSITION();
            }
        }
    }
}
