@import (reference) "_pm-global.less";

// SLIDEOUT PANEL STYLING //
// Author: Chris Brosky - August 2017 //

.pm-slideout-container {
  position: fixed;
  float: right;
  right: -480px;
  top: 0;
  width: 460px;
  height: 100%;
  background: @COLOR-PM-WHITE1;
  z-index: 500;
  display: block;

  &.contracted {
    right: -480px;
    .PM-TRANSITION(all,0.5s,ease-in-out);
  }

  &.expanded {
    right: 0;
    .PM-TRANSITION(all,0.5s,ease-in-out);
  }

  .pm-slideout-btn-wrapper {
    width: 42px;
    height: auto;
    position: relative;
    left: -62px;
    top: 175px;
    z-index: 505;

    .pm-slideout-btn {
      display: none;
      width: 42px;
      height: 40px;
      margin: 5px 0;
      border-radius: 4px 0 0 4px;
      box-shadow: -1px 2px 2px rgba(0,0,0,.2);
      opacity: 0.5;
      cursor: pointer;
      .PM-TRANSITION();

      &.active, &:hover {
        opacity: 1;
        .PM-TRANSITION();
      }

      &.pm-slideout-media-locker-btn {
        background-color: @COLOR-PM-FUSCHIA1;
        .PM-TRANSITION();
      }

      &.pm-slideout-obs-btn {
        background-color: @COLOR-PM-TEAL1;
        .PM-TRANSITION();
      }

      &.pm-slideout-reports-btn {
        background-color: @COLOR-PM-SLATE1;
        .PM-TRANSITION();

        .pm-icon-item-report {
          font-size: 28px;
          margin-left: -14px;
          top: 17px;
        }
      }

      .pm-so-icon {
        left: 50%;
        margin-left: -16px;
      }
    }
  }

  .pm-slideout-border {
      position: absolute;
      top: 0;
      left: -20px;
      width: 20px;
      height: 100%;
      background: #00baa2;
      box-shadow: -2px 0 3px rgba(0,0,0,.25);
  }

  .pm-icon-font,
  .pm-so-icon {
      display: inline-block;
      height: 32px;
      width: 32px;
      color: @COLOR-PM-WHITE1;
      font-size: 32px;
      position: relative;
      top: 50%;
      margin-top: -16px;
  }

  .pm-slideout-close {
      float: right;
      cursor: pointer;
  }

  .pm-slideout-content {
      position: absolute;
      top: 0;
      width: 100%;
      display: none;

     .pm-slideout-header {
       height: 50px;
       padding: 0 15px;
       background-color: #30383A;
       position: relative;

       h3 {
         display: inline-block;
         color: @COLOR-PM-WHITE1;
         font-size: 20px;
         padding: 0;
         margin: 0;
         height: 32px;
         line-height: 32px;
         position: absolute;
         top: 50%;
         margin-top: -16px;
       }
     }
  }

  .pm-slideout-media-locker-btn {
      display: none;
  }

  .pm-slideout-obs-btn {
      display: none;
  }

  .pm-so-content-body {
      overflow-y: auto;
      padding: 15px;

      .container:first-child {
        width: 100%;
        padding: 0;
      }
  }
}

.pm-slideout-body-closed {
  width: 100%;
}

.pm-slideout-body-open {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 75%;
    height: 100vh;
    overflow: auto;
    float: left;
    xtouch-action: none;
}


//toggle button

.pm-toggle-fixed {
    position: absolute;
    bottom: 0px;
    top: 0px;
    right: 15px;
    width: 44px;
    height: 19px;
}

.pm-slideout-toggler {
    position:fixed;
    bottom:15px;
    display: none;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.6);
    color: @COLOR-PM-WHITE1;
    border-radius:3px;
    cursor: pointer;

    .pm-toggle {
        position: fixed;
        width: 200px;
        bottom: 10px;
    }
}

.pm-slideout-control-closed {
  width: 0;
  display: none;
}

.pm-slideout-control-open {
    position: relative;
    width: 25%;
    float: left;
    background: @COLOR-PM-WHITE1;
    transition: 0.5s;

    .pm-scroll-bar {
        background: rgba(0, 186, 162, 1);
        height: 100vh;
        float: left;
        display: block;

        .pm-icon-ui-drag {
            margin-top: 45vh;
        }
    }

    .pm-slideout-top {
        height: 50px;
        width: 100%;
        background: #252d30;
        position: relative;
        border:thin solid;
        padding-right:10px;

       h3 {
         display: inline-block;
         color: @COLOR-PM-WHITE1;
         font-size: 20px;
         margin: 13px 0 0 20px;
       }

       .pm-icon-md {
            right: 10px;
            top: 8px;
       }
    }

    .pm-slideout-content {
         padding: 20px;
         height: calc(~"100vh - 125px");
         overflow: auto;
    }
}
.pm-slideout-panel-content {
    padding:20px;
}
.pm-slideout-toggler-east {
    background: none;

    &.pm-slideout-toggler {
        top: 0 !important;
        height: 100% !important;
    }

    &:before {
        font-family: 'pm-font-icons';
        font-size:28px;
        letter-spacing:0px;
        margin-left: -4px;
        content: "\e9c7";
        color: @COLOR-PM-WHITE1;
        display:block;
        position: absolute !important;
        top: 50% !important;
        margin-top: -14px !important;
    }
}

.pm-slideout-toggle-in,
.pm-slideout-toggle-out {
    position:fixed;
    bottom:15px;
    display: none;
    padding: 0 5px;
    background-color: rgba(0, 0, 0, 0.6);
    color: @COLOR-PM-WHITE1;
    border-radius:3px;
    cursor: pointer;
    float:left;

    &:before {
        font-family: 'pm-font-icons';
        font-size:28px;
        letter-spacing:0px;
        color: @COLOR-PM-WHITE1;
        display:block;
    }
}

.pm-slideout-toggle-out {

    &:before {
        content: "\e9ad";
    }
}
.pm-slideout-toggle-in {

    &:before {
        content: "\e9ae";
    }
}

.pm-slideout-toggler-south {

    &:before {
        font-family: 'pm-font-icons';
        font-size: 28px;
        line-height: 20px;
        content: "\e928";
        color: #666;
        display: block;
    }
}

.pm-slideout-bg-medialocker {
    background: #a200a2 !important;
}
.pm-slideout-bg-evidence {
    background: #00B5DF !important;
}
.pm-slideout-bg-reporting {
    background: @COLOR-PM-PURPLE1 !important;
}
.pm-slideout-bg-evaluation {
    background: @COLOR-PM-TEAL1 !important;
}
.pm-slideout-bg-observation {
    background: #116384 !important;
}
.pm-slideout-bg-book {
    background: @COLOR-PM-BLUE2 !important;
}
.pm-slideout-bg-discuss {
    background: @COLOR-PM-YELLOW2 !important;
}
.pm-slideout-bg-link {
    background: @COLOR-PM-PINK2 !important;
}
.pm-slideout-bg-calendar {
    background: #E59F19 !important;
}
.pm-slideout-bottom {
    height: 50px;
    width: 100%;
    background: #e7eaec;

    .pm-icon-md {
        color: @COLOR-PM-WHITE1;
        padding:20px 15px 30px;
        width: 42px;
        height: 40px;
        cursor: pointer;
        float: left;
    }

    .active{
        border-bottom: 4px solid @COLOR-PM-WHITE1;
        padding-bottom:26px;

        &:before{
            content: "";
            position:absolute;
            bottom:0;
            left:36%;
            z-index: 5;
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 5px 6px 5px;
            border-color: transparent transparent @COLOR-PM-WHITE1 transparent;
        }
    }
    .pm-slideout-bg-medialocker {
        background: #a200a2;
    }
    .pm-slideout-bg-reporting {
        background: @COLOR-PM-PURPLE1;
    }
    .pm-slideout-bg-observation {
        background: #116384 !important;
    }
    .pm-slideout-bg-observations {
      background: #FEA81E !important;
    }
    .pm-slideout-bg-book {
        background: @COLOR-PM-BLUE2;
    }
    .pm-slideout-bg-discuss {
        background: @COLOR-PM-YELLOW2;
    }
    .pm-slideout-bg-link {
        background: @COLOR-PM-PINK2;
    }
    .pm-slideout-bg-calendar {
        background: #E59F19;
    }

    .pm-so-icon {
        display: inline-block;
        height: 32px;
        width: 32px;
        color: @COLOR-PM-WHITE1;
        font-size: 32px;
        position: relative;
        top: 50%;
        margin-top: -16px;
        margin-left: -11px;
    }

    .pm-icon-item-observation,
    .pm-icon-item-report {
        margin-left: -8px;
    }
}


//UI Layout
.ui-layout-pane {
    background: @COLOR-PM-WHITE1;
    overflow: auto;
}

.ui-layout-pane .pm-portal-header,
.ui-layout-center .pm-portal-header {
    padding-left: 0;
    padding-right: 0;
    position: relative;
}

.ui-layout-content {
    padding: 10px;
    position: relative;
    overflow: auto;
}

.layout-child-container, .layout-content-container {
    padding: 0;
    overflow: hidden;
}

.layout-child-container {
    border: 0;
}

.layout-scroll {
    overflow: auto;
}

.layout-hide {
    display: none;
}

.ui-layout-resizer {
    background: #DDD;
}


.ui-layout-resizer-drag {
}

.ui-layout-resizer-hover {
}

.ui-layout-resizer-open-hover,
.ui-layout-resizer-dragging {
    background: #C4E1A4;
}

.ui-layout-resizer-dragging {
    border: 1px solid #BBB;
}

.ui-layout-resizer-north-dragging,
.ui-layout-resizer-south-dragging {
    border-width: 1px 0;
}

.ui-layout-resizer-west-dragging, .ui-layout-resizer-east-dragging {
    border-width: 0 1px;
}

.ui-layout-resizer-dragging-limit {
    background: #E1A4A4;
}

.ui-layout-resizer-closed-hover {
    background: #EBD5AA;
}

.ui-layout-resizer-sliding {
    opacity: .1;
    filter: alpha(opacity=10);
}

.ui-layout-resizer-sliding-hover {
    opacity: 1;
    filter: alpha(opacity=100);
}

.ui-layout-resizer-north-sliding-hover {
    border-bottom-width: 1px;
}

.ui-layout-resizer-south-sliding-hover {
    border-top-width: 1px;
}

.ui-layout-resizer-west-sliding-hover {
    border-right-width: 1px;
}

.ui-layout-resizer-east-sliding-hover {
    border-left-width: 1px;
}

.ui-layout-toggler {
    border: 1px solid #BBB;
    background-color: #BBB;
}

.ui-layout-resizer-hover .ui-layout-toggler {
    opacity: .6;
    filter: alpha(opacity=60);
}

.ui-layout-toggler-hover,
.ui-layout-resizer-hover .ui-layout-toggler-hover {
    background-color: #EDEDED;
    opacity: 1;
    filter: alpha(opacity=100);
}

.ui-layout-toggler .content {
    color: #666;
    font-size: 12px;
    font-weight: 700;
    width: 100%;
    padding-bottom: .35ex;
}

.ui-layout-mask-inside-pane {
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
}

.ui-layout-mask {
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden !important;
    position: absolute !important;
    opacity: 0 !important;
    filter: alpha(opacity=0) !important;
}

div.cos-draggable-text {
  padding-left:21px;
  position: relative;
}
div.cos-draggable-text:before {
  content: "\e9c8";
  font-family: 'pm-font-icons' !important;
  position: absolute;
  top:8px;
  left:3px;
}
div.cos-draggable-text:hover {
  background: #ffd;
}

.cos-eval-title {
  background: #efefef;
  margin: -20px -20px 0 -20px;
  padding: 20px;
}
.cos-eval-panel {
  padding-top: 13px;
}
.cos-eval-panel-inside {
  border: thin solid #bbb;
  border-radius: 5px;
  padding:10px;
}