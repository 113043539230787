@import (reference) "../Theme/Common/_common.less";

.tnl-attachments-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
        padding: 10px 0;
        border-bottom: 1px dashed @COLOR-TNL-GRAY4;

        &:last-child {
            border: none;
        }
    }

    .tnl-delete-icon-margin-left {
        margin-left: 10px;
    }
}