@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

// Label
.label {
    border-collapse: separate;
    display: inline-block;
    font-size: 90%;
    font-weight: normal;
    padding: 4px 8px;
    border-radius: 8px;

    .tnl-stat-block.stat-block-sm {
        padding: 10px 10px;
        margin-top: 15px;

        span {
            color: #fff;
        }
    }

    a:hover {
        color: #fff;
    }

    .icon-font {
        color: #fff;
    }
    &.label-default {
        background: @COLOR-PM-GRAY;
    }
    &.label-primary {
        background: @COLOR-TNL-BLUE2;
    }
    &.label-success {
        background: @COLOR-PM-GREEN;
    }
    &.label-info {
        background: @COLOR-TNL-BLUE3;
    }
    &.label-warning {
        background: @COLOR-PM-ORANGE1;
    }
    &.label-danger {
        background: @COLOR-DANGER;
    }
    &.label-tnl-custom {
        background: @COLOR-TNL-GRAY6;
        border: 1px solid @COLOR-TNL-GRAY4;
        color: @COLOR-TNL-GRAY2;

        .icon-font {
            color: @COLOR-TNL-GRAY2;
        }
    }

    &.tnl-status {
        display: inline-block;
        position: relative;
        margin: 0;
        text-decoration: none;

        > span {
            display: inline-block;
            vertical-align: middle;

            &:not(:last-child) {
                padding-right: 2px;
            }
        }
        &.tnl-status-complete {
            background: @COLOR-PM-GREEN6;
            border: 1px solid @COLOR-PM-GREEN3;
            color: @COLOR-PM-GREEN3;

            .cos-obs-side-drop {
                border-top: 1px solid @COLOR-PM-GREEN3;
            }

            .icon-font {
                color: @COLOR-PM-GREEN3;
            }
            &:hover {
                background-color: @COLOR-LINK-HOVER;
                border-color: @COLOR-LINK-HOVER;
                color: #fff;

                .cos-obs-side-drop {
                    border-top: thin solid @COLOR-LINK-HOVER;
                }

                .icon-font {
                    color: #fff;
                }
            }
        }
        &.tnl-status-in-progress, &.tnl-status-complete, &.tnl-status-not-started{
            label {
                white-space: initial;
                display: inline;
            }

            .cos-obs-side-buttonwrap {
                text-align: center;
                .pm-btn-lg {
                    margin: 0;
                }
            }

            &.cos-obs-slide-status {
                text-align: left;
                position: relative;
                margin-bottom: 5px;

                span {
                    font-size: 15px;
                    font-weight: 500;
                }

                .fa-check-square, .fa-rotate-90 {
                    font-size: 20px;
                }
                .cos-collapser {
                    .pm-icon-md {
                        position:absolute;
                        right:6px;
                        top:0;
                    }

                }
                .cos-obs-stats {
                    color: @COLOR-COS-DEFAULT;
                    background: @COLOR-PM-GREY3;
                    font-weight: 300;
                    margin: 2px 0 2px -5px;
                    padding: 7px;
                    border-radius: 0 15px 15px 0;
                }
                .pm-icon-form-dropdown {

                    width: 36px;
                    background: white;
                    color: @COLOR-PM-BLUE;
                    height: 40px;
                    font-size: 23px;
                    padding: 7px 0px 0px 4px;
                    cursor: pointer;
                }
            }
            .cos-obs-side-drop {
                margin: 10px -10px -10px -10px;
                padding: 5px;
                background: #fff;
                color: @COLOR-COS-DEFAULT;
                &:hover {
                    color: @COLOR-COS-DEFAULT;
                }

                .cos-obs-side-standard {
                    border-top: 2px solid;
                    border-bottom: 2px solid;
                    margin: 8px -5px;
                    position: relative;
                    padding: 10px 5px;
                    padding-right: 28px;

                    .pm-icon-ui-expand-chevron {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 30px;
                        background: white;
                        color: @COLOR-PM-BLUE;
                        height: 26px;
                        font-size: 23px;
                        padding: 0 0 0 3px;
                        cursor: pointer;
                    }

                    span {
                        white-space: normal;
                        font-weight: 500;
                        padding-bottom: 10px;
                        display: inline-block;
                    }
                }

            }
            .cos-obs-side-details {
                font-size: 15px;
                line-height: 20px;
                padding-bottom: 5px;
                white-space: initial;
            }
            .cos-obs-stats {
                font-weight: 600;
                font-size: 15px;
            }
        }
        &.tnl-status-in-progress {
            background: @COLOR-PM-BLUE7;
            border: 1px solid @COLOR-PM-BLUE3;
            color: @COLOR-PM-BLUE3;

            .cos-obs-side-drop {
                border-top: 1px solid @COLOR-PM-BLUE3;
            }

            .icon-font {
                color: @COLOR-PM-BLUE3;
            }
            &:hover {
                background-color: @COLOR-LINK-HOVER;
                border-color: @COLOR-LINK-HOVER;
                color: #fff;

                .cos-obs-side-drop {
                    border-top: thin solid @COLOR-LINK-HOVER;
                }

                .icon-font {
                    color: #fff;
                }
            }
        }
        &.tnl-status-not-started {
            background: @COLOR-TNL-GRAY5;
            border: 1px solid #67696b;
            color: @COLOR-TNL-GRAY2;
            padding: 12px 10px 13px 10px;

            .cos-obs-side-drop {
                border-top: 1px solid #67696b;
                margin: 13px -10px -10px -10px;

            }

            .icon-font {
                color: @COLOR-TNL-GRAY2;
            }
            &:hover {
                background-color: @COLOR-LINK-HOVER;
                border-color: @COLOR-LINK-HOVER;
                color: #fff;

                .cos-obs-side-drop {
                    border-top: thin solid @COLOR-LINK-HOVER;
                }

                .icon-font {
                    color: #fff;
                }
            }
        }
        .tnl-pane-minor-header {
            margin-top: 23px;
        }
        .alert {
            padding: 9px;
            p {
                white-space: normal;
                line-height: 1.8rem;
            }
        }
    }

}

.label-default,
.label-default a,
.label-warning,
.label-warning a,
.label-success,
.label-success a {
    color: #fff !important; // Overide jQuery Ui
}

.tnl-label-group {
    .label {
        display: inline-block;
        margin: 0 4px 4px 0;
    }

    &.tnl-demographics {

        &.compressed {
            max-height: 150px;
            overflow: auto;
        }
        .label-demographic,
        .label-demographic a {
            color: @COLOR-TNL-GRAY1 !important; // Overide jQuery Ui
            background-color: #fff;
            .LESS-ROUNDED-CORNERS();
            border: 1px solid @COLOR-TNL-GRAY4;
        }
        .label {

            input {
                position: relative;
                top: 2px;
                margin: 0;
                padding: 0;
                line-height: 1;
            }
        }
    }
}
