@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

.tnl-teaser {
    .tnl-teaser-container {
        .generic {
            ul, ol {
                margin: 10px 10px 10px 17px;

                li {
                    list-style-type: initial;
                    list-style-position: inside;
                    text-indent: -1.4em;
                    margin-left: 21px;
                }
            }
        }
    }

    // Do not pad or left/right-margin tnl-teaser. For Masonry gutter width, use Masonry options.
    margin: 0 15px 30px 12px;
    background: #fff;
    border: 1px solid @COLOR-TNL-GRAY4;
    //-moz-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.05);
    //-webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.05);
    //box-shadow: 0 10px 60px rgba(0, 0, 0, 0.05);

    > header {
        h1, h2, h3 {
            margin: 0;
            padding: 0;
        }
        h1 {
            margin-bottom: 10px;
        }
    }
    // TODO: Reconsider defining all this H stuff here. It's conflicting with teaser content.
    // Consider putting class names on teaser headers.
    .tnl-teaser h3.popover-title {
        padding: 8px 14px;
    }
    &.tnl-chan-1-wide {
        width: 32%;
    }
    &.tnl-chan-2-wide {
        width: 48%;
    }
    &.tnl-chan-3-wide {
        width: 99%;
    }

    &.tnl-teaser-max-height-sm {
        max-height: 300px;
        overflow-y: scroll;
    }
    &.tnl-teaser-max-height-md {
        max-height: 450px;
        overflow-y: scroll;
    }

    .tnl-teaser-container {
        padding:20px;

        > header, * header {
            margin-bottom: 20px; 

            p {
                margin: 0;
                padding: 0;
            }
        }

        .pm-video-wrapper {
            position: relative;
            padding-bottom: 56.25%; /* 16:9 */
            padding-top: 0;
            height: 0;

            .pm-iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    &.pm-teaser {
        border-top: none;
        box-shadow: 0 -5px 0 0 @COLOR-PS-BLUE4;
        border-color: @COLOR-PM-GREY8;

        > header {
            padding: 20px; 
            border-bottom: 1px solid @COLOR-PS-GREY1;
            background-color: @COLOR-PS-GREY2;
        }

        .tnl-item-list .tnlif.fa {
            margin-right: 5px;
        }

        .cos-inline-icon-text {
            height: 38px;
            display: table-cell;
            vertical-align: middle;
        }

        .cos-teaser-row {
            .pm-icon-md {
                top: 2px;
            }

            .pm-btn-group.pm-icon-action-btns {
                top: 4px;
            }

            .cos-inline-icon-text {
                height: 38px;
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
    
    // Alert in My Courses Teaser //
    
    .alert {
        margin-bottom: 51px;
    }

    //MyCoursesTeaser//
    .pm-teaser-sub {
        background-color: #f1f5f8;
        margin: 0 -20px;
        border-top: #e7e9ed 2px solid;
        padding: 10px 0 0 10px;
        margin-bottom: 0!important;

        .pm-tease-icon-space{
            padding-left: 15px;
        }

        h2 {
            padding-top: 10px;
            .COS-OPEN-SANS-REGULAR() !important;
        }
    }

    .tnl-item-list {

        >li{

            .pm-hide-icon {
                display:inline-block;

                .fa {
                    left:0;
                    top:4px;
                    width: 36px;
                    font-size: 18px;
                }
            }

            .pm-resize-teaser-icon {
                .pm-icon-lg {

                    &.pm-icon-solid {
                        height: 40px;
                        width: 40px;

                        .pm-icon-font {
                            margin: -17px 0 0 -13px;

                            &:before {
                                font-size: 25px;
                            }
                        }
                    }
                }
            }

            .pm-tease-icon-sm {
                .pm-icon-font{

                    &:before {
                        font-size: 23px;
                    }
                }
            }
        }

        .space-left {

            &:first-child {
                padding-top: 15px;
            }
            > div {
                left:0;
                margin-right:0;
            }
        }
    }

    @media (max-width: @BOOTSTRAP-LG){
        &.tnl-chan-1-wide {
            width: 48%;
        }
        &.tnl-chan-2-wide {
            width: 99%;
        }
    }
    @media (max-width: @BOOTSTRAP-SM) {
        border-left-color: transparent;
        border-right-color: transparent;
        border-top-color: transparent;
        border-bottom-color: @COLOR-TNL-GRAY4;
        .LESS-BOX-SHADOW-RESET();

        .tnl-teaser-container {
            padding-bottom: 40px;
            padding-left: 0;
            padding-right: 0;
        }

        &.tnl-chan-1-wide {
            width: 99%;
        }
    }
}

.pm-masonry-item-wrap {
    position: relative; 
    top: 0; 
    left: 0; 
    height: 100%; 
    width: 100%; 
    padding: 30px 0 20px 0; 

    .pm-masonry-items {
        position: relative; 
        top: 0; 
        left: 20px; 
        height: 100%; 
        width: 94.8%; 
        display: block;

        .pm-build-layout {
            padding: 0;
        }
    }
}

 @media (max-width: @BOOTSTRAP-SM) {
    .pm-masonry-item-wrap {
        .pm-masonry-items {
            left: 10px;
        }
    }
 }

//TODO: Refactor out tnl-teaser-single.
// tnl-page-header is the correct page header.
.tnl-teaser-single {
    > header {
        padding: 0;

        h1 {
            display: inline-block;
            margin: 0;
            padding: 14px 35px;
        }

        h2, h3, h4, h5, h6 {
            display: inline-block;
            margin: 0 0 20px 0;
        }

        p {
            font-size: @COS-FONT-SIZE-12;
            margin: 0 0 20px 0;
    
            &:last-child {
                margin: 0;
            }
        }
    }
}

/*TODO: Refactor out the following*/
.tnl-chan-head1 {
    overflow: hidden;
    margin: 0 0 20px;
}

.tnl-chan-head-l {
    display: none; 
    float: left;
    margin: 0 10px 0 0;
    width: 20px;
    height: 22px;
}

.tnl-chan-head-text {
    float: left;
    color: @COLOR-COS-DEFAULT;
    font-size: @COS-FONT-SIZE-30;
    font-weight: normal;
}

.tnl-chan-head-icons {
    float: right;
}

.tnl-chan-body-c1 {
    clear: both;
}

.tnl-chan-body-c1:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}
