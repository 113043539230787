@import (reference) "../Theme/Common/_common.less";
@import (reference) "../Css/_pm-global.less";

// DOM prior to editing.
.editable-click, 
a.editable-click {
    background-color: efefef;
    border: 1px solid #ccc;
    color: inherit;
    padding: 3px;
    .PM-ROUNDED-CORNERS();

    &:hover {
        background-color: #fff;
        border: 1px solid #00427C;
        cursor: pointer;
        .PM-ROUNDED-CORNERS();
    }
}

// DOM during editing.
.editable-container {
    
    .form-inline .form-group,
    .form-group {
        margin: 0;
    }
}
