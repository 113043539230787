/*

    Keep adjustments to a minimum.
    Ideally this file should only adjust global elements.
    Adjusments to specific modules should be handled in the module's print file.
    The goal is for the pages to print as they appear in the web browser.
    Avoid fully restyling printed pages.
    Keep in mind that usually the print results will follow Bootstrap's col-xs settings.

*/
@import (reference) "../Theme/Common/_common.less";

@media print 
{
    * {
        text-shadow: none !important;
        -moz-box-shadow: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
    body {
        margin: 0;
        border: none;
    }
    b,
    strong {
        font-weight: bold !important;
    }
    a,
    a:active,
    a:visited {
        text-decoration: underline !important;
    }
    pre,
    blockquote {
        page-break-inside: avoid !important;
    }

    // Forms
    select {
        background: #fff !important;
    }
    textarea,
    input {
        background: #fff !important;
        page-break-inside: avoid !important;
    }
    .infield {
        width: auto !important;
    }
    .intitle {
		background: #fff !important;
        width: auto !important;
    }
    .infield,
	.intitle,
	.intitle-left {
		padding: 1px 10px !important;
	}
    
    // Tables
    /* 
        Remember that large parts of our legacy site's layout is still controlled by tables.
        .tnl-table {} controls the expectations for non-layout tables.
    */
    .table {
        border-collapse: collapse !important;
    }
    thead {
        display: table-header-group !important;
    }
    table, td, th {
        font-size: 11pt !important;
    }
    .tnl-table-container {
        padding: 0 0 1em 0 !important;
    }
    .tnl-table-header {
        padding: 0 !important;
    }
    .tnl-table {
        border: none !important;

        > thead > tr,
        > tbody > tr {
            page-break-inside: avoid !important;
        }
        > thead > tr > th,
        > thead > tr > td,
        > tbody > tr > td,
        > tr > th,
        > tr > td  {
            background-color: #fff !important;
            border: none !important;
            font-size: 9pt !important;
            font-weight: normal !important;
            padding: 5px !important;
            white-space: normal !important;

            a, body, div, h1, h2, h3, h4, h5, h6, p, span, table, td, th,
            .infield, .intitle, .intitle-left {
                font-size: 9pt !important;
                font-weight: normal !important;
            }
        }
        // Supports improperly constructed .tnl-table
        > thead > tr > th,
        > tbody > tr > th {
            border-bottom: 1px solid #000 !important;
            font-size: 7pt !important;
        }
    }
    .tnl-grid-num-results {
        font-size: 8pt !important;
    }

    // Page
    .tnl-structure {
        left: 0;
    }
    .tnl-content {
        margin: 0 !important;
        padding: 0 !important;

        .container-fluid {
            margin: 0 !important;
            padding: 0 !important;
        }
    }
    .tnl-header {
        height: auto !important;

        .title {
            display: block !important;
            padding: 0 !important;
        }
        .tnl-popup-menu {
            display: none !important;
        }
    }

    // Images
    img {
        max-width: 100% !important;
        page-break-inside: avoid !important;
    }

    // Teasers
    .tnl-teaser {
        display: block !important;
        margin-bottom: 1em !important;
        position: static !important;
        width: auto !important;

        .tnl-teaser-container {
            > header {
                margin: 0 !important;
                padding: 0 !important;
            }
        }
    }

    /*
        We have some screens that have issues with rte widths, override to auto for print.
        This may not work well across the product.
    */
	.tnl-rte-content,
    .tnl-rte-display,
    .tnl-rich-text-read-only {
		height: auto !important;
		max-height: none !important;
		overflow: visible !important;
		padding: 0 !important;
		width: auto !important;
	}

    //
    .readonly-text-area {
        height: auto !important;
        max-height: none !important;
        overflow: visible !important;
        padding: 0 !important;
    }
    .readonly-text {
        max-height: none !important;
        overflow: visible !important;
    }  
    .tnl-print-only,
    .print-only {
        display: inherit !important;
    }
    .tnl-print-overflow
    .print-overflow {
        height: auto !important;
        max-height: none !important;
        overflow: visible !important;
    }
    .fa {
        font-family: FontAwesome !important;
    }
    .im {
        font-family: icon-moon !important;
    }
	.app-body {
		padding: 0 !important;
	}

    // Empari only adjustments
    .empari {
        #selectedIndicatorTable td {
            display:block !important;
           

            textarea {
                border: none;
                display: block;
                width: 100%;
            }
        }
        
        .tnl-transcript-search {
            display: none !important;
        }
        .tnl-obs {
            height: auto !important;
        }
        .tnl-observation-total {
            border: 1px solid @COLOR-TNL-GRAY4 !important;
            color: #000 !important;
        }
        .tnl-obv-box {
		    border: none !important;
		    height: auto !important;
		    max-height: none !important;
		    padding: 0 !important;
		    overflow: visible !important;
	    }
        .tnl-popup-menu-action {
            display: none !important;
        }
        .tnl-content-box {
            background-image: none !important;
            border-bottom: 1px solid #000 !important;
            margin: 10px 0 !important;

            .start {
                display: none !important;
            }
            .item {
                padding: 2px 0 !important;
            }
        }
        .tnl-pbia {
		    page-break-inside: avoid !important;
	    }
	    .left-navigation {
	       display: none !important;
	    }
        .tnl-grid-th-Menu {
            display: none !important;
        }
        .layout-box,
        .display-panel {
            padding: 0 !important;
        }
        .layout-box {
            page-break-before: avoid !important;
            page-break-inside: avoid !important;
        }
	    .input-title-bar {
		    background: #fff !important;
		    background-image: none;
		    border-style: solid !important;
		    border-width: 1px 1px !important;
	    }
    }
    
    // Hide from print.
    .tnl-search-bar,
    .tnl-main-site-header,
    .tnl-site-toolbar,
    .save-page-bar,
    .tnl-table-search,
    .tnl-table-show,
    .tnl-toolbar,
    .tnl-page-footer,
	.tnl-rte-edit,
    .tnl-no-print,
    .noprint,
    .btn,
    .boot-btn,
    .save-bar,
    .popup-cancel-button,
    .menu_expandable,
    .tnl-btn-icon,
    .tnl-btn-icon-text,
	.tnl-btn-text,
	.tnl-pdf-link,
    .tnl-site-head,
    .profiler-results,
    .tnl-actionbar-flex > .container-fluid > section:not(.tnl-page-container),
    #footer,
	#tabrow,
	#tnl-welcome-bar {
		display: none !important;
	}

    // Remove content appeneded to links by Bootstrap.css
    a[href]:after {
        content: "" !important;
    }
    abbr[title]:after {
        content: "" !important;
    }
    a[href^="javascript:"]:after,
    a[href^="#"]:after {
        content: "" !important;
    }

}

.tnl-print-only,
.print-only {
    display: none;
}
.cos-print-preview {
    margin-left: 10px;
    width: 377px;
    overflow-x: auto;
}

@media (min-width: 768px) and (max-width: 991px) {
    .cos-print-preview {
        width: 261px;
    }
}

@media (min-width: 992px) {
    .cos-print-preview {
        width: 555px;
    }
}