@import (reference) "../Theme/Common/_common.less";

.tnl-demographic-list{
    margin-top:10px;
}

.tnl-demographic-list .tnl-demographic-group {
    margin: 0 0 20px 0;
    padding: 10px;
    border: 2px solid @COLOR-TNL-GRAY4;
}

.tnl-demographic-group .tnl-demographic-group {
    margin: 0;
    border-width: 1px;
}

.tnl-demographic-list .tnl-demographic-group-title {
    margin: 0 0 10px 0;
    padding-bottom: 6px;
    font-weight: bold;
}

.tnl-demographic-list > li {
    margin: 0 0 5px 0;
}

.tnl-demographic-list .tnl-demographic-list > li {
    margin-left: 40px;
}

div.tnl-demographic-groups {
    float: left;
    clear: left;
}

div.tnl-demographic-groups > ol {
    margin-left: 20px;
}

div.tnl-demographic-groups > ol > li {
    list-style-type: decimal;
}
