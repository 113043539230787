@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

.tnl-programs-outline {
    .collapse.in {
        margin-left: -10px;
    }
    .collapse.in.no-left-margin {
        margin-left: 0;
    }
    .tnl-programs-outline-group {
        position: relative;
    }
    .tnl-programs-outline-header {
        font-size: @COS-FONT-SIZE-10;
        .COS-OPEN-SANS-600();
        padding: 10px 15px;
    }
    .tnl-programs-outline-group-header {
        font-size: @COS-FONT-SIZE-12;
        padding: 10px 0;

        .tnl-programs-outline-group-title {
            font-size: @COS-FONT-SIZE-16;
            .COS-OPEN-SANS-600();
        }
        .tnl-programs-outline-group-icon {
                font-size: @COS-FONT-SIZE-22;
            vertical-align: middle;
        }
        .tnl-group-icon, .tnl-group-name {
            padding-left: 0;
        }
    }

    .tnl-programs-outline-task {
        font-size: @COS-FONT-SIZE-12;
        padding: 10px 0;

        .tnl-programs-outline-task-link {
            font-size: @COS-FONT-SIZE-DEFAULT;
            vertical-align: middle;
        }
        .tnl-programs-outline-task-icon {
            font-size: @COS-FONT-SIZE-18;
            margin-right: 10px; 
            vertical-align: middle;
        }
        .tnl-programs-outline-task-action {
            padding-bottom: 0;
            padding-top: 0;
        }
    }

    .selected {
        background: @COLOR-PM-TURQUOISE-LIGHT;
    }

    .tnl-task-row > div,
    .tnl-group-row > div {
        border-bottom: 1px solid @COLOR-PM-GREY11;
        height: 40px;
    }
    
    .no-border {
        border: none !important;
    }
    .tnl-tree-column {
        position: relative;
    }
    .tnl-tree-vline {
        background: @COLOR-PM-GREY11;
        position: absolute;
        width: 1px;
        margin-left: -10px;
    }
    .tnl-tree-hline {
        background: @COLOR-PM-GREY11;
        height: 1px;
        position: absolute;
    }

     .program-task-required, 
     a.program-task-required {
        color: @COLOR-PM-BLUE;
    }
    
    .program-task-optional,
    a.program-task-optional {
        color: @COLOR-PM-PURPLE;
    }

    .program-task-complete, 
    a.program-task-complete {
        color: @COLOR-PM-GREEN;
    }

    .program-task-requirements-met, 
    a.program-task-requirements-met {
        color:@COLOR-PM-TURQUOISE; 
    }

    .program-task-not-open, a.program-task-not-open {
        color: @COLOR-PM-GREY11;
    }

    .program-task-other, a.program-task-other {
        font-style: italic;
    }

    .tnl-task-link:hover {
       color: @COLOR-PM-YELLOW !important;
       text-decoration:underline;
    }

    .task-icon { 
        font-size: 20px; 
        margin-right: 6px; 
        vertical-align: middle;
    }

    .status-icon { 
        font-size: 20px; 
    }
}

.tnl-program-dashboard {
    .tnl-program-tasks {
        .tnl-program-task {
            border-bottom: 1px solid @COLOR-PM-GREY11;
            margin-bottom: 20px;
        
            .tnl-program-status.label {
                display: block;
                padding: 10px;
                text-align: left;

                .LESS-ROUNDED-CORNERS-RESET();
            }
            .tnl-program-task-instance {
                margin-bottom: 5px;
            }
            .tnl-program-task-icon {
                font-size: @COS-FONT-SIZE-30;
            }
            .tnl-program-task-name {
                font-size: @COS-FONT-SIZE-24;
            }
            .tnl-program-task-date {

            }
        }
        .tnl-program-group {
            
        }
        .tnl-program-tasks {
            margin-bottom: 20px;
            padding: 10px;

            &.complete {
                border: 2px solid @COLOR-PM-GREEN-LIGHT;
            }
            &.in-progress {
                border: 2px solid @COLOR-TNL-GRAY2;
            }
        }
    }
}

.tnl-task-list-tools {
    .tnl-toolbar {
        margin: 30px 0;

        .checkbox-inline,
        .tnl-task-tool-left {
        margin-bottom:12px;
        margin-right: 20px;
        }
    }
} 

.tnl-task-list {
    .tnl-tasks-group-title {
        font-size: @COS-FONT-SIZE-22;

    }
    .tnl-task-block-doubleline {
        margin-top: -12px;
    }
    .tnl-programs-task {
        display:inline-block; 
        margin-bottom: auto; 
        margin-top:auto;
        vertical-align:middle; 

    }
    .tnl-programs-task-link {
        font-size: @COS-FONT-SIZE-18;

    }
    .tnl-programs-taskdetails {
        color: @COLOR-PM-GREY11;
        font-size: @COS-FONT-SIZE-DEFAULT;
        display:block;
    }

    .tnl-programs-groupdetails {
        color: @COLOR-PM-GRAY;
        font-size: @COS-FONT-SIZE-DEFAULT;
        display:block;
    }

    .tnl-programs-group-icon {
            font-size: @COS-FONT-SIZE-24;
            vertical-align: middle;
    }

}

.tnl-xxlarge-icon .icon-font {
    font-size: 60pt;
} 

.tnl-xlarge-icon .icon-font {
    font-size: 40pt;
} 

.tnl-instance-row {
    border-top: 1px solid @COLOR-PM-GREY11;
    height: 40px;
}
//Left Tree Nav

    .tnl-leftnavpanel {
        padding: 0;
        margin: 0;
        background: #ddd;
        display:none;


        .tnl-task-search {
            padding:12px;
         }

        .tnl-task-filtering {
            padding:12px 12px 0 0;
        }

        .tnl-others-people-list-filter {
            .nav > li > a {
                padding: 10px 7px;
            }
        }

        .nav-pills {
            margin-top:-22px;
            padding-left:12px;

            & > li {
                padding: 0 5px 0 0;
            }

            & > li.active.program-task-all > a, 
            & > li.active.program-task-all > a:focus, 
            & > li.program-task-all > a:hover, 
            & > li.program-task-all > a:hover > span {
                background: @COLOR-PM-GREY11;
                color:#fff;
            }

            & > li.active.program-task-pending > a, 
            & >li.active.program-task-pending > a:focus, 
            & >li.program-task-pending >a:hover,
            & >li.program-task-pending >a:hover > span {
                background: @COLOR-PM-BLUE; 
                color:#fff;
            }

            & > li.active.program-task-optional > a, 
            & > li.active.program-task-optional > a:focus, 
            & > li.program-task-optional >a:hover,
            & > li.program-task-optional >a:hover > span {
                background: @COLOR-PM-PURPLE;
                color:#fff;
            }

            & > li.active.program-task-complete > a, 
            & > li.active.program-task-complete > a:focus, 
            & > li.program-task-complete > a:hover,
            & > li.program-task-complete > a:hover > span {
                background: @COLOR-PM-GREEN;
                color:#fff;
            }
            
            & > li.active.program-task-notstarted > a, 
            & > li.active.program-task-notstarted > a:focus, 
            & > li.program-task-notstarted > a:hover,
            & > li.program-task-notstarted > a:hover > span {
                background: @COLOR-PM-GRAY-LIGHT;
                color:#fff;
            }

            & > li.active.program-task-inprogress > a, 
            & > li.active.program-task-inprogress > a:focus, 
            & > li.program-task-inprogress > a:hover,
            & > li.program-task-inprogress > a:hover > span {
                background: @COLOR-PM-TURQUOISE-LIGHT;
                color:#fff;
            }
        } 

        .tnl-tasktree {
            background: #fafafa;
            height: 400px;
            overflow-y: scroll;
            overflow-x: hidden;
            padding: 10px;
            div.row:first-child > div:nth-child(2) {
                text-align: center;
            }
        }      
    }

    .tnl-page-container.tnl-page-with-navpanel {
        padding-left:15px;
    } 

//Task Icon in side bar


.tnl-site-toolbar .tnl-site-toolbar-items>li.nav_task_icon {
        position: relative;
        height:60px;
        width:60px;
        background: transparent;
        padding-top:10px;
        overflow:visible;

        &.active {
                background: @COLOR-PM-GREEN;

            a .icon-font {
                color: #fff
            }

            &:after {
            content:"";
            position: relative;
            top: -28px;
            float:right;
            margin-right:-20px;
            border-top: 10px solid transparent;
            border-left: 10px solid @COLOR-PM-GREEN;
            border-right: 10px solid transparent;
            border-bottom: 10px solid transparent;
            }
        }

}

.tnl-task-minmax {

    input[type="number"] {
        width: 65px;
    }

    .form-control {
        display: inline;
    }

    label {
        font-weight: 400;
    }
}
