@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

.modal {

    .modal-content {
        border-radius: 10px 10px 6px 6px;
        background-clip: padding-box;
        border: none;
    }

    .modal-header {
        height: 50px;
        background-color: @COLOR-PM-GREY7;
        padding: 15px 25px;
        overflow: hidden;
        border-radius: 6px 6px 0 0;

        h3 {
            background-color: transparent;
            border: none;
            width: 95%;
            margin: 0;
            text-transform: capitalize;
            color: @COLOR-PM-WHITE1;
            line-height: 1.4rem;
        }

        button {
            &.close {
                opacity: 1;
                text-shadow: none !important;
                color: @COLOR-PM-WHITE1;

                span {
                    color: @COLOR-PM-WHITE1;
                    .PM-TRANSITION();
                    font-size: 24px;
                    height: 24px;
                    width: 24px;
                    position: relative;
                    top: 0;
                    left: 0;
                }

                &:hover {
                    span {
                        color: @COLOR-PM-GREEN1;
                        .PM-TRANSITION();
                    }
                }

                &:active, &:focus {
                    outline: none !important;
                }
            }
        }
    }

    .modal-body {
        padding: 25px 25px 10px 25px;

        .boot-btn {
            border-radius: 3px 0 0 3px;
            padding: 7px 12px;

            &.dropdown-toggle {
                margin-left: -3px;
            }

            &.btn-sm {
                line-height: 1.5px;
            }

            span:nth-child(2) {
                padding-left: 5px;
                &.sr-only {
                    display: none;
                }
            }
        }

        .pm-form-row {
            margin-right: 0;
            margin-left: 0;

            .pm-form-label {
                padding-left: 0;
            }
        }

        > header {
            margin: 0 0 1em 0;

            h1, h2, h3, h4, h5, h6 {
                margin-left: 0;
                padding-left: 0;
            }
            p {
                margin-left: 0;
                padding-left: 0;
            }
        }

        input[type="text"] {
            border-radius: 4px;
            min-height: 30px;
            margin-left: 5px;
            background-color: #fff;
            border: 1px solid #ccc;
            -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
            -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
            -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
            transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
            width: 90%;
        }

        input[type="checkbox"] {
            position: relative;
            top: 2px;
            margin-right: 5px;
        }

        .form-control:focus,
        input[type="text"]:focus {
            border-color: @COLOR-PM-BLUE2;
            outline: none !important;
        }

        .select2-container {
            &.form-control {
                border: none;
            }
        }

        .save-page-bar {
          margin-top: 15px;
        }
    }

    .tnl-table-header {
        width: 100%;
        padding: 10px 0 20px;

        input[type="text"] {
            width: auto;
        }

        .tnl-table-filter {
            position: relative;
            clear: both;
            width: 100%;
            margin: 15px 0 0;
            padding: 0 0 30px;

            >div {
                position: relative;
                padding: 5px 10px;
            }

            span {
                &.apply {
                    position: absolute;
                    bottom: 0;
                    padding: 5px 0;
                    width: 100%;
                    text-align: center;
                    background: #fff;
                }
            }
        }
    }

    .modal-footer {
        padding: 20px;
        text-align: left;
        border-top: none !important;
        border-radius: 0 0 6px 6px;
        clear: both;
        
        .btn + .btn,
        .btn { //TODO: Remove this adjacent definition once the modal's footer correctly contains tnl-toolbar
            margin: 0 15px 5px 0;
        }

        .pm-link-btn {
            &:hover {
                color: @COLOR-PM-LINK-H !important;
            }
        }
    }

}