@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

.tnl-faceted-nav {
    margin-bottom: 10px;
    border: 1px solid @COLOR-TNL-GRAY4;
    background: @COLOR-TNL-GRAY6;
    .PM-ROUNDED-CORNERS();

    p {
        margin: 0;
    }

    ul li,
    header p a,
    footer p a {
        font-size: @COS-FONT-SIZE-12;
    }

    header h1 {
        position: relative;
        margin: 0 0 10px 0;
        padding: 0 20px 0 0;
        color: @COLOR-TNL-GRAY2;
        font-size: @COS-FONT-SIZE-12;
        border-bottom: 1px dotted @COLOR-TNL-GRAY4;
        .COS-OPEN-SANS-600();
    }

    header p {
      margin: 0 0 2px 0;
    }

    .tnl-facets {
      padding: 15px 0 0;

      &:last-child {
        padding-bottom: 15px;
      }

      .tnl-ratings span {
        top: 0;
        vertical-align: middle;
      }

      .tnl-facets-header {
        position: relative;
        padding: 0 0 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid #ccc;

        .pm-facets-header {
          font-size: @COS-FONT-SIZE-DEFAULT;
          .COS-OPEN-SANS-600();
        }

        .actions {
          position: absolute;
          top: -7px;
          right: 5px;
        }

        p {
          margin: 0 25px 2px 0;
        }
      }
    }

    ul li.has-icon {
        padding-left: 27px;
    }

    &.full-bleed {
	    width: auto;
	    background: none;
	    border: none;
        .LESS-BOX-SHADOW-RESET();
    }

    &.full-bleed .tnl-facets {
	    width: auto;
    }

    ul li,
    &.tnl-facets-header p a,
    &.tnl-facets-footer p a {
        font-size: 15px;
    }

    &.tnl-facets-header h1 {
        line-height: 16px;
        font-size: 16px;
    }

    .tnl-facets-content {

      ul {
        margin: 0;
        padding: 0;
        overflow: auto;
        max-height: 550px;

        li {
          display: block;
          position: relative;
          padding: 5px 15px 5px 5px;
          line-height: 15px;
          .LESS-ROUNDED-CORNERS();
          color: @COLOR-TNL-GRAY2;
          cursor: pointer;

          a {
            white-space: normal;
          }

          &:hover {
            .tnl-ratings {
              color: @COLOR-PM-WHITE1 !important;
            }

            a,
            a:hover {
              color: @COLOR-PM-LINK-H;
              text-decoration: none;
            }

            label {
              color: @COLOR-TNL-GRAY2;
            }
          }

          &:active {
            a,
            a:active {
              color: @COLOR-PM-LINK-A;
              text-decoration: none;
            }
          }

          &.on {
            color: @COLOR-PM-WHITE1 !important;
            background: @COLOR-TNL-GRAY5;

            a,
            a:hover {
              color: @COLOR-PM-LINK-H !important;
            }
          }

          label {
            display: block;
            position: relative;
            .LESS-ROUNDED-CORNERS();
            color: @COLOR-TNL-GRAY2;
            cursor: pointer;
            font-weight: normal;
          }

          input[type='checkbox'] {
            position: relative;
            top: 2px;
          }
        }
      }

      .filter-item-icon {
          position: absolute;
          top: 5px;
          left: 5px;
          font-size: @COS-FONT-SIZE-12;
      }

      input.tnl-faceted-checkbox {
        position: relative;
        top: 2px;
        margin: 0 5px 0 0;
      }
    }
}
