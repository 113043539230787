@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

.tnl-stat-block {
    color: #fff;
    text-align: center;
    .PM-ROUNDED-CORNERS();

    a:hover {
        text-decoration:none;
    }
   .stat-block-content {
        p {
            color: #fff;
            margin: 0;
        }
    }

    &.stat-block-top-bar {
        border-top: 4px solid transparent;
        .LESS-ROUNDED-CORNERS-RESET();
    }

    &.stat-block-lg {
        padding: 20px 10px;

        .stat-block-header {
            h2 {
                font-size: 30px;
                line-height: 24px;
            }
        }
        .stat-block-content {
            margin: 0;

            p {
                font-size: 18px;
            }

        }
    }

    &.stat-block-sm {
        padding: 15px 10px;

        .stat-block-header {
            h2 {
                font-size: 24px;
                line-height: 18px;
                margin-bottom: 2px;
            }
        }
        .stat-block-content {
            margin: 0;

            p {
                font-size: 14px;
                line-height: 13px;
            }

        }
    }
    .icon-font {
        color: #fff;
    }
    &.stat-block-primary {
        background: @COLOR-PRIMARY;
    }
    &.stat-block-success {
        background: @COLOR-SUCCESS;
    }
    &.stat-block-default {
        background: @COLOR-TNL-GRAY2;
    }
    &.stat-block-alt-1 {
        background: @COLOR-PM-ORANGE1;
    }

    &.stat-block-complete {
        background: @COLOR-PM-GREEN6 ;
        color: @COLOR-PM-GREEN3;
        border-color: @COLOR-PM-GREEN6 ;

        .stat-block-header {
            h2 {
                color: @COLOR-PM-GREEN3;
            }
        }
        .stat-block-content {
            .icon-font,
            p {
                color: @COLOR-PM-GREEN3;
                margin: 0;
            }
        }

    }

    &.stat-block-in-progress {
      background: @COLOR-PM-BLUE7 ;
      color: @COLOR-PM-BLUE3;
      border-color: @COLOR-PM-BLUE7;

      .stat-block-header {
          h2 {
              color: @COLOR-PM-BLUE3;
          }
      }
      .stat-block-content {
        .icon-font,
        p {
            color: @COLOR-PM-BLUE3;
            margin: 0;
        }
      }
    }

    &.stat-block-not-started {
        background: #eee;
        color: @COLOR-TNL-GRAY2;
        border-color: #eee;

        .stat-block-header {
            h2 {
                color: @COLOR-TNL-GRAY2;
            }
        }
        .stat-block-content {
            .icon-font,
            p {
                color: @COLOR-TNL-GRAY2;
                margin: 0;
            }
        }

    }

    &.stat-block-basic {
        background: transparent;
        border-right: 1px solid @COLOR-TNL-GRAY2;
        color: @COLOR-TNL-GRAY2;
        padding-left: 0;
        text-align: left;
        .LESS-ROUNDED-CORNERS-RESET();

        .stat-block-header {
            h2 {
                color: @COLOR-TNL-GRAY2;
                text-align: left;
            }
        }
        .stat-block-content {
            p {
                color: @COLOR-TNL-GRAY2;
                margin: 0;
                text-align: left;
            }
        }
    }

    /*Colors for many stat blocks (1st should be stat-block-primary, then start with 1 and repeat after 9)*/
    &.stat-block-0,
    &.stat-block-1,
    &.stat-block-2,
    &.stat-block-3,
    &.stat-block-4,
    &.stat-block-5,
    &.stat-block-6,
    &.stat-block-7,
    &.stat-block-8,
    &.stat-block-9 {
        margin: 4px 0;
        .COS-OPEN-SANS-REGULAR();
    }

    &.stat-block-0 {
        background: @COLOR-PM-BLUE ;
    }

    &.stat-block-1 {
        background: @COLOR-PM-GREEN ;
    }

    &.stat-block-2 {
        background: @COLOR-PM-ORANGE1;
    }

    &.stat-block-3 {
        background: @COLOR-TNL-GRAY2 ;
    }

    &.stat-block-4 {
        background: @COLOR-PM-PURPLE ;
    }

    &.stat-block-5 {
        background: @COLOR-PM-BLUE-DARK ;
    }

    &.stat-block-6 {
        background: @COLOR-PM-GREEN-DARK ;
    }

    &.stat-block-7 {
        background: @COLOR-PM-ORANGE-DARK ;
    }

    &.stat-block-8 {
        background: @COLOR-PM-GRAY ;
    }

    &.stat-block-9 {
        background: @COLOR-PM-PURPLE-DARK ;
    }

    &.clickable {
        &:hover {

            background: @COLOR-PM-ORANGE1;

            // Use the following CSS for gradient overlay to match the programs mocks.
            //background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.1)));
            //background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
            //background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
            //background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
            //background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
            //background-image: linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
            cursor: pointer;

            h2,
            p,
            .icon-font {
                color: #fff;
            }
            border-color: @COLOR-PM-ORANGE1;
        }
    }

    &.selected {

      &.stat-block-complete {
          border-color: @COLOR-PM-GREEN3;
      }
      &.stat-block-in-progress {
          border-color: @COLOR-PM-BLUE3;
      }
      &.stat-block-not-started {
          border-color: @COLOR-TNL-GRAY2;
      }
    }
}

.badge-block,
 #observation-instance-body, .tnl-observation-preview {

  .tnl-stat-block {

    .stat-block-header {
      h2 {
          color: #fff;
      }
    }
  }
}

.tnlobs_subscore {

  .tnl-stat-block {
    background-color:transparent !important;
    border: 1px solid #333;
    width: 80%;
    padding: 8px;
    margin-left: 25px;

    .stat-block-header {
      h2 {
        color: #333 !important;
        font-size: 16px;
      }
    }

    .stat-block-content {
      p {
      color: #333;
      }
    }
  }
}
