@import (reference) "_pm-global.less";

// TOGGLE BUTTON STYLING //
// Author: Chris Brosky - August 2017 //
.pm-switch-control {
   display:inline-block;
   margin-right: 5px;
}

.pm-toggle-switch {
  position: relative;
  display: inline-block;
  width: 66px;
  height: 30px;
  margin: 0 0 5px;

  /* Toggle Slider Container */
  input {
    display: none;
  }

  .pm-toggle-btn {
     box-shadow: inset 1px 1px 4px 0.6px rgba(0, 0, 0, 0.35);

     &.on {
       background-color: @COLOR-PM-GREEN3;
     }

     &.off {
       background-color: @COLOR-PM-RED1;
     }
  }

  /* Round Toggle Slider Button */
  .pm-toggle-circle {
    content: "";
    position: absolute;
    left: 5px;
    top: 50%;
    height: 22px;
    width: 22px;
    margin-top: -11px;
    border-radius: 34px;
    box-shadow: 1px 1px 4px 0.6px rgba(0, 0, 0, 0.35);
    background-color: @COLOR-PM-WHITE1;
    -webkit-transition: .4s;
    transition: .4s;
    z-index: 100;
  }

  .pm-toggle-btn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 66px;
    height: 30px;
    box-shadow: inset 1px 1px 4px 0.6px rgba(0, 0, 0, 0.35);
    border-radius: 34px;
    -webkit-transition: .4s;
    transition: .4s;
    cursor: pointer;

    &.on, &.off {
      .pm-toggle-btn-text {
        position: absolute;
        top: 50%;
        margin-top: -10px;
        font-size: @COS-FONT-SIZE-DEFAULT;
        color: #fff;
        line-height: normal;
        .PM-TRANSITION();
        .COS-OPEN-SANS-600();
      }
    }

    /* Button States onClick */
    &.on {
      .pm-toggle-btn-text {
        left: 11px;
      }

      .pm-toggle-circle {
        -webkit-transform: translateX(34px);
        -ms-transform: translateX(34px);
        transform: translateX(34px);
      }
    }

    &.off {
      .pm-toggle-btn-text {
        right: 11px;
      }
    }
  }

  /* Disabled Styles */
  &.disabled {
    opacity: 1;

    .pm-toggle-circle {
      box-shadow: 1px 1px 4px 0.6px rgba(0, 0, 0, 0.2);
    }

    .pm-toggle-btn {
      box-shadow: inset 1px 1px 4px 0.6px rgba(0, 0, 0, 0.2);
      background-color: @COLOR-PM-GREY3;
      &.on, &.off {
        .pm-toggle-btn-text {
          color: @COLOR-PM-GREY9;
        }
      }
    }
  }
}
