@import (reference) "../Theme/Common/_common.less";

//  TODO: Depending on which toggle the engeineers use in their helper, adjust this style.


// Bootstrap switch.
// http://www.bootstrap-switch.org
.bootstrap-switch {

    border-color: @COLOR-TNL-GRAY3;
    .LESS-ROUNDED-CORNERS();

    .bootstrap-switch-handle-off.bootstrap-switch-primary,
    .bootstrap-switch-handle-on.bootstrap-switch-primary {
        background: @COLOR-TNL-GREEN2;
    }

    .bootstrap-switch-handle-off.bootstrap-switch-default,
    .bootstrap-switch-handle-on.bootstrap-switch-default {
        color: @COLOR-TNL-GRAY1;
        background: @COLOR-TNL-GRAY5;
    }

    .bootstrap-switch-container,
    .bootstrap-switch-handle-on,
    .bootstrap-switch-handle-off {
        .LESS-ROUNDED-CORNERS-RESET();
    }

}

// Bootstrap toggle.
// http://www.bootstraptoggle.com
// https://gitcdn.github.io/bootstrap-toggle/2.2.0/css/bootstrap-toggle.min.css
// https://gitcdn.github.io/bootstrap-toggle/2.2.0/js/bootstrap-toggle.min.js
.toggle {
    border: 1px solid @COLOR-TNL-GRAY4;
    .LESS-ROUNDED-CORNERS();

    &:hover {
        border: 1px solid @COLOR-TNL-GRAY4;
    }
    .toggle-handle {
        background: #fff;
        .LESS-ROUNDED-CORNERS();

        &:hover {
            background: #fff;
        }
    }
}


// Tnl
.tnl-btn-group-toggle {
    background: @COLOR-TNL-GRAY4; // Divider line color.
    border: 1px solid @COLOR-TNL-GRAY4;
    border-radius: 2px;
    
    .btn:last-child {
        margin-right: 0;
    }
    .btn,
    a.btn {
        background: @COLOR-TNL-GRAY6;
        border-radius: 0;
        color: @COLOR-TNL-GRAY4;
        margin-right: 1px;
    
        span.icon-font.fa,
        span.icon-font.im {
            color: @COLOR-TNL-GRAY4;
        }
        &:hover {
            color: @COLOR-TNL-GRAY2;
            .LESS-BOX-SHADOW-INSET-RESET();

            span.icon-font.fa,
            span.icon-font.im {
                color: @COLOR-TNL-GRAY2;
            }
        }
    }
    .btn.active,
    a.btn.active {
        background: #fff;
        box-shadow: none;
        color: @COLOR-TNL-ORANGE1;
    
        span.icon-font.fa,
        span.icon-font.im {
            color: @COLOR-TNL-ORANGE1;
        }
    }
}