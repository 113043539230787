@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";



.tnl-discussion-container {

    .tnl-discussion-post {

        .tnl-discussion-post-header {
            margin-bottom: 2px;

            .tnl-discussion-post-pic {
                display: inline-block;
                margin-right: 5px;
            }
            .tnl-discussion-post-user {
                .COS-OPEN-SANS-600();
                display: inline-block;
                vertical-align: baseline;
            }
        }
    }

    .tnl-discussion-post-main {
        border-bottom: 1px solid @COLOR-TNL-GRAY4;
        margin-bottom: 20px;
        padding-bottom: 20px;

        .tnl-draft-edit {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
        }

       .tnl-discussion-post-actions {
        
            .tnl-discussion-post-action {
                display: inline-block;
                font-size: @FONT-SIZE-SM;
                margin-right: 10px;
                vertical-align: baseline;
            }
        }
    }

    .tnl-discussion-post-time {
        display: inline-block;
        color: @COLOR-TNL-GRAY4;
        font-size: @FONT-SIZE-XSM;
        vertical-align: baseline;
    }

    .tnl-discussion-reply {
        margin: 15px 20px 0 30px;

        .tnl-discussion-post-main {
            border: none;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    &.no-indent {
        .tnl-discussion-reply {
            margin-left: 0;
        }
    }

}
