@import (reference) "_pm-global.less";

.pm-content-panel {
  padding: 20px;

  h2 {
    margin-bottom: 0;
    line-height: 35px;
  }
}

.pm-select-files-panel {
  padding: 20px;
  margin: 10px 0;
  border-radius: 10px;
  background: @COLOR-PM-WHITE1;

  .pm-dragdrop-icon {
    max-height: 112px;
  }

  .pm-dragdrop-title,
  .pm-dragdrop-text {
    display: block;
  }

  .pm-dragdrop-title {
    font-size: 18px;
    font-weight: 700;
  }

  .pm-dragdrop-text {
    margin-bottom: 15px;
  }

  .pm-btn-lg {
    margin: 0 0 10px 0;
  }
}

.pm-show-files-panel {
  padding: 20px;
  margin: 20px;
}

.pm-create-folder-panel {
  padding: 20px;
  margin: 20px 0;
  border-radius: 25px;
  background: @COLOR-PM-GREY4;

  .pm-folders-icon {
    max-height: 190px;
  }
}

.pm-ml-add-folder {
  display: block;

  .pm-add-item {
    padding-left: 0;
    float: right;
    margin: 0 10px 3px 0;

    span:nth-child(2) {
      position: relative;
      top: -10px;
      margin-left: 5px;
    }
  }
}

.pm-ml-show-folders-panel {
  padding: 0;
  margin: 0;
}

.pm-ml-show-folders-table {
  margin: 0;

  .pm-ml-file-list {
    padding: 0;

    .pm-ml-list {
      padding: 0;

      .pm-list-icons {
        height: 45px;
      }
    }

    &:first-child {
      .pm-ml-folder-details {
        border-top: none;
      }
    }

    .pm-ml-folder-details {
      background-color: @COLOR-PM-GREY12;
      border-top: 1px solid @COLOR-PM-GREY5;

      .pm-file-details {
        .pm-ml-file-name {
          padding-left: 0 !important;
        }
      }
    }
  }
}

.pm-list-header {
  background: @COLOR-PM-GREY7;
  font-style: normal;
  font-size: @COS-FONT-SIZE-DEFAULT;
  color: @COLOR-PM-WHITE1;
  padding: 10px 0;
  .COS-OPEN-SANS-REGULAR();

  &.pm-view-trash,
  .row {
    margin: 0;
  }

  input[type=checkbox] {
    margin-top: 0;
  }
}

.pm-ml-file-list {

  .pm-ml-icon-col {
    min-height: 45px;

    .pm-icon-action-btns {
      position: relative;
      top: 7px;
    }

    .pm-ml-drag-icon {
      top: 7px !important;
      margin-right: 10px;
    }
  }

  .pm-file-div {

     .pm-file-details {
      position: relative;
      height: 45px;

      .pm-ml-file-name,
      .pm-ml-file-date,
      .pm-ml-file-count {
        position: absolute;
        width: 97%;
        top: 50%;
        margin-top: -11px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .pm-ml-file-name {
        padding-left: 5px;
      }

      .pm-ml-file-count {
        left: 34px;
      }
    }

    .pm-file-details-icon {
      .pm-icon-svg {
        position: relative;
        top: 4px;
        left: 10px;
      }
    }

    .pm-ml-expand,
    .pm-ml-contract {
      position: absolute;
      top: 6px;
      right: 6px;
      float: right;
      cursor: pointer;
    }
  }

  &:nth-child(even) {
    background: @COLOR-PM-WHITE1;
  }

  &:nth-child(odd) {
    background: @COLOR-PM-GREY4;
  }

  input[type=checkbox] {
    top: -2px;
    display: inline-block;
    margin-right: 15px;
    box-shadow:  none !important;
  }

  &.pm-view-trash {

    .row {
      margin: 0;
    }

    .pm-ml-icon-col {
      input[type=checkbox] {
        position: relative;
        top: 3px;
      }
    }

    .pm-file-div {
      .pm-file-details {
        .pm-ml-file-name {
          padding-left: 0;
        }
      }
    }
  }
}

.pm-ml-list {
  margin: 0;
  
  &:nth-child(odd),
  &:nth-child(even) {
    font-style: normal;
    font-size: @COS-FONT-SIZE-DEFAULT;
    color: @COLOR-COS-DEFAULT;
    padding: 5px 0;
    .COS-OPEN-SANS-REGULAR();
  }

  .pm-list-icons {
    position: relative;
    top: 2px;
    height: 30px;

    a {
      margin: 0 10px 0 0;
    }
  }

  .ui-draggable {
    z-index:500;
  }

  &.ui-draggable {
    z-index:500;
  }
}

.pm-folder-file-list {
    display: none;
}

.pm-section-seperator {
   background: #bdbcbc;
   height: 60px;
}

.pm-more-link {
    float:right
}

.pm-ml-back-btn {
  padding: 15px 10px 10px;

  span:nth-child(2) {
    position: relative;
    top: -10px;
    margin-left: 5px;
  }
}

.pm-folder-list-item {
  div {
    line-height: 36px;
    top: 2px;
    max-height: 40px;
  }
}

.pm-file-draggable {
  &.ui-draggable.ui-draggable-dragging {
    width: 100% !important;
    max-width: 1863px;
    position: absolute;
    border: 1px dashed @COLOR-PM-GREY5;
    background-color: @COLOR-PM-YELLOW3;
    border-radius: 3px;
    z-index: 500;
  }
}

.pm-ml-add-file,
.pm-ml-add-folder {
  .pm-btn-group.pm-icon-action-btns .pm-action-btn .pm-show-text {
    top: -1px;
  }
}

.pm-ml-add-file {
  .pm-ml-info-text {
    position: relative;
    top: -4px;
  }
}


/* --== ATTACH FILES DROP AREA ==-- */
.pm-attach-file-control {
  border-radius: 10px;

  .pm-attach-file {

    &.pm-media-locker-drop {
      border: 2px dashed @COLOR-PM-GREY5;
      border-radius: 10px;
      padding: 20px;
      background-color: rgba(222, 231, 237, .5);
      .PM-TRANSITION();

      &:hover {
        background-color: rgba(138, 189, 230, .15);
        border-color: @COLOR-PM-BLUE4;
        .PM-TRANSITION();
      }
    }

    .pm-media-locker-icon-bg {
        background: transparent 50% 50% no-repeat url(/images/pm/medialocker/dragdrop_illustration.svg);
        background-blend-mode: overlay;
        width: 100%;
        height: 68px;
        background-size: contain;
        margin: 5px auto;
    }

    .pm-media-locker-drop-text {
        display: block;
        width: 100%;
        text-align: center;
        margin: 0 auto;
        .COS-OPEN-SANS-600();
    }
  }

  &.ui-droppable {
    &.ui-state-active {
      border: none;
      background: none;
      color: @COLOR-COS-DEFAULT;
      .PM-TRANSITION();
      .COS-OPEN-SANS-REGULAR();

      .pm-media-locker-drop,
      .pm-media-locker-drop:hover {
        background-color: rgba(138, 189, 230, .15) !important;
        border-color: @COLOR-PM-BLUE4 !important;
        .PM-TRANSITION();
      }
    }
  }
}

.pm-attach-file-col {
  .pm-attach-file-control {
    margin-bottom: 5px;
  }
}

.pm-add-file-btn {
    display: block !important;
    margin: 0 auto !important;
    text-align: center !important;
}

.pm-short-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}


/* --== SLIDE OUT PANEL - FOLDERS AREA ==-- */
.pm-slideout-panel-content {
  hr {
    margin: 1em 0 2em;
  }

  .pm-file-row {
    margin-bottom: 5px;

    .pm-file-text {
      height: 30px;
      line-height: 30px;
      padding-left: 5px;
    }
  }

  .pm-ml-list {

    .pm-folder-name {
      padding-left: 0;
      .PM-OVERFLOW-ELLIPSIS();
    }

    .pm-folder-size {
      padding-left: 20px;
    }

    .pm-folder-name,
    .pm-folder-size {
      height: 35px;
      line-height: 35px;
    }

    .pm-short-name {
      width: auto;
      line-height: 36px;
      max-height: 36px;
      padding-left: 0;
      .COS-OPEN-SANS-REGULAR();
    }

    .pm-icon-col {
      .pm-icon-md {
        .pm-icon-font {
          top: 3px;
        }
      }
    }

    .pm-folder-file-list {

      .pm-file-div {
        margin-bottom: 10px;

        .pm-short-icon {
          height: 36px;
        }

        .pm-short-name {
          width: auto;
          line-height: 36px;
          max-height: 36px;
          padding-left: 0;
          .COS-OPEN-SANS-REGULAR();
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

/* --== DROPZONE SPECIFIC RESPONSIVE STYLES ==-- */
@media only screen and (max-width: 1717px) and (min-width: 1145px) {
  .pm-page-container {

    .col-lg-2,
    .col-md-2 {
      width: 25% !important
    }

    .col-lg-10,
    .col-md-10 {
      width: 75% !important;
    }
  }
}

@media only screen and (max-width: 1144px) and (min-width: 930px) {
  .pm-page-container {

    .col-sm-3 {
      width: 33.3333% !important;
    }

    .col-sm-9 {
      width: 66.6666% !important;
    }
  }
}

@media only screen and (max-width: 929px) {
  .col-sm-3,
  .col-sm-9 {

    &.pm-attach-file-col {
      margin-bottom: 20px;
    }
  }
}

/* Small devices (768px and below) */
@media only screen and (max-width: 768px) {
  .pm-content-panel .pm-ml-add-folder .pm-add-item {
    float: left;
  }
  .pm-ml-list div {
    max-height: 100% !important;
    line-height: initial !important;
  }

  .pm-ml-list .pm-list-icons {
    height: auto !important;
  }

  .pm-list-header input[type=checkbox],
  .pm-ml-file-list input[type=checkbox] {
    left: 10px !important;
  }

  .pm-attach-file-col {
    margin-bottom: 20px;
  }
}

.pm-file-div.ui-draggable-dragging {
    border: 1px solid #A6AAB9;
    background-color: #E1EAF7 !important;
    border-radius: 5px;
    padding: 5px;
}
.pm-highlighted-dragging {
    opacity:0.5;
}
.pm-highlighted-hover-zone {
}
.pm-highlighted-droppable-zone {
    background-color: rgba(138,189,230,.3) !important;
}