@import (reference) "_pm-global.less";

.pm-item-list {
	margin: 0;
	padding: 0;
	list-style-type: none;

    > li {
      position: relative;
	    padding: 10px 0;
	    border-bottom: 1px solid #ccc;

      .pm-btn-lg {
          margin-top: 5px;
      }

      &:first-child {
          padding-top: 10px;
      }

      .pm-icon-list{
          float: left;
          clear: both;
      }
      .pm-text-list {
          float: left;
          padding: 5px 0 0 5px;
      }

			.fa {
				position: relative;
				top: -8px;
				left: -10px;
			}
    }

    > li:last-child {
	    border-bottom: none;
    }

    > li .title {
	    margin-right: 20px;
    }

    > li .description,
    > li .links {
	    margin: 1px 0;
	    padding: 0;
	    color: @COLOR-PM-GREY1;
	    font-size: @COS-FONT-SIZE-DEFAULT;
	    line-height: 15px;
    }

    > li .links a {
	    margin-left: 10px;

			&:first-child {
				margin-left: 0;
			}
    }

    > li .description:last-child {
	    margin-bottom: 0;
    }

    > li.selected {
	    color: @COLOR-PM-ORANGE1;
        .COS-OPEN-SANS-600();

      a,
      &:hover a,
      label {
        color: @COLOR-PM-ORANGE1;
        text-decoration: none;
        .COS-OPEN-SANS-600();
      }
    }

    .pagination {
        display: inline-block;
        margin: 0;
        text-align: center;
    }

    .right {
	    text-align: right;
    }

		.left {
			text-align: left;
		}

    > li.has-icon > .list-icon {
			position: absolute;
			top: auto;
			left: 0;
			display: inline-block;
			width: 25px;
			text-align: center;
    }

    > li.has-icon > div {
      position: relative;
      left: 30px;
      margin-right: 71px;
    	display: block;
    }

    > li.show-all {
      text-align: center;
    }

    > li.show-all a,
    > li.note {
	    border-bottom: none;
	    font-size: @COS-FONT-SIZE-DEFAULT;
	    padding-bottom: 0;
    }

    > li.note {
	    padding: 5px 10px 5px 0;
	    color: @COLOR-PM-GREY1;
    }

    &.attachments .actions {
        display: inline-block;
        position: absolute;
        top: 13px;
        left: 5px;
        width: 18px;
        height: 17px;
        text-align: center;
    }

    &.attachments .file {
        position: relative;
    }

    &.attachments .title {
        position: relative;
        left: 24px;
        margin-right: 24px;
    }

    &.simple li {
        padding: 0 0 10px;
        border: none;
    }

    &.simple li:last-child {
        padding: 0;
    }

    li.no-results {
        border: none;
    }

    &.pm-item-list-small {
        font-size: @COS-FONT-SIZE-DEFAULT;
    }
}

.pm-link-list {
	margin: 0;
	padding: 0;
	list-style-type: none;

	> li,
	a {
    margin: 0;
    padding: 0;
	}
}

.pm-list {
  margin: 5px 0;
  padding: 0;
  list-style-type: none;

  & > li {
      margin: 0 0 10px;
      padding: 0;
  }
}

.pm-legend-items {
    text-align: center;
    list-style-type: none;

    & > li {
        padding: 0 10px;
        display: inline-block;

        .pm-icon-sm {
            top: 6px;

            .pm-icon-info-in-progress-circle:before {
                color: @COLOR-PM-FUSCHIA1;
            }

            .pm-icon-info-unassigned-circle:before {
                color: @COLOR-PM-GREY5;
            }

            .pm-icon-info-complete:before {
                color: @COLOR-PM-GREEN4;
            }
        }
    }
}