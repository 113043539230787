@import (reference) "_pm-global.less";
// Chris Brosky - 9/11/17 //

/* ======================== LESS VARIABLES ======================== */

// LESS VARIABLES
@size23:        23px; // 23px
@size30:        30px; // 30px
@size40:        40px; // 40px
@size28:        28px; // 28px
@size36:        36px; // 36px
@size46:        46px; // 46px
@fontSM-solid:  16px; // Icon size for SMALL solid backgrounds
@fontMD-solid:  22px; // Icon size for MEDIUM solid backgrounds
@fontLG-solid:  30px; // Icon size for LARGE solid backgrounds
@percentHalf:   0.5;

// SMALL ICON MIXIN //
.PM-ICON-SM () {
  position: relative;
  display: inline-block;
  width: @size23;   /* 23px */
  height: @size23;  /* 23px */

  &.pm-icon-font [class^="pm-icon-"],
  [class*=" pm-icon-"] {
    position: absolute;
    top: 0;
    left: 0;
    font-size: @size23; /* 23px */

    &:before {
      font-size: @size23; /* 23px */
    }
  }
}

// MEDIUM ICON MIXIN //
.PM-ICON-MD () {
  position: relative;
  display: inline-block;
  width: @size30;   /* 30px */
  height: @size30;  /* 30px */

  &.pm-icon-font [class^="pm-icon-"],
  [class*=" pm-icon-"] {
    position: absolute;
    top: 0;
    left: 0;
    font-size: @size30; /* 30px */

    &:before {
      font-size: @size30; /* 30px */
    }
  }
}

// LARGE ICON MIXIN //
.PM-ICON-LG () {
  position: relative;
  display: inline-block;
  width: @size36;   /* 36px */
  height: @size36;  /* 36px */

  &.pm-icon-font [class^="pm-icon-"],
  [class*=" pm-icon-"] {
    position: absolute;
    top: 0;
    left: 0;
    font-size: @size36; /* 36px */

    &:before {
      font-size: @size36; /* 36px */
    }
  }
}

/* ======================== SVG STYLES ======================== */
.SVG-ICON-BG() {
  background-size: cover;
  background-position: 50% 0;
  display: block;
}
.SVG-ICON-SM() {
  width: @size23;   /* 23px */
  height: @size28;  /* 28px */
}
.SVG-ICON-MD() {
  width: @size30;   /* 30px */
  height: @size36;  /* 36px */
}
.SVG-ICON-LG() {
  width: @size36;   /* 36px */
  height: @size46;  /* 46px */
}

/* ======================== CLASS STYLES ======================== */
// SMALL ICONS //
.pm-icon-sm {
  .PM-ICON-SM();
}

.pm-icon-sm.pm-icon-solid {
  color: @COLOR-PM-WHITE1;
  .FONT-ICON-SM-SOLID();
  .CIRCLE();
}

.pm-svg-sm.pm-icon-svg {
  width: @size23;   /* 23px */
  height: @size28;  /* 28px */
  display: inline-block;

  span {
    .SVG-ICON-BG();
    .SVG-ICON-SM();
  }

  &.pm-icon-square {
    width: @size23 !important;  /* 23px */
    height: @size23 !important; /* 23px */

    .pm-icon-info-activity-not-started,
    .pm-icon-info-activity-in-progress,
    .pm-icon-info-activity-pending-completion,
    .pm-icon-info-activity-complete,
    .pm-icon-info-activity-reopened {
      width: @size23 !important;  /* 23px */
      height: @size23 !important; /* 23px */
    }
  }
}

// MEDIUM ICONS //
.pm-icon-md {
  .PM-ICON-MD();
}

.pm-icon-md.pm-icon-solid {
  color: @COLOR-PM-WHITE1;
  .FONT-ICON-MD-SOLID();
  .CIRCLE();
}

.pm-svg-md.pm-icon-svg {
  width: @size30;   /* 30px */
  height: @size36;  /* 36px */
  display: inline-block;

  span {
    .SVG-ICON-BG();
    .SVG-ICON-MD();
  }

  &.pm-icon-square {
    width: @size30 !important;  /* 30px */
    height: @size30 !important; /* 30px */

    .pm-icon-info-activity-not-started,
    .pm-icon-info-activity-in-progress,
    .pm-icon-info-activity-pending-completion,
    .pm-icon-info-activity-complete,
    .pm-icon-info-activity-reopened {
      width: @size30 !important;  /* 30px */
      height: @size30 !important; /* 30px */
    }
  }
}

// LARGE ICONS //
.pm-icon-lg {
  .PM-ICON-LG();
}

.pm-icon-lg.pm-icon-solid {
  color: @COLOR-PM-WHITE1;
  .FONT-ICON-LG-SOLID();
  .CIRCLE();
}

.pm-svg-lg.pm-icon-svg {
  width: @size36;   /* 36px */
  height: @size46;  /* 46px */
  display: inline-block;

  span {
    .SVG-ICON-BG();
    .SVG-ICON-LG();
  }

  &.pm-icon-square {
    width: @size46 !important;  /* 46px */
    height: @size46 !important; /* 46px */

    .pm-icon-info-activity-not-started,
    .pm-icon-info-activity-in-progress,
    .pm-icon-info-activity-pending-completion,
    .pm-icon-info-activity-complete,
    .pm-icon-info-activity-reopened {
      width: @size46 !important;  /* 46px */
      height: @size46 !important; /* 46px */
    }
  }
}

/* Show Icon and Text*/
.pm-icon-text {
    display: inline-flex;
      //show text
    .pm-show-text {
        display: inline-block;
        width: auto;
        position: relative;
        vertical-align: top;

        &.pm-text-sm {
            line-height: 2.3rem;
        }

        &.pm-text-md {
            line-height: 3rem;
        }

        &.pm-text-lg {
            line-height: 3.2rem;
        }

        &.left {
        margin-right: 6px;
        }

        &.right {
        margin-left: 6px;
        }
    }
}

/* ======================== LESS SORCERY ======================== */

// LESS MIXINS
.BACKGROUND-COLOR(@background: transparent) {
  background-color: @background;
}

.CIRCLE(@radius: 50%) {
  -webkit-border-radius: @radius;
   -moz-border-radius: @radius;
        border-radius: @radius;
}

.OUTLINE(@border: 1px solid) {
  border: @border;
  border-color: @COLOR-PM-BODY;
  box-sizing: border-box;
  text-shadow:1px 1px 1px 1px rgba(0,0,0,0.005);
}

// SMALL SOLID ICON DEFINITION //
.FONT-ICON-SM-SOLID() {
  position: relative;
  display: inline-block;
  height: @size28; // Icon wrap height
  width: @size28; // Icon wrap width

  &.pm-icon-font [class^="pm-icon-"],
  [class*=" pm-icon-"] {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -8px 0 0 -8px;
    font-size: @fontSM-solid;

    &:before {
      font-size: @fontSM-solid;
    }
  }
}

// MEDIUM SOLID ICON DEFINITION //
.FONT-ICON-MD-SOLID() {
  position: relative;
  display: inline-block;
  height: @size30; // Icon wrap height
  width: @size30; // Icon wrap width

  &.pm-icon-font [class^="pm-icon-"],
  [class*=" pm-icon-"] {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -11px 0 0 -11px;
    font-size: @fontMD-solid;

    &:before {
      font-size: @fontMD-solid;
    }
  }
}

// LARGE SOLID ICON DEFINITION //
.FONT-ICON-LG-SOLID() {
  position: relative;
  display: inline-block;
  height: @size46; // Icon wrap height
  width: @size46; // Icon wrap width

  &.pm-icon-font [class^="pm-icon-"],
  [class*=" pm-icon-"] {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -15px;
    font-size: @fontLG-solid;

    &:before {
      font-size: @fontLG-solid;
    }
  }
}

/* ======================== COLOR STYLES ======================== */
.pm-yellow1,
.pm-teal1,
.pm-fuschia1,
.pm-slate1,
.pm-green1,
.pm-grey5 {
    span {
        color: #fff;
    }
}

.pm-yellow1 {
    background-color: @COLOR-PM-YELLOW1;    /* #ffbe22 */
}
.pm-teal1 {
    background-color: @COLOR-PM-TEAL1;      /* #00baa2 */
}
.pm-fuschia1 {
    background-color: @COLOR-PM-FUSCHIA1;   /* #93268f */
}
.pm-slate1 {
    background-color: @COLOR-PM-SLATE1;     /* #116384 */
}
.pm-green1 {
    background-color: @COLOR-PM-GREEN1;     /* #8ec641 */
}
.pm-grey5 {
    background-color: @COLOR-PM-GREY5;     /* #a4aaba */
}
.pm-color-yellow1 {
    color: @COLOR-PM-YELLOW1;    /* #ffbe22 */
}

.pm-color-white1 {
  color: @COLOR-PM-WHITE1;
}
.pm-color-teal1 {
    color: @COLOR-PM-TEAL1;      /* #00baa2 */
}
.pm-color-fuschia1 {
    color: @COLOR-PM-FUSCHIA1;   /* #93268f */
}
.pm-color-grey5 {
    color: @COLOR-PM-GREY5;   /* #cce5a9 */
}
.pm-color-slate1 {
    color: @COLOR-PM-SLATE1;     /* #116384 */
}
.pm-color-green1 {
    color: @COLOR-PM-GREEN1;     /* #8ec641 */
}
.pm-color-green4 {
    color: @COLOR-PM-GREEN4;     /* #008f07 */
}
.pm-font-green1 {
    color: @COLOR-PM-GREEN3;     /* #3d6802 */
}
.pm-font-gray5 {
    color: @COLOR-PM-GREY5;     /* #a4aaba */
}
.pm-color-red1 {
    color: @COLOR-PM-RED1;       /* #bb2127 */
}
.pm-color-blue1 {
    color: @COLOR-PM-BLUE1;       /* #165fab */
}
.ps-color-orange1 {
    color: @COLOR-PS-ORANGE1;     /* #f97d16 */
}
.ps-color-orange2 {
    color: @COLOR-PS-ORANGE2;     /* #ffeee6 */
}
.pm-icon-clickable {
    cursor: pointer;
}

/* ======================== QUESTION MARK TOOLTIP ======================== */
.pm-icon-sm {
  &.pm-question-mark-fill {
    width: 14px;
    height: 14px;
    top: 3px;
    left: 5px;
    border-radius: 50%;
    background-color: @COLOR-PM-WHITE1;
    margin-right: 15px;

    .pm-icon-info-questionmark-circlefill {
      font-size: 14px;

      &:before {
        font-size: 14px;
      }
    }
  }
}

.tnl-table {
  th {
    .pm-question-mark-fill {
      background-color: #000;
    }
  }
}

.attachments {
    .pm-btn-group {
        margin-right: 5px;
    }

    .pm-icon-sm {
        &.pm-question-mark-fill {
          left: 0;
          top: -8px;
        }
    }
}

/* ======================== USER ADMIN ICONS ======================== */
.pm-icon-sm,
.pm-icon-md,
.pm-icon-lg {

    &.pm-in-use {
        color: @COLOR-PM-GREEN4 !important;

        .pm-icon-info-assigned-circle:before {
          color: @COLOR-PM-GREEN4 !important;
        }
    }

    &.pm-not-in-use {
        color: @COLOR-PM-RED1 !important;
    }

    &.cos-move-up-icon {
        transform: rotate(180deg);
        top: 1px;
    }
}
