@import (reference) "../Theme/Common/_common.less";

.bs-example {
    background: #fff;
    border: 1px solid @COLOR-TNL-GRAY4;
    padding: 20px;

    &.tnl-example-preview {
        padding-top: 40px;

        &:after {
            color: @COLOR-TNL-GRAY3;
            content: "Preview";
            font-size: @FONT-SIZE-SM;
            left: 20px;
            position: absolute;
            top: 10px;
        }
   }

}