@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

.pm-bd-badge-bar-wrap {
    position: relative;
    width: 100%;
    height: 165px;
    background-color: transparent;
    margin: 0 0 20px 0;
}

.pm-bd-badge-bar {
    width: 88%; /* Adjust width of bar */
    height: 60px;
    position: relative;
    top: 32%;
    margin: 0 auto;
}

.pm-bd-badge-bar:after {
    content: "";
    height: 60px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgba(0,0,0,.1);
    border-bottom: 4px solid rgba(0,0,0,.14);
    background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(0,2,34,0.06) 100%);
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,2,34,0.06) 100%);
    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(0,2,34,0.06) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#0f000222',GradientType=0 );
}
    
.pm-bd-badge {
    display: inline-block; 
    height: 60px;
    position: relative;
    float: left;
}

.pm-bd-badge-earned {
    background-color: #00bb54;
}
    
.pm-bd-badge-in-progress {
    background-color: #ff7c00;
}
  
.pm-bd-badge-not-started {
    background-color: #E5E5E5;
}
    
.pm-bd-callout-bubble {
    width: 50px;
    height: 35px;
    background-color: #f4f5f7;
    border: 1px solid #d5d6d8;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    margin-left: -26px;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.1);
}
    
.pm-bd-callout-bubble.pm-bd-down {
    top: -47px;
}
    
.pm-bd-callout-bubble.pm-bd-up {
    bottom: -47px;
}

.pm-bd-callout-value {
    width: 100%;
    position: absolute;
    top: 8px;
    left: 0;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
}
	
.pm-bd-badge-earned .pm-bd-callout-value {
	color: #00bb54;
}
	
.pm-bd-badge-in-progress .pm-bd-callout-value {
    color: #ff7c00;
}
    
.pm-bd-badge-not-started .pm-bd-callout-value {
    color: #36474f;
} 

.pm-bd-triangle-down {
	width: 0; 
	height: 0;
	border-style: solid;
	border-width: 6px 6px 0 6px;
	border-color: #d5d6d8 transparent transparent transparent;
	position: relative;
	left: 50%;
	margin-left: -5.5px;
	top: 100%;
}
	
.pm-bd-triangle-down:after {
	content: '';
	width: 0; 
	height: 0;
	border-style: solid;
	border-width: 5px 5px 0 5px;
	border-color: #f4f5f7 transparent transparent transparent; 
	position: absolute;
	top: -6px;
	left: -5px;
	}
	 
.pm-bd-triangle-up {
	width: 0; 
	height: 0;
	border-style: solid;
	border-width: 0 6px 6px 6px;
	border-color: transparent transparent #d5d6d8 transparent;
	position: relative;
	left: 50%;
	margin-left: -5.5px;
	top: -6px;
}
.pm-bd-triangle-up:after {
	content: '';
	width: 0; 
	height: 0;
	border-style: solid;
	border-width: 0px 5px 5px 5px;
	border-color: transparent transparent #f4f5f7 transparent; 
	position: absolute;
	top: 1px;
	left: -5px;
}

.pm-bd-status {
    height: 40px;
    width: 8em;
    display: inline-block;
    text-align: center;
    float: left;
    padding: 10px 5px;
    border-radius: 3px;
    background-color: #f4f5f7;
    border: 1px solid #d5d6d8;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.1);
}
    
.pm-bd-status-earned {
    border-bottom: 3px solid #00bb54;
}
    
.pm-bd-status-in-progress {
    margin: 0 20px;
    border-bottom: 3px solid #ff7c00;
}
    
.pm-bd-status-not-started {
    border-bottom: 3px solid #c5c5c5;
}
    
.pm-bd-badge.pmb-bd-no-activity {
    width:100%; 
    text-align: center; 
    color: #36474f; 
    font-weight: bold; 
    font-size: 16px; 
    line-height: 58px; 
    text-shadow: 1px 1px 1px #f4f5f7; 
    background: repeating-linear-gradient(
        45deg,
        #f4f5f7,
        #f4f5f7 7px,
        #e2e2e2 7px,
        #e2e2e2 14px);
}
    
.pm-bd-key-center {
    width: 346px;
}
    
.pm-bd-status-block {
    display: block;
    position: relative;
}
    
.pm-bd-status-value {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px; 
    font-weight: bold;
}
    
.pm-bd-status-earned .pm-bd-status-value {
    color: #00bb54;
}
    
.pm-bd-status-in-progress .pm-bd-status-value {
    color: #ff7c00;
}
    
.pm-bd-status-not-started .pm-bd-status-value {
    color: #36474f;
}
    
@media screen and (max-width: 767px) {
    .pm-bd-status-in-progress {
        margin: 0 5px;
    }
        
    .pm-bd-key-center {
        width: 316px;
    }
}