@import (reference) "_pm-global.less";

.pm-filter-boxes {

    .col-xs-3 {
        padding: 0 3px;
    }

    .pm-filter-box {
        color: #797979;
        padding:0;
        padding-bottom: 3px;
        text-align: center;
        cursor: pointer;
        margin:0 -3px;
        width: 100%;

        .pm-filter-box-count {
            font-family: 'Montserrat_Bold', sans-serif;
            font-size: 20px;
            margin-left: 8px;
        }
        .pm-filter-box-count-type {
            font-family: 'Montserrat_Regular', sans-serif;
            font-size: 14px;
            margin-left: 5px;
        }
    }

    .pm-icon-sm .pm-icon-font {
        top:4px;
    }

    .pm-filter-box-total {
        opacity: 0.6;
        background-color: @COLOR-PM-BLUE7;
        border-top: 5px solid @COLOR-PM-BLUE7;
        transition: all 0.3s;

        &.active {
            border-color: @COLOR-PM-BLUE3;
            color: @COLOR-PM-BLUE3;
            opacity: 1;
        }
        
        &:hover {
            color: @COLOR-PM-BLUE3;
            opacity: 1;
        }

    }

    .pm-filter-box-not-sent {
        opacity: 0.8;
        background-color: @COLOR-PM-GREY4;
        border-top: 5px solid @COLOR-PM-GREY4;
        transition: all 0.3s;

        &.active {
            border-color: @COLOR-PM-GREY10;
            color: @COLOR-PM-GREY10;
            opacity: 1;
        }
        
        &:hover {
            color: @COLOR-PM-GREY10;
            opacity: 1;
        }
    }

    .pm-filter-box-sent {
        opacity: 0.8;
        background-color: @COLOR-PM-GREEN6;
        border-top: 5px solid @COLOR-PM-GREEN6;
        transition: all 0.3s;

        &.active {
            border-color: @COLOR-PM-GREEN4;
            color: @COLOR-PM-GREEN4;
            opacity: 1;
        }
        
        &:hover {
            color: @COLOR-PM-GREEN4;
            opacity: 1;
        }
    }

    .pm-filter-box-removed {
        opacity: 0.8;
        background-color: @COLOR-PM-RED3;
        border-top: 5px solid @COLOR-PM-RED3;
        transition: all 0.3s;

        &.active {
            border-color: @COLOR-PM-RED1;
            color: @COLOR-PM-RED1;
            opacity: 1;
        }
        
        &:hover {
            color: @COLOR-PM-RED1;
            opacity: 1;
        }
    }
}
