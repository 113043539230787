@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";


/* TODO: Refactor error bos to use Bootstrap alert. */
.tnl-error-box {
    background: none;
    color: @COLOR-DANGER;
    padding: 5px 0;
    font-size: @FONT-SIZE-XSM;
    margin: 4px 0;
    width: 50%;
}
.tnl-error-box2 {
    display: block;
    margin: 10px;
    padding: 0;
}

.alert {
    padding: 20px;
    border-width: 4px;
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-style: solid;
    color: @COLOR-TNL-GRAY1;
    .LESS-ROUNDED-CORNERS-RESET();
    .LESS-BOX-SHADOW-RESET();

    header {
      height: 30px;
      position: relative;
      top: 3px;
      left: 0;
      margin-bottom: 15px;

        h1 {
          margin-top: 0;
        }

        h2 {
          &.pm-warning-title {
            height: 20px;
            position: absolute;
            top: 50%;
            left: 40px;
            margin-top: -10px;
            line-height: initial;
          }
        }

        .icon-font {
          float: left;
          margin: 4px 8px 10px 0;
        }
    }

    h4 {
      font-size: 18px;
      margin-bottom: 10px;
      font-weight: 600;
    }

    p {
      color: @COLOR-COS-DEFAULT;
      font-size: @COS-FONT-SIZE-DEFAULT;
      margin: 0;
    }

    div, p, ul, ol {
      &:last-child {
        margin-bottom: 0;
      }
    }

    .tnl-toolbar {
      &:last-child {
        .btn {
          margin-bottom: 0;
        }
      }
    }

    &.alert-success,
    &.alert-info {
        header {
          .pm-icon-font {
            &:before {
                background: rgba(255, 255, 255, 1) !important;
                border-radius: 50% !important;
                }
            }
        }
    }

    &.alert-success {
      background: @COLOR-PM-GREEN6;
      border-color: @COLOR-PM-GREEN3;

      header {
        .pm-icon-font {
          &:before {
            color: @COLOR-PM-GREEN3;
          }
        }

        p {
          color: @COLOR-COS-DEFAULT;
        }
      }

      .tnl-toolbar-bottom {
        margin-left: 2px;
      }
    }

    &.alert-info {
      background: @COLOR-PM-BLUE7;
      border-color: @COLOR-PM-BLUE1;

      header {
        .icon-font,
        .pm-icon-font {
          color: @COLOR-PM-BLUE1;
        }

        p {
          color: @COLOR-COS-DEFAULT;
        }
      }
    }

    &.alert-default {
      background: @COLOR-TNL-GRAY5;
      border-color: @COLOR-TNL-GRAY2;

      header {
        .icon-font,
        .pm-icon-font {
          color: @COLOR-TNL-GRAY1;
        }

        p {
          color: @COLOR-COS-DEFAULT;
        }
      }
    }

    &.alert-warning {
      background: @COLOR-PS-ORANGE2;
      border-color: @COLOR-PS-ORANGE1;

      header {
        .pm-icon-font {
          color: @COLOR-PS-ORANGE1;
        }
      }

      p {
        color: @COLOR-COS-DEFAULT;
      }
    }

    &.alert-danger {
      background: @COLOR-PM-RED3;
      border-color: @COLOR-PM-RED2;

      header {
        .pm-icon-font {
          &:before {
            position: absolute;
            color: @COLOR-PM-RED2;
            z-index: 5;
          }

          &:after {
              content: "";
              position: relative;
              display: block;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 0 13px 26px 13px;
              border-color: transparent transparent #ffffff transparent;
          }
        }
      }

      p {
        color: @COLOR-COS-DEFAULT;
      }

      .input-validation-error,
      .validation-summary-errors {
        background-color: transparent;
        border: none;
        display: inline;
        padding: 0;
        margin: 0;
        text-align: left;
      }
    }

    &.alert-orange {
        background: @COLOR-PS-ORANGE2;
        border-color: @COLOR-PS-ORANGE1;

        header {
            .pm-icon-font {
                &:before {
                    position: absolute;
                    color: @COLOR-PS-ORANGE1;
                    z-index: 5;
                }

                &:after {
                    content: "";
                    position: relative;
                    display: block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 13px 26px 13px;
                    border-color: transparent transparent #ffffff transparent;
                }
            }
        }
    }

    &.alert-update {
      background: @COLOR-INFO-LT;
      border: 1px solid @COLOR-INFO-DK;
      bottom: 0;
      position: fixed;
      right: 0;
      z-index: 9999;

      header {
        h2,
        .icon-font {
          color: @COLOR-INFO-DK;
          font-weight: bold;
        }
      }

      .close {
        right: -10px;
        top: -4px;
      }

      .input-validation-error,
      .validation-summary-errors {
        background-color: transparent;
        border: none;
        display: inline;
        padding: 0;
        margin: 0;
        text-align: left;
      }
    }

    &.alert-system {
      background: @COLOR-WARNING-LT;
      border: 1px solid @COLOR-WARNING-DK;
      bottom: 0;
      position: fixed;
      right: 0;
      z-index: 9999;

      header {
        h2,
        .icon-font {
          color: @COLOR-WARNING-DK;
          font-weight: bold;
        }
      }

      .input-validation-error,
      .validation-summary-errors {
        background-color: transparent;
        border: none;
        display: inline;
        padding: 0;
        margin: 0;
        text-align: left;
      }
    }

    .alert-header-icon{
      padding-top: 1px;
    }
}

 .alert-success {
   background-image: none;
 }
