@import (reference) "_pm-global.less";

// TRANSFER CREDIT MANAGER STYLES //
// Author: Mike Thomas - March 2019 //

.cos-tcm-wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 20px;
  .cos-tcm-nav {
    padding: 20px;
  }
  .col-tcm-content{
    padding: 20px;
    background: #fff;
  }

  .pm-tcm-field-list-wrapper {
    border: thin solid rgba(220, 220, 220, 1);
    border-radius: 4px;
    margin: 10px 0;
    display: grid;

    .cos-view-custom-field {
      padding: 20px;

      .cos-title {
        display: inline-block;
        float: left;
        margin-bottom: 20px;
        div {
          display: inline-block;
          font-size: 18px;
          font-weight: bold;
          padding-right: 20px;
          &.cos-key-custom {
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .pm-tcm-field-wrapper {
    border: thin solid rgba(220, 220, 220, 1);
    border-radius: 4px;
    margin: 10px 0;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px;
    .cos-drag{
      background: rgba(220, 220, 220, 1);
      height: 100%;
      display: grid;
      align-items: center;
      border-radius: 4px 0 0 4px;
      cursor: pointer;
    }
    .cos-view-custom-field {
      padding: 20px;

      .cos-title {
        display: inline-block;
        float: left;
        margin-bottom:20px;
        div {
          display:inline-block;
          font-size:18px;
          font-weight:bold;
          padding-right: 20px;
        }
      }

      .icon-list {
        display: grid;
        grid-template-columns: auto auto auto auto;
        align-items: center;
        justify-content: end;
        grid-gap: 15px;
        margin-bottom:20px;

        .pm-btn-group {
          margin-right: 0;
        }

        .cos-completed {
          display: inline-block;
        }
        .cos-required {
          display: inline-block;
        }
        .cos-edit {
          display: inline-block;
        }
        .cos-trash-lock {
          display: inline-block;
        }
      }
    }
  }
}

.pm-tcm-editable-field-wrapper {
  border: thin solid @COLOR-PM-LINK;
  border-radius: 4px;
  margin: 10px 0;
  display: grid;
  padding: 0 20px;
  .pm-tcm-field-choice-wrapper {
    border-top: 1px solid #ccc;
    padding: 8px;
    .cos-drag {
      background: #dcdcdc;
      border-radius: 4px;
      width: 28px;
      margin-top: -5px;
      padding-top: 5px;
      
    }
  }
  .edit-bar {
    background-color: @COLOR-PM-LINK;
    display: grid;
    grid-template-columns: 1fr auto;
    margin:0 -20px 15px -20px;
    padding: 5px;
    border-radius: 4px 4px 0 0;
    h3 {
      color: white;
      padding: 10px 0 0 10px;
    }
  }
  .row {
    margin-bottom: 10px;
  }
}

.pm-tcm-field-list-wrapper {
  padding: 8px;
  .cos-key {
    padding-left: 24px;
    position: absolute;
    top: 4px;
  }
  &.pm-tcm-not-active {
    background-color: #f1f1f1;
    .cos-check-right {
      float: right;
    }
    .cos-key-custom {
      display: none!important;
    }

    .form-control[disabled], select[disabled] {
      border: 1px solid @COLOR-PM-GREY11!important;
      background-color: @COLOR-PM-GREY3!important;
    }
  }

}
.cos-tcm-not-active {
  background: #ededed;
  border-radius: 4px;
  .cos-tcm-not-displayed {
    display: none;
    height: 0;
    transition: all 0.5s ease;
  }
}
.cos-tcm-not-displayed {
  transition: all 0.5s ease;
  height: auto;
  display: block;
}

.cos-tcm-footer {
  border-top: thin solid #ccc;
  background: #fff;
  padding: 20px;
  margin-bottom: -30px;
  display: grid;
  justify-content: center;
  grid-template-columns: auto auto;
}

.ex3 .hide3 {
  width: 50%;
  display: table;
  height: 150px; /* any arbitrary height but best at the minimum initial height you would want. */
  overflow: hidden;
  background: red;
  transition: all 0.5s ease;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
}
.inner3 {
  padding: 10px;
}
.ex3 input[type="checkbox"]:checked + .hide3 {
  height: 0;
  opacity: 0;
  display: block;
}

.cos-position-r {
  position: relative;
}
.cos-icon-right {
  width: max-content;
  position: absolute;
  right: 43px;
  top: 6px;
}