@import (reference) "_pm-global.less";

.tnl-history {
    > main {
        > article {
            border-bottom: 1px solid @COLOR-PS-GREY1;
            max-height: 125px; 
            padding: 10px 0;
            overflow-x: auto; 

            .tnl-history-date,
            .tnl-history-user {
                color: @COLOR-PM-GREY11;
                font-size: @COS-FONT-SIZE-10;
            }
            .tnl-history-message {
                display: block;
            }
            &:first-child {
                padding-top: 0;
            }
        }
    }
}
.tnl-pane-minor {
    .tnl-history {
        > main {

            > article {
                .tnl-history-message  {
                    font-size: @COS-FONT-SIZE-12;
                }
            }
        }
    }
}