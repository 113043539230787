@import (reference) "../Theme/Common/_common.less";
/* TODO: Replace tnl-menu with the Bootstrap menu. */
.tnl-menu-div.open {
    position: relative;
}

.tnl-menu-div {
    display: inline-block;
    *display: inline;
    z-index: 1;
    height: 18px;
    text-align: left;
    white-space: nowrap;
}

.tnl-menu-box {
    margin-left: auto;
    min-width: 100%;
    left: 0;
    display: none;
    position: absolute;
    z-index: 100;
}

.tnl-menu-box .tnl-menu ul {
    border: 1px solid @COLOR-TNL-GRAY3;
    .LESS-BOX-SHADOW;
}

.tnl-menu-box .tnl-menu ul a {
    color: @COLOR-TNL-GRAY2;
}

.tnl-menu-box .tnl-menu ul a:hover{
    color: #fff;
    background: @COLOR-TNL-GRAY3;
    text-decoration: none;
}

.tnl-menu-box.left {
    right: 0;
    left: auto;
}

.tnl-menu {
    z-index: 200;
}


.tnl-menu ul {
    display: block;
    margin: 0;
}

.tnl-menu-div > a > .icon-font {
    position: absolute;
    top: 3px;
    left: 3px;
}

.tnl-menu-div > a.tnl-btn-icon-text > .icon-font {
    left: 6px;
}

.tnl-menu-div > a.tnl-menu-btn > .icon-font {
    left: 4px;
}

.tnl-menu-box .icon-font {
    color: @COLOR-TNL-GRAY2;
}

.tnl-menu-div .tnl-btn-text {   
    padding: 2px 10px 3px;
    font-size: @BUTTON-TEXT-SIZE;
    line-height: 17px;
}
.tnl-menu-div > .tnl-btn-icon-text > span.fa-play {
    top: 5px;
    left: 6px;
    font-size: 10px;
}
.tnl-menu-div > .tnl-btn-icon > .icon-font.fa-play {
    top: 5px;
    left: 5px;
}

.tnl-menu-div > .tnl-btn-icon-text > span.fa-stop {
    top: 4px;
    left: 6px;
    font-size: 10px;
}
.tnl-menu-div > .tnl-btn-icon > .icon-font.fa-stop {
    top: 5px;
    left: 5px;
}

.tnl-menu-div .tnl-btn-icon-text, 
.tnl-menu-div a.tnl-btn-icon-text:active, 
.tnl-menu-div a.tnl-btn-icon-text:visited, 
.tnl-menu-div a.tnl-btn-icon-text:hover {
    position: relative;
    margin: 0;
    padding: 3px 10px 3px 25px;
    color: #fff;
    font-size: @BUTTON-TEXT-SIZE;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
}