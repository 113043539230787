@import (less) "bootstrap-datetimepicker.css";

.timepicker{
    .btn {
        display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0px;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.42857;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        cursor: pointer;
        -moz-user-select: none;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 4px;
    }
    a {
        color: #428BCA;
        text-decoration: none;
        background: none;
    }

    .list-unstyled {
        list-style: outside none none;
    }

    .separator{
        border-bottom:transparent;
    }

    .table td a:hover{
        background:none;
        color:black;
    }

    .table td span:hover {
        background: #EEE none repeat scroll 0% 0%;
    }
}