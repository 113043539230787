@import (reference) "_pm-global.less";

.alert {
    &.alert-dismissible {
        color: @COLOR-PM-WHITE1;
        border-top: none;
        padding: 1.1rem 2rem;

        .close {
            right: -11px;

            &:focus {
                outline: none !important;
            }
        }

        &.alert-success {
            background-color: #0f960f;
        }

        &.alert-error {
            background-color: #c90f09;
        }

        &.alert-warning {
            background-color: #ad7f13;
        }

        &.alert-info {
            background-color: #0176ab;
        }

        button {
            color: @COLOR-PM-WHITE1;
            text-shadow: none;
            opacity: .75;

            &:hover {
                color: @COLOR-PM-WHITE1;
                opacity: 1 !important;
                .PM-TRANSITION();
            }
        }

        .pm-icon-sm,
        .pm-icon-md {
            top: 2px;
        }


        &.pm-icon-font [class^="pm-icon-"],
        [class*="pm-icon-"] {
            &:before {
                color: @COLOR-PM-WHITE1;
            }
        }

        .pm-alert-title {
            position: relative;
            bottom: 4px;
            left: 10px;
            font-size: 15px;
            .COS-OPEN-SANS-REGULAR();
        }

        .pm-alert-text {
            font-size: @COS-FONT-SIZE-12;
            margin-left: 5px;
        }

        .pm-alert-title,
        .pm-alert-text {
            color: #fff;
        }
    }
}
