@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

.bold {
    .COS-OPEN-SANS-700();
}

.semibold {
    .COS-OPEN-SANS-600();
}

.pm-bd-badge-col-main {
    width: 70%;
    margin: 0 auto; 
    position: relative;
    left: 50%;
    margin-left: -50%; 
    min-height: 42px;
    text-align: center;
}

.pm-bd-badge-col {
    min-height: 254px;
    width: 160px;
    margin: 10px 10px;
    background-color: #fffeff;
    box-shadow: 1px 2px 4px rgba(0,0,0,.4);
    color: #555;
}

.pm-bd-badge-count-container {
    min-height: 254px; 
    margin: 10px 0;
}

.pm-bd-badge-count-col {
    min-height: 254px;
    margin: 10px 0;
    text-align: center;  
}

.pm-bd-badge-count-wrap {
    position: absolute; 
    top: 50%; 
    margin-top: -50px; 
    width: 65%; 
    float: left;
}

.pm-bd-badge-count-wrap span {
    display: block;
    width: 90%;
    text-align: center; 
    padding: 0 5px;
}

.pm-bd-badge-count {
    font-size: 40px; 
    font-weight: 600; 
    color: #36474f;
}

.pm-bd-badge-wrap {
    margin: 0 -10px;
    min-width: 160px;
}

.pm-bd-down-icon {
    position: absolute; 
    top: 50%; 
    margin-top: -20px; 
    right: 0; 
    width: 35%; 
    float: right;
}

.pm-bd-down-icon span {
    display: inline-block;
    width: 12px;
    padding: 0 5px 0 15px;
    color: #ACB0B4;
    font-size: 22px;
}

.pm-bd-badge-img {
    height: 160px; 
    background: #ccc; 
    text-align: center;
}

.pm-bd-badge-img img {
    border: 2px solid #c5c5c5;
    box-sizing: border-box;
    height: 160px;
    width: 160px;
}

.pm-bd-badge-name-wrap {
    position: relative;
    top: 15px;
    text-align: center;
}

.pm-bd-badge-name {
    height: 60px; 
    background: #fff; 
    text-align: center;
    color: #36474f;
}

.pm-bd-badge-name a {
    color: #2470ee;
}

.pm-bd-badge-name span {
    display: block;
}

.pm-bd-badge-progress-graph {
    .COS-OPEN-SANS-REGULAR();
    height: 24px;
    line-height: 24px;
    background-color: #2470ee;
    color: #fff;
    font-size: 14px;
    text-indent: 3px;
}

.pm-bd-badge-progress {
    position: relative;
    height: 24px;
    background: #e5e5e5; 
}

.pm-bd-badge-progress:after {
    content: "";
    height: 24px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgba(0,0,0,.1);
    border-bottom: 3px solid rgba(0,0,0,.14);
    background: -moz-linear-gradient(top,rgba(255,255,255,0) 0,rgba(0,2,34,.06) 100%);
    background: -webkit-linear-gradient(top,rgba(255,255,255,0) 0,rgba(0,2,34,.06) 100%);
    background: linear-gradient(to bottom,rgba(255,255,255,0) 0,rgba(0,2,34,.06) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#0f000222', GradientType=0);
}

/* Tablets and Greater */
@media screen
and (max-width : 1196px)
and (min-width : 763px) {
    .pm-bd-badge-count-container {
        width: 100% !important;
        min-height: initial;
        margin: 0;
        padding: 0;
    }
    
    .pm-bd-badge-col-main {
        width: 100%;
    }
    
    .pm-bd-badge-count-col {
        min-height: 100px !important;
    }
    
    .pm-bd-badge-count-wrap {
        position: initial;
        top: 0;
        margin-top: 0;
        width: 100%;
        float: none;
    }
    
    .pm-bd-badge-count-wrap span {
        width: 100%;
        padding: 0;
    }
    
    .pm-bd-down-icon {
        position: initial;
        padding: 20px 0 0 0;
        width: 100%;
        float: none;
        text-align: center;
    }
    
    .pm-bd-down-icon span {
        padding: 0;
    }
    
    .pm-bd-down-icon i {
        transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
    }
}

/* Tablets - Portrait */
@media screen
and (max-width : 991px)
and (min-width : 762px)
and (orientation : portrait) {
    .pm-bd-badge-col-main {
        width: 100%;
        padding: 0;
    }
    
    .pm-bd-badge-row-main {
        width: 680px !important;
        margin: 0 auto !important;
    }

    .pm-bd-badge-col {
        margin: 5px !important;
    }
    
    .pm-bd-badge-count-col {
        min-height: 100px !important;
    }
    
    .pm-bd-badge-count-wrap {
        position: initial;
        top: 0;
        margin-top: 0;
        width: 100%;
        float: none;
    }
    
    .pm-bd-badge-count-wrap span {
        width: 100%;
        padding: 0;
    }
    
    .pm-bd-down-icon {
        position: initial;
        padding: 20px 0 0 0;
        width: 100%;
        float: none;
        text-align: center;
    }
    
    .pm-bd-down-icon span {
        padding: 0;
    }
    
    .pm-bd-down-icon i {
        transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
    }
}

/* Handhelds */
@media screen 
and (max-width : 761px)
and (min-width : 480px) {
    .pm-bd-badge-count-container {
        min-height: initial;
        margin: 0 0 40px;
    }
    
    .pm-bd-badge-col-main {
        width: 100%;
    }
    
    .pm-bd-badge-row-main {
        width: 88.5%;
        margin: 0 auto;
    }
    
    .pm-bd-badge-col {
        margin: 5px;
    }
    
    .pm-bd-badge-count-col {
        min-height: 100px !important;
    }
    
    .pm-bd-badge-count-wrap {
        position: initial;
        width: 100%;
        top: 0;
        float: initial;
        margin: 0 auto;    
    }
    
    .pm-bd-badge-count-wrap span {
        width: 100%;
        padding: 0;
    }

    .pm-bd-down-icon {
        position: initial;
        margin-top: 0;
        width: 100%;
        float: left;
        text-align: center;
        transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
    }
}

@media screen 
and (max-width : 761px)
and (min-width : 300px)
and (orientation : portrait) {
    .pm-bd-badge-col-main {
        width: 100%;
    }
    
    .pm-bd-badge-row-main {
        width: 61.1%;
        margin: 0 auto;
    }
    
    .pm-bd-badge-count-col {
        min-height: 100px !important;
    }
    
    .pm-bd-badge-count-wrap {
        position: initial;
        width: 100%;
        top: 0;
        float: initial;
        margin: 0 auto;    
    }
    
    .pm-bd-badge-count-wrap span {
        width: 100%;
        padding: 0;
    }

    .pm-bd-down-icon {
        position: initial;
        margin-top: 0;
        width: 100%;
        float: left;
        text-align: center;
        transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
    }
}