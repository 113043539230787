@import (reference) "_pm-global.less";

// ACTION BUTTONS - ICON ONLY //
// Author: Chris Brosky - June 2017 //

// LESS VARIABLES //
@color-hover:  @COLOR-PM-LINK-H;
@color-active:  @COLOR-PM-LINK-A;
@color-open:    @COLOR-PM-GREY9;

.pm-btn-group {
    margin-right: 15px;
    vertical-align: baseline;

    &.pm-dropdown-btn-default,
    &.pm-dropdown-btn-sm {

        .dropdown-toggle {
          .pm-action-btn-text {
            position: relative;
            top: -5px;
            padding-left: 5px;
            color: #fff;
          }

          .pm-icon-sm .pm-icon-font {
            color: #fff;
          }
        }

        .dropdown-toggle[disabled] {
          .PM-DISABLED-BUTTON();
        }

        &.open {
          .dropdown-toggle {
              &.pm-action-btn {
                .pm-icon-font {
                  color: @color-active;
                }
              }

              &.btn-default {
                background-color: @COLOR-PM-GREEN1 !important;
                color: @COLOR-PM-WHITE1;
                .PM-TRANSITION();

                &:hover {
                  background-color: @COLOR-PM-GREEN1;
                }

                &.pm-dropdown-outline {
                  background-color: @COLOR-PM-WHITE1 !important;
                  color: @COLOR-PM-GREEN1;
                  border: 2px solid @COLOR-PM-GREEN1;
                  padding: 2px 12px 1px !important;
                  .PM-TRANSITION();

                    .pm-action-btn-text {
                        color: @COLOR-PM-GREEN1;
                    }

                    .pm-icon-sm {
                        .pm-icon-font {
                            color: @COLOR-PM-GREEN1
                        }
                    }

                  &:hover {
                    background-color: @COLOR-PM-WHITE1;
                    border: 2px solid @COLOR-PM-GREEN1;
                    .PM-TRANSITION();

                    .pm-action-btn-text {
                      color: @COLOR-PM-GREEN1;
                    }

                    .pm-icon-sm {
                      .pm-icon-font {
                        color: @COLOR-PM-GREEN1;
                      }
                    }
                  }
                }
              }
            }

            .pm-action-dropdown {
                li {
                    a {
                        padding-right: 10px;
                        margin-right: 0 !important;
                        width: initial !important;

                        .pm-icon-sm {
                          height: 30px;
                          width: 30px;
                          margin-right: 2px;

                          .pm-icon-font {
                            height: 30px;
                            width: 30px;
                            display: inline-block;
                          }
                        }

                        &:active {
                          &:before {
                              color: @color-active;
                              background-color: @color-active;
                          }
                        }
                    }
                }
            }
        }

        .btn {
            &.btn-default {
              padding: 4px 12px 3px;
              background-color: @COLOR-PM-LINK;
              .PM-TRANSITION();

              &:hover {
                background-color: @color-hover;
                .PM-TRANSITION();
              }

              &.pm-dropdown-outline {
                background-color: @COLOR-PM-WHITE1;
                border: 2px solid @COLOR-PM-LINK; /* #0066A5 */
                padding: 2px 12px 1px !important;
                .PM-TRANSITION();

                  .pm-action-btn-text,
                  .pm-icon-font {
                      color: @COLOR-PM-LINK;
                  }

                &:hover {
                  background-color: @COLOR-PM-WHITE1;
                  border: 2px solid @COLOR-PM-LINK-H; /* #00427C */
                  .PM-TRANSITION();

                  .pm-action-btn-text {
                    color: @COLOR-PM-LINK-H;
                  }

                  .pm-icon-sm {
                    .pm-icon-font {
                      color: @COLOR-PM-LINK-H;
                    }
                  }
                }
              }
            }

            .pm-icon-sm {
                .pm-icon-font {
                    top: 1px;
                    left: -6px;
                    font-size: 22px;

                    &.pm-icon-ui-collapse {
                        left: 0;
                    }
                }
            }
        }

        .pm-action-dropdown {
            border-radius: 4px;
            margin-top: 8px;

            .pm-icon-sm {
                margin-right: 15px;
                height: 3rem;
                height: 30px;

                .pm-icon-font {
                    top: 1px;

                    &:before {
                        font-size: 30px;
                    }
                }
            }

            .pm-action-btn-text {
                position: relative;
                top: 0;
                display: inline-block;
                padding: 6px 5px !important;
            }

            .divider {
                padding-bottom: 0;
            }

            a .pm-icon-font {
                padding-left: 0 !important;
                position: relative;
                top: 4px;
            }

            &:before, &:after {
                content: '';
                position: absolute;
                display: inline-block;
            }

            &:before {
                top: -7px;
                right: 1.5rem;
                border-right: 7px solid transparent;
                border-bottom: 7px solid #999;
                border-left: 7px solid transparent;
            }

            &:after {
                top: -6px;
                right: 1.6rem;
                border-right: 6px solid transparent;
                border-bottom: 6px solid #fff;
                border-left: 6px solid transparent;
            }

            li {
                a {
                    color: @COLOR-COS-DEFAULT;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 0;
                        margin-top: -15px;
                        width: 3px;
                        height: 30px;
                        border-radius: 0 1px 1px 0;
                        background-color: transparent;
                        .PM-TRANSITION();
                    }

                    &:hover {
                        background-image: none !important;
                        background-color: transparent;
                        color: @color-hover;
                        .PM-TRANSITION();

                        &:before {
                            color: @color-hover;
                            background-color: @color-hover;
                            .PM-TRANSITION();
                        }
                    }

                    &:active {
                        color:@color-active;
                        .PM-TRANSITION(@prop: all, @time: .1s, @ease: linear);
                    }

                    &:focus {
                        outline: none;
                        background-image: none;
                        background-color: transparent;
                    }
                }
            }
        }
    }

    &.pm-dropdown-btn-sm {
      button,
      .btn.btn-default {
        padding: 2px 12px 1px !important;
      }
    }
}
