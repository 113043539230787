@import (reference) "../Theme/Common/_common.less";
@import (reference) "../Css/_pm-global.less";

.pm-site-nav {
    border: none;
    box-shadow: none;
    background-image: none;
    border-radius: 0;
    margin-bottom: 0;

    .pm-site-nav-container {
        background-color: @COLOR-PM-GREY1;
        min-height: 6rem;
        padding-left: 2.5rem;
    }

    .navbar-collapse {
        padding-left: 0;
        border: none;
    }

    .nav-user {
        text-align: right;
        position: relative;
        border: 1px solid transparent; // When not-masquerading
    }

    .navbar-nav {

        > li:hover {
            background-color: @COLOR-PM-LINK;
        }

        > li > a,
        > li > a:hover,
        > li > a:focus {
            color: #fff;
            font-size: 1.4rem;
            font-family: 'Montserrat', sans-serif;
            text-shadow: none;
            }

        .active {

            > a:link,
            > a:hover,
            > a:active,
            > a:visited {
                .COS-OPEN-SANS-700();
                .LESS-BOX-SHADOW-RESET();
                background: none;
                color: @COLOR-PM-WHITE1;
            }
        }
    }

    .navbar-toggle {
        float: none;
        border: none;
        margin-left: 10px;
    }

    .pm-navbar-nav {
        float: none !important;
        padding-top: 0;
    }

    .navbar-toggle:hover,
    .navbar-toggle:focus {
        background: none;
    }

    .pm-user-menu-name {
        display: inline-block;
        position: relative;
        top: 10px;
        max-width: 13rem;
        .LESS-ELLIPSIS-ONE-LINE;
    }

    .divider {
        padding: 0;
    }
}

.navbar-toggle .icon-bar {
    background-color: @COLOR-TNL-GRAY2;
}

.container > .navbar-header,
.container-fluid > .navbar-header,   
.container > .navbar-collapse,
.container-fluid > .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
}

.overflow-nav {

    > .dropdown-toggle {
        .icon-font {
            font-size: @FONT-SIZE-LG;
        }
    }
}

@media (min-width: 768px) {
    .navbar-nav>li>a {
        padding: 20px 10px;
    }
}

@media (max-width: 768px) {

    .pm-site-nav {
        min-height: 0;

        .pm-site-nav-container {
            min-height: 0;
        }
    }
}

@media (max-width: 768px) {
    .navbar-toggle {
        display: block!important;
    }
    .navbar-header {
        float:inherit!important;
    }
    .navbar-nav>li {
        float: none!important;
    }
    .hidden-sm {
        display:block!important;
    }
}