@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

//
// PM NEW STYLES - cbrosky Feb 2017 //
//
.pm-module-programs,
.pm-module-badging,
.pm-module-professional-development {
  .pm-nav-tabs {
    li {
      &:active {
        a,
        a:link,
        a:active {
          border-top-color: @COLOR-PM-BLUE1 !important;
        }
      }
    }
  }
}

.pm-nav-tabs {
  margin: 0 !important;
  border-bottom: 0 !important;
  padding-left: 38px;
  padding-top: 15px;

  li {
    padding-bottom: 0 !important;
    height: 45px;
    .PM-TRANSITION();

    a {
      bottom: -5px;
      background-color: @COLOR-PM-GREY4;
      border-top: 0 solid transparent;
      border-right: 1px solid rgba(232, 232, 232, 0.35);
      border-bottom: 0 solid transparent;
      border-left: 1px solid rgba(255, 255, 255, 0.35);
      box-shadow: 0 1px 0 0 @COLOR-PM-GREY4, 0 -1px 2px #d8d8d8;
      margin-right: 1px;
      color: @COLOR-COS-DEFAULT;

      &:hover {
        background-color: @COLOR-PM-WHITE1;
        bottom: -5px;
        border-right: 1px solid #ccc;
        border-color: transparent rgba(204, 204, 204, 0.36) transparent transparent;
      }
    }

    &.active {
      .PM-TRANSITION();
      a,
      a:link,
      a:active {
        margin-top: -1px;
        border-top-width: 6px;
        border-top-style: solid;
        border-radius: 4px 4px 0 0;
        overflow: hidden;
        border-left: none;
        border-right: none;
        bottom: 0;
        box-shadow: 0 -6px 0 -7px #d8d8d8, 0 0 0 0 #fff, 5px 0px 5px -3px rgba(78,90,105,.25), -8px 1px 3px -5px rgba(31,73,125,.08);
        -webkit-box-shadow: 0 -6px 0 -7px #d8d8d8, 0 0 0 0 #fff, 5px 0px 5px -3px rgba(78,90,105,.25), -8px 1px 3px -5px rgba(31,73,125,.08);
        -moz-box-shadow: 0 -6px 0 -7px #d8d8d8, 0 0 0 0 #fff, 5px 0px 5px -3px rgba(78,90,105,.25), -8px 1px 3px -5px rgba(31,73,125,.08);
        z-index: 3 !important;
        color: @COLOR-COS-DEFAULT;
        .COS-OPEN-SANS-600();
      }

      &:first-child > a {
        box-shadow: 0 -6px 0 -6px #d8d8d8, 0 0 0 0 #fff, 4px -2px 5px -2px rgba(78,90,105,.22), -5px 1px 3px -3px rgba(31,73,125,.08);
        -webkit-box-shadow: 0 -6px 0 -6px #d8d8d8, 0 0 0 0 #fff, 4px -2px 5px -2px rgba(78,90,105,.22), -5px 1px 3px -3px rgba(31,73,125,.08);
        -moz-box-shadow: 0 -6px 0 -6px #d8d8d8, 0 0 0 0 #fff, 4px -2px 5px -2px rgba(78,90,105,.22), -5px 1px 3px -3px rgba(31,73,125,.08);
      }
    }
  }

  .pm-ajax-nav-tab-item {
    &.active {
     a {
         border-top-color: @COLOR-PS-BLUE4 !important;
     }
    }
  }
}

.pm-navbar-no-container {
    margin: 0 25px;
}

.pm-calibration {
    .pm-tab-container {
        padding: 0;
        background-color: transparent;
    }
}

// RESPONSIVE STYLES - cbrosky Feb 2017 //
.pm-tab-container {
  padding: 10px 40px;
  background-color: @COLOR-PM-WHITE1;
}

@media screen and (max-width: 767px) {
  .pm-tab-container {
      margin: 0 0 40px;
      padding: 0 5px;
  }

  .pm-nav-tabs {
    .PM-ROUNDED-CORNERS() !important;
    overflow: hidden !important;
    box-shadow: 0 0 3px 1px rgba(31,73,125,.2);
    margin: 0 0  15px 0 !important;
    padding: 0 !important;
    background: @COLOR-PM-WHITE1;

    li {
      width: 100%;
      height: auto !important;
      border-top: 0 solid transparent;
      border-bottom: 1px solid transparent;
      margin-bottom: -1px;
      padding-bottom: 1px!important;

      &.active {
        a {
          background-color: @COLOR-PM-BLUE3 !important;
          margin-top: 0;

          &:link {
            margin-top: 0;
          }
        }

        &:hover {
          a {
            background-color: @COLOR-PM-LINK !important;
          }
        }

        a,
        a:focus {
          color: @COLOR-PM-WHITE1 !important;
        }
      }

      a {
        border: none !important;
        border-radius: 0 !important;
        box-shadow: none !important;
        overflow: hidden;
        color: @COLOR-COS-DEFAULT;
        bottom: 0 !important;
        background-color: @COLOR-PM-WHITE1 !important;
        margin-bottom: 0 !important;
        margin-right: 0 !important;

        &:hover {
          color: @COLOR-PM-WHITE1;
          background-color: @COLOR-PM-LINK-H !important;
        }
      }
    }
  }

  // PD Tabs //
  // THESE WILL OVERWRITE navbar.less default :active style //
  .pm-module-programs,
  .pm-module-badging,
  .pm-module-professional-development {
    .nav-tabs.pm-nav-tabs {
      li {
        &.pm-ajax-nav-mobile {

          a:hover {
            background: @COLOR-PM-BLUE1 !important;
          }

          &.active {
            a,
            a:focus {
              background: @COLOR-PM-BLUE1 !important;
            }
          }
        }
      }
    }
  }

  .pm-nav-tabs > li:first-child,
  .pm-nav-tabs > li.active:first-child {
      border-radius: 4px 4px 0 0;
  }

  .pm-nav-tabs > li:first-child,
  .pm-nav-tabs > li.active:first-child,
  .pm-nav-tabs > li:last-child,
  .pm-nav-tabs > li.active:last-child,
  .pm-nav-tabs > li:last-child > a,
  .pm-nav-tabs > li.active:last-child > a {
    box-shadow: none !important;
    overflow: hidden;
  }

  .pm-nav-tabs > li:last-child,
  .pm-nav-tabs > li.active:last-child {
      border-radius: 0 0 4px 4px;
  }

  .pm-nav-tabs > li:last-child,
  .pm-nav-tabs > li.active:last-child,
  .pm-nav-tabs > li:last-child > a,
  .pm-nav-tabs > li.active:last-child > a {
    border-bottom: 0 !important;
  }
}
