@import (reference) "_pm-global.less";

// OBSERVATION COMPETENCY STYLES //
// Author: Chris Brosky - June 2019 //

.cos-obs-comp-col-title {
  margin-bottom: 10px;
}

.cos-obs-comp-modal {
  .cos-obs-comp-modal-wrapper {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px 10px;
  }
}

.cos-obs-comp-wrapper {
  border:1px solid @COLOR-PS-GREY1;
  border-radius: 4px;
  padding-top: 10px;
  margin-bottom: 10px;

  .cos-col-xs-12 {
    padding: 0 0 10px 20px;

    .cos-col-row {
      margin-right: 10px;
    }
  }

  .cos-icon-col {
    margin: 0 5px;
    width: 28px;
    height: 28px;

    &.cos-obs-count-box {
      position: relative;
      top: 3px;
      display: inline-block;
      width: 28px;
      height: 28px;
      text-align: center;
    }

    &.cos-add-comp {
      position: relative;
      top: 3px;
      display: inline-block;
      min-width: 100px;
    }

    &.cos-obs-comp-lock-icon {
      position: relative;
      top: 3px;
      float: right;

      .pm-icon-sm {
        position: absolute;
        width: 28px;
        height: 28px;
        top: 0;
        left: 0;

        span {
          position: absolute;
          left: 50%;
          top: 50%;
          margin: -1.25rem 0 0 -1.15rem;
          font-size: 23px;
          color: @COLOR-PS-ORANGE3;
        }
      }
    }
  }

  .cos-obs-comp-count {
    position: relative;
    height: 34px;
    line-height: 34px;

    a {
      .tnl-menu-text {
        .COS-OPEN-SANS-600();
      }
    }
  }

  .cos-obs-comp-delete-icon {
    position: relative;
    top: 3px;
    height: 28px;
    width: 28px;
    display: block;

    .pm-icon-font {
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -1.25rem 0 0 -1.15rem;
      font-size: 23px;
    }
  }

  .cos-obs-comp-add-icon {
    display: inline-block;
    height: 24px;

    .pm-icon-font {
      font-size: 23px;
    }

    .tnl-menu-text {
      position: relative;
      top: -5px;
      font-size: 14px;
    }
  }

  .cos-obs-comp-input-col {
    padding-left: 0;
  }

  .form-group {
    margin-bottom: 0;

    > div .row:first-child {
      margin: 0 0 -10px -10px;
    }
  }

  .cos-obs-comp-row {
    background-color: #F5F5F5;
    border: 1px solid #ccc;
    padding: 5px;
    margin: 0 0 10px;
    .PM-ROUNDED-CORNERS();
  }

  .cos-obs-comp-group {
    .cos-obs-comp-row {
      padding: 5px 0 5px 5px;
    }
  }

  .cos-form-autosave {
    margin-left: 10px;

    button {
      margin: 5px 0 10px;
    }
  }

  /* --== FLAT COMPETENCY STYLES ==-- */
  &.cos-obs-comp-flat {
  }

  /* --== TREE COMPETENCY STYLES ==-- */
  &.cos-obs-comp-tree {

    .cos-obs-comp-delete-icon {
      float: right;

      &.parent {
        margin-right: 21px;
      }
    }

    .cos-obs-comp-parent-options {
      width: 160px;
      height: 34px;
      float: right;
      margin-right: -40px;

      .cos-obs-parent-icons {
        display: inline-block;
        margin: 0 73px 0 4px;

        > div {
          padding: 0;
        }
      }
    }

    .cos-gac-group-wrapper {
      border: 1px solid @COLOR-PS-GREY1;
      border-radius: 3px;
      padding: 10px 0 8px;
      display: flow-root;
      margin-bottom: 15px;

      &::after {
        content: "";
        display: block;
        clear: both;
      }
    }

    .cos-obs-comp-container {
      margin-bottom: 5px;

      .cos-obs-item-wrapper {
        margin-left: 40px;
        padding: 5px 5px 1px;
        margin-bottom: 10px;
        margin-top: 5px;
        border: 1px solid @COLOR-PS-GREY1;
        border-radius: 3px;
        background: #f9f9f9;

        .cos-indent-row {
          position: relative;
          border: 1px solid @COLOR-PS-GREY1;
          padding: 4px;
          border-radius: 4px;
          background: #f5f5f5;
          margin: 0 0 6px 0;

          &:before {
            content: "";
            position: absolute;
            left: -28px;
            width: 17px;
            height: 17px;
            background-color: transparent;
            border-width: 0 0 1px 1px;
            border-color: #c5c5c5;
            border-style: solid;
            box-sizing: border-box;
          }

          .cos-obs-comp-options {
            position: relative;
            width: 550px;
            height: 34px;
            float: right;

            .cos-obs-comp-option-icon {
              min-width: 160px;
              height: 34px;
              float: left;
              margin: 0 5px;
            }
          }

          .form-control {
            border: 1px solid @COLOR-PS-GREY1 !important;
          }
        }
        &.cos-obs-item-hide {
          background-color: transparent!important;
          border: none!important;
        }
      }

      .cos-obs-comp-input-col {
        margin-left: 20px;
        width: ~"calc(58.33333333% - 2px)";
      }

      .cos-obs-comp-row {
        .row {
          margin-bottom: 0;
        }
      }

      .cos-obs-item-row {
        margin-left: 40px;
      }

      .cos-tree-form-group {
        padding-left: 0;

        .cos-obs-comp-input-col {
          margin-left: 0;
          width: ~"calc(100% - 41px)";
        }
      }
    }

    .cos-obs-comp-input {
      &.cos-lvl-one,
      &.cos-lvl-two {
        .COS-OPEN-SANS-600();
        font-size: @COS-FONT-SIZE-16;
      }
    }

    .cos-obs-count {
      display: block;
      width: 26px;
      height: 26px;
      line-height: 26px;

      a {
        .tnl-menu-text {
          .COS-OPEN-SANS-600();
        }
      }
    }

    .cos-obs-comp-add-icon {
      position: relative;
      top: 2px;
    }

    .cos-obs-comp-add-set-btn {
      margin-top: 10px;
    }

    .form-group {
      .cos-col-xs-8 {
        width: ~"calc(66.66666667% - 45px)";
        height: 34px;
      }
    }

  }
}

.cos-obs-comp-title-row {
  .cos-obs-comp-title-wrap {
    margin-left: 40px;

    .cos-obs-comp-title-text {
      width: 550px;
      float: right;
      margin-right: 40px;

      .cos-col-header {
        display: inline-block;
        height: 40px;
        width: 160px;
        max-width: 160px;
        vertical-align: bottom;
      }
    }
  }
}
