@import (reference) "_pm-global.less";

// SPLIT BUTTON - WITH ICONS //
// Author: Chris Brosky - June 2017 //

// LESS VARIABLES //
@color-hover:  @COLOR-PM-LINK-H;
@color-active:  @COLOR-PM-LINK-A;
@color-open:    @COLOR-PM-GREY9;

.pm-btn-group {
    margin-right: 15px;
    vertical-align: baseline;

    &.pm-split-action-btn {

        .pm-action-btn {
            box-shadow: none !important;
            background: none !important;
            border: none !important;
            padding: 0;
            margin: 0 5px 0 0;
            .PM-TRANSITION();

            &:first-child {
                margin-left: 0;
            }

            .pm-action-btn-label {
                height: 30px;
                display: inline-block;

                .pm-action-btn-text {
                    display: inline-block;
                    position: relative;
                    top: -9px;
                    vertical-align: middle;
                    padding: 5px 0;
                    color: @COLOR-PM-LINK;
                    .PM-TRANSITION();
                }
            }

            &:hover {
                color: @color-hover;
                .PM-TRANSITION();

                .pm-action-btn-text {
                    color: @color-hover;
                    .PM-TRANSITION();
                }

                .pm-icon-font {
                    &.pm-icon-ui-collapse {
                        color: @color-hover !important;
                        .PM-TRANSITION();
                    }
                }
            }

            &:active {
                color: @color-active;
                .PM-TRANSITION();

                .pm-action-btn-text {
                    color: @color-active;
                    .PM-TRANSITION();
                }

                .pm-icon-font {
                    &.pm-icon-ui-collapse {
                        color: @color-active !important;
                        .PM-TRANSITION();
                    }
                }
            }

            &:focus {
                outline: none;
            }

            .pm-icon-sm {
                margin-right: 5px !important;
                height: 3rem;
                width: 3rem;
                top: 2px;

                .pm-icon-font {
                    &:before {
                        font-size: 30px;
                    }
                }
            }
        }

        a {
            display: inline-block;
            height: 3rem;
            width: 3rem;
            margin-right: 12px !important;

            .pm-icon-sm {
                .pm-icon-font {
                    color: @COLOR-COS-DEFAULT;
                    .PM-TRANSITION();
                }
            }

            &:link {
                color: @COLOR-COS-DEFAULT;
            }

            &:hover {
                color: @color-hover;
                .PM-TRANSITION();

                .pm-icon-sm {
                    .pm-icon-font {
                        color: @color-hover;
                        .PM-TRANSITION();
                    }
                }
            }

            &:active {
                color: @color-active;
                .PM-TRANSITION();

                .pm-icon-sm {
                    .pm-icon-font {
                        color: @color-active;
                        .PM-TRANSITION(@prop: all, @time: .1s, @ease: linear);
                    }
                }
            }

            &:focus {
                outline: none;
            }
        }

        .pm-action-btn[disabled] {
          .pm-action-btn-label {
            .pm-action-btn-text {
              color: #767676 !important;

              &:hover {
                color: #767676 !important;
              }
            }
            .pm-icon-font {
              color: #767676 !important;

              &:hover {
                color: #767676 !important;
              }
            }
          }
        }

        .pm-action-dropdown {
            border-radius: 4px;
            margin-top: 4px;

            .pm-icon-sm {
                margin-right: 5px !important;
                height: 30px;
                width: 30px;

                .pm-icon-font {
                    top: 1px;
                    display: inline-block;
                    height: 30px;
                    width: 30px;

                    &:before {
                        font-size: 30px;
                    }
                }
            }

            .pm-action-btn-text {
                position: relative;
                top: -6px;
            }

            a .pm-icon-font {
                padding-left: 0 !important;
                position: relative;
                top: 4px;
            }

            &:before, &:after {
                content: '';
                position: absolute;
                display: inline-block;
            }

            &:before {
                top: -7px;
                left: 8rem;
                border-right: 7px solid transparent;
                border-bottom: 7px solid #999;
                border-left: 7px solid transparent;
            }

            &:after {
                top: -6px;
                left: 8.1rem;
                border-right: 6px solid transparent;
                border-bottom: 6px solid #ffffff;
                border-left: 6px solid transparent;
            }

            li {
                a {
                    color: @COLOR-COS-DEFAULT;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 0;
                        margin-top: -15px;
                        width: 3px;
                        height: 30px;
                        border-radius: 0 1px 1px 0;
                        background-color: transparent;
                        .PM-TRANSITION();
                    }

                    &:hover {
                        background-image: none !important;
                        background-color: transparent;
                        color: @color-hover;
                        .PM-TRANSITION();

                        &:before {
                            color: @color-hover;
                            background-color: @color-hover;
                            .PM-TRANSITION();
                        }
                    }

                    &:active {
                        color: @color-active;
                        .PM-TRANSITION(@prop: all, @time: .1s, @ease: linear);
                    }

                    &:focus {
                        outline: none;
                        background-image: none;
                        background-color: transparent;
                    }
                }
            }
        }

        .dropdown-toggle {
            &.pm-action-btn {
                .pm-icon-font {
                    top: 3px;
                    .PM-TRANSITION();

                    &:before {
                        font-size: 24px !important;
                    }
                }
            }

            .pm-action-btn-text {
                position: relative;
                top: -5px;
                padding-left: 5px;
            }
        }

        &.open {
            .dropdown-toggle {
                &.pm-action-btn {
                    .pm-icon-font {
                        color: @color-open;
                    }
                }
            }

            .pm-action-btn {
                &.dropdown-toggle {
                    .pm-action-btn-text {
                        color:  @color-open;
                    }

                    .pm-icon-font {
                        &.pm-icon-ui-collapse {
                            color: @color-open !important;
                        }
                    }
                }
            }

            .pm-action-dropdown {
                padding-right: 5px;

                li {
                    a {
                        overflow: visible;
                        width: 100%;
                        display: block;
                        margin-right: 0 !important;

                        &:active {
                            &:before {
                                color: @color-active;
                                background-color: @color-active;
                            }
                        }

                        .pm-action-btn-text {
                            display: inline;
                            width: 100%;
                            padding: 6px 5px;
                        }
                    }
                }
            }
        }

        &.open {
          .pm-action-dropdown {
            padding-right: 10px;

            &.dropdown-menu-right {
              &:before {
                right: 2rem;
                left: auto;
              }

              &:after {
                right: 2.1rem;
                left: auto;
              }

              li {
                a {
                  display: inline-block;
                  padding-right: 0;
                  max-width: none;
                }
              }
            }
          }
        }
    }
}
