@import (reference) "_pm-global.less";
// Chris Brosky - 4/18/2018 //

.cos-ad-hoc-page {

    .pm-section-content {
        padding: 0 10px !important;
    }

    .cos-adhoc-report-name {
        display: block;
        height: 37px;
        line-height: 36px;
        font-size: 16px;
        .COS-OPEN-SANS-600();
    }

    .cos-adhoc-description-name {
        display: block;
    }

    .cos-recent-reports-row {
        .cos-recent-reports-col {
            margin-top: 0;
        }
    }

    .cos-new-report-row {
        padding: 15px 10px;

        .cos-adhoc-report-name {
            height: auto;
            line-height: initial;
        }

        .pm-tab-container {
            padding: 0 40px !important;

            table {
                thead > tr > th,
                tbody > tr > td {
                    &:first-child {
                        width: 70px;
                    }
                }

                tbody > tr > td {
                    padding: 3px 10px 1px !important;

                    a {
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .cos-view-recent-reports-row {
        padding: 10px 0;

        .cos-view-icon {
            position: relative;
            height: 37px;
            padding-left: 19px;

            .pm-icon-action-btns {
                position: absolute;
                top: 50%;
                margin-top: -16px;
                width: 30px;
                height: 30px;
            }
        }

        .pm-icon-md [class*=" pm-icon-"],
        .pm-icon-md.pm-icon-font [class^=pm-icon-] {
            top: 50%;
            margin-top: -14px;
        }
    }

    .cos-new-report-row,
    .cos-view-recent-reports-row {
        border-bottom: 1px solid @COLOR-PM-GREY5;

        &:nth-child(odd) {
            background-color: @COLOR-PM-WHITE1;
        }

        &:nth-child(even) {
            background-color: @COLOR-PM-GREY4;
        }
    }

    /* RESPONSIVE STYLES */
    @media (min-width: 768px) and (max-width: 1700px) {
        .cos-adhoc-report-name,
        .cos-adhoc-description-name {
            padding-left: 40px;
        }
    }

    @media (max-width: 768px) {
        .cos-adhoc-report-name {
            display: block;
            margin-top: 10px;
        }

        .cos-view-recent-reports-row {
            .cos-adhoc-report-name {
                height: auto;
                line-height: 18px;
                padding-left: 10px;
            }
        }

        .cos-new-report-row {
            .pm-tab-container {
                padding: 0 !important;
            }
        }
    }
}
