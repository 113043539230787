@import (reference) "../Theme/Common/_common.less";
@import (reference) "../Css/_pm-global.less";

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
    background-color: @COLOR-TNL-GRAY5;
    color: @COLOR-TNL-GRAY2;
    text-shadow: none;
}

.nav-user {

    > .dropdown > a > img {
        margin-left: 10px;
    }

    .dropdown-menu {
        min-width: 220px;
    }
}

.navbar-nav > li > .dropdown-menu {
    .LESS-ROUNDED-CORNERS();
}
