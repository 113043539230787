@import (reference) "../Theme/Common/_common.less";


.tnl-less-ellipsis-one-line {
	.LESS-ELLIPSIS-ONE-LINE();
}

//TODO: Why is this called tnl-ellipsis when it in no way ellipsis anything?
.tnl-ellipsis,
.tnl-ellipsis-grid {
    position: relative;
    margin-right: 15px;
    
    a {
        position: absolute;
        top: 0;
        right: -16px;
        z-index: 100;
        text-decoration: none;
        .COS-OPEN-SANS-600();
    }

    p {
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
    }

    .popover {
        min-width: 300px;
    }

    .popover-content {
        max-height: 400px;
        overflow: auto;

        p {
            white-space: normal;
            overflow: visible;
        }
    }

    .tnl-ellipsis-expander {
        display: inline-block;
        cursor: pointer;
    }

}