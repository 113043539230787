@import (reference) "_pm-global.less";
// === PM PD Dashboard Styles === //
// == 11/10/2017 - Chris Brosky == //

.pm-pd-user-dashboard {
	padding: 10px;

	.pm-tab-container {
		background-color: @COLOR-PM-WHITE1 !important;
	}

	.pm-pd-user-dashboard-row {
		&.pm-create-plc {
			padding: 10px 0 20px;

			button {
				margin: 0;
			}
		}

		.tnl-table-container {
			padding-bottom: 0 !important;
			margin-bottom: 0 !important;
		}
	}

	.tnl-table {
		tbody {
			tr {
				td {
					&:nth-child(2) a span {
						font-family: 'Open_Sans_Semibold', sans-serif;
					}

					&:nth-child(4) {
						.tnl-show-more {
							display: inline-block;
							width: 70%;
							position: relative;
							top: 10px;
							left: 4px;
							margin-bottom: 5px;
						}
					}

					.pm-icon-action-thumb-up {
						font-size: @COS-FONT-SIZE-22;
						width:30px;
						height: 30px;
						color: @COLOR-PM-GREEN4;
						display: inline-block;
						float: left;
						position: relative;
						top: 3px;
					}
				}
			}
		}
	}

	.pm-pd-playist-options {
		margin-bottom: 20px;
		height: 34px;

		div:first-child {
			height: 100%;
		}

		.pm-pd-playlist-count {
			position: absolute;
			top: 50%;
			margin-top: -10px;
		}

		select {
		  max-width: 124px;
		}
	}

	.pm-teaser-container {
		.pm-icon-info-in-progress-circle {
			color: @COLOR-PM-FUSCHIA1;
		}

		.pm-icon-info-complete2 {
			color: @COLOR-PM-GREEN4;
		}
	}

	.pm-pd-legend-row {
		.pm-icon-sm {
			position: relative;
			top: 6px;
			margin-right: 5px;
		}
	}

	.pm-pagination-row {
		margin: 15px 0;

		.pm-pagination {
			margin: 0;
		}
	}
}

.pm-pages {
    float:left;
    margin: 25px 0 0 15px;
}

.pm-results-per-page {
    float:right;
    margin: 6px 15px 0 0;
}
