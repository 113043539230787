@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";


.jumbotron {
    border: 1px solid @COLOR-TNL-GRAY4;
    border-radius: 12px;
    
    h1 {
        color: @COLOR-TNL-ORANGE1;
    }

}