@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

#tnl-competency-structure-content {
    .panel-title {
        display: inline;
    }
}

.tnl-competency-container {
    border-width: 1px;
    border-bottom-width: 10px;
    border-top-width: 10px;
    margin-bottom: 10px;
    border-radius: 1px 1px 0 0;
}

.tnl-competency-framework-container {
    margin-bottom: 20px;
    border: 2px solid transparent;
}

.tnl-competency-framework {
    width: 100%;
    background: #fff;
    padding-bottom: 12px;

    .collapse .in {
        border-top: 1px solid @COLOR-TNL-GRAY4;
    }
        
    .tnl-competency-framework-header.tnl-collapse-trigger-header {
        cursor: pointer;
    }

    .tnl-competency-framework-header {
        background-color: @COLOR-TNL-GRAY6;
        padding: 15px 20px;

        .icon-font {
            font-size: 18px;
        }
        .form-group {
            margin: 0;
        }
        .strike-through {
            color: rgb(204, 204, 204);
            text-decoration: line-through;
        }
        h1 {
            color: @COLOR-PM-GRAY-DARK; //@COLOR-TNL-GRAY1; 
            font-size: @FONT-SIZE-XLG;
            font-weight: normal; 
            margin: 0;

            .tnlf-collapse-trigger-icon {
                cursor: pointer;
            }

            .label {
                font-size: 60%;
            }
        }
        h2, h3, h4, h5, h6 {
            color: @COLOR-TNL-GRAY1;
            font-size: @FONT-SIZE-LG;
            font-weight: normal;

            small {
                color: @COLOR-TNL-GRAY1;
            }
            .label {
                font-size: 60%;
            }
        }
        p {
            color: @COLOR-TNL-GRAY2;
            font-size: @FONT-SIZE-SM;
            margin: 0;
        }

        .pm-link-btn {
            margin-top: 0;
            .PM-TRANSITION();

            &.pm-btn-lg {
                padding-left: 0;

                &:hover {
                    .tnl-menu-text {
                        color: @COLOR-PM-ORANGE1 !important;
                        .PM-TRANSITION();
                    }
                }
            }
        }

        @media (max-width: @BOOTSTRAP-SM) {
            padding: 10px;
        }
    }

    .tnl-competency-level {
        background-color: #fff;
        padding: 0 20px;
        margin: 45px 0;

        header {
            h2 {
                margin-bottom: 0;

                .badge {
                    position: relative;
                    top: -2px;
                    padding: 3px 10px;
                }
            }
        }

        > table {
            width: 100%;
            table-layout: fixed;
        }

        > table > tbody > tr {
            > td {
                padding: 10px 0;

            }

            &:last-child {
                > td {
                    border-bottom: none;
                }
            }
        }

        .tnl-competency-framework-header {
            background-color: transparent;
            border-bottom: none;
            margin: 0;
            padding: 0;

            #wizardIllustration & .badge {
                background-color: @COLOR-PM-ORANGE1;
            }

            .tnl-toolbar {
                border-top: 1px dashed @COLOR-TNL-GRAY4;
                padding-top: 10px;
            }
            @media (max-width: @BOOTSTRAP-SM) {
                padding: 5px 0;
            } 
            & > table{
                width: 100%
            }           

        }

        @media (max-width: @BOOTSTRAP-SM) {
            padding: 0 10px;
        }  
    }

    .tnl-label-value-collection {
        margin: 0 0 20px 0;
    }

}

&.tnl-competency-framework-preview {
    h1,
    h2,
    p,
    th,
    td {
        font-size: @FONT-SIZE-XSM !important;
    }
    .tnl-scale-table tbody {
        display: none;
    }
    .tnl-competency-framework-container {
        padding-left: 2px;
    }
}
    
&.customize {

    .tnl-competency-field {
        border: 1px dashed @COLOR-TNL-GRAY4;
        margin-bottom: 20px;
        padding: 20px;

        .tnl-toolbar {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 20px;
        }
        &:hover {
            background: #fff;
            border-style: solid;
            .LESS-BOX-SHADOW();
        }
    }
}

.tnl-competency-charts {
    margin: 0 20px;

    .tnl-collapse {
        margin-top: 20px;

        .panel .panel-heading {
            padding: 5px 10px;

            > .panel-title > a {
                font-size: @FONT-SIZE-SM;
            }
        }
    }

}

.tnl-evidence {

    .has-error {
        .tnl-evidence-required {
            color: @COLOR-REQUIRED;
        }
    }

    .tnl-evidence-required {
        color: @COLOR-TNL-GRAY3;
        font-size: @FONT-SIZE-XSM;
        font-weight: normal;
        padding: 0 0 5px;
    }
}

.tnl-scale-container {
    margin-bottom: 5px;

    &:last-child {
        margin-bottom: 0;
    }

    h1 {
        font-size: @FONT-SIZE-DEFAULT;
        margin-bottom: 10px;
    }

    .tnl-rating-required {
        color: @COLOR-TNL-GRAY3;
        font-size: @FONT-SIZE-XSM;
        font-weight: normal;
        padding: 0 0 5px;
    }
    .tnl-scale {
        background-color: #fff;

        .tnl-scale-table {
            width: 99%;
        
            @media (max-width: 1024px) {
                display: block;
            }
            @media print {
                display: table !important;
            }
            
            tbody {
                @media (max-width: 1024px) {
                    display: block;
                }
                @media print {
                    display: block !important;
                }
            }
            tr {
                @media (max-width: 1024px) {
                    display: block;
                }
                @media print {
                    display: table-row !important;
                }
            }
            td {
                border: 1px solid @COLOR-TNL-GRAY4;
                font-size: @FONT-SIZE-XSM;
                padding: 10px;
                vertical-align: top;

                header {
                    margin: 5px 0 10px 0;

                    h1 {
                        color: @COLOR-TNL-GRAY1;
                        font-size: @FONT-SIZE-DEFAULT;

                        .icon-font {
                            color: @COLOR-TNL-GRAY1;
                            font-size: 18px;
                            margin-right: 4px;
                        }
                        span {
                            vertical-align: middle;
                        }
                        @media print {
                            font-size: 9pt !important;
                        }
                    }
                }
                label {
                    font-size: @FONT-SIZE-XSM;
                }
                textarea {
                    margin-bottom: 10px;
                    
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                .tnl-scale-textarea {
                    padding-right: 0;
                }
                .tnl-scale-textarea-delete {
                    padding-left: 0;
                }
                .tnl-scale-text {
                    display: none;

                    @media (max-width: 1024px) {
                        margin: 0 10px;
                    }
                }
                &.selected {
                    background-color: @COLOR-PM-YELLOW3;
                    border: 2px solid #000;
                }
                &.deselected {
                    div,
                    h1, h2, h3, h4, h5, h6,
                    header,
                    input,
                    label,
                    p {
                        color: #67696b !important;
                    }

                    .icon-font.disabled {
                        color: #67696b !important;
                    }
                }

                .icon-font.disabled {
                    color: #67696b !important;
                }
                .tnl-scale-text-split {
                    font-size: @FONT-SIZE-SM;

                    ul {
                        margin: 0;
                        padding-left: 20px;
                    }
                    label {
                        margin-bottom: 0;
                    }
                    div.checkbox {
                        border: 1px solid transparent;
                        margin-top: 0;
                        margin-bottom: 0;
                        padding: 5px;
                        .LESS-ROUNDED-CORNERS();
                    }
                }
                @media (max-width: 1024px) {
                    display: block;
                    padding: 5px;

                    &:not(:last-child) {
                        border-bottom-width: 0;
                    }
                }
                @media print {
                    display: table-cell !important;

                    &:not(:last-child) {
                        border-bottom-width: 1px !important;
                    }

                    p, li {
                        font-size: 9pt !important;
                    }
                }
            }           
        
            .tnl-scale-met,
            .tnl-scale-points { // TODO: Reconsider location of this def. Prototype use only.
                display: none;
            }
        }

        &.has-error {

            .tnl-scale-table td {
                border-color: @COLOR-REQUIRED;
            }
            .tnl-rating-required {
                color: @COLOR-REQUIRED;
            }
        }
    }
    .tnl-scale:not(.readonly) {
        .tnl-scale-table {
            td {
                cursor: pointer;

                &:hover {
                    &:not(.selected) {
                        background-color: @COLOR-PM-YELLOW3; 
                    }
                }
                .tnl-scale-text-split {
                    div.checkbox {
                        &:hover {
                            background: rgba(0, 0, 0, .05);
                            cursor: pointer;
                        }
                    }
                    .checkbox label {
                        cursor: text;
                    }
                }
            }
        }
    }

    .tnl-scale.readonly {
    .tnl-scale-table {
        td {
            .tnl-scale-text-split {
                .checkbox label {
                    cursor: text;
                }
            }
        }
    }
    }
}