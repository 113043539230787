@import (reference) "../Theme/Common/_common.less";

//This Css rule is to fix the tooltip flickering issue:
//https://github.com/google/google-visualization-issues/issues/2162 https://github.com/google/google-visualization-issues/issues/2162
//Remove this line if future google chart release fixes this issue.
svg > g > g:last-child { pointer-events: none }

.tnl-chart-container {
    position: relative;
    text-align: center;

    .tnl-legend {
        margin-top: 15px;
    }
    .tnl-chart-doughnut-text-container {
        position: relative;
        text-align: center;

        .tnl-chart-doughnut-text {
            bottom: 0;
            left: 0;
            line-height: 15px;
            position: absolute;
            right: 0;
            text-align: center;
            top: 0;
            z-index: 1;
        }
    }
}


  .chart-legend-element { 
      list-style: none; padding-bottom:16px;margin-top:4px; 

  }

  .chart-legend-element li { 
      float: left; margin-right: 10px; 

  }

  .chart-legend-element dt { 
      border: 1px solid #b8b8b8; 
      float: left; 
      width: 16px;
      height: 16px; 
      margin: 2px; 
      margin-top:4px; 
      border-radius: 14px }
  
  .chart-legend-element dd { 
      float: left; margin: 2px; 
  }


  //TODO Add new colors to _common.less
  .chart-legend-element {
      
      .chartcolor7 {
            background-color: @COLOR-PM-GREEN; 

      }
        .chartcolor6 { 
            background-color: @COLOR-PM-AQUA; 

        }
        .chartcolor5 { 
            background-color: @COLOR-PM-TURQUOISE; 

        }
        .chartcolor4 { 
            background-color:@COLOR-PM-BLUE; 

        }
        .chartcolor3 { 
            background-color: @COLOR-PM-GRAY; 

        }
        .chartcolor2 { 
            background-color: @COLOR-PM-ORANGE; 
        }
        .chartcolor1 { 
            background-color: @COLOR-PM-RED; 

        }    
}

th.chart-headers {
            width:1067px;
            padding-left:100px !important;
            padding-right:100px !important
}

.chart-date {
            float:left; 
            width:16.6%;  
            text-align:center;
            margin:0;
            overflow:hidden
}

.chart-date > div {
        font-weight:normal;
        font-size: small;
}