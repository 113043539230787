/* */
// This file is reserved for common .LESS variables and mixins.
@import (reference) "../../Css/_pm-global.less";

@BOOTSTRAP-SM: 768px;
@BOOTSTRAP-MD: 992px;
@BOOTSTRAP-LG: 1200px;
@BUTTON-TEXT-SIZE: 14px;
@BORDER-RADIUS: 4px;

@COLOR-LINK: @COLOR-PM-BLUE ;
@COLOR-LINK-VISITED: @COLOR-PM-BLUE-DARK ;
@COLOR-LINK-HOVER: @COLOR-PM-ORANGE;

// PM Tabs Module Colors
@COLOR-PM-MODULE-PD: @COLOR-PM-BLUE1 ;
@COLOR-PM-MODULE-BADGING: @COLOR-PM-BLUE1 ;
@COLOR-PM-MODULE-PROGRAMS: @COLOR-PM-BLUE1 ;

// PM colors.
@COLOR-PM-GREEN: #A8AD00 ;
@COLOR-PM-GREEN-DARK: #5E6000 ;
@COLOR-PM-GREEN-LIGHT: #d7d98a ;
@COLOR-PM-BLUE: #2857AF ;
@COLOR-PM-BLUE1: #165fab ;
@COLOR-PM-BLUE-DARK: #142c58 ;
@COLOR-PM-BLUE-LIGHT: #def3fa ;
@COLOR-PM-BLUE-MED: #188fc7 ;
@COLOR-PM-AQUA: #40C1AC ;
@COLOR-PM-TURQUOISE-LIGHT: #AECDFF ;
@COLOR-PM-TURQUOISE: #5BC2E7 ;
@COLOR-PM-TURQUOISE-DARK : #326b80 ;
@COLOR-PM-TURQUOISE-LIGHT: #def2fa ;
@COLOR-PM-GRAY: #808285 ;
@COLOR-PM-GRAY-LIGHT: #C7C8C9 ;
@COLOR-PM-GRAY-MED:  lighten( @COLOR-PM-GRAY, 10%) ; //#9a9c9e
@COLOR-PM-GRAY-MDDARK: darken( @COLOR-PM-GRAY, 10%) ;  //#28292a
@COLOR-PM-GRAY-DARK: darken( @COLOR-PM-GRAY, 35%) ;  //#28292a
@COLOR-PM-ORANGE: #DD7C00 ;
@COLOR-PM-ORANGE-DARK: darken( @COLOR-PM-ORANGE, 10%) ;
@COLOR-PM-ORANGE-LIGHT: #f8e5cc ;
@COLOR-PM-RED: #bb2127 ;
@COLOR-PM-RED-DARK: #671118 ;
@COLOR-PM-PURPLE-LIGHT: #8F72C0 ;
@COLOR-PM-PURPLE: #8560a8 ;
@COLOR-PM-PURPLE1: #8340e8;
@COLOR-PM-PURPLE-DARK: #62447f ;
@COLOR-PM-WHITE1: #fff;
@COLOR-PM-YELLOW: #ff8a00 ;
@COLOR-PM-YELLOW1: #ffbe22 ;

// TNL colors.
@COLOR-TNL-BLUE-DARK: @COLOR-PM-BLUE-DARK ;
@COLOR-TNL-BLUE1: @COLOR-PM-BLUE ;
@COLOR-TNL-BLUE2: lighten( @COLOR-PM-BLUE-MED, 40%); //#91b4ce ;
@COLOR-TNL-BLUE3: @COLOR-PM-TURQUOISE ; //#a5c5dd
@COLOR-TNL-BLUE4: @COLOR-PM-BLUE-LIGHT ;
@COLOR-TNL-GREEN-DARK: @COLOR-PM-GREEN-DARK ;
@COLOR-TNL-GREEN1: @COLOR-PM-GREEN ;
@COLOR-TNL-GREEN2: @COLOR-PM-GREEN ; //#90af2b;
@COLOR-TNL-GREEN3: @COLOR-PM-GREEN-LIGHT ;
@COLOR-TNL-ORANGE-DARK: @COLOR-PM-ORANGE-DARK ;
@COLOR-TNL-GREEN-HIGHLIGHT : #A6C14B ;
@COLOR-TNL-ORANGE1: @COLOR-PM-ORANGE ;
@COLOR-TNL-ORANGE-HIGHLIGHT : #F97313 ;
@COLOR-TNL-GRAY1: @COLOR-PM-GRAY-DARK ;
@COLOR-TNL-GRAY2: @COLOR-PM-GRAY-MDDARK ;
@COLOR-TNL-GRAY3: @COLOR-PM-GRAY ;
@COLOR-TNL-GRAY4: #bbb ;
@COLOR-TNL-GRAY5: #eee ;
@COLOR-TNL-GRAY6: #fafafa ;
@COLOR-TNL-PURPLE: @COLOR-PM-PURPLE ;
@COLOR-TNL-PURPLE-DARK: @COLOR-PM-PURPLE-DARK ;
@COLOR-TNL-YELLOW-HIGHLIGHT : @COLOR-PM-YELLOW ;

// Common colors.
@COLOR-COMPLETE: @COLOR-PM-GREEN ;

@COLOR-DANGER: @COLOR-PM-RED-DARK ;
@COLOR-DANGER-LT: lighten( @COLOR-DANGER, 50%);
@COLOR-DANGER-DK: darken( @COLOR-DANGER, 20%);

@COLOR-INFO: @COLOR-PM-BLUE-MED ; //@COLOR-TNL-BLUE2 ;
@COLOR-INFO-LT: lighten( @COLOR-INFO, 50%);
@COLOR-INFO-DK: darken( @COLOR-INFO, 20%);

@COLOR-PRIMARY: @COLOR-PM-BLUE ;
@COLOR-PRIMARY-LT: lighten( @COLOR-PRIMARY, 50%);
@COLOR-PRIMARY-DK: darken( @COLOR-PRIMARY, 20%);

@COLOR-RECOMMENDED: @COLOR-PM-ORANGE ;

@COLOR-REQUIRED: @COLOR-PM-RED; //hsl(0, 100%, 40%) ;
@COLOR-REQUIRED-LT: lighten( @COLOR-REQUIRED, 50%);
@COLOR-REQUIRED-DK: darken( @COLOR-REQUIRED, 20%);

@COLOR-SUCCESS: @COLOR-PM-GREEN ;
@COLOR-SUCCESS-LT: @COLOR-PM-GREEN-LIGHT; //lighten( @COLOR-SUCCESS, 50%);
@COLOR-SUCCESS-DK: darken( @COLOR-SUCCESS, 20%);

@COLOR-WARNING: @COLOR-PM-ORANGE; //hsl(42, 95%, 57%) ;
@COLOR-WARNING-LT: @COLOR-PM-ORANGE-LIGHT ;
@COLOR-WARNING-DK: darken( @COLOR-WARNING, 10%);

// Fonts.
#Fonts {
    .SansSerif () {
        font-family: 'Open_Sans_Regular', sans-serif;
    }
    .Serif () {
        font-family: 'Open_Sans_Regular', sans-serif;
    }
}

@FONT-SIZE-XSM: 12px ;
@FONT-SIZE-SM: 14px ;
@FONT-SIZE-DEFAULT: 15px ;
@FONT-SIZE-MD: 16px ;
@FONT-SIZE-LG: 18px ;
@FONT-SIZE-XLG: 20px ;
@FONT-SIZE-XXLG: 24px ;

// Mixins
.LESS-ROUNDED-CORNERS () {
    -moz-border-radius: @BORDER-RADIUS;
    -webkit-border-radius: @BORDER-RADIUS;
    border-radius: @BORDER-RADIUS;
    border-collapse: separate;
}

.LESS-ROUNDED-CORNERS-RESET () {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

.LESS-GRADIENT-BG ( @top: #fff, @bottom: #f2f2f2, @fallback: url(/Images/tnl-bg-fallback.jpg) repeat-x bottom #fff ) {
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    background-color: #fff;
    background: @fallback;
    background-image: -webkit-gradient(linear, 0 0, 0 100, from( @top), to(@bottom)) ;
    background-image: -webkit-linear-gradient(top, @top , @bottom);
    background-image: -moz-linear-gradient(top, @top , @bottom);
    background-image: -ms-linear-gradient(top, @top , @bottom);
    background-image: -o-linear-gradient(top, @top , @bottom);
    background-image: linear-gradient(top, @top , @bottom);
}

.LESS-BOX-SHADOW ( @x: 0, @y: 0, @blur: 10px, @color: rgba(0,0,0,0.10)) {
    .LESS-BOX-SHADOW-RESET();
    -moz-box-shadow: @arguments;
    -webkit-box-shadow: @arguments;
    box-shadow: @arguments;
}

.LESS-BOX-SHADOW-INSET ( @x: 0, @y: 0, @blur: 4px, @color: rgba(0,0,0,0.25)) {
    .LESS-BOX-SHADOW-RESET();
    -moz-box-shadow: inset @arguments;
    -webkit-box-shadow: inset @arguments;
    box-shadow: inset @arguments;
}

.LESS-BOX-SHADOW-RESET() {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.LESS-BOX-SHADOW-INSET-RESET() {
    .LESS-BOX-SHADOW-RESET();
}

.LESS-TEXT-SHADOW ( @x: -1px, @y: 1px, @color: @COLOR-TNL-GRAY3) {
    text-shadow: @arguments;
}

.LESS-DISPLAY-INLINE-BLOCK() {
    display: inline-block;
    zoom: 1;
    *display: inline;
    _height: 1em;
}

.LESS-BOX() {
    padding: 20px;
    border: 1px solid @COLOR-TNL-GRAY4;
    .LESS-GRADIENT-BG();
    .LESS-BOX-SHADOW();
}

.LESS-ELLIPSIS-ONE-LINE( @width: auto) {
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: @width;
}
.LESS-ELLIPSIS-RESET() {
    overflow: visible;
    -ms-text-overflow: unset;
    -o-text-overflow: unset;
    text-overflow: unset;
    white-space: nowrap;
    width: auto;
}

.LESS-OPACITY ( @opacity) {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=@opacity)";
    opacity: @opacity;
}

@-moz-document url-prefix() {
    fieldset {
        display: table-cell;
    }
}
