@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

.tnl-nav-link-group .tnl-nav-link:hover{
    background:none !important;
    border:none !important;
}

.tnl-program {
    position: relative;
    margin: 0 0 25px 0;
    padding: 20px;
    border: 1px solid @COLOR-TNL-GRAY4;
    .LESS-BOX-SHADOW;
}

.tnl-program-group-head {
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
    margin: 0 0 10px;
    width: 100%;
}

.tnl-program-group-head-status {
    position: absolute;
    top: 22px;
    left: 20px;
    &.complete{
        color: @COLOR-TNL-GREEN1;
    }
}

.tnl-program-group-head-text {
    display: inline-block;
    margin: 0;
    padding: 0 0 0 25px;
    color: @COLOR-TNL-GRAY2;
    font-size: @FONT-SIZE-LG;
    font-weight: normal;
    line-height: 22px;
}

.tnl-program-group-head-stamp {
    display: inline-block;
    padding: 0 0 0 25px;
    color: @COLOR-TNL-GRAY3;
    font-size: @FONT-SIZE-XSM;
}

.tnl-program-group-head-actions {
    display: table-cell;
    vertical-align: top;
    text-align: right;
    white-space: nowrap;
}

.tnl-program-group-activity {
    position: relative;
    padding: 10px;
    border-top: 1px solid @COLOR-TNL-GRAY4;
}

.tnl-program-group-activity:last-child {
    border-bottom: 1px solid @COLOR-TNL-GRAY4;
}

.tnl-program-group-activity-content {
    position: relative;
    margin: 0 100px 0 0;
}

.tnl-program-group-activity-content-icons {
    position: absolute;
    top: 2px;
    left: 4px;
    &.complete{
        color: @COLOR-TNL-GREEN1;
    }
}

.tnl-program-group-activity-content-title {
    display: inline-block;
    margin-left: 30px;
}

span.tnl-program-group-activity-content-title {
    color: @COLOR-TNL-GRAY2;
}

.tnl-program-group-activity-content-desc,
.tnl-program-group-activity-content-owner {
    margin: 0 0 0 30px;
    padding: 0;
    color: @COLOR-TNL-GRAY3;
    font-size: @FONT-SIZE-XSM;
    .LESS-ELLIPSIS-ONE-LINE;
}

.tnl-program-group-activity-actions {
    position: absolute;
    top: 11px;
    right: 60px;
}

.tnl-program-group-head-multi {
    display: inline;
    margin: 0 10px;
    padding: 0 5px 0 0;
}

section.tnl-preview-program {
    margin-top: .5em;
}

.tnl-program-group-activity.complete .tnl-program-group-activity-content-title,
.tnl-program-group-activity.complete .tnl-program-group-activity-content-title:visited,
.tnl-program-group-activity.complete .tnl-program-group-activity-content-title:active,
.tnl-program-group-activity.complete .tnl-program-group-activity-content-desc,
.tnl-program-group-activity.complete .tnl-program-group-activity-content-owner {
    color: @COLOR-COMPLETE;
}

@media (max-width: 720px) {
    .tnl-program-group-head-text, 
    .tnl-program-group-head-actions, 
    .tnl-program-group-head-stamp {
        display: block;
    }
}

@media (max-width: 767px) {
    .pr-status-box {
        display:none;
     }
}

#TaskDetails {
    .tnl-task-detail-row {
        margin:12px 0 12px 0;
    }

    .tnl-task-detail-area {
        margin-top:14px;
    }
}

   
.pr-status-area {
    .pr-status-box {
        border-top: 6px dotted @COLOR-TNL-GRAY5;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        top: 50px;
        position: relative;
    }

    .pr-status {
        border-radius: 50% ; 
        border: 4px solid transparent ; 
        padding: 4px;
        width: 90px;
        height: 90px;
        vertical-align: middle;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4px;
    }

    .pr-status-active {
        border-color: @COLOR-PM-TURQUOISE ;
    }

    .tnl-pr-status-circle {
        width: 74px;
        height: 74px;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid @COLOR-TNL-GRAY4;
        text-align: center;
        vertical-align: middle;
        padding-top: 26px;
        margin-left: auto;
        margin-right: auto;
    }

    .prdraft {
        color: @COLOR-PM-GRAY;
        border-color: @COLOR-PM-GRAY;
    }
    .prtest {
        color: @COLOR-PM-ORANGE1;
        border-color: @COLOR-PM-ORANGE1;
    }
    .pr-status-active .prtest {
        color: #fff;
        background-color: @COLOR-PM-ORANGE1;
    }
    .practive {
        color: @COLOR-PM-BLUE;
        border-color: @COLOR-PM-BLUE;
    }
    .pr-status-active .practive {
        color: #fff;
        background-color: @COLOR-PM-BLUE;
    }  
    .prclosed {
        color: @COLOR-PM-GRAY;
        border-color: @COLOR-PM-GRAY;
    }
    .pr-status-active .prclosed,
    .pr-status-active .prdraft {
        color: #fff;
        background-color: @COLOR-PM-GRAY;
    }

    .pm-primary-btn {
        margin-right: 0;
    }
}

#ManageProgram {
    .tnl-prg-details-block {
        height: 250px;
    }
    .alert.alert-info {
        &.practive {
            background: @COLOR-PM-BLUE-LIGHT;
            border-color: @COLOR-PM-BLUE;
        }

        &.prdraft,
        &.prclosed {
            background:@COLOR-TNL-GRAY5;
            border-color:@COLOR-PM-GRAY;
        }

        &.prtest {
            background:@COLOR-PM-ORANGE-LIGHT;
            border-color:@COLOR-PM-ORANGE-DARK;
        }

    }
}
