@import (reference) "_pm-global.less";

.pm-loading-book {
    background-image: url(/images/loading/loading-book-50.gif);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: #fff;
    border: 1px solid @COLOR-TNL-GRAY4;
    .LESS-ROUNDED-CORNERS();
    height: 120px;
    margin: 10px;
}
.pm-loading-book-text {
    background-color: #fff;
    border: 1px solid @COLOR-TNL-GRAY4;
    .LESS-ROUNDED-CORNERS();
    margin: 10px;
    padding-bottom: 15px;
        
    .pm-book {
        background-image: url(/images/loading/loading-book-50.gif);
        background-repeat: no-repeat;
        height: 125px;
        width: 110px;
        position: relative;
        margin: 0 auto;
        
        &:after {
            content: "Loading";
            text-align: center;
            position: absolute;
            left: 29px;
            bottom: 0;
        }
    }

}
.pm-loading-dots {
    background-color: #fff;
    border: 1px solid @COLOR-TNL-GRAY4;
    .LESS-ROUNDED-CORNERS();
    margin: 10px;
    padding-bottom: 15px;
    position: relative;
    height:20px;
        
        .waiting-indicator {
            top: 11px;
            height: 20px;
            background-repeat: no-repeat;
        }
}