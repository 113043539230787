@import (reference) "../Theme/Common/_common.less";
@import (less) "bootstrap-datepicker.css";
@import (reference) "_pm-global.less";

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
    background: @COLOR-TNL-GREEN1 no-repeat;
    filter: none;
    color: #fff;
}

.datepicker table tr td.today {
    position: relative;
}

.datepicker table tr td.today:before {
    border-bottom: 7px solid #fff;
    border-left: 7px solid transparent;
    border-top-color: rgba(0, 0, 0, 0);
    bottom: 2px;
    content: "";
    display: inline-block;
    position: absolute;
    right: 2px;
}

.datepicker table tr td.today {
    position: relative;
}

.datepicker table tr td.today:before {
    border-bottom: 7px solid #fff;
    border-left: 7px solid transparent;
    border-top-color: rgba(0, 0, 0, 0);
    bottom: 2px;
    content: "";
    display: inline-block;
    position: absolute;
    right: 2px;
}

.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled] {
  background-color: @COLOR-TNL-GREEN-HIGHLIGHT;
}
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active {
  background-color: @COLOR-TNL-GREEN-HIGHLIGHT;
}
.datepicker table tr td.today:hover:hover {
  color: #fff;
}

.datepicker table tr td.range.today,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover {
    background: @COLOR-TNL-GREEN1;
    filter: none;
    color: #fff;
}

.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:hover:hover,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.disabled:hover:hover,
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today:hover.disabled,
.datepicker table tr td.range.today.disabled.disabled,
.datepicker table tr td.range.today.disabled:hover.disabled,
.datepicker table tr td.range.today[disabled],
.datepicker table tr td.range.today:hover[disabled],
.datepicker table tr td.range.today.disabled[disabled],
.datepicker table tr td.range.today.disabled:hover[disabled] {
  background-color: @COLOR-TNL-GREEN-HIGHLIGHT;
}
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active {
  background-color: @COLOR-TNL-GREEN-HIGHLIGHT;
}
.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
  background: #9e9e9e;
  filter: none;
  color: #fff;
  text-shadow: none;
}
.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background: @COLOR-TNL-ORANGE1;
  color: #fff;
}
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled] {
  background-color: @COLOR-TNL-ORANGE-HIGHLIGHT;
}
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active {
  background-color: @COLOR-PM-HEADER2;
}

.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
  background-color: @COLOR-TNL-ORANGE-HIGHLIGHT;
}

.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active {
  background-color: @COLOR-TNL-ORANGE-HIGHLIGHT;
}

.datepicker.dropdown-menu th,
.datepicker.dropdown-menu td {
  padding: 4px 8px;
}

.bootstrap-datetimepicker-widget {
    .glyphicon {
        font-family: 'pm-font-icons' !important;
    }

    .glyphicon-time:before {
        content: "\e936"!important;
        font-size: 20px!important;
        line-height: 0;
    }

    .glyphicon-calendar:before {
        content: "\e912"!important;
        font-size: 20px!important;
        line-height: 0;
    }

    .glyphicon-chevron-up:before {
        content: "\e928"!important;
        font-size: 20px!important;
    }

    .glyphicon-chevron-down:before {
        content: "\e929"!important;
        font-size: 20px!important;
    }

    .glyphicon-chevron-left:before {
        content: "\e91d"!important;
        font-size: 20px!important;
    }

    .glyphicon-chevron-right:before {
        content: "\e91b"!important;
        font-size: 20px!important;
    }

    a.btn {
        color: @COLOR-PM-BLUE1!important;
    }

    button.btn.btn-primary {
        background: @COLOR-PM-HEADER2!important;
    }

    .dow {
        border-bottom: thin solid #CCC!important;
    }

    .datepicker table tr td.today {
        background: @COLOR-PM-GREEN1 no-repeat;
    }

    .picker-switch td span {
        line-height:37px!important;
        height: 30px!important;
    }

} 

