@import (reference) "_pm-global.less";

.badge {
  background-color: @COLOR-PM-ORANGE1;
  color: @COLOR-PM-WHITE1;
  margin: 0 5px 0 0;
  .COS-OPEN-SANS-600();

  &.new {
      background-color: @COLOR-PM-GREEN1;
      cursor: pointer;
  }

  &.badge-results{
      color: @COLOR-PM-GREY5;
      background-color: @COLOR-PM-WHITE1;
  }
  &.badge-default {
      background-color: @COLOR-PM-YELLOW2;
      color: @COLOR-PM-WHITE1;
  }
  &.badge-primary {
      background-color: @COLOR-PM-BLUE2;
      color: @COLOR-PM-WHITE1;
  }
  &.badge-success {
      background-color: @COLOR-PM-GREEN4;
      color: @COLOR-PM-WHITE1;
  }
  &.badge-info {
      background-color: @COLOR-PM-BLUE7;
      color: @COLOR-PM-BLUE1;
  }
  &.badge-warning {
      background-color: @COLOR-PM-BROWN1;
      color: @COLOR-PM-WHITE1;
  }
  &.badge-danger {
      background-color: @COLOR-PM-RED1;
      color: @COLOR-PM-WHITE1;
  }
  &.badge-link {
      background-color: transparent;
      color: @COLOR-COS-DEFAULT;
  }

  #wizardIllustration & {
      color: @COLOR-COS-DEFAULT;
      background-color: @COLOR-PM-GREY5;
  }
}

.pm-badge-approve {
  .pm-attachment-link {
    a {
      span {
        top: -1px !important;
      }
    }
  }
}

.pm-create-badge-btn {
  .btn-group {
    float: right;
    margin-bottom: 25px;
  }
}

#wizardIllustration {

  .badge-info {
    color: @COLOR-PM-WHITE1;
    background-color: @COLOR-PM-LINK;
  }
  .badge-success {
    color: @COLOR-PM-WHITE1;
    background-color: @COLOR-PM-GREEN4;
  }
}

.tnl-table-badge {
  background-color: @COLOR-PM-GREY7;
  color: @COLOR-PM-WHITE1;
  .COS-OPEN-SANS-REGULAR();
}

.pm-badge-div {
   .dropdown-menu {
     left: -46px;
   }
   
   .pm-badge-title, .pm-badge-creator {
        padding-bottom: 10px;
   }
   
   .pm-badge-delete-btn {
        margin-top:23px;
   }
}

.pmf-view-badge-library-page {
    .pm-dropdown-btn-default {
      margin-top: 15px;
      margin-right: 35px;
    }
}

.pm-badge-library-btn button {
    margin-bottom:10px;
}
.pm-margin-none-override {
  margin: 0!important;
}

.pm-badge-info-block-editing {
  max-width: 540px;
  padding-bottom: 10px;
}
.pm-badge-info-block-editing-border-none {
  margin-bottom: 15px;
  border-bottom: none;
  max-width: 540px;
}

.pm-badge-placeholder {
  width: 90px;
  height: 90px;
  background: #f2f2f2;
  position: relative;
  border: 40px solid #fff;
  margin: 20px auto;
  outline: 1px #a4aaba solid;
}

.pm-badge-not-added {
  border: thin solid rgb(164, 170, 186);
  background: rgb(235,239,242);
  padding: 41px 0;
  text-align: center;
  clear: both;

  .pm-badge-not-added-icon {
    color: #A4AABA;
  }
  .pm-badge-not-added-text {
    color: #A4AABA;
  }
}

.pm-badge-label {
  .COS-OPEN-SANS-600();

  &.pm-badge-label-align {
    margin-top: 7px;
  }
}

.pm-badges-earned {
  background-color: #F1F5F8;
  padding: 10px;
  margin-bottom: 10px;
}

.pm-badge-img {
  display: block;
  margin: 0 auto;
}

.pm-badge-name {
  padding: 5px 0 15px;
  display: block;
}

.pm-badge-image {
  padding-bottom: 10px;
}