@import (reference) "../Theme/Common/_common.less";

.markdown-input {
    height: 100px;
    min-width: 99%;
    max-width: 99%;
    width: 200px;
}

.markdown-wrapper {
    position: relative;
    margin-bottom: 10px;

    > a {
        position: absolute;
        top: 0;
        right: -5px;
        display: none;
    }

}

.markdown-editor {

    > textarea {
        height: 100px;
        width: 400px;
        min-width: 100%;
        max-width: 100%;
    }

    > div > div.markdown-preview {
        overflow: auto;
        max-height: 200px;
        max-width: 600px;
    }

}
