@import (reference) "_pm-global.less";

// === PM Section Header Styles === //
// == 9/29/2017 - Chris Brosky == //

.PM-SECTION-BOX-SHADOW() {
  box-shadow:         0 1px 4px 1px rgba(0,0,0,.25);
  -moz-box-shadow:    0 1px 4px 1px rgba(0,0,0,.25);
  -webkit-box-shadow: 0 1px 4px 1px rgba(0,0,0,.25);
  -ms-box-shadow:     0 1px 4px 1px rgba(0,0,0,.25);
  -o-box-shadow:      0 1px 4px 1px rgba(0,0,0,.25);
}

.pm-section-container {
  margin-bottom: 30px;

  .pm-section-block {
    &.pm-section-border {
      border: 1px solid @COLOR-PM-GREY8;
      border-top: none;
      box-shadow: 0 -5px 0 @COLOR-PM-BLUE5;
      margin-top: 5px;

      .pm-section-header {
        border-top: none;
      }
    }

    &.pm-section-box-shadow {
      .PM-SECTION-BOX-SHADOW();
    }

    .pm-section-header {
      position: relative;
      /* z-index: 2; */
      background-color: @COLOR-PS-GREY2;
      border-top: 5px solid @COLOR-PS-BLUE4;
      border-bottom: 1px solid @COLOR-PS-GREY1;
      margin-bottom: 0;
      padding: 0 20px;

      .pm-section-header-title {
        display: block;
        line-height: 4rem;
        font-size: 16px;
        color: @COLOR-COS-DEFAULT;
        .COS-OPEN-SANS-600();

        &.pm-width-70 {
          width: 70%;
        }

        &.pm-width-100 {
          width: 100%;
        }
      }

      .pm-section-header-btn,
      .pm-section-header-icon {
        display: inline-block;
        height: 3rem;
        position: absolute;
        top: 50%;
        margin-top: -15px;
        right: 20px;

        .pm-primary-btn {
          margin: 0;
        }
      }

      .pm-section-header-btn {
        height: 4rem;
        top: 0;
        margin-top: 0;

        .pm-primary-btn {
          position: relative;
          right: 0;
          top: 50%;
          margin-top: -15px;
          float: right;
        }

        .pm-btn-group {
          position: relative;
          right: 0;
          top: 50%;
          margin-top: -15px;
          margin-right: 0;
          float: right;

            &.pm-dropdown-btn-sm {
                margin-top: 0;
            }

          &.pm-split-action-btn,
          &.pm-icon-action-btns  {
            margin-top: -17.5px;
            margin-right: 0;
          }

          &.pm-icon-action-btns {
            .pm-action-btn {
              .pm-icon-sm {
                top: 5px !important;
              }
            }
          }

          &.pm-split-action-btn {
            margin-right: -20px;

            .pm-action-dropdown {
              margin-top: 5px;
            }
          }
        }

        &.pm-width-25 {
          width: 25%;
        }
      }

      // Color choices for Section Headers //
      &.gold1 {
        border-top-color: @COLOR-PM-GOLD1; /* #E49F19 */
      }

      &.blue1 {
        border-top-color: @COLOR-PM-BLUE1; /* #165fab */
      }

      &.blue2 {
        border-top-color: @COLOR-PS-BLUE4; /* #0083c4 */
      }

      &.pink2 {
        border-top-color: @COLOR-PM-PINK2; /* #fe407e */
      }

      &.teal1 {
        border-top-color: @COLOR-PM-TEAL1; /* #00baa2 */
      }

      &.purple1 {
        border-top-color: @COLOR-PM-PURPLE1; /* #8340e8*/
      }

      &.green4 {
        border-top-color: @COLOR-PM-GREEN4; /* #008f07 */
      }

      &.red1 {
        border-top-color: @COLOR-PM-RED1; /* #bb2127 */
      }
    }

    .pm-section-content {
        padding: 20px;
        background-color: #fff;
    }
  }
}

// --=== RESPONSIVE STYLES ===-- //
/* --== Screen widths between 992px and 1200px ==-- */
@media (min-width: @PM-BOOTSTRAP-MD) and (max-width: @PM-BOOTSTRAP-LG) {
  .pm-section-container {
    .pm-section-block {
      .pm-section-header {
        .pm-section-header-btn {
          width: 100%;
          height: 3rem;
          position: relative;
          left: 0;
          margin-top: 0;
          padding-bottom: 7px;

          .pm-primary-btn,
          .pm-btn-group {
            float: left;
          }

          .pm-btn-group {
            &.pm-split-action-btn {
              &.open {
                .pm-action-dropdown {
                  margin-top: 5px;

                  &.dropdown-menu-right {
                    right: initial;
                    left: auto;

                    &:before {
                      right: auto;
                      left: 8.2rem;
                    }

                    &:after {
                      right: auto;
                      left: 8.3rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: @PM-BOOTSTRAP-LG) {
  .pm-section-container {
    margin-bottom: 20px;
  }
}

//View Description rotate chevron//

.pm-rotate .pm-icon-ui-expand-chevron {
    -moz-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.pm-rotate.pm-down .pm-icon-ui-expand-chevron {
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}