/* TNL Icon Sprites */
.tnl-icon-sm,
.tnl-icon-md,
.tnl-icon-lg {
    overflow: hidden;
    display: inline-block;
}

.tnl-icon-sm {
    width: 16px;
    height: 16px;
    background: url('images/tnl-icons-small-ui-default.png') no-repeat 0 16px;
}

.ui-state-default .tnl-icon-sm {
    background-image: url(images/tnl-icons-small-ui-default.png);
}

.ui-state-hover .tnl-icon-sm,
.ui-state-focus .tnl-icon-sm {
    background-image: url(images/tnl-icons-small-ui-focus.png);
}

.ui-state-active .tnl-icon-sm {
    background-image: url(images/tnl-icons-small-ui-active.png);
}

.ui-state-highlight .tnl-icon-sm {
    background-image: url(images/tnl-icons-small-ui-highlight.png);
}

.ui-state-error .tnl-icon-sm,
.ui-state-error-text .tnl-icon-sm {
    background-image: url(images/tnl-icons-small-ui-error.png);
}